import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Actor } from '../models/jena/actor';
import { Helpers } from '../utilities/helpers';
import { Tool } from '../models/jena/tool';
import { Manus } from '../models/jena/manus';
import { Person } from '../models/jena/person';
import { ActorSparql } from '../sparql/actorSparql';
import { ToolSparql } from '../sparql/toolSparql';
import { ManusSparql } from '../sparql/manusSparql';
import { PersonSparql } from '../sparql/personSparql';
import { EntitySparql } from '../sparql/entitySparql';
import { GenericSubclass } from '../models/jena/genericClass';
import { GenericSparql } from '../sparql/genericSparql';
import { PersonRole } from '../models/jena/personRole';
import { PersonRoleSparql } from '../sparql/personRoleSparql';
import { Department } from '../models/jena/department';
import { DepartmentSparql } from '../sparql/departmentSparql';
import { Team } from '../models/jena/team';
import { TeamSparql } from '../sparql/teamSparql';
import { School } from '../models/jena/school';
import { SchoolSparql } from '../sparql/schoolSparql';
import { Institution } from '../models/jena/institution';
import { InstitutionSparql } from '../sparql/institutionSparql';
import { Faculty } from '../models/jena/faculty';
import { FacultySparql } from '../sparql/facultySparql';
import { Device } from '../models/jena/device';
import { DeviceSparql } from '../sparql/deviceSparql';
import { ModelHelper } from '../utilities/modelHelper';
import { Manuscript } from '../models/jena/manuscript';
import { ManuscriptSparql } from '../sparql/manuscriptSparql';
import { InitialSparql } from '../sparql/initialSparql';
import { WindowArea } from '../models/enums/windowArea';
import { EntityRoot } from '../models/jena/entityRoot';
import { AuxiliaryRoot } from '../models/jena/auxiliaryRoot';
import { AuxiliarySparql } from '../sparql/auxiliarySparql';
import { AuxiliaryArea } from '../models/enums/auxiliaryArea';
import { AnnotationSparql } from '../sparql/annotationSparql';
import { Word } from '../models/word';
import { Sentence } from '../models/sentence';
import { Triplet } from '../models/jena/triplet';
import {supportsPassiveEventListeners} from "@angular/cdk/platform";

const PREFIXES = Helpers.PREFIXES;
@Injectable({
    providedIn: 'root'
})
export class FusekiService {
    private customHttpClient: HttpClient;
    private showConsoleLog = false;

    constructor(private http: HttpClient, private httpBackend: HttpBackend) {
        this.customHttpClient = new HttpClient(httpBackend);
    }

    routeParams: any;
    // webApiUrl = environment.webApiUrl;
    fusekiUrl = environment.fusekiUrl;
    fusekiUsername = environment.fusekiUsername;
    fusekiPassword = environment.fusekiPassword;
    headers: Headers | undefined;

    public query(datasetName: string, sparqlQuery: string, binder: (bindings: any) => any): Observable<any> {
        this.showConsoleLog ? console.log(sparqlQuery) : null;

        var params = new HttpParams().set('query', sparqlQuery);

        return this.http.post(this.fusekiUrl + `/${datasetName}/query`,
            params,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
            .pipe(
                map(
                    (result: any) => {
                        return binder(result.results.bindings);
                    }
                )
            );
    }

    public query2(datasetName: string, sparqlQuery: string): Observable<any> {
        this.showConsoleLog ? console.log(sparqlQuery) : null;

        var params = new HttpParams().set('query', sparqlQuery);

        return this.http.post(this.fusekiUrl + `/${datasetName}/query`,
            params,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }


    public update(datasetName: string, sparqlQuery: string): Observable<any> {
        this.showConsoleLog ? console.log(sparqlQuery) : null;

        var params = new HttpParams().set('update', sparqlQuery);

        console.log(sparqlQuery);

        return this.http.post(this.fusekiUrl + `/${datasetName}/`,
            params,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                }),
                responseType: 'text'
            });
    }

    // -------------- //
    // GET METHODS    //
    // -------------- //

    public getLocalFile(filename: string): Observable<Blob> {
        return this.http.get(`./assets/data/${filename}`, { responseType: 'blob' })
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public getDataset(datasetName: string): Observable<Blob> {
        return this.http.get(this.fusekiUrl + `/${datasetName}/data`, { responseType: 'blob' })
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }

                )
            );
    }

    public getDatasetFromFuseki(datasetName: string): Observable<Array<Triplet>> {
        var sparqlQuery = ManuscriptSparql.duplicateManuscript(datasetName);

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToManuscriptXML);
    }

    public getActors(datasetName: string): Observable<Array<Actor>> {
        var sparqlQuery = ActorSparql.getActors();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToActors);
    }

    public getTools(datasetName: string): Observable<Array<Tool>> {
        var sparqlQuery = ToolSparql.getTools();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToTools);
    }

    public getDevices(datasetName: string): Observable<Array<Device>> {
        var sparqlQuery = DeviceSparql.getDevices();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToDevices);
    }

    public getPersons(datasetName: string): Observable<Array<Person>> {
        var sparqlQuery = PersonSparql.getPersons();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToPersons);
    }

    public getDepartments(datasetName: string): Observable<Array<Department>> {
        var sparqlQuery = DepartmentSparql.getDepartments();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToDepartments);
    }

    public getFaculties(datasetName: string): Observable<Array<Faculty>> {
        var sparqlQuery = FacultySparql.getFaculties();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToFaculties);
    }

    public getInstitutions(datasetName: string): Observable<Array<Institution>> {
        var sparqlQuery = InstitutionSparql.getInstitutions();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToInstitutions);
    }

    public getSchools(datasetName: string): Observable<Array<School>> {
        var sparqlQuery = SchoolSparql.getSchools();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToSchools);
    }

    public getTeams(datasetName: string): Observable<Array<Team>> {
        var sparqlQuery = TeamSparql.getTeams();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToTeams);
    }

    public getManuses(datasetName: string): Observable<Array<Manus>> {
        var sparqlQuery = ManusSparql.getManuses();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToManuses);
    }

    public getManuscripts(datasetName: string): Observable<Array<Manuscript>> {
        var sparqlQuery = ManuscriptSparql.getManuscripts();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToManuscripts);
    }

    public getSubclasses(datasetName: string, className: string): Observable<Array<GenericSubclass>> {
        var sparqlQuery = GenericSparql.getSubclasses(className);

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToGenericSubclasses);
    }

    public getPersonRoles(datasetName: string): Observable<Array<PersonRole>> {
        var sparqlQuery = PersonRoleSparql.getPersonRoles();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToPersonRoles);
    }

    public getEntityContent(datasetName: string, windowArea: WindowArea): Observable<EntityRoot> {
        var sparqlQuery = EntitySparql.getEntityContent(windowArea);

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToEntityRoot);
    }

    public getAuxiliaryContent(datasetName: string, auxiliaryArea: AuxiliaryArea): Observable<AuxiliaryRoot> {
        var sparqlQuery = AuxiliarySparql.getAuxiliaryContent(auxiliaryArea);

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToAuxiliaryRoot);
    }

    public getAnnotatedContext(datasetName: string): Observable<any> {
        var sparqlQuery = AnnotationSparql.getAnnotatedContext();

        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToActors);
    }

    // -------------- //
    // UPLOAD METHODS //
    // -------------- //

    public uploadDatasetFile(datasetName: string, file: Blob): Observable<any> {
        return this.http.post(this.fusekiUrl + `/${datasetName}/data`,
            file,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/rdf+xml'
                })
            })
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public uploadDatasetTurtle(datasetName: string, file: Blob): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.post(this.fusekiUrl + `/${datasetName}/data`, formData);
    }

    public uploadDatasetFileAsJson(datasetName: string, file: Array<Triplet>): Observable<any> {
        return this.http.post(this.fusekiUrl + `/${datasetName}/data`,
            file,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            })
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // CREATE METHODS //
    // -------------- //

    public createDataset(datasetName: string, datasetType: string) {
        return this.customHttpClient.post(this.fusekiUrl + `/$/datasets?dbName=${datasetName}&dbType=${datasetType}`,
            null,
            {
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + btoa(`${this.fusekiUsername}:${this.fusekiPassword}`)
                })
            })
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public initializeManuscript(datasetName: string, side: string) {
        var sparqlQuery = InitialSparql.initializeManuscript(side);

        return this.update(datasetName, sparqlQuery);
    }

    public initializeAuxiliary(datasetName: string, column: string, auxiliaryArea: AuxiliaryArea) {
        var sparqlQuery = AuxiliarySparql.initializeAuxiliary(column, auxiliaryArea);

        return this.update(datasetName, sparqlQuery);
    }

    public addCharacter(datasetName: string, area: string, row: number, character: string, position: number | undefined, manus?: Manus, actor?: Actor): Observable<any> {
        if (character.length != 1)
            throw "Character must be a string of length 1."

        var sparqlQuery = EntitySparql.addCharacter(area, row ? row : 1, character, position ? position : 1, manus, actor);

        return this.update(datasetName, sparqlQuery);
    }

    public addAuxiliaryCharacter(datasetName: string, rowName: string, character: string, position: number | undefined): Observable<any> {
        if (character.length != 1)
            throw "Character must be a string of length 1."

        var sparqlQuery = AuxiliarySparql.addCharacter(rowName, character, position ? position : 1);

        return this.update(datasetName, sparqlQuery);
    }

    public addSpace(datasetName: string, area: string, row: number, position: number | undefined, manus?: Manus, actor?: Actor): Observable<any> {
        var sparqlQuery = EntitySparql.addSpace(area, row ? row : 1, position ? position : 1, manus, actor);

        return this.update(datasetName, sparqlQuery);
    }

    public addAuxiliarySpace(datasetName: string, rowName: string, position: number | undefined): Observable<any> {
        var sparqlQuery = AuxiliarySparql.addSpace(rowName, position ? position : 1);

        return this.update(datasetName, sparqlQuery);
    }

    public addRow(datasetName: string, area: string, rowPosition: number, columnPosition: number, windowArea: WindowArea): Observable<any> {
        var sparqlQuery = EntitySparql.addRow(area, rowPosition, columnPosition, windowArea);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteRow(datasetName: string, area: string, rowPosition: number, windowArea: WindowArea): Observable<any> {
        var sparqlQuery = EntitySparql.deleteRow(area, rowPosition, windowArea, true);

        return this.update(datasetName, sparqlQuery);
    }

    public addAuxiliaryRow(datasetName: string, strokeName: string, auxiliaryArea: AuxiliaryArea): Observable<any> {
        var sparqlQuery = AuxiliarySparql.addRow(strokeName, auxiliaryArea);

        return this.update(datasetName, sparqlQuery);
    }

    public addPages(datasetName: string, side: string): Observable<any> {
        var sparqlQuery = EntitySparql.addPages(side);

        return this.update(datasetName, sparqlQuery);
    }

    public createActor(datasetName: string, person: Person, personRole: PersonRole): Observable<any> {
        var sparqlQuery = ActorSparql.createActor(person, personRole);

        return this.update(datasetName, sparqlQuery);
    }

    public createTool(datasetName: string, genericSubclass: GenericSubclass): Observable<any> {
        var sparqlQuery = ToolSparql.createTool(genericSubclass);

        return this.update(datasetName, sparqlQuery);
    }

    public createDevice(datasetName: string, device: Device): Observable<any> {
        var sparqlQuery = DeviceSparql.createDevice(device);

        return this.update(datasetName, sparqlQuery);
    }

    public createManus(datasetName: string, actor: Actor, tool: Tool): Observable<any> {
        var sparqlQuery = ManusSparql.createManus(actor, tool);

        return this.update(datasetName, sparqlQuery);
    }

    public createManuscript(datasetName: string, manuscript: Manuscript, device: Device): Observable<any> {
        var sparqlQuery = ManuscriptSparql.createManuscript(manuscript, device);

        return this.update(datasetName, sparqlQuery);
    }

    public createPerson(datasetName: string, person: Person): Observable<any> {
        var sparqlQuery = PersonSparql.createPerson(person);

        return this.update(datasetName, sparqlQuery);
    }

    public createDepartment(datasetName: string, department: Department, institution: Institution, faculty: Faculty, school: School): Observable<any> {
        var sparqlQuery = DepartmentSparql.createDepartment(department, institution, faculty, school);

        return this.update(datasetName, sparqlQuery);
    }

    public createFaculty(datasetName: string, faculty: Faculty, institution: Institution, department: Department, school: School): Observable<any> {
        var sparqlQuery = FacultySparql.createFaculty(faculty, institution, department, school);

        return this.update(datasetName, sparqlQuery);
    }

    public createInstitution(datasetName: string, institution: Institution, genericSubclass: GenericSubclass): Observable<any> {
        var sparqlQuery = InstitutionSparql.createInstitution(institution, genericSubclass);

        return this.update(datasetName, sparqlQuery);
    }

    public createSchool(datasetName: string, school: School, institution: Institution): Observable<any> {
        var sparqlQuery = SchoolSparql.createSchool(school, institution);

        return this.update(datasetName, sparqlQuery);
    }

    public createTeam(datasetName: string, team: Team, faculty: Faculty, institution: Institution, department: Department, school: School): Observable<any> {
        var sparqlQuery = TeamSparql.createTeam(team, faculty, institution, department, school);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteAllWords(datasetName: string): Observable<any> {
        var sparqlQuery = AnnotationSparql.deleteAllWords();

        return this.update(datasetName, sparqlQuery);
    }

    public groupCharactersToWord(datasetName: string, word: Word, index: number): Observable<any> {
        var sparqlQuery = AnnotationSparql.groupCharactersToWord(word, index);

        return this.update(datasetName, sparqlQuery);
    }

    public groupWordsToSentence(datasetName: string, sentence: Sentence, index: number): Observable<any> {
        var sparqlQuery = AnnotationSparql.groupWordsToSentence(sentence, index);

        return this.update(datasetName, sparqlQuery);
    }

    // -------------- //
    // UPDATE METHODS //
    // -------------- //

    public updatePerson(datasetName: string, person: Person): Observable<any> {
        var sparqlQuery = PersonSparql.updatePerson(person);

        return this.update(datasetName, sparqlQuery);
    }

    public updateInstitution(datasetName: string, institution: Institution): Observable<any> {
        var sparqlQuery = InstitutionSparql.updateInstitution(institution);

        return this.update(datasetName, sparqlQuery);
    }

    public updateSchool(datasetName: string, school: School, institution: Institution): Observable<any> {
        var sparqlQuery = SchoolSparql.updateSchool(school, institution);

        return this.update(datasetName, sparqlQuery);
    }

    public updateDepartment(datasetName: string, department: Department, institution: Institution, faculty: Faculty, school: School): Observable<any> {
        var sparqlQuery = DepartmentSparql.updateDepartment(department, institution, faculty, school);

        return this.update(datasetName, sparqlQuery);
    }

    public updateFaculty(datasetName: string, faculty: Faculty, institution: Institution, department: Department, school: School): Observable<any> {
        var sparqlQuery = FacultySparql.updateFaculty(faculty, institution, department, school);

        return this.update(datasetName, sparqlQuery);
    }

    public updateTeam(datasetName: string, team: Team, faculty: Faculty, institution: Institution, department: Department, school: School): Observable<any> {
        var sparqlQuery = TeamSparql.updateTeam(team, faculty, institution, department, school);

        return this.update(datasetName, sparqlQuery);
    }

    // -------------- //
    // DELETE METHODS //
    // -------------- //

    public deleteDataset(datasetName: string) {
        return this.customHttpClient.delete(this.fusekiUrl + `/$/datasets/${datasetName}`,
            {
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + btoa(`${this.fusekiUsername}:${this.fusekiPassword}`)
                })
            })
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public deleteCharacter(datasetName: string, area: string, row: number, position: number, isBackspace: boolean, windowArea: WindowArea): Observable<any> {
        var sparqlQuery = EntitySparql.deleteCharacter(area, row, position, isBackspace, windowArea);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteAuxiliaryCharacter(datasetName: string, rowName: string, position: number, isBackspace: boolean): Observable<any> {
        var sparqlQuery = AuxiliarySparql.deleteCharacter(rowName, position, isBackspace);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteActor(datasetName: string, actor: Actor): Observable<any> {
        var sparqlQuery = ActorSparql.deleteActor(actor);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteManus(datasetName: string, manus: Manus): Observable<any> {
        var sparqlQuery = ManusSparql.deleteManus(manus);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteManuscript(datasetName: string, manuscript: Manuscript): Observable<any> {
        var sparqlQuery = ManuscriptSparql.deleteManuscript(manuscript);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteTool(datasetName: string, tool: Tool): Observable<any> {
        var sparqlQuery = ToolSparql.deleteTool(tool);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteDevice(datasetName: string, device: Device): Observable<any> {
        var sparqlQuery = DeviceSparql.deleteDevice(device);

        return this.update(datasetName, sparqlQuery);
    }

    public deletePerson(datasetName: string, person: Person): Observable<any> {
        var sparqlQuery = PersonSparql.deletePerson(person);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteDepartment(datasetName: string, department: Department): Observable<any> {
        var sparqlQuery = DepartmentSparql.deleteDepartment(department);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteFaculty(datasetName: string, faculty: Faculty): Observable<any> {
        var sparqlQuery = FacultySparql.deleteFaculty(faculty);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteInstitution(datasetName: string, institution: Institution): Observable<any> {
        var sparqlQuery = InstitutionSparql.deleteInstitution(institution);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteSchool(datasetName: string, school: School): Observable<any> {
        var sparqlQuery = SchoolSparql.deleteSchool(school);

        return this.update(datasetName, sparqlQuery);
    }

    public deleteTeam(datasetName: string, team: Team): Observable<any> {
        var sparqlQuery = TeamSparql.deleteTeam(team);

        return this.update(datasetName, sparqlQuery);
    }

    public getSentences(datasetName: string): Observable<any> {
        const sparqlQuery = AnnotationSparql.getSentences();
        return this.query(datasetName, sparqlQuery, ModelHelper.bindResultToSentences);
    }
}
