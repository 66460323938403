import { Device } from "./device";
import { Individual } from "./individual";

export class Manuscript extends Individual {
    genericName?: string;
    siglum?: string;
    dayExact?: number;
    monthExact?: number;
    yearExact?: string;
    dayApproximate?: number;
    monthApproximate?: number;
    yearApproximate?: string;
    aroundFrom?: string;
    aroundTo?: string;
    aroundBy?: string;
    device?: Device;
}
