import { Device } from "../models/jena/device";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class DeviceSparql {
    public static createDevice(device: Device): string {
        var sparql = PREFIXES +
        `
        INSERT {
            ?new a <${device.subclass?.name}>;
            ${device.genericName != null ? `${SH.genericNameProperty} "` + device.genericName + `";` : ''}
            ${device.siglum != null ? `${SH.siglumProperty} "` + device.siglum + `";` : ''}
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'${Helpers.getDeviceLabel(device.subclass?.label!)}',STR(MAX(?res) + 1))) as ?new)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{
                            ?z a <${device.subclass?.name}>.
                            FILTER (STRSTARTS(str(?z), str(ent:)))
                        }.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + '${Helpers.getToolLabel(device.subclass?.label!)}', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a <${device.subclass?.name}>.
                                FILTER (STRSTARTS(str(?s), str(ent:)))
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static getDevices(): string {
        var sparql = PREFIXES +
        `
        SELECT
        ?device
        ?genericName
        ?siglum
        ?subclass
        ?label
        WHERE {
            ?device a ?subclass.
            OPTIONAL{?device ${SH.genericNameProperty} ?genericName}.
            OPTIONAL{?device ${SH.siglumProperty} ?siglum}.
            ?subclass rdfs:subClassOf* ${SH.deviceClass};
                rdfs:label ?label.
        FILTER (langMatches(lang(?label), "en"))
        FILTER (?subclass != ${SH.deviceClass})
        FILTER (STRSTARTS(str(?device), str(ent:)))
        }
        ORDER by ?label
        `;

        return sparql;
    }

    public static deleteDevice(device: Device): string {
        var sparql = PREFIXES +
        `
        DELETE {
            ?x a <${device.subclass?.name}>
        }
        WHERE {
            ?x a <${device.subclass?.name}>
            FILTER(?x = IRI('${device.name}'))
        };
        `;

        return sparql;
    }
}
