<div class="container">
    <mat-tab-group class="container">
        <mat-tab class="container" label="Transcription and Annotation">
            <app-board class="container" [(entity)]="entity"></app-board>
        </mat-tab>
        <mat-tab class="container" label="Collation">
            <app-collation class="container"></app-collation>
        </mat-tab>
        <mat-tab class="container" label="Editing">
            <app-critical-editing class="container" [(entity)]="entity"></app-critical-editing>
        </mat-tab>
    </mat-tab-group>
</div>
