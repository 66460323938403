<block-ui></block-ui>
<h2 mat-dialog-title>{{ isLocked ? 'View Department' : (isEdit ? 'Update Department' : 'New Department') }} </h2>

<div mat-dialog-content class="content">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Name">
            <div class="tab-container">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Full name</mat-label>
                    <input matInput [(ngModel)]="department.fullName!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Short name</mat-label>
                    <input matInput [(ngModel)]="department.shortName!" [disabled]="isLocked">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Address">
            <div class="tab-container">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Street</mat-label>
                    <input matInput [(ngModel)]="department.street!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Street number</mat-label>
                    <input matInput [(ngModel)]="department.streetNumber!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>City</mat-label>
                    <input matInput [(ngModel)]="department.city!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>State</mat-label>
                    <input matInput [(ngModel)]="department.state!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Zip code</mat-label>
                    <input matInput [(ngModel)]="department.zipcode!" [disabled]="isLocked">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Member">
            <div class="tab-container">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Select institution:</mat-label>
                    <mat-select [(ngModel)]="selectedInstitution" [compareWith]="compareInstitutions" [disabled]="selectedFaculty != null || selectedSchool != null || isLocked">
                        <mat-option [value]="">(Select option...)</mat-option>
                        <mat-option *ngFor="let institution of institutions" [value]="institution">{{ getNameWithoutUri(institution.name!) }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>or Select faculty:</mat-label>
                    <mat-select [(ngModel)]="selectedFaculty" [compareWith]="compareFaculties" [disabled]="selectedInstitution != null || selectedSchool != null || isLocked">
                        <mat-option [value]="">(Select option...)</mat-option>
                        <mat-option *ngFor="let faculty of faculties" [value]="faculty">{{ getNameWithoutUri(faculty.name!) }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>or Select school:</mat-label>
                    <mat-select [(ngModel)]="selectedSchool" [compareWith]="compareSchools" [disabled]="selectedInstitution != null || selectedFaculty != null || isLocked">
                        <mat-option [value]="">(Select option...)</mat-option>
                        <mat-option *ngFor="let school of schools" [value]="school">{{ getNameWithoutUri(school.name!) }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()">Cancel</button>
</div>
