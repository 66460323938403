import { Actor } from "../models/jena/actor";
import { Person } from "../models/jena/person";
import { PersonRole } from "../models/jena/personRole";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class ActorSparql {
    public static getActors(): string {
        var sparql = PREFIXES +
        `
        SELECT ?actor ?person ?personRole ?label
        WHERE {
            ?actor a ${SH.actorClass};
                ${SH.genericallyDependsOnAtSomeTime} ?person;
  	            ${SH.isConcretizedByAtSomeTime} ?personRole.
            ?personRole ${SH.nameProperty} ?label.
        }
        ORDER BY ?actor
        `;

        return sparql;
    }

    public static createActor(person: Person, personRole: PersonRole): string {
        var sparql = PREFIXES +
        `
        INSERT {
            ?newActor a ${SH.actorClass};
    			${SH.genericallyDependsOnAtSomeTime} <${person.name}>;
    			${SH.isConcretizedByAtSomeTime} <${personRole.name}>.
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'act',STR(MAX(?res) + 1))) as ?newActor)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{?z a ${SH.actorClass}}.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + 'act', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.actorClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static deleteActor(actor: Actor): string {
        var sparql = PREFIXES +
        `
        DELETE { ?x ?y ?z }
        WHERE {
        ?x a ${SH.actorClass}.
        ?x ?y ?z.
        FILTER(?x = IRI("${actor.name}"))
        }
        `;

        return sparql;
    }
}
