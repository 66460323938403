import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'app-dialog-derivation',
    templateUrl: './dialog-derivation.component.html',
    styleUrls: ['./dialog-derivation.component.scss']
})
export class DialogDerivationComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogDerivationComponent>,) { }

    ngOnInit(): void {
    }

    public okClicked() {

    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }

}
