import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { NavItem } from 'src/app/models/navItem';
import { OwlItem } from 'src/app/models/owlItem';
import { OwlService } from 'src/app/services/owl.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogAddOwlItemComponent } from './dialog-add-owl-item/dialog-add-owl-item.component';
import { DialogEditOwlItemComponent } from './dialog-edit-owl-item/dialog-edit-owl-item.component';

/** Flat node with expandable and level information */
interface NavItemFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    node: NavItem
}

@Component({
    selector: 'app-manage-owl-items',
    templateUrl: './manage-owl-items.component.html',
    styleUrls: ['./manage-owl-items.component.scss']
})
export class ManageOwlItemsComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;
    private _transformer = (node: NavItem, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.displayName,
            level: level,
            node: node
        };
    };

    treeControl = new FlatTreeControl<NavItemFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    dataSource = new MatTreeFlatDataSource(
        this.treeControl, this.treeFlattener);

    public mouseOverNode!: NavItemFlatNode | null;

    constructor(private _owlService: OwlService,
        private _dialog: MatDialog,) {
            this.getOntologyTree();
    }

    private getOntologyTree() {
        this.blockUI.start();

        this._owlService.getOntologyTreeAsNavItems().subscribe(
            (result) => {
                this.dataSource.data = result;
                this.blockUI.stop();
            },
            (error) => {
                console.log(error);
                this.blockUI.stop();
            }
        );
    }

    hasChild = (_: number,
        node: NavItemFlatNode) => node.expandable;

    ngOnInit(): void { }

    public nodeClicked(node: NavItemFlatNode) {
        this.openEditDialog(node.node);
    }

    public addParent() {
        this.openAddDialog(null);
    }

    public addChild(node: NavItemFlatNode) {
        this.openAddDialog(node.node);
    }

    public deleteNode(node: NavItemFlatNode) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: 'Delete Owl Item: ' + node.name,
                infoMessage: 'Are you sure you want to delete this Owl Item ?'
            }
        });

        dialogRef.afterClosed().subscribe(
            (result) => {
                if (result === true) {
                    this.blockUI.start();

                    var temp = new OwlItem();
                    temp.id = node.node.id;

                    this._owlService.deleteOwlItem(temp).subscribe(
                        (innerResult) => {
                            this.blockUI.stop();

                            this.getOntologyTree();
                        },
                        (error) => {
                            console.log(error);
                            this.blockUI.stop();
                        }
                    );
                }
            }
        );
    }

    public openAddDialog(navItem: NavItem | null) {
        const dialogRef = this._dialog.open(DialogAddOwlItemComponent, {
            width: '480px',
            data: {
                navItem: navItem
            }
        });

        dialogRef.afterClosed().subscribe(
            (result: NavItem) => {
                if (Helpers.isNotNullOrUndefined(result)) {
                    var owlItem = new OwlItem();
                    owlItem.name = result.displayName;
                    owlItem.parentId = result.parentId;
                    owlItem.disabled = result.disabled;
                    owlItem.isRoot = result.isRoot;

                    this.blockUI.start();

                    this._owlService.addOwlItem(owlItem).subscribe(
                        (innerResult) => {
                            this.blockUI.stop();

                            this.getOntologyTree();
                        },
                        (error) => {
                            console.log(error);
                            this.blockUI.stop();
                        }
                    );
                }
            }
        );
    }

    public openEditDialog(navItem: NavItem) {
        const dialogRef = this._dialog.open(DialogEditOwlItemComponent, {
            width: '480px',
            data: {
                navItem: navItem
            }
        });

        dialogRef.afterClosed().subscribe(
            (result: NavItem) => {
                if (Helpers.isNotNullOrUndefined(result)) {
                    var owlItem = new OwlItem();
                    owlItem.id = result.id;
                    owlItem.disabled = result.disabled;
                    owlItem.isRoot = result.isRoot;

                    this.blockUI.start();

                    this._owlService.updateOwlItem(owlItem).subscribe(
                        (result) => {
                            this.blockUI.stop();

                            // this.getOntologyTree();
                        },
                        (error) => {
                            console.log(error);
                            this.blockUI.stop();
                        }
                    );
                }
            }
        );
    }

    public getNodeClass(navItem: NavItem) {
        if (navItem.disabled && navItem.isRoot) {
            return 'isRootAndDisabled';
        } else if (navItem.disabled) {
            return 'disabled'
        } else if (navItem.isRoot) {
            return 'isRoot'
        } else {
            return '';
        }
    }

    public isMouseOver(node: NavItemFlatNode): boolean {
        if (Helpers.isNotNullOrUndefined(this.mouseOverNode)) {
            return this.mouseOverNode!.name === node.name;
        }

        return false;
    }

    public onMouseOverNode(node: NavItemFlatNode) {
        this.mouseOverNode = node;
    }

    public onMouseOut() {
        this.mouseOverNode = null;
    }
}
