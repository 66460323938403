<block-ui></block-ui>
<h2 mat-dialog-title>Roles of user: {{keycloakUser.username}}</h2>

<div mat-dialog-content class="content">
    <div fxLayout="row">
        <mat-checkbox
        [(ngModel)]="administrator_role"
        (change)="onSelectedChange($event, 'administrator')">Administrator</mat-checkbox>
    </div>
    <div fxLayout="row">
        <mat-checkbox
        [(ngModel)]="basic_role"
        (change)="onSelectedChange($event, 'basic')">Basic</mat-checkbox>
    </div>
    <div fxLayout="row">
        <mat-checkbox
        [(ngModel)]="guest_role"
        (change)="onSelectedChange($event, 'guest')">Guest</mat-checkbox>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
</div>
