import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class InitialSparql {

    public static initializeManuscript(side: string): string {
        var pageOrRecto = '';
        var sideFront = '';
        var sideBack = ''

        if (side === '1') {
            sideFront = 'ent:page1';
            sideBack = 'ent:page2';
        } else if (side === '2') {
            sideFront = 'ent:r1';
            sideBack = 'ent:v2';
        }

        pageOrRecto = `
        ${sideFront} a ${side === '1' ? SH.pageClass : SH.rectoClass} ;
            ${SH.continuantPartOfAtAllTimes} ent:depleaf1 ;
            ${SH.orderProperty} 1 ;
            ${SH.absoluteOrderProperty} 1 .

        ${sideBack} a ${side === '1' ? SH.pageClass : SH.versoClass} ;
            ${SH.continuantPartOfAtAllTimes} ent:depleaf1 ;
            ${SH.orderProperty} 2 ;
            ${SH.absoluteOrderProperty} 2 .

        ent:main1 a ${SH.mainAreaClass} ;
            ${SH.locatedInAtSomeTime} ${sideFront} .

        ent:head1 a ${SH.headClass} ;
            ${SH.locatedInAtSomeTime} ${sideFront} .

        ent:foot1 a ${SH.footClass} ;
            ${SH.locatedInAtSomeTime} ${sideFront} .

        ent:inner1 a ${SH.innerMarginClass} ;
            ${SH.locatedInAtSomeTime} ${sideFront} .

        ent:outer1 a ${SH.outerMarginClass} ;
            ${SH.locatedInAtSomeTime} ${sideFront} .

        ent:interlinear1 a ${SH.interlinearSpaceClass} ;
            ${SH.locatedInAtSomeTime} ${sideFront} .

        ent:intercolumnar1 a ${SH.intercolumnarSpaceClass} ;
            ${SH.locatedInAtSomeTime} ${sideFront} .

        ent:main2 a ${SH.mainAreaClass} ;
            ${SH.locatedInAtSomeTime} ${sideBack} .

        ent:head2 a ${SH.headClass} ;
            ${SH.locatedInAtSomeTime} ${sideBack} .

        ent:foot2 a ${SH.footClass} ;
            ${SH.locatedInAtSomeTime} ${sideBack} .

        ent:inner2 a ${SH.innerMarginClass} ;
            ${SH.locatedInAtSomeTime} ${sideBack} .

        ent:outer2 a ${SH.outerMarginClass} ;
            ${SH.locatedInAtSomeTime} ${sideBack} .

        ent:interlinear2 a ${SH.interlinearSpaceClass} ;
            ${SH.locatedInAtSomeTime} ${sideBack} .

        ent:intercolumnar2 a ${SH.intercolumnarSpaceClass} ;
            ${SH.locatedInAtSomeTime} ${sideBack} .

        ent:row1 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:main1;
            ${SH.orderProperty} 1.
        ent:row2 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:head1;
            ${SH.orderProperty} 1.
        ent:row3 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:foot1;
            ${SH.orderProperty} 1.
        ent:row4 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:inner1;
            ${SH.orderProperty} 1.
        ent:row5 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:outer1;
            ${SH.orderProperty} 1.
        ent:row6 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:interlinear1;
            ${SH.orderProperty} 1.
        ent:row7 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:intercolumnar1;
            ${SH.orderProperty} 1.

        ent:row8 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:main2;
            ${SH.orderProperty} 1.
        ent:row9 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:head2;
            ${SH.orderProperty} 1.
        ent:row10 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:foot2;
            ${SH.orderProperty} 1.
        ent:row11 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:inner2;
            ${SH.orderProperty} 1.
        ent:row12 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:outer2;
            ${SH.orderProperty} 1.
        ent:row13 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:interlinear2;
            ${SH.orderProperty} 1.
        ent:row14 a ${SH.rowClass};
            ${SH.locatedInAtSomeTime} ent:intercolumnar2;
            ${SH.orderProperty} 1.
        `
        var sparql = PREFIXES +
        `
        INSERT {
            ent:depleaf1 a ${SH.dependentLeafClass} .

            ${pageOrRecto}
        }
        WHERE {};
        `;

        return sparql;
    }
}
