import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter, Inject,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ContextMenu } from 'src/app/models/contextMenu';
import { Entity, EntityTag } from 'src/app/models/entity';
import { EditorConfig, MainEditorConfig } from 'src/assets/data/editorConfig';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuxiliaryEditorComponent } from '../auxiliary-editor/auxiliary-editor.component';
import { EntityRoot } from 'src/app/models/jena/entityRoot';
import { AuxiliaryArea } from 'src/app/models/enums/auxiliaryArea';
import { DisplayCharacter } from 'src/app/models/display/displayCharacter';
import { AnnotationService } from 'src/app/services/annotation.service';
import { DelaEditorComponent } from '../dela-editor/dela-editor.component';
import { AnnotationColors } from '../../../models/annotationColors';
import { EntityService } from 'src/app/services/entity.service';
import {InitializationService} from "src/app/services/initialization.service";
import {concat, Observable, Subscription} from "rxjs";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import { BatchManager } from "../../../utilities/batchedCall";
import {MatDialog} from "@angular/material/dialog";
import {DialogAutoPunctuationComponent} from "../dialog-autopuntucation/dialog-auto-punctuation.component";

@Component({
    selector: 'app-board',
    templateUrl: './board.component.html',
    styleUrls: ['./board.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class BoardComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @BlockUI('board-container') blockUI!: NgBlockUI;

    @ViewChild('inRow') inRowAuxiliaryEditorComponent!: AuxiliaryEditorComponent;
    @ViewChild('over') overAuxiliaryEditorComponent!: AuxiliaryEditorComponent;
    @ViewChild('above') aboveAuxiliaryEditorComponent!: AuxiliaryEditorComponent;
    @ViewChild('below') belowAuxiliaryEditorComponent!: AuxiliaryEditorComponent;
    @ViewChild('mainEditor') mainEditorComponent!: DelaEditorComponent;
    @ViewChild('headerEditor') headerEditorComponent!: DelaEditorComponent;
    @ViewChild('footerEditor') footerEditorComponent!: DelaEditorComponent;
    @ViewChild('innerMarginEditor') innerMarginEditorComponent!: DelaEditorComponent;
    @ViewChild('outerMarginEditor') outerMarginEditorComponent!: DelaEditorComponent;
    @ViewChild('interlinearEditor') interlinearEditorComponent!: DelaEditorComponent;
    @ViewChild('intercolumnarEditor') intercolumnarEditorEditorComponent!: DelaEditorComponent;
    _entity!: Entity;

    get hasChanges() {
        return BatchManager.getInstance().hasQueued;
    }

    @Input()
    set entity(val: Entity) {
        this.entityChange.emit(val);
        this._entity = val;
    }
    get entity() {
        return this._entity;
    }
    @Output()
    entityChange: EventEmitter<Entity> = new EventEmitter<Entity>();
    private _editorLoads: Observable<EntityRoot | undefined>[] = [];

    public editorConfig: AngularEditorConfig = EditorConfig();
    public mainEditorConfig: AngularEditorConfig = MainEditorConfig();
    public headerHtmlContent: any;
    public footerHtmlContent: any;
    public innerMarginHtmlContent: any;
    public outerMarginHtmlContent: any;
    public mainHtmlContent!: string;
    public aboveHtmlContent: any;
    public belowHtmlContent: any;
    public overHtmlContent: any;
    public overallInfo: string = '';

    public isDisplayContextMenu!: boolean;
    public rightClickMenuItems: Array<ContextMenu> = [];
    public rightClickMenuPositionX!: number;
    public rightClickMenuPositionY!: number;

    public selectedEntityTags: Array<EntityTag>;
    public entityData!: EntityRoot;
    private _editorMode = "transcription";

    get editorMode(): string {
        return this._editorMode;
    }

    set editorMode(value: string) {
        if (this._editorMode != value) {
            this._editorMode = value;
            this.refreshAllEditors();
        }
    }

    private _annotationLevel = "word";
    set annotationLevel(value: string) {
        if (this._annotationLevel != value) {
            this._annotationLevel = value;
            this.refreshAllEditors();
        }
    }

    get annotationLevel(): string {
        return this._annotationLevel;
    }

    // public annotationLevel = "word";
    public annotationColors = new AnnotationColors();

    public existsManuscript = false;
    private _initializationServiceSub: Subscription | undefined;

    constructor(private _fusekiService: FusekiService,
        private _snackBar: MatSnackBar,
        public annotationService: AnnotationService,
        private _entityService: EntityService,
        private _dialog: MatDialog,
        private _initializationService: InitializationService) {
        this.selectedEntityTags = new Array<EntityTag>();
    }

    public ngOnInit(): void {
    }

    public ngOnDestroy() {
        if (this._initializationServiceSub)
            this._initializationServiceSub.unsubscribe();
    }

    public ngAfterViewInit(): void {
        this._initializationServiceSub = this._initializationService.initializationComplete.subscribe((component) => {
            if (component.datasetName != undefined) {
                this._editorLoads.push(component.startUpdate());
                if (this._editorLoads.length == 7) {
                    this.blockUI.start("Loading manuscript. Please wait patiently...");
                    concat(...this._editorLoads).subscribe({
                        next: (result) => {},
                        error: (error) => {
                            this.blockUI.stop();
                            throw error;
                        },
                        complete: () => {
                            this.blockUI.stop();
                        }
                    });
                }
            }
        });
    }

    public openAutopunctuationDialog() {
        const text = this.mainEditorComponent.getAutoPunctuatedText();

        this._dialog.open(DialogAutoPunctuationComponent, { data: { content: text }});
    }

    public ngOnChanges(): void {
        if (Helpers.isNotNullOrUndefined(this._entity.id)) {
            this._fusekiService.getManuscripts(this._entity.id!).subscribe(
                (result) => {
                    if (Helpers.isNullOrUndefined(result) || result.length === 0) {
                        this._snackBar.open('You must add a manuscript!', '', Helpers.getRedSnackbarConfig());
                    }
                }
            );
        }
    }

    public annotate() {
        if (this.editorMode == 'annotation') {
            if (this.annotationLevel == 'word') {
                this.annotationService.makeWordFromCharacters();
            }
            else if (this.annotationLevel == 'sentence'){
                this.annotationService.makeSentenceFromWordsToSentence();
            }
        }

        this.refreshAllEditors();
    }

    public canGroup(): boolean {
        if (this.annotationLevel === 'word') {
            return this.annotationService.annotationCharacters.length > 0;
        } else if (this.annotationLevel === 'sentence') {
            return this.annotationService.wordsToSentence.length > 0;
        } else {
            return false;
        }
    }

    public saveAnnotations(): void {
        this.annotationService.addAnnotations(this._entity.id!).subscribe();
    }

    public saveTranscription(): void {
        console.log('Saving transcription');
        BatchManager.getInstance().ExecuteDeferredCallsSequentially();
    }

    refreshAllEditors() {
        this.mainEditorComponent.updateDisplay();
        this.headerEditorComponent.updateDisplay();
        this.footerEditorComponent.updateDisplay();
        this.innerMarginEditorComponent.updateDisplay();
        this.outerMarginEditorComponent.updateDisplay();
        this.interlinearEditorComponent.updateDisplay();
        this.intercolumnarEditorEditorComponent.updateDisplay();
    }

    public fromCharCode(code: number): string {
        return String.fromCharCode(code);
    }

    public resetCustomAttribute(node: Node) {
        if (node instanceof HTMLElement) {
            (<HTMLElement>node).removeAttribute('dela-status');
        }

        for (var i = 0; i < node.childNodes.length; i++) {
            this.resetCustomAttribute(node.childNodes[i]);
        }
    }

    public onLinkClick($event: any) {
        var character = $event.character as DisplayCharacter;
        var auxiliaryArea = $event.auxiliaryArea as AuxiliaryArea;

        switch (auxiliaryArea) {
            case AuxiliaryArea.InRow:
                if (character.hasInRow) {
                    this.inRowAuxiliaryEditorComponent.focusToItem(character.strokeName!);
                } else {
                    this.inRowAuxiliaryEditorComponent.initialize(character.owlName!);
                }

                break;
            case AuxiliaryArea.Over:
                if (character.hasOver) {
                    this.overAuxiliaryEditorComponent.focusToItem(character.strokeName!);
                } else {
                    this.overAuxiliaryEditorComponent.initialize(character.owlName!);
                }
                break;
            case AuxiliaryArea.Above:
                if (character.hasAbove) {
                    this.aboveAuxiliaryEditorComponent.focusToItem(character.strokeName!);
                } else {
                    this.aboveAuxiliaryEditorComponent.initialize(character.owlName!);
                }
                break;
            case AuxiliaryArea.Below:
                if (character.hasBelow) {
                    this.belowAuxiliaryEditorComponent.focusToItem(character.strokeName!);
                } else {
                    this.belowAuxiliaryEditorComponent.initialize(character.owlName!);
                }
                break;
        }
    }

    public onProjectNameFocusOut(event: any): void
    {
        this._entityService
            .updateEntity(this._entity)
            .subscribe(
                (result) => {
                    if (Helpers.isNullOrUndefined(result))
                        this._snackBar.open('Error renaming Project!', '', Helpers.getRedSnackbarConfig());
                    else
                        this._snackBar.open('Project renamed', '', Helpers.getGreenSnackbarConfig());
                });
    }

    protected readonly AnnotationService = AnnotationService;
}
