import { DisplayRow } from "./displayRow";

export class DisplayAuxiliaryItem {
    rows?: Array<DisplayRow>;
    stroke?: string;

    constructor(rows: Array<DisplayRow>) {
        this.rows = rows;
    }
}
