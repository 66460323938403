import { Injectable } from "@angular/core";
import { DisplayCharacter } from '../models/display/displayCharacter';
import { Word } from "../models/word";
import { Sentence } from "../models/sentence";
import { FusekiService } from "./fuseki.service";
import { concat } from "rxjs";

type SentenceToWordMapping = {
    sentence: string;
    wordIndex: string;
};

@Injectable({
    providedIn: 'root'
})
export class AnnotationService {
    annotationCharacters: Array<DisplayCharacter>;
    words: Array<Word>;
    wordsToSentence:Array<Word>;
    sentences:Array<Sentence>;

    constructor(private fusekiService: FusekiService) {
        this.annotationCharacters = new Array<DisplayCharacter>();
        this.words = new Array<Word>();
        this.wordsToSentence = new Array<Word>();
        this.sentences = new Array<Sentence>();
    }

    //Characters Start
    addCharacter(char: DisplayCharacter) {
        if (!this.annotationCharacters.includes(char)) {
            this.annotationCharacters.push(char);
        } else {
            this.annotationCharacters.splice(this.annotationCharacters.indexOf(char), 1);
        }
    }

    getCharacters() {
        return this.annotationCharacters;
    }

    clearCharacters() {
        this.annotationCharacters = new Array<DisplayCharacter>();
    }
    //Characters End

    //Words Start
    makeWordFromCharacters() {
            if (this.annotationCharacters.length > 0) {
                let word = new Word(this.annotationCharacters);
                word.characters.forEach(c => {
                    c.word = word;
                    word.index = c.wordIndex;
                });

                this.words.push(word);
            }

            this.annotationCharacters = new Array<DisplayCharacter>();
    }

    getWordFromCharacter(character: DisplayCharacter) {
        let word;

        for (const w of this.words) {
            for (const ch of w.characters) {
                if (ch.owlName == character.owlName && ch.order == character.order && ch.utfCode == character.utfCode && ch.owlOrder == character.owlOrder)
                    word = ch.word
            }
        }

        if (word == null)
            return;

        return word;
    }

    getCurrentWords() {
        return this.words;
    }
    //Words End

    //Sentences Start
    addWordToSentences(word: Word) {
        if (!this.wordsToSentence.includes(word)) {
            this.wordsToSentence.push(word);
        } else {
            this.wordsToSentence.splice(this.wordsToSentence.indexOf(word), 1);
        }
    }

    makeSentenceFromWordsToSentence() {
        if (this.wordsToSentence.length > 0) {
            let sentence = new Sentence(this.wordsToSentence)
            sentence.words.forEach(x => {
                x.sentence = sentence;
            });

            this.sentences.push(sentence)
        }

        this.wordsToSentence = new Array<Word>();
    }

    loadSentences(datasetName: string) {
        this.fusekiService.getSentences(datasetName).subscribe((data: any) => {
            this.groupWordIndexesBySentence(data).forEach((wordIndexes) => {
                const words = this.words.filter(word => wordIndexes.includes(word.index!));
                const sentence = new Sentence(words);
                words.forEach(word => word.sentence = sentence);
                this.sentences.push(sentence);
            });
        });
    }

    groupWordIndexesBySentence(data: SentenceToWordMapping[]): number[][] {
        const grouped = data.reduce((acc, { sentence, wordIndex }) => {
            // Create a unique key for each sentence to use for grouping
            const key = sentence;

            // Ensure an array exists for the key, then push the current wordIndex converted to a number
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(parseInt(wordIndex));

            return acc;
        }, {} as Record<string, number[]>);

        // Return just the values from the grouped object, which are arrays of word indexes
        return Object.values(grouped);
    }

    getSentenceFromCharacter(character:DisplayCharacter){
        let word = this.getWordFromCharacter(character);

        if(!word)
            return;

        let sentence = word.sentence

        if(!sentence)
            return;

        return sentence;
    }
    //Sentences End

    clearEverything() {
        this.annotationCharacters = new Array<DisplayCharacter>();
        this.wordsToSentence = new Array<Word>();
        this.words = new Array<Word>();
        this.sentences = new Array<Sentence>();
    }

    // Read from owl
    getEverythingFromOwl(datasetName: string): any {
        return this.fusekiService.getAnnotatedContext(datasetName);
    }

    // Add Annotations
    addAnnotations(datasetName: string)
    {
        const observables = [ this.fusekiService.deleteAllWords(datasetName) ];

        let index = 1;
        this.words.forEach(word =>
            observables.push(this.fusekiService.groupCharactersToWord(datasetName, word, index++)));

        index = 1;
        this.sentences.forEach(sentence =>
            observables.push(this.fusekiService.groupWordsToSentence(datasetName, sentence, index++)));

        return concat(...observables);

        /*index = 1;
        this.phrases.forEach(phrase =>
            this.fusekiService.groupWordsToPhrase(datasetName, phrase, index++));

        index = 1;
        this.clauses.forEach(clause =>
            this.fusekiService.groupPhrasesToClause(datasetName, clause, index++));

        index = 1;
        this.colons.forEach(colon =>
            this.fusekiService.groupClausesToColon(datasetName, colon, index++));

        index = 1;
        this.sentences.forEach(sentence =>
            this.fusekiService.groupColonsToSentence(datasetName, sentence, index++));*/
    }
}
