<div class="content">
    <mat-card>
        <mat-card-title>
            <h2>User List</h2>
        </mat-card-title>
        <mat-card-content>
            <div class="table-container" *blockUI="'usersContainer'">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef> Username </th>
                        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef> First Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef> Last Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="enabled">
                        <th mat-header-cell *matHeaderCellDef> Enabled </th>
                        <td mat-cell *matCellDef="let element"><mat-icon>{{ element.enabled ? 'check_circle_outline' : 'cancel' }}</mat-icon></td>
                    </ng-container>

                    <ng-container matColumnDef="emailVerified">
                        <th mat-header-cell *matHeaderCellDef> Verified Email </th>
                        <td mat-cell *matCellDef="let element"><mat-icon>{{ element.emailVerified ? 'check_circle_outline' : 'cancel' }}</mat-icon></td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openRolesDialog(element)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Roles</span>
                                </button>
                            </mat-menu>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>
