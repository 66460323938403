import { Helpers } from "src/app/utilities/helpers";
import { AuxiliaryItem } from "../jena/auxiliaryItem";
import { AuxiliaryRoot } from "../jena/auxiliaryRoot";
import { EntityRow } from "../jena/entityRow";
import { AuxiliaryCursorPosition } from "./auxiliaryCursorPosition";
import { DisplayAuxiliaryItem } from "./displayAuxiliaryItem";
import { DisplayCharacter } from "./displayCharacter";
import { DisplayRow } from "./displayRow";
import { AuxiliaryArea } from "../enums/auxiliaryArea";

export class DisplayAuxiliary {
    items?: Array<DisplayAuxiliaryItem>;
    public cursorPosition;

    constructor() {
        this.items = [];
        this.cursorPosition = new AuxiliaryCursorPosition(1, 1, 1);
    }

    public getCharacter(itemIndex: number, rowOrder?: number, characterOrder?: number): DisplayCharacter | undefined {
        var item = this.items![itemIndex - 1];
        var row = item ? item.rows!.find(x => x.order == rowOrder) : undefined;
        return row?.characters.find(x => x.order == characterOrder);
    }

    public getCharacterAtCursor(): DisplayCharacter | undefined {
        return this.getCharacter(this.cursorPosition.item!, this.cursorPosition.row, this.cursorPosition.column);
    }

    public getRowAtCursor(): DisplayCharacter | undefined {
        return this.getCharacter(this.cursorPosition.item!, this.cursorPosition.row, this.cursorPosition.column);
    }

    public isCursorAtFirstRow(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'linebreak' && this.cursorPosition.row === 1;
    }

    public isCursorAtRowStart(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'linebreak';
    }

    public isCursorAtEnd(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'leadingspace';
    }

    public isCursorAtStart(): boolean | undefined {
        if (this.cursorPosition.item === 1 && this.cursorPosition.row === 1 && this.cursorPosition.column === 1) {
            return true;
        }

        return false;
    }

    public isCursorAtItemStart(): boolean | undefined {
        if (this.cursorPosition.row === 1 && this.cursorPosition.column === 1) {
            return true;
        }

        return false;
    }

    public isCursorAtFirstItem(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'sectionbreak' && this.cursorPosition.item === 1 && this.cursorPosition.row === 1;
    }

    public setCursorPosition(item?:number, row?: number, column?: number) {
        this.cursorPosition.item = item;
        this.cursorPosition.row = row;
        this.cursorPosition.column = column;
    }

    public getStrokeNameOfCharacter(): string | undefined {
        var tempItem = this.cursorPosition.item;

        if (this.isCursorAtItemStart() && this.cursorPosition.item! > 1) {
            tempItem = this.cursorPosition.item! - 1;
        }

        return this.items![tempItem! - 1].stroke;
    }

    public getRowPositionOfCharacter(): number | undefined {
        var tempItem = this.cursorPosition.item;

        if (this.isCursorAtItemStart() && this.cursorPosition.item! > 1) {
            tempItem = this.cursorPosition.item! - 1;

            return this.items![tempItem - 1].rows!.length;
        } else {
            if (this.cursorPosition.row === 1) {
                return 1;
            } else {
                if (this.cursorPosition.column === 1) {
                    return this.cursorPosition.row! - 1;
                } else {
                    return this.cursorPosition.row;
                }
            }
        }
    }

    public getRowNameOfCharacter(): string | undefined {
        var tempItem = this.cursorPosition.item;

        if (this.isCursorAtItemStart() && this.cursorPosition.item! > 1) {
            tempItem = this.cursorPosition.item! - 1;

            return this.items![tempItem - 1].rows![this.items![tempItem - 1].rows!.length - 1].owlName;
        } else {
            return this.getCharacterAtCursor()?.row.owlName;
        }
    }

    public getColumnPositionOfCharacter(): number | undefined {
        var tempRow = this.getRowPositionOfCharacter();
        var tempItem = this.cursorPosition.item;

        if (this.isCursorAtItemStart() && this.cursorPosition.item! > 1) {
            tempItem = this.cursorPosition.item! - 1;
        }

        var item = this.items![tempItem! - 1];
        var virtualCharacters = new Array<DisplayCharacter>();
        var result = -1;

        if (this.cursorPosition.column! > 1) {
            if (Helpers.isNotNullOrUndefined(item?.rows![tempRow! - 1])) {
                item?.rows![this.cursorPosition.row! - 1].characters.filter(i => i.isVirtual === true && i.order < this.cursorPosition.column!).forEach(v => {
                    virtualCharacters.push(v);
                });

                result = this.cursorPosition.column! - virtualCharacters.length;
            }
        } else {
            if (Helpers.isNotNullOrUndefined(item?.rows![tempRow !- 1])) {
                item?.rows![tempRow! - 1].characters.filter(i => i.isVirtual === true).forEach(v => {
                    virtualCharacters.push(v);
                });

                result = item?.rows![tempRow !- 1]!.characters!.length! - virtualCharacters.length + 1;
            }
        }

        return result;
    }

    moveCursorRight(fromKeyboard: boolean = true): void {
        if (this.isCursorAtEnd()) {
            return;
        } else if (this.isCursorAtItemStart()) {
            if (fromKeyboard) {
                this.cursorPosition.column!++;
            }
        } else if (this.isCursorAtRowStart()) {
            if (this.items![this.cursorPosition.item! - 1].rows![this.cursorPosition.row! - 1].characters.filter(i => i.isVirtual === false).length > 0) {
                if (fromKeyboard) {
                    this.cursorPosition.column!++;
                }
            } else if (this.items![this.cursorPosition.item! - 1].rows!.length > this.cursorPosition.row!) {
                this.cursorPosition.row!++;
                this.cursorPosition.column = 1;
            } else if (this.items!.length > this.cursorPosition.item!) {
                this.cursorPosition.item!++;
                this.cursorPosition.row = 1;
                this.cursorPosition.column = 1;
            } else {
                this.cursorPosition.column!++;
            }
        } else {
            if (this.items![this.cursorPosition.item! - 1].rows![this.cursorPosition.row! - 1].characters.length === this.cursorPosition.column) {
                if (this.items![this.cursorPosition.item! - 1].rows!.length > this.cursorPosition.row!) {
                    this.cursorPosition.row!++;
                    this.cursorPosition.column = 1;
                } else {
                    this.cursorPosition.item!++;
                    this.cursorPosition.row = 1;
                    this.cursorPosition.column = 1;
                }
            } else {
                this.cursorPosition.column!++;
            }
        }
    }

    moveCursorLeft(fromKeyboard: boolean = true): void {
        if (this.isCursorAtFirstItem()) {
            return;
        } else if (this.isCursorAtItemStart()) {
            if (fromKeyboard) {
                this.cursorPosition.item!--;
                this.cursorPosition.row = this.items![this.cursorPosition.item! - 1].rows!.length;
                this.cursorPosition.column = this.items![this.cursorPosition.item! - 1].rows![this.items![this.cursorPosition.item! - 1].rows!.length - 1].characters.length;
            }
        } else if (this.isCursorAtRowStart()) {
            if (this.cursorPosition.row! > 1) {
                this.cursorPosition.row!--;
                this.cursorPosition.column = this.items![this.cursorPosition.item! - 1].rows![this.cursorPosition.row! - 1].characters.length;
            } else {
                this.cursorPosition.column!--;
            }
        } else {
            this.cursorPosition.column!--;
        }
    }

    updateCursorPositionAfterDelete() {
        if ((this.cursorPosition.column! > this.items![this.cursorPosition.item! - 1].rows![this.cursorPosition.row! - 1].characters.length)
        && !this.isCursorAtEnd()) {
            this.cursorPosition.item!++;
            this.cursorPosition.row = 1;
            this.cursorPosition.column = 1;
        }
    }

    public refresh(root: AuxiliaryRoot, auxiliaryArea: AuxiliaryArea): void {
        if (root.items && root.items.length > 0) {
            const items: DisplayAuxiliaryItem[] = [];

            root.items.forEach((auxiliaryItem: AuxiliaryItem) => {
                const rows: DisplayRow[] = [];
                const item = new DisplayAuxiliaryItem(rows);
                item.stroke = auxiliaryItem.stroke;

                var itemIndex = 1;
                var rowIndex = 1;

                auxiliaryItem.rows.forEach((entityRow: EntityRow) => {
                    const characters: DisplayCharacter[] = [];
                    const row = new DisplayRow(entityRow.name, entityRow.order, characters);

                    var columnsIndex = 1;

                    if (rowIndex === 1) {
                        if (auxiliaryArea !== AuxiliaryArea.InRow) {
                            characters.push(new DisplayCharacter('linkedCharacter', undefined, auxiliaryItem.utfCodeOfOrigin, row, columnsIndex, true));
                            columnsIndex++;
                        }
                        characters.push(new DisplayCharacter('sectionbreak', undefined, 0x005d, row, columnsIndex, true));
                        columnsIndex++;

                    }

                    characters.push(new DisplayCharacter('linebreak', undefined, 0x00A6, row, columnsIndex, true));
                    columnsIndex++;

                    entityRow.columns.forEach(column => {
                        const character = new DisplayCharacter(
                            column.name,
                            undefined,
                            column.utfCode,
                            row,
                            columnsIndex,
                            false,
                            column.order
                        );

                        characters.push(character);
                        columnsIndex++;
                    });

                    rows.push(row);
                    rowIndex++;
                });

                items.push(item);

                itemIndex++;
            });

            if (items[items.length - 1].rows) {
                let lastRow = items[items.length - 1].rows![items[items.length - 1].rows!.length - 1];
                    lastRow.characters.push(
                        new DisplayCharacter(
                            'leadingspace',
                            undefined,
                            0x0020,
                            lastRow,
                            lastRow.characters.length + 1,
                            true
                        )
                    );
            }

            this.items = items;
        } else {
            this.items = [];
        }
    }
}
