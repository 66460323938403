import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Institution } from 'src/app/models/jena/institution';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogInstitutionManageComponent } from './dialog-institution-manage/dialog-institution-manage.component';

@Component({
    selector: 'app-institution-list',
    templateUrl: './institution-list.component.html',
    styleUrls: ['./institution-list.component.scss']
})
export class InstitutionListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    displayedColumns: string[] = ['name', 'type', 'fullName', 'street', 'city', 'state', 'actions'];
    dataSource: MatTableDataSource<Institution>;
    public institutions: Array<Institution>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar) {
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.dataSource = new MatTableDataSource();
        this.institutions = new Array<Institution>();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getInstitutions(this.entityId!);
        }
    }

    private _getInstitutions(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getInstitutions(datasetName).subscribe({
            next: (result: Array<Institution>) => {
                this.institutions = result;
                this.dataSource.data = this.institutions;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public new() {
        const dialogRef = this._dialog.open(DialogInstitutionManageComponent, {
            width: '800px',
            data: {
                institution: new Institution(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.createInstitution(this.entityId!, result.institution, result.institutionType).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new institution', '', Helpers.getGreenSnackbarConfig());
                            this._getInstitutions(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while creating new institution', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public delete(institution: Institution) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '480px',
            data: {
                titleMessage: `Delete institution ${institution.name}`,
                infoMessage: 'Are you sure you want to delete this institution?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteInstitution(this.entityId!, institution).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted institution', '', Helpers.getGreenSnackbarConfig());
                            this._getInstitutions(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting institution', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public edit(institution: Institution) {
        const dialogRef = this._dialog.open(DialogInstitutionManageComponent, {
            width: '800px',
            data: {
                institution: institution,
                entityId: this.entityId,
                isEdit: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.updateInstitution(this.entityId!, result.institution).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully updated institution', '', Helpers.getGreenSnackbarConfig());
                            this._getInstitutions(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while updating institution', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public view(institution: Institution) {
        this._dialog.open(DialogInstitutionManageComponent, {
            width: '800px',
            data: {
                institution: institution,
                entityId: this.entityId,
                isLocked: true
            }
        });
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }
}
