import { Default } from "./default";
import { Participant } from "./participant";

export class Entity {
    id?: string;
    title?: string;
    description?: string;
    rawText?: string;
    markupText?: string;
    words?: Array<string>;
    entityTags?: Array<EntityTag>;
    participants?: Array<Participant>;
    default?: Default;
    side?: string;
    // Access Data
    createdDate!: Date;
    modifiedDate!: Date;
    deletedDate!: Date;
    createdBy!: string;
    modifiedBy!: string;
    deletedBy!: string;

    constructor() {
        this.words = new Array<string>();
        this.entityTags = new Array<EntityTag>();
    }
}

export class EntityTag {
    id?: string;
    startIndex?: number;
    text?: string;
    tag?: string;
    comment?: string;
    // Access Data
    createdDate!: Date;
    modifiedDate!: Date;
    deletedDate!: Date;
    createdBy!: string;
    modifiedBy!: string;
    deletedBy!: string;
}
