import { createSelector } from '@ngrx/store';
import { IAppState } from '../states/app.state';
import { IUIState } from './../states/ui.state';

const selectUI = (state: IAppState) => state.ui;

export const selectAllUI = createSelector(
    selectUI,
    (state: IUIState) => state
);

export const selectOverallInfo = createSelector(
    selectUI,
    (state: IUIState) => state.overallInfo
);

