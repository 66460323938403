import { Area } from "./area";
import { Department } from "./department";
import { Institution } from "./institution";
import { School } from "./school";

export class Faculty extends Area {
    institution?: Institution;
    school?: School;
    department?: Department;
}
