import { GenericSubclass } from "../models/jena/genericClass";
import { Tool } from "../models/jena/tool";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class ToolSparql {
    public static createTool(genericSubclass: GenericSubclass): string {
        var sparql = PREFIXES +
        `
        INSERT {
            ?newTool a <${genericSubclass.name}>.
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'${Helpers.getToolLabel(genericSubclass.label!)}',STR(MAX(?res) + 1))) as ?newTool)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{
                            ?z a <${genericSubclass.name}>.
                            FILTER (STRSTARTS(str(?z), str(ent:)))
                        }.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + '${Helpers.getToolLabel(genericSubclass.label!)}', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a <${genericSubclass.name}>.
                                FILTER (STRSTARTS(str(?s), str(ent:)))
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static getTools(): string {
        var sparql = PREFIXES +
        `
        SELECT ?tool ?subclass ?label
        WHERE {
            ?tool a ?subclass.
        ?subclass rdfs:subClassOf* ${SH.toolClass};
                    rdfs:label ?label.
        FILTER (langMatches(lang(?label), "en"))
        FILTER (?subclass != ${SH.toolClass})
        FILTER (STRSTARTS(str(?tool), str(ent:)))
        }
        ORDER by ?label
        `;

        return sparql;
    }

    public static deleteTool(tool: Tool): string {
        var sparql = PREFIXES +
        `
        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?x a <${tool.subclass?.name}>.
            ?x ?y ?z.
            FILTER(?x = IRI('${tool.name}'))
        };
        `;

        return sparql;
    }
}
