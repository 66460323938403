import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Entity } from 'src/app/models/entity';
import { SortingDirection } from 'src/app/models/enums/sortingDirection';
import { KeycloakUser } from 'src/app/models/keycloakUser';
import { EntityService } from 'src/app/services/entity.service';
import { UserService } from 'src/app/services/user.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-dialog-participants',
    templateUrl: './dialog-participants.component.html',
    styleUrls: ['./dialog-participants.component.scss']
})
export class DialogParticipantsComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;
    displayedColumns: string[] = ['checkbox', 'username','email','firstName','lastName'];
    dataSource: MatTableDataSource<KeycloakUser>;
    public keycloakUsers!: Array<KeycloakUser>;
    public entity: Entity;
    public checkboxes: { [key: string]: boolean };

    length!: number;
    pageIndex!: number;
    pageSize!: number;

    private defaultSortingColumn = 'username';
    private defaultSortingDirection = SortingDirection.Descending;

    public administrator_role = false;
    public basic_role = false;
    public guest_role = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogParticipantsComponent>,
        private _userService: UserService,
        private _entityService: EntityService,
        private _snackBar: MatSnackBar) {
            this.checkboxes = {};
            this.dataSource = new MatTableDataSource();
            this.entity = data.entity;
        }

    ngOnInit(): void {
        this.pageIndex = 1;
        this.pageSize = 10;
        this._findUsers(this.pageIndex, this.pageSize, this.defaultSortingColumn, this.defaultSortingDirection);
    }

    private _findUsers(pageNo: number, size: number, sortingColumn: string, sortDirection: SortingDirection) {
        this.blockUI.start();

        const conditions = new Array<string>();

        this._userService.getKeycloakUsers().subscribe(
            (result) => {
                this.keycloakUsers = result;
                this.dataSource.data = this.keycloakUsers;

                this.keycloakUsers.forEach(u => this.checkboxes[u.username!] = this.entity.participants?.findIndex(p => p.username === u.username)! > -1);

                this.blockUI.stop();
            },
            (error) => {
                this.blockUI.stop();
            }
        );
    }

    public onSelectedChange(event: any, keycloakUser: KeycloakUser) {
        if (event.checked === true) {
            this.blockUI.start();

            this._entityService.addParticipantToEntity(this.entity!.id!, keycloakUser.username!).subscribe(
                result => {
                    this.blockUI.stop();
                    this._snackBar.open('Successfully added particiapnt ' + keycloakUser.username! + ' to current entity ', '', Helpers.getGreenSnackbarConfig());
                },
                error => {
                    this.blockUI.stop();
                    this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
                }
            );
        } else if (event.checked === false) {
            this.blockUI.start();

            this._entityService.deleteParticipantFromEntity(this.entity!.id!, keycloakUser.username!).subscribe(
                result => {
                    this.blockUI.stop();
                    this._snackBar.open('Successfully deleted role ' + keycloakUser.username! + ' from user ', '', Helpers.getGreenSnackbarConfig());
                },
                error => {
                    this.blockUI.stop();
                    this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
                }
            );
        }
    }

    public okClicked() {
        this.dialogRef.close(true);
    }
}
