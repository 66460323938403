import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavItem } from 'src/app/models/navItem';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
    @Input() items!: NavItem[];
    @ViewChild('childMenu') public childMenu: any;

    @Output()
    onContextMenuItemClick: EventEmitter<any> = new EventEmitter<any>();

    constructor(public router: Router) {
    }

    ngOnInit() {
    }

    public onContextMenuClick(data: NavItem): any {
        this.onContextMenuItemClick.emit(data);
    }

    public handleMenuItemClick(data: NavItem) {
        this.onContextMenuItemClick.emit(data);
    }
}
