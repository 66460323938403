import { WindowArea } from "../models/enums/windowArea";

export class SparqlHelper {
    // Classes
    public static actorClass = 'owl:MSC_0002570';
    public static characterClass = 'owl:MSC_0002547';
    public static columnClass = 'del:MSC_0003061';
    public static departmentClass = 'del:MSC_0003831';
    public static dependentLeafClass = 'owl:MSC_0002609';
    public static deviceClass = 'owl:MSC_0002114';
    public static facultyClass = 'del:MSC_0003832';
    public static footClass = 'del:MSC_0003105';
    public static genericTranscriptionClass = 'del:MSC_0003826';
    public static headClass = 'del:MSC_0003106';
    public static innerMarginClass = 'del:MSC_0003051';
    public static institutionClass = 'del:MSC_0003748';
    public static intercolumnarSpaceClass = 'del:MSC_0003056';
    public static interlinearSpaceClass = 'del:MSC_0003054';
    public static letterClass = 'owl:MSC_0001671';
    public static mainAreaClass = 'del:MSC_0003059';
    public static manusClass = 'owl:MSC_0002578';
    public static manuscriptClass = 'del:MSC_0003785';
    public static outerMarginClass = 'del:MSC_0003107';
    public static personClass = 'del:MSC_0000009';
    public static personRoleClass = 'del:MSC_0003812';
    public static rowClass = 'del:MSC_0003060';
    public static schoolClass = 'del:MSC_0003833';
    public static sideClass = 'owl:MSC_0002528';
    public static strokeClass = 'owl:MSC_0002522';
    public static teamClass = 'del:MSC_0000006';
    public static toolClass = 'del:MSC_0000045';
    public static pageClass = 'owl:MSC_0002607';
    public static rectoClass = 'del:MSC_0003057';
    public static versoClass = 'del:MSC_0003058';
    public static whitespaceClass = 'del:MSC_0003055';
    public static wordClass = 'owl:MSC_0001677';
    public static sentenceClass = 'owl:MSC_0001958'

    // Object properties
    public static bearerOf = 'obo:BFO_0000196';
    public static continuantPartOfAtAllTimes = 'obo:BFO_0000177';
    public static continuantPartOfAtSomeTime = 'obo:BFO_0000176';
    public static hasContinuantPartAtAllTimes = 'obo:BFO_0000110';
    public static genericallyDependsOnAtSomeTime = 'obo:BFO_0000084';
    public static hasAncestor = 'del:MSC_0003828';
    public static hasParticipantAtSomeTime = 'obo:BFO_0000057';
    public static isConcretizedByAtSomeTime = 'obo:BFO_0000058';
    public static locatedInAtSomeTime = 'obo:BFO_0000171';
    public static memberPartOfAtAllTimes = 'obo:BFO_0000173';
    public static memberPartOfAtSomeTime = 'obo:BFO_0000129';
    public static projectedOnTheLeftOf = 'del:MSC_0003135';
    public static projectedOnTheRightOf = 'del:MSC_0003134';
    public static projectedAbove = 'del:MSC_0003132';
    public static projectedBelow = 'del:MSC_0003133';
    public static projectedAsOverlapping = 'del:MSC_0003148';

    // Data properties
    public static absoluteOrderProperty = 'del:MSC_0004399';
    public static approximateDateOfBirthByProperty = 'del:MSC_0003808';
    public static approximateDateOfBirthFromProperty = 'del:MSC_0003766';
    public static approximateDateOfBirthToProperty = 'del:MSC_0003767';
    public static approximateDateOfDeathByProperty = 'del:MSC_0003809';
    public static approximateDateOfDeathFromProperty = 'del:MSC_0003769';
    public static approximateDateOfDeathToProperty = 'del:MSC_0003770';
    public static cityProperty = 'del:MSC_0003754';
    public static dayOfBirthProperty = 'del:MSC_0003761';
    public static dayOfDeathProperty = 'del:MSC_0003758';
    public static firstNameProperty = 'del:MSC_0000257';
    public static flourishedByProperty = 'del:MSC_0003806';
    public static flourishedFromProperty = 'del:MSC_0003805';
    public static flourishedToProperty = 'del:MSC_0003807';
    public static fullNameProperty = 'del:MSC_0003830';
    public static genericNameProperty = 'del:MSC_0003743';
    public static lastNameProperty = 'del:MSC_0000259';
    public static middleNameProperty = 'del:MSC_0000258';
    public static monthOfBirthProperty = 'del:MSC_0003762';
    public static monthOfDeathProperty = 'del:MSC_0003759';
    public static nameProperty = 'del:MSC_0000261';
    public static nickNameProperty = 'del:MSC_0000262';
    public static shortNameProperty = 'del:MSC_0000260';
    public static siglumProperty = 'del:MSC_0000255';
    public static stateProperty = 'del:MSC_0003755';
    public static streetNumberProperty = 'del:MSC_0003757';
    public static streetProperty = 'del:MSC_0003753';
    public static titleProperty = 'del:MSC_0000263';
    public static yearOfBirthProperty = 'del:MSC_0003763';
    public static yearOfDeathProperty = 'del:MSC_0003760';
    public static zipCodeProperty = 'del:MSC_0003756';
    public static orderProperty = 'owl:MSC_0002563';
    public static unicodeCodePointProperty = 'del:MSC_0000295';
    public static unicodeCharacterProperty = 'del:MSC_0000294';

    // Individuals
    public static emptySetIndividual = 'del:MSC_0003175';

    public static getArea(windowArea: WindowArea): string {
        switch(windowArea) {
            case WindowArea.None: {
                return '';
            }
            case WindowArea.Main: {
                return 'ent:main';
            }
            case WindowArea.Header: {
                return 'ent:head';
            }
            case WindowArea.Footer: {
                return 'ent:foot';
            }
            case WindowArea.InnerMargin: {
                return 'ent:inner';
            }
            case WindowArea.OuterMargin: {
                return 'ent:outer';
            }
            case WindowArea.InterlinearSpace: {
                return 'ent:interlinear';
            }
            case WindowArea.IntercolumnarSpace: {
                return 'ent:intercolumnar';
            }
        }
    }
}
