<block-ui></block-ui>
<h2 mat-dialog-title>Auto-punctuation</h2>

<div mat-dialog-content class="content">
    <dx-html-editor style="margin-right: 7px; width: 700px; height: 300px;" valueType="html" [(value)]="content">
        <dxo-toolbar [multiline]="true">
            <dxi-item name="bold"></dxi-item>
            <dxi-item name="italic"></dxi-item>
            <dxi-item name="underline"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="color"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item
                name="size"
                [acceptedValues]="[
                                    '8pt',
                                    '10pt',
                                    '12pt',
                                    '14pt',
                                    '18pt',
                                    '24pt',
                                    '36pt'
                                ]"
            ></dxi-item>
            <dxi-item
                name="font"
                [acceptedValues]="[
                                    'Arial',
                                    'Georgia',
                                    'Tahoma',
                                    'Times New Roman',
                                    'Verdana'
                                ]"
            ></dxi-item>
        </dxo-toolbar>
    </dx-html-editor>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
</div>
