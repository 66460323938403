import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EntityResponse } from '../models/api/entityResponse';
import { Entity, EntityTag } from '../models/entity';
import { SortingDirection } from '../models/enums/sortingDirection';
import { Participant } from '../models/participant';
import { DefaultType } from '../models/enums/defaultType';

@Injectable({
    providedIn: 'root'
})
export class EntityService {

    constructor(private http: HttpClient) {
    }

    routeParams: any;
    webApiUrl = environment.webApiUrl;
    headers: Headers | undefined;

    // -------------- //
    // CREATE METHODS //
    // -------------- //

    public createEntity(_entity: Entity): Observable<Entity> {
        return this.http.post(this.webApiUrl + `/api/Entities/CreateEntityAsync`,
        _entity)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // POST METHODS //
    // -------------- //

    public addParticipantToEntity(_entityId: string, _username: string): Observable<Participant> {
        return this.http.post(this.webApiUrl + `/api/Entities/AddParticipantToEntity?entityId=${_entityId}&username=${_username}`,
        null)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // UPDATE METHODS //
    // -------------- //

    public updateEntity(_entity: Entity): Observable<Entity> {
        return this.http.put(this.webApiUrl + `/api/Entities/UpdateEntityAsync`,
        _entity)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public updateEntityByDefaultType(_entityId: string, _defaultType: DefaultType, _value: string): Observable<Entity> {
        return this.http.put(this.webApiUrl + `/api/Entities/UpdateEntityByDefaultTypeAsync?entityId=${_entityId}&defaultType=${_defaultType}&value=${_value}`,
        null)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public updateEntityByDefaultShowCharacterInfo(_entityId: string, _value: boolean): Observable<Entity> {
        return this.http.put(this.webApiUrl + `/api/Entities/UpdateEntityByDefaultShowCharacterInfoAsync?entityId=${_entityId}&value=${_value}`,
        null)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public updateEntityTag(_entityTag: EntityTag): Observable<EntityTag> {
        return this.http.put(this.webApiUrl + `/api/Entities/UpdateEntityTagAsync`,
        _entityTag)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // --------------   //
    // DELETE METHODS   //
    // --------------   //
    public deleteEntity(_entity: Entity): Observable<Entity> {
        return this.http.delete<Entity>(this.webApiUrl + `/api/Entities/DeleteEntityAsync?entityId=${_entity.id}`);
    }

    public deleteParticipantFromEntity(entityId: string, username: string): Observable<Array<Participant>> {
        return this.http.delete(this.webApiUrl + `/api/Entities/DeleteParticipantFromEntity?entityId=${entityId}&username=${username}`)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public deleteEntityTag(_entityTagId: string): Observable<EntityTag> {
        return this.http.delete(this.webApiUrl + `/api/Entities/DeleteEntityTag?entityTagId=${_entityTagId}`)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // FIND METHODS   //
    // -------------- //

    public findEntities(conditions: string[],
        pageNo: number,
        size: number,
        sortingColumn: string,
        sortingDirection: SortingDirection): Observable<EntityResponse<Entity>> {

        return this.http.post(this.webApiUrl + `/api/Entities/FindEntitiesPagedAsync?size=${size}&sortingColumn=${sortingColumn}&page=${pageNo}&sortingDirection=${sortingDirection}`,
            conditions)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // GET METHODS    //
    // -------------- //

    public getEntityById(id: string): Observable<Entity> {
        return this.http.get(this.webApiUrl + `/api/Entities/GetEntityByIdAsync/${id}`)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }
}
