import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Person } from 'src/app/models/jena/person';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogPersonManageComponent } from './dialog-person-manage/dialog-person-manage.component';

@Component({
    selector: 'app-person-list',
    templateUrl: './person-list.component.html',
    styleUrls: ['./person-list.component.scss']
})
export class PersonListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    displayedColumns: string[] = ['name', 'firstName', 'middleName', 'lastName', 'genericName', 'nickName','actions'];
    dataSource: MatTableDataSource<Person>;
    public persons: Array<Person>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar) {
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.dataSource = new MatTableDataSource();
        this.persons = new Array<Person>();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getPersons(this.entityId!);
        }
    }

    private _getPersons(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getPersons(datasetName).subscribe({
            next: (result: Array<Person>) => {
                this.persons = result;
                this.dataSource.data = this.persons;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public new() {
        const dialogRef = this._dialog.open(DialogPersonManageComponent, {
            width: '800px',
            data: {
                person: new Person()
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.createPerson(this.entityId!, result).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new person', '', Helpers.getGreenSnackbarConfig());
                            this._getPersons(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while creating new person', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public delete(person: Person) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '480px',
            data: {
                titleMessage: `Delete person ${person.name}`,
                infoMessage: 'Are you sure you want to delete this person?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deletePerson(this.entityId!, person).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted person', '', Helpers.getGreenSnackbarConfig());
                            this._getPersons(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting person', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public edit(person: Person) {
        const dialogRef = this._dialog.open(DialogPersonManageComponent, {
            width: '800px',
            data: {
                person: person,
                isEdit: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.updatePerson(this.entityId!, result).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully updated person', '', Helpers.getGreenSnackbarConfig());
                            this._getPersons(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while updating person', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public view(person: Person) {
        this._dialog.open(DialogPersonManageComponent, {
            width: '800px',
            data: {
                person: person,
                isLocked: true
            }
        });
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }
}
