export class CursorPosition {
    public page?: number;
    public row?: number;
    public column?: number;

    constructor(page:number, row: number, column: number) {
        this.page = page;
        this.row = row;
        this.column = column;
    }
}
