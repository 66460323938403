<block-ui></block-ui>
<h2 mat-dialog-title>Add Owl Item:</h2>

<div mat-dialog-content class="content">
    <div fxLayout="row" class="item">
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="newNavItem.displayName">
        </mat-form-field>
    </div>
    <div fxLayout="row" class="item">
        <div class="itemTitle">Disabled: </div>
        <div class="itemValue"><mat-checkbox [(ngModel)]="newNavItem.disabled"></mat-checkbox></div>
    </div>
    <div fxLayout="row" class="item">
        <div class="itemTitle">Is Root: </div>
        <div class="itemValue"><mat-checkbox [(ngModel)]="newNavItem.isRoot"></mat-checkbox></div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()" *ngIf="newNavItem.displayName">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()" >Cancel</button>
</div>
