import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { EntityTag } from 'src/app/models/entity';
import { OverallInfo } from 'src/app/models/overallInfo';
import { FusekiService } from 'src/app/services/fuseki.service';
import { selectOverallInfo } from 'src/app/store/selectors/ui.selectors';
import { IAppState } from 'src/app/store/states/app.state';

@Component({
    selector: 'app-overall-info',
    templateUrl: './overall-info.component.html',
    styleUrls: ['./overall-info.component.scss']
})
export class OverallInfoComponent implements OnInit {

    public selectedOverallInfo?: OverallInfo;

    constructor(private _fusekiService: FusekiService,
        private _snackBar: MatSnackBar,
        private _store: Store<IAppState>,
        private _changeDetectorRef: ChangeDetectorRef,
        private _dialog: MatDialog) {
    }

    public ngOnInit(): void {
        this._store.select(selectOverallInfo).subscribe(i => {
            this.selectedOverallInfo = i!
            // this._changeDetectorRef.detectChanges();
        });
    }

    public fromCharCode(code: number): string {
        return String.fromCharCode(code);
    }

}
