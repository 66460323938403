import { Area } from "./area";
import { Faculty } from "./faculty";
import { Institution } from "./institution";
import { School } from "./school";

export class Department extends Area {
    institution?: Institution;
    school?: School;
    faculty?: Faculty;
}
