import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {catchError, Observable, switchMap, tap, throwError} from 'rxjs';
import {NavItem} from 'src/app/models/navItem';
import {FusekiService} from 'src/app/services/fuseki.service';
import {Helpers} from 'src/app/utilities/helpers';
import {DialogDerivationComponent} from '../dialog-derivation/dialog-derivation.component';
import {DialogSpaceDerivationComponent} from '../dialog-space-derivation/dialog-space-derivation.component';
import {WindowArea} from 'src/app/models/enums/windowArea';
import {DialogOntologyInfoComponent} from '../dialog-info/dialog-info.component';
import {Entity} from 'src/app/models/entity';
import {Manus} from 'src/app/models/jena/manus';
import {Actor} from 'src/app/models/jena/actor';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IAppState} from 'src/app/store/states/app.state';
import {SetOverallInfo} from 'src/app/store/actions/ui.actions';
import {OverallInfo} from 'src/app/models/overallInfo';
import {DisplayEntity} from 'src/app/models/display/displayEntity';
import {EntityRoot} from 'src/app/models/jena/entityRoot';
import {AuxiliaryArea} from 'src/app/models/enums/auxiliaryArea';
import {DisplayCharacter} from '../../../models/display/displayCharacter';
import {AnnotationService} from 'src/app/services/annotation.service';
import {AnnotationColors} from '../../../models/annotationColors';
import {BatchManager, DeferredCall} from "../../../utilities/batchedCall";
import {InitializationService} from "src/app/services/initialization.service";
import {Word} from "../../../models/word";
import {CollationService} from "../../../services/collation.service";

@Component({
    selector: 'app-dela-editor',
    templateUrl: './dela-editor.component.html',
    styleUrls: ['./dela-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DelaEditorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() height: string = '100';
    @Input() datasetName: string | undefined;
    @Input() entity: Entity | undefined;
    @Input() mode: string | undefined;
    @Input() annotationLevel: string | undefined;
    @Input() windowArea: WindowArea = WindowArea.None;
    @Output() onLinkClick = new EventEmitter<any>();
    @BlockUI('editor-container') blockUI!: NgBlockUI;
    @ViewChild('entityContainer', { read: ElementRef }) entityContainer!: ElementRef;

    public displayEntity: DisplayEntity;
    public nestedMenuItems: Array<NavItem>;
    public rightClickMenuPositionX!: number;
    public rightClickMenuPositionY!: number;
    private selectedShowCharacterInfo?: boolean;

    private colors = new AnnotationColors();

    private removeKeyDown!: () => void;

    public isFocused = false;

    constructor(private router: Router,
        private _renderer: Renderer2,
        private _fusekiService: FusekiService,
        private _dialog: MatDialog,
        private _store: Store<IAppState>,
        private _changeDetectorRef: ChangeDetectorRef,
        private _annotationService: AnnotationService,
        private _initializationService: InitializationService) {
        this.nestedMenuItems = new Array<NavItem>();
        this.initializeMenuItems();
        this.displayEntity = new DisplayEntity();
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['mode'] && !changes['mode'].firstChange && changes['mode'].previousValue != changes['mode'].currentValue) {
            this.updateDisplay();
        }

        if (changes['annotationLevel'] && !changes['annotationLevel'].firstChange && changes['annotationLevel'].previousValue != changes['annotationLevel'].currentValue) {
            this.updateDisplay();
        }

        if (this.datasetName) {
            this._initializationService.initializationComplete.emit(this);
        }
    }

    startUpdate(): Observable<EntityRoot | undefined> {
        return this.updateEntity().pipe(
            tap(() => {
                this.updateDisplay();
                this._changeDetectorRef.detectChanges();
            })
        );
    }

    reload() {
        this.router.navigateByUrl('/edit-entity/' + this.datasetName);
    }

    updateEntity(): Observable<EntityRoot | undefined> {
        if (this.datasetName) {
            return this._fusekiService.getEntityContent(this.datasetName, this.windowArea)
                .pipe(
                    tap((result: EntityRoot) => {
                        this.displayEntity.refresh(result);
                        this.getWords();
                    }),
                    catchError((error) => {
                        console.error(`Failed to get entity content: ${error.message}`)
                        return throwError(() => error);
                    })
                );
        } else {
            return throwError(() => new Error('datasetName is undefined'));
        }
    }

    getWords(): void {
        let wordIndex = 0;
        this.displayEntity.pages.forEach(page => {
            page.rows.forEach(row => {
                row.characters.forEach(character => {
                    if (character.wordIndex) {
                        if (wordIndex != character.wordIndex) {
                            this._annotationService.makeWordFromCharacters();
                        }

                        this._annotationService.addCharacter(character);
                        wordIndex = character.wordIndex;
                    }
                });
            });
        });

        if (wordIndex != 0) {
            this._annotationService.makeWordFromCharacters();
        }

        if (this.windowArea === WindowArea.Main)
            this._annotationService.loadSentences(this.datasetName!);
    }

    clearDisplay(): void {
        Array.from(this.entityContainer.nativeElement.children).forEach((child: any) => {
            this._renderer.removeChild(this.entityContainer.nativeElement, child);
        });
    }

    updateDisplay(): void {
        const startTimeInMs = new Date().getTime();

        this.clearDisplay();
        this.displayEntity.pages.forEach(page => {
            page.rows.forEach(row => {
                row.characters.forEach(character => {
                    /*if (character.order == 2 && character.owlName == 'linebreak')
                        return;*/

                    let divColumn = this._renderer.createElement('div');
                    this._renderer.addClass(divColumn, 'column');
                    this._renderer.listen(divColumn, 'mousedown', (event) => {
                        this.onCharacterClick(event)
                        event.preventDefault();
                        this.entityContainer.nativeElement.parentElement.focus();
                    });

                    let metaPage = this._renderer.createElement('meta');
                    this._renderer.setProperty(metaPage, 'name', 'page');
                    this._renderer.setProperty(metaPage, 'content', page.order);

                    let metaRow = this._renderer.createElement('meta');
                    this._renderer.setProperty(metaRow, 'name', 'row');
                    this._renderer.setProperty(metaRow, 'content', row.order);

                    let metaOrder = this._renderer.createElement('meta');
                    this._renderer.setProperty(metaOrder, 'name', 'order');
                    this._renderer.setProperty(metaOrder, 'content', character.order);

                    let divCharacter = this._renderer.createElement('div');
                    this._renderer.addClass(divCharacter, 'character');
                    this._renderer.addClass(divCharacter, 'tooltip');
                    let newChar = String.fromCharCode(character.utfCode);
                    let textCharacter = this._renderer.createText(newChar == ' ' ? '\u00A0' : newChar);

                    if (character.hasInRow) {
                        this._renderer.addClass(divCharacter, 'inrow-character');
                    }
                    if (character.hasOver) {
                        this._renderer.addClass(divCharacter, 'over-character');
                    }
                    if (character.hasAbove) {
                        this._renderer.addClass(divCharacter, 'above-character');
                    }
                    if (character.hasBelow) {
                        this._renderer.addClass(divCharacter, 'below-character');
                    }

                    if (character.owlName === 'pagebreak') {
                        let spanTooltip = this._renderer.createElement('span');
                        this._renderer.addClass(spanTooltip, 'tooltiptext');
                        let tooltipText = this._renderer.createText(page.order.toString());
                        this._renderer.appendChild(spanTooltip, tooltipText);

                        this._renderer.appendChild(divCharacter, spanTooltip);
                    }

                    this._renderer.appendChild(divCharacter, textCharacter);
                    this._renderer.appendChild(divColumn, metaPage);
                    this._renderer.appendChild(divColumn, metaRow);
                    this._renderer.appendChild(divColumn, metaOrder);
                    this._renderer.appendChild(divColumn, divCharacter);

                    if (page.order === this.displayEntity.cursorPosition.page &&
                        character.order == this.displayEntity.cursorPosition.column &&
                        character.row.order == this.displayEntity.cursorPosition.row &&
                        this.mode == 'transcription'
                        && this.isFocused
                    ) {
                        let divCursor = this._renderer.createElement('div');
                        this._renderer.addClass(divCursor, 'cursor');
                        this._renderer.appendChild(divColumn, divCursor);
                    }
                    this._renderer.appendChild(this.entityContainer.nativeElement, divColumn);

                    if (this.mode == 'annotation') {
                        this.annotationUpdateDisplayProcedures(divColumn, character);
                    }

                    if (this.mode == 'transcription' && this.nestedMenuItems.some(x => x.id === 'clearSelections')) {
                        this.nestedMenuItems = this.nestedMenuItems.filter(x => x.id != 'clearSelections');
                    }
                });
            });
        });

        if (this.removeKeyDown) {
            this.removeKeyDown();
        }
        this.removeKeyDown = this._renderer.listen(
            this.entityContainer.nativeElement.parentElement, 'keydown', (event) => this.onKeyDown(event));

        this._renderer.setAttribute(this.entityContainer.nativeElement.parentElement, 'tabindex', '-1');

        const endTimeInMs = new Date().getTime();
        const durationInMs = endTimeInMs - startTimeInMs;

        this._changeDetectorRef.detectChanges();

        console.log(`updateDisplay took ${durationInMs} ms`);
    }

    private controlAndEnterPressed(): void {
        this._addPages();
        this.updateDisplay();
        //this.ngOnInit();
        //this.reload();
    }

    private enterPressed(): void {
        this._addRow();
        this.updateDisplay();
    }

    private arrowRightPressed(): void {
        this.displayEntity.moveCursorRight(true);
        this.updateStoreCharacter();
        this.updateDisplay();
    }

    private arrowLeftPressed(): void {
        this.displayEntity.moveCursorLeft(true);
        this.updateStoreCharacter();
        this.updateDisplay();

    }

    private backspacePressed(): void {
        if (this.displayEntity.isCursorAtStart() ||
            this.displayEntity.isCursorAtFirstRow()) {
            return;
        }

        if (this.displayEntity.getColumnPositionOfCharacter()! > 1) {
            const area = this.displayEntity.getAreaAtCursor();
            const row = this.displayEntity.getRowPositionOfCharacter()!;
            const column = this.displayEntity.getColumnPositionOfCharacter()!;

            this.displayEntity.deleteCharacter(true);
            const deferred: DeferredCall = {
                fn: this._fusekiService.deleteCharacter,
                context: this._fusekiService,
                args: [
                    this.datasetName!,
                    area,
                    row,
                    column - 1,
                    true,
                    this.windowArea
                ]
            };

            BatchManager.getInstance().Enqueue(deferred);
            this.updateDisplay();
        } else if (this.displayEntity.getCharacterBeforeCursor()?.owlName === 'linebreak' &&
            this.displayEntity.getCharacterBeforeCursor()?.previousCharacter?.owlName !== 'pagebreak') {
            const area = this.displayEntity.getAreaAtCursor();
            const row = this.displayEntity.getRowPositionOfCharacter()!;

            this.displayEntity.deleteRow(true);
            this.updateDisplay();

            const deferred: DeferredCall = {
                fn: this._fusekiService.deleteRow,
                context: this._fusekiService,
                args: [
                    this.datasetName!,
                    area,
                    row,
                    this.windowArea
                ]
            };

           BatchManager.getInstance().Enqueue(deferred);
        } else if (this.displayEntity.getCharacterAtCursor()?.page?.order! > 1) {
            const area = this.displayEntity.getAreaAtCursor();
            const row = this.displayEntity.getRowPositionOfCharacter()!;

            this.displayEntity.deletePage(true);
            this.updateDisplay();
        }
    }

    private deletePressed(): void {
        if (this.displayEntity.isCursorAtStart() ||
            this.displayEntity.isCursorAtEnd() ||
            this.displayEntity.isCursorAtFirstRow() ||
            this.displayEntity.isCursorAtPageStart()) {
            return;
        }

        if (!this.displayEntity.isCursorAtRowStart() && !this.displayEntity.isCursorAtInRowSymbol()) {
            const area = this.displayEntity.getAreaAtCursor();
            const row = this.displayEntity.getRowPositionOfCharacter()!;
            const column = this.displayEntity.getColumnPositionOfCharacter()!;

            this.displayEntity.deleteCharacter(false);
            const deferred: DeferredCall = {
                fn: this._fusekiService.deleteCharacter,
                context: this._fusekiService,
                args: [
                    this.datasetName!,
                    area,
                    row,
                    column,
                    true,
                    this.windowArea
                ]
            };

            BatchManager.getInstance().Enqueue(deferred);
        } else {
            console.log('Delete row');
        }

        this.updateDisplay();
    }

    private spaceKeyPressed(): void {
        if (this.displayEntity.isCursorAtStart() ||
            this.displayEntity.isCursorAtFirstRow()) {
            return;
        }

        var showDialog = true;

        if (Helpers.isNullOrUndefined(this.selectedShowCharacterInfo)) {
            if (Helpers.isNotNullOrUndefined(this.entity?.default) && Helpers.isNotNullOrUndefined(this.entity?.default?.showCharacterInfo)) {
                showDialog = this.entity?.default?.showCharacterInfo!;
            }
        } else {
            showDialog = this.selectedShowCharacterInfo!;
        }

        if (showDialog) {
            const dialogRef = this._dialog.open(DialogOntologyInfoComponent, {
                width: '800px',
                data: {
                    entityId: this.datasetName
                }
            });

            dialogRef.afterClosed().subscribe(
                (result: any) => {
                    if (result && result !== false) {
                        this.selectedShowCharacterInfo = result.default?.showCharacterInfo!;

                        this._addSpace(result.manus, result.actor);
                    }
                }
            );
        } else {
            const manus = new Manus();
            manus.name = this.entity?.default?.manus!;
            const actor = new Actor();
            actor.name = this.entity?.default?.actor!;

            this._addSpace(manus, actor);
        }
    }

    private defaultKeyPressed(event: KeyboardEvent): void {
        if (this.displayEntity.isCursorAtStart() ||
            this.displayEntity.isCursorAtFirstRow()) {
            return;
        }

        let showDialog = true;

        if (Helpers.isNullOrUndefined(this.selectedShowCharacterInfo)) {
            if (Helpers.isNotNullOrUndefined(this.entity?.default) &&
                Helpers.isNotNullOrUndefined(this.entity?.default?.showCharacterInfo)) {
                showDialog = this.entity?.default?.showCharacterInfo!;
            }
        } else {
            showDialog = this.selectedShowCharacterInfo!;
        }

        if (showDialog) {
            const dialogRef = this._dialog.open(DialogOntologyInfoComponent, {
                width: '800px',
                data: {
                    entityId: this.datasetName
                }
            });

            dialogRef.afterClosed().subscribe(
                (result: any) => {
                    if (result && result !== false) {
                        this.selectedShowCharacterInfo = result.default?.showCharacterInfo!;

                        this._addCharacter(event, result.manus, result.actor);
                    }
                }
            );
        } else {
            const manus = new Manus();
            manus.name = this.entity?.default?.manus!;
            const actor = new Actor();
            actor.name = this.entity?.default?.actor!;

            this._addCharacter(event, manus, actor);
        }
    }

    private _addCharacter(event: KeyboardEvent, manus?: Manus, actor?: Actor) {
        const area = this.displayEntity.getAreaAtCursor();
        const row = this.displayEntity.getRowPositionOfCharacter();
        const column = this.displayEntity.getColumnPositionOfCharacter();

        this.displayEntity.addCharacter(event.key);

        const deferred: DeferredCall = {
            fn: this._fusekiService.addCharacter,
            context: this._fusekiService,
            args: [
                this.datasetName!,
                area,
                row!,
                event.key,
                column!,
                manus,
                actor
            ]
        };

        BatchManager.getInstance().Enqueue(deferred);
        this.updateDisplay();
    }

    private _addSpace(manus?: Manus, actor?: Actor) {
        const area = this.displayEntity.getAreaAtCursor();
        const row = this.displayEntity.getRowPositionOfCharacter();
        const column = this.displayEntity.getColumnPositionOfCharacter();

        this.displayEntity.addCharacter(String.fromCharCode(0x25E6));

        const deferred: DeferredCall = {
            fn: this._fusekiService.addSpace,
            context: this._fusekiService,
            args: [
                this.datasetName!,
                area,
                row!,
                column!,
                manus,
                actor
            ]
        };

        BatchManager.getInstance().Enqueue(deferred);
        this.updateDisplay();
    }

    private _addRow() {
        let area = this.displayEntity.getAreaAtCursor();
        let row = this.displayEntity.getRowPositionOfCharacter()!;
        let column = this.displayEntity.getColumnPositionOfCharacter()!;

        this.displayEntity.addRow();

        const deferred: DeferredCall = {
            fn: this._fusekiService.addRow,
            context: this._fusekiService,
            args: [
                this.datasetName!,
                area,
                row,
                column,
                this.windowArea
            ]
        };
        BatchManager.getInstance().Enqueue(deferred);

        this.updateDisplay();
    }

    private _addPages() {
        this.displayEntity.addPage();

        this.blockUI.start('Inserting new pages');

        this._fusekiService.addPages(
            this.datasetName!,
            this.entity?.side!
        ).pipe(
            switchMap(() => this.updateEntity())
        ).subscribe({
            next: () => {
                this.updateDisplay();
                this.blockUI.stop();
            },
            error: error => console.log(error)
        });
    }

    public openInfo() {
        const dialogRef = this._dialog.open(DialogOntologyInfoComponent, {
            width: '800px',
            data: {
                entityId: this.datasetName
            }
        });

        dialogRef.afterClosed().subscribe(

        );
    }

    onEditorClick(event: MouseEvent) {
        this.isFocused = true;
        this.displayEntity.setCursorPosition(
            1,
            this.displayEntity.getLastRow(1)?.order,
            this.displayEntity.getLastRow(1)?.getLastCharacter().order
        );
        this.updateDisplay();
    }


    onCharacterClick(event: MouseEvent) {
        this.isFocused = true;
        let cell = event.currentTarget as HTMLElement;
        let page = cell.querySelectorAll('meta')[0];
        let row = cell.querySelectorAll('meta')[1];
        let order = cell.querySelectorAll('meta')[2];
        let offset = 0;

        let targetCharacter = this.displayEntity.getCharacter(+page.content, +row.content, +order.content);
        if (targetCharacter?.owlName == 'linebreak' && targetCharacter.previousCharacter?.owlName == 'pagebreak')
            targetCharacter = targetCharacter.nextCharacter;

        this.displayEntity.setCursorPositionToCharacter(targetCharacter!);

        if (this.mode == 'annotation') {
            this.onAnnotationCharacterClick()
        }
        this.updateDisplay();
        this.updateStoreCharacter();
        event.stopPropagation();
    }

    onFocusOut(event: any) {
        this.isFocused = false;
        this.updateDisplay();
    }

    onRightClick(event: MouseEvent,
        trigger: MatMenuTrigger,
        triggerElement: HTMLElement) {
        if (Helpers.isNotNullOrUndefined(this.displayEntity.cursorPosition)) {
            this.nestedMenuItems[this.nestedMenuItems.findIndex(i => i.id === 'initialInfoRoot')]
                .displayName = `Page ${this.displayEntity.cursorPosition.page} |  Row ${this.displayEntity.cursorPosition.row} `;
            var character = this.displayEntity.getCharacterAtCursor();

            if (character?.hasInRow) {
                this.nestedMenuItems[this.nestedMenuItems.findIndex(i => i.id === 'linkToAuxiliary')].children!.filter(i => i.id === 'linkToAuxiliaryInRow')[0].displayName = 'In Row (Edit)';
            }
            if (character?.hasOver) {
                this.nestedMenuItems[this.nestedMenuItems.findIndex(i => i.id === 'linkToAuxiliary')].children!.filter(i => i.id === 'linkToAuxiliaryOver')[0].displayName = 'Over (Edit)';
            }
            if (character?.hasAbove) {
                this.nestedMenuItems[this.nestedMenuItems.findIndex(i => i.id === 'linkToAuxiliary')].children!.filter(i => i.id === 'linkToAuxiliaryAbove')[0].displayName = 'Above (Edit)';
            }
            if (character?.hasBelow) {
                this.nestedMenuItems[this.nestedMenuItems.findIndex(i => i.id === 'linkToAuxiliary')].children!.filter(i => i.id === 'linkToAuxiliaryBelow')[0].displayName = 'Below (Edit)';
            }


            triggerElement.style.left = event.clientX + 5 + "px";
            triggerElement.style.top = event.clientY + 5 + "px";
            if (trigger.menuOpen) {
                trigger.closeMenu();
                trigger.openMenu();
            } else {
                trigger.openMenu();
            }
        }

        event.preventDefault();
    }

    fromCharCode(code: number): string {
        return String.fromCharCode(code);
    }

    public updateStoreCharacter(): void {
        var overallInfo = new OverallInfo();
        var displayCharacter = this.displayEntity.getCharacterAtCursor();
        overallInfo.name = displayCharacter?.owlName!;
        overallInfo.utfCode = displayCharacter?.utfCode!;
        overallInfo.columnOrder = displayCharacter?.owlOrder!;
        overallInfo.rowName = displayCharacter?.row.owlName!;
        overallInfo.rowOrder = displayCharacter?.row.order!;
        overallInfo.pageName = displayCharacter?.page!.owlName;
        overallInfo.pageOrder = displayCharacter?.page!.order;
        overallInfo.areaName = displayCharacter?.page!.areaName!;
        overallInfo.strokeName = displayCharacter?.strokeName;
        overallInfo.transcriptionName = displayCharacter?.transcriptionName;
        overallInfo.manusName = displayCharacter?.manusName;
        overallInfo.annotatorName = displayCharacter?.annotatorName;
        overallInfo.manuscriptName = displayCharacter?.manuscriptName;
        // const character = this.displayEntity.getCharacterAtCursor()!;
        this._store.dispatch(new SetOverallInfo(overallInfo));
    }

    public handleMenuItemClick(data: NavItem, trigger: MatMenuTrigger) {
        if (data.id === 'derivation') {
            this.openDerivationDialog();
        } else if (data.id === 'viewInfo') {
        } else if (data.id === 'linkToAuxiliaryInRow') {
            let character = this.displayEntity.getCharacterAtCursor();
            this.onLinkClick.emit({ character: character, auxiliaryArea: AuxiliaryArea.InRow });
        } else if (data.id === 'linkToAuxiliaryOver') {
            let character = this.displayEntity.getCharacterAtCursor();
            this.onLinkClick.emit({ character: character, auxiliaryArea: AuxiliaryArea.Over });
        } else if (data.id === 'linkToAuxiliaryAbove') {
            let character = this.displayEntity.getCharacterAtCursor();
            this.onLinkClick.emit({ character: character, auxiliaryArea: AuxiliaryArea.Above });
        } else if (data.id === 'linkToAuxiliaryBelow') {
            let character = this.displayEntity.getCharacterAtCursor();
            this.onLinkClick.emit({ character: character, auxiliaryArea: AuxiliaryArea.Below });
        } else if (data.id === 'clearSelections') {
            this._annotationService.clearEverything();
            this.updateDisplay()
        }
    }

    public clearEverything() {
        this._annotationService.clearEverything();
    }

    public openSpaceDerivationDialog() {
        const dialogRef = this._dialog.open(DialogSpaceDerivationComponent, {
            width: '640px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(

        );
    }

    public openDerivationDialog() {
        const dialogRef = this._dialog.open(DialogDerivationComponent, {
            width: '400px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(

        );
    }

    private initializeMenuItems() {
        const initialInfoRoot: NavItem = {
            id: 'initialInfoRoot',
            displayName: `Row - | Col -`,
            iconName: 'info',
            children: new Array<NavItem>()
        };
        this.nestedMenuItems.push(initialInfoRoot);



        const owlTreeRoot: NavItem = {
            id: 'owlTree',
            displayName: 'Owl Tree',
            iconName: 'list',
            children: new Array<NavItem>()
        };

        this.nestedMenuItems.push(owlTreeRoot);

        const derivation: NavItem = {
            id: 'derivation',
            displayName: 'Derivation',
            iconName: 'business',
            children: new Array<NavItem>()
        };

        this.nestedMenuItems.push(derivation);

        const viewInfo: NavItem = {
            id: 'viewInfo',
            displayName: 'View Info',
            iconName: 'visibility',
            children: new Array<NavItem>()
        };

        this.nestedMenuItems.push(viewInfo);

        const insert: NavItem = {
            id: 'insert',
            displayName: 'Insert',
            iconName: 'add',
            children: new Array<NavItem>()
        };

        const mark: NavItem = {
            id: 'mark',
            displayName: 'Mark',
            iconName: 'create',
            children: new Array<NavItem>()
        };

        const sign: NavItem = {
            id: 'sign',
            displayName: 'Sign',
            iconName: 'create',
            children: new Array<NavItem>()
        };

        insert.children?.push(mark);
        insert.children?.push(sign);

        this.nestedMenuItems.push(insert);

        const linkToInRow: NavItem = {
            id: 'linkToAuxiliaryInRow',
            displayName: 'In Row',
            iconName: 'border_horizontal',
            children: new Array<NavItem>()
        };

        const linkToOver: NavItem = {
            id: 'linkToAuxiliaryOver',
            displayName: 'Over',
            iconName: 'border_inner',
            children: new Array<NavItem>()
        };

        const linkToAbove: NavItem = {
            id: 'linkToAuxiliaryAbove',
            displayName: 'Above',
            iconName: 'border_top',
            children: new Array<NavItem>()
        };

        const linkToBelow: NavItem = {
            id: 'linkToAuxiliaryBelow',
            displayName: 'Below',
            iconName: 'border_bottom',
            children: new Array<NavItem>()
        };

        const link: NavItem = {
            id: 'linkToAuxiliary',
            displayName: 'Link',
            iconName: 'link',
            children: new Array<NavItem>()
        };

        link.children?.push(linkToInRow);
        link.children?.push(linkToOver);
        link.children?.push(linkToAbove);
        link.children?.push(linkToBelow);

        this.nestedMenuItems.push(link);
    }

    public getRightClickMenuStyle() {
        return {
            position: 'fixed',
            left: `${this.rightClickMenuPositionX}px`,
            top: `${this.rightClickMenuPositionY}px`
        }
    }

    public getStyle(): string {
        return `height:${this.height}px;`;
    }

    public onKeyDown(event: KeyboardEvent) {
        event.preventDefault();
        var annotationStatement = this.mode == 'annotation';

        if (annotationStatement && this.annotationLevel == 'word' && event.key == ' ') {
            this.selectMultipleCharacters()
            this.updateDisplay()
            return;
        }
        else if (annotationStatement && this.annotationLevel == 'phrase') {
            return;
        }
        else if (annotationStatement) {
            return;
        }

        if (this.blockUI.isActive) {
            return;
        }

        if (event.ctrlKey && event.key === 'Enter') {
            event.preventDefault();
            this.controlAndEnterPressed();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            this.enterPressed();

        } else if (event.key === 'ArrowRight') {
            event.preventDefault();
            this.arrowRightPressed();
        } else if (event.key === 'ArrowLeft') {
            event.preventDefault();
            this.arrowLeftPressed();

        } else if (event.key === 'ArrowUp') {
            event.preventDefault();

        } else if (event.key === 'ArrowDown') {
            event.preventDefault();

        } else if (event.key === 'Backspace') {
            event.preventDefault();
            this.backspacePressed();

        } else if (event.key === 'Delete') {
            event.preventDefault();
            this.deletePressed();
        } else if (event.key === ' ') {
            event.preventDefault();
            this.spaceKeyPressed();
        } else if (!event.ctrlKey && !event.shiftKey && !event.metaKey && !event.altKey && event.key !== 'Escape') {
            event.preventDefault();
            this.defaultKeyPressed(event);
        }
    }

    /************************
    *                       *
    *   Annotation Methods  *
    *                       *
    *************************/
    private selectMultipleCharacters() {
        const charactersToMark = [this._annotationService.getCharacters()[0], this.displayEntity.getCharacterAtCursor()];

        const [char1, char2] = charactersToMark.sort(this.compareCharacters);

        const legalChars = this.legalChars();

        let currLegalChar = char1;
        const endLegalChar = char2;
        let currLegalIndex = legalChars.findIndex(character => character === currLegalChar);
        const endLegalIndex = legalChars.findIndex(character => character === endLegalChar);

        while (currLegalIndex < endLegalIndex) {
            this._annotationService.addCharacter(currLegalChar!);
            currLegalChar = this.legalStep(currLegalChar!);
            currLegalIndex = legalChars.findIndex(character => character === currLegalChar);
        }
    }

    // Helper function to compare characters based on page, row, and order
    compareCharacters(a: DisplayCharacter | undefined, b: DisplayCharacter | undefined) {
        if (!a || !b) {
            return 0;
        }

        if (a.page!.order !== b.page!.order) {
            return a.page!.order - b.page!.order;
        }

        if (a.row.order !== b.row.order) {
            return a.row.order - b.row.order;
        }

        return a.order - b.order;
    }

    private isLegalChar(character: DisplayCharacter): boolean {
        return !(character.isVirtual || character.utfCode == 9702);
    }

    private legalChars(): DisplayCharacter[] {
        let characters: DisplayCharacter[] = [];

        this.displayEntity.pages.forEach(page =>
            page.rows.forEach(row =>
                row.characters.forEach(character => {
                    if (this.isLegalChar(character))
                        characters.push(character);
                })));

        return characters;
    }

    private legalStep(currLegalChar: DisplayCharacter): DisplayCharacter {
        let characters: DisplayCharacter[] = this.legalChars();
        let currLegalIndex = characters.findIndex(character => character == currLegalChar);
        return characters[currLegalIndex + 1];
    }

    private onAnnotatedWordClick(character: DisplayCharacter): void {
        let word = this._annotationService.getWordFromCharacter(character);

        if (!word)
            return;

        this._annotationService.addWordToSentences(word);
    }

    private onAnnotationCharacterClick(): void {
        const character = this.displayEntity.getCharacterAtCursor();

        if (this.annotationLevel == 'sentence' && character) {
            this.onAnnotatedWordClick(character);
        }

        if (this.annotationLevel == 'word' && character && !character?.isVirtual && character?.utfCode != 9702) {
            this._annotationService.addCharacter(character);
        }
    }

    private getCurrentModeColor(): string {
        switch (this.annotationLevel) {
            case 'word': return this.colors.wordColor;
            case 'sentence': return this.colors.sentenceColor;

            default: return '';
        }
    }

    private annotationUpdateDisplayProcedures(divColumn: Element, character: DisplayCharacter) {
        if (!this.nestedMenuItems.some(x => x.id === 'clearSelections')) {
            const clearSelections: NavItem = {
                id: 'clearSelections',
                displayName: `Clear Selections`,
                iconName: 'clear',
                children: new Array<NavItem>()
            };

            this.nestedMenuItems.push(clearSelections);
        }

        if (this.annotationLevel === 'word') {
            this._annotationService.getCurrentWords().forEach(word => {
                if (this.isCharacterInWord(character, word)) {
                    let metaWord = this._renderer.createElement('meta');
                    this._renderer.setProperty(metaWord, 'name', 'word-hash');
                    this._renderer.setProperty(metaWord, 'content', word.wordHash);
                    this._renderer.appendChild(divColumn, metaWord);
                    this._renderer.addClass(divColumn, 'annotated-word');
                    this._renderer.listen(divColumn, 'mouseenter', (ev: MouseEvent) => {
                        if (ev.currentTarget instanceof Element) {
                            const meta: HTMLMetaElement | null = ev.currentTarget.querySelector('meta[name="word-hash"]');
                            if (meta) {
                                const found = document.querySelectorAll(`meta[name="word-hash"][content="${meta.content}"]`);
                                found.forEach(el => {
                                    this._renderer.setStyle(el.parentElement, 'background', this.colors.wordColor);
                                });
                            }
                        }
                    });

                    this._renderer.listen(divColumn, 'mouseleave', (ev: MouseEvent) => {
                        if (ev.currentTarget instanceof Element) {
                            const meta: HTMLMetaElement | null = ev.currentTarget.querySelector('meta[name="word-hash"]');
                            if (meta) {
                                const found = document.querySelectorAll(`meta[name="word-hash"][content="${meta.content}"]`);
                                found.forEach(el => {
                                    this._renderer.setStyle(el.parentElement, 'background', '#FFC107');
                                });
                            }
                        }
                    });
                }
            });

            if (this._annotationService.getCharacters().includes(character))
                this._renderer.addClass(divColumn, 'highlight');
        }

        if (this.annotationLevel == 'sentence') {
            this._annotationService.getCurrentWords().forEach(word => {
                if (this.isCharacterInWord(character, word)) {
                    let metaWord = this._renderer.createElement('meta');
                    this._renderer.setProperty(metaWord, 'name', 'word-hash');
                    this._renderer.setProperty(metaWord, 'content', word.wordHash);
                    this._renderer.appendChild(divColumn, metaWord);
                    this._renderer.addClass(divColumn, 'annotated-word-grey');
                }
            });

            const word = this._annotationService.getWordFromCharacter(character);
            let idx = 0;
            this._annotationService.sentences.forEach(sentence => {
                if (sentence.words.includes(word!)) {
                    this._renderer.addClass(divColumn, 'annotated-sentence' + (idx % 2 == 0 ? '-2' : ''));
                }
                idx++;
            });

            if (this._annotationService.wordsToSentence.includes(word!)) {
                $('div').find(`meta[name="word-hash"][content=${word!.wordHash}]`).parent().addClass('highlight');
            }

            // this.highlightSentence(divColumn, character);
        }
    }

    private isCharacterInWord(displayCharacter: DisplayCharacter, word: Word): boolean {
        return word.characters.some(character => {
            return character.isVirtual == displayCharacter.isVirtual &&
                character.order == displayCharacter.order &&
                character.owlName == displayCharacter.owlName &&
                character.owlOrder == displayCharacter.owlOrder &&
                character.utfCode == displayCharacter.utfCode;
        });
    }

    private highlightSentence(divColumn: Element, character: DisplayCharacter) {
        let word = this._annotationService.getWordFromCharacter(character)

        if (!word)
            return;

        this._annotationService.sentences.forEach(sentence => {
            const cssAttribute = 'is-sentence-' + sentence.sentenceHash;

            if (sentence.words.includes(word!)) {
                this._renderer.addClass(divColumn, cssAttribute);
            }

            $(`.${cssAttribute}`).hover(() => {
                $(`.${cssAttribute}`).css('background', this.colors.wordColor);
            }, function () {
                $(`.${cssAttribute}`).css('background', '#FFC107');
            });
        });


    }

    ngOnDestroy(): void {
        this._annotationService.clearEverything();
        console.log('Destroying DelaEditorComponent');
    }

    /*************************
    *                        *
    * Annotation Methods end *
    *                        *
    *************************/
    public getAutoPunctuatedText() {
        return CollationService.getFullText(this._annotationService, true);
    }
}
