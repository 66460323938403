<block-ui></block-ui>
<h2 mat-dialog-title>{{ isLocked ? 'View Actor' : 'New Actor' }} </h2>

<div mat-dialog-content class="content">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Generically depends on:</mat-label>
        <mat-select [(ngModel)]="selectedPerson">
            <mat-option *ngFor="let person of persons" [value]="person">{{ person.getDisplayName() }} ({{ getNameWithoutUri(person.name!) }})</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Is concretized by:</mat-label>
        <mat-select [(ngModel)]="selectedPersonRole">
            <mat-option *ngFor="let personRole of personRoles" [value]="personRole">{{ personRole.label }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()">Cancel</button>
</div>
