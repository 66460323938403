<block-ui></block-ui>
<h2 mat-dialog-title>Derivation</h2>

<div mat-dialog-content class="content">
    <div class="top">
        <div class="left">
            <ul>
                <li><strong>has role in MS</strong></li>
            </ul>
        </div>
        <div class="right">
            <div class="choice" style="margin-top: 8px;">
                <mat-checkbox>glyph (letter, mark, etc.) separator</mat-checkbox>
            </div>
            <div class="choice">
                <mat-checkbox>word separator</mat-checkbox>
            </div>
            <div>
                <mat-form-field appearance="fill" style="margin-bottom: -1.25em">
                    <mat-label>defined role</mat-label>
                    <mat-select>
                        <mat-option value="Role 1">Role 1</mat-option>
                        <mat-option value="Role 2">Role 2</mat-option>
                        <mat-option value="Role 3">Role 3</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-checkbox>remember selection</mat-checkbox>
            </div>
        </div>
    </div>
    <div class="bottom" style="margin-top: 10px;">
        <mat-checkbox>use default settings</mat-checkbox>
        <mat-checkbox>don't show this again</mat-checkbox>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()" >Cancel</button>
</div>
