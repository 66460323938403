import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class PersonRoleSparql {
    public static getPersonRoles(): string {
        var sparql = PREFIXES +
        `
        SELECT ?personRole ?label
        WHERE {
            ?personRole a ${SH.personRoleClass};
                ${SH.nameProperty} ?label
        }
        ORDER BY ?label
        `;

        return sparql;
    }
}
