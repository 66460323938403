import { Person } from "../models/jena/person";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class PersonSparql {
    public static getPersons(): string {
        var sparql = PREFIXES +
        `
        SELECT
        ?person
        ?firstName
        ?middleName
        ?lastName
        ?genericName
        ?nickName
        ?dayOfBirth
        ?monthOfBirth
        ?yearOfBirth
        ?dayOfDeath
        ?monthOfDeath
        ?yearOfDeath
        ?approximateDateOfBirthFrom
        ?approximateDateOfBirthTo
        ?approximateDateOfBirthBy
        ?approximateDateOfDeathFrom
        ?approximateDateOfDeathTo
        ?approximateDateOfDeathBy
        ?flourishedFrom
        ?flourishedTo
        ?flourishedBy
        WHERE {
            ?person a ${SH.personClass}.
            OPTIONAL{?person ${SH.firstNameProperty} ?firstName}.
            OPTIONAL{?person ${SH.middleNameProperty} ?middleName}.
            OPTIONAL{?person ${SH.lastNameProperty} ?lastName}.
            OPTIONAL{?person ${SH.genericNameProperty} ?genericName}.
            OPTIONAL{?person ${SH.nickNameProperty} ?nickName}.
            OPTIONAL{?person ${SH.dayOfBirthProperty} ?dayOfBirth}.
            OPTIONAL{?person ${SH.monthOfBirthProperty} ?monthOfBirth}.
            OPTIONAL{?person ${SH.yearOfBirthProperty} ?yearOfBirth}.
            OPTIONAL{?person ${SH.dayOfDeathProperty} ?dayOfDeath}.
            OPTIONAL{?person ${SH.monthOfDeathProperty} ?monthOfDeath}.
            OPTIONAL{?person ${SH.yearOfDeathProperty} ?yearOfDeath}.
            OPTIONAL{?person ${SH.approximateDateOfBirthFromProperty} ?approximateDateOfBirthFrom}.
            OPTIONAL{?person ${SH.approximateDateOfBirthToProperty} ?approximateDateOfBirthTo}.
            OPTIONAL{?person ${SH.approximateDateOfBirthByProperty} ?approximateDateOfBirthBy}.
            OPTIONAL{?person ${SH.approximateDateOfDeathFromProperty} ?approximateDateOfDeathFrom}.
            OPTIONAL{?person ${SH.approximateDateOfDeathToProperty} ?approximateDateOfDeathTo}.
            OPTIONAL{?person ${SH.approximateDateOfDeathByProperty} ?approximateDateOfDeathBy}.
            OPTIONAL{?person ${SH.flourishedFromProperty} ?flourishedFrom}.
            OPTIONAL{?person ${SH.flourishedToProperty} ?flourishedTo}.
            OPTIONAL{?person ${SH.flourishedByProperty} ?flourishedBy}.
            FILTER (STRSTARTS(str(?person), str(ent:)))
        }
        ORDER BY ?person
        `;

        return sparql;
    }

    public static createPerson(person: Person): string {

        var sparql = PREFIXES +
        `
        INSERT {
            ?newPerson a ${SH.personClass};
                ${person.firstName != null ? `${SH.firstNameProperty} "` + person.firstName + `";` : ''}
                ${person.middleName != null ? `${SH.middleNameProperty} "` + person.middleName + `";` : ''}
                ${person.lastName != null ? `${SH.lastNameProperty} "` + person.lastName + `";` : ''}
                ${person.genericName != null ? `${SH.genericNameProperty} "` + person.genericName + `";` : ''}
                ${person.nickName != null ? `${SH.nickNameProperty} "` + person.nickName + `";` : ''}
                ${person.dayOfBirth != null ? `${SH.dayOfBirthProperty} "` + person.dayOfBirth + `";` : ''}
                ${person.monthOfBirth != null ? `${SH.monthOfBirthProperty} "` + person.monthOfBirth + `";` : ''}
                ${person.yearOfBirth != null ? `${SH.yearOfBirthProperty} "` + person.yearOfBirth + `";` : ''}
                ${person.dayOfDeath != null ? `${SH.dayOfDeathProperty} "` + person.dayOfDeath + `";` : ''}
                ${person.monthOfDeath != null ? `${SH.monthOfDeathProperty} "` + person.monthOfDeath + `";` : ''}
                ${person.yearOfDeath != null ? `${SH.yearOfDeathProperty} "` + person.yearOfDeath + `";` : ''}
                ${person.approximateDateOfBirthFrom != null ? `${SH.approximateDateOfBirthFromProperty} "` + person.approximateDateOfBirthFrom + `";` : ''}
                ${person.approximateDateOfBirthTo != null ? `${SH.approximateDateOfBirthToProperty} "` + person.approximateDateOfBirthTo + `";` : ''}
                ${person.approximateDateOfBirthBy != null ? `${SH.approximateDateOfBirthByProperty} "` + person.approximateDateOfBirthBy + `";` : ''}
                ${person.approximateDateOfDeathFrom != null ? `${SH.approximateDateOfDeathFromProperty} "` + person.approximateDateOfDeathFrom + `";` : ''}
                ${person.approximateDateOfDeathTo != null ? `${SH.approximateDateOfDeathToProperty} "` + person.approximateDateOfDeathTo + `";` : ''}
                ${person.approximateDateOfDeathBy != null ? `${SH.approximateDateOfDeathByProperty} "` + person.approximateDateOfDeathBy + `";` : ''}
                ${person.flourishedFrom != null ? `${SH.flourishedFromProperty} "` + person.flourishedFrom + `";` : ''}
                ${person.flourishedTo != null ? `${SH.flourishedToProperty} "` + person.flourishedTo + `";` : ''}
                ${person.flourishedBy != null ? `${SH.flourishedByProperty} "` + person.flourishedBy + `";` : ''}
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'pers',STR(MAX(?res) + 1))) as ?newPerson)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{
                            ?z a ${SH.personClass}.
                            FILTER (STRSTARTS(str(?z), str(ent:)))
                        }.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + 'pers', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.personClass}.
                                FILTER (STRSTARTS(str(?s), str(ent:)))
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static updatePerson(person: Person): string {
        var sparql = PREFIXES +
        `
        DELETE {?ent ?x ?y}
        WHERE {
            ?ent ?x ?y.
            FILTER(?ent = IRI("${person.name}"))
            FILTER(?y != ${SH.personClass})
        };

        INSERT {
            ${person.firstName != null ? `?x ${SH.firstNameProperty} "` + person.firstName + `".` : ''}
            ${person.middleName != null ? `?x ${SH.middleNameProperty} "` + person.middleName + `".` : ''}
            ${person.lastName != null ? `?x ${SH.lastNameProperty} "` + person.lastName + `".` : ''}
            ${person.genericName != null ? `?x ${SH.genericNameProperty} "` + person.genericName + `".` : ''}
            ${person.nickName != null ? `?x ${SH.nickNameProperty} "` + person.nickName + `".` : ''}
            ${person.dayOfBirth != null ? `?x ${SH.dayOfBirthProperty} "` + person.dayOfBirth + `".` : ''}
            ${person.monthOfBirth != null ? `?x ${SH.monthOfBirthProperty} "` + person.monthOfBirth + `".` : ''}
            ${person.yearOfBirth != null ? `?x ${SH.yearOfBirthProperty} "` + person.yearOfBirth + `".` : ''}
            ${person.dayOfDeath != null ? `?x ${SH.dayOfDeathProperty} "` + person.dayOfDeath + `".` : ''}
            ${person.monthOfDeath != null ? `?x ${SH.monthOfDeathProperty} "` + person.monthOfDeath + `".` : ''}
            ${person.yearOfDeath != null ? `?x ${SH.yearOfDeathProperty} "` + person.yearOfDeath + `".` : ''}
            ${person.approximateDateOfBirthFrom != null ? `?x ${SH.approximateDateOfBirthFromProperty} "` + person.approximateDateOfBirthFrom + `".` : ''}
            ${person.approximateDateOfBirthTo != null ? `?x ${SH.approximateDateOfBirthToProperty} "` + person.approximateDateOfBirthTo + `".` : ''}
            ${person.approximateDateOfBirthBy != null ? `?x ${SH.approximateDateOfBirthByProperty} "` + person.approximateDateOfBirthBy + `".` : ''}
            ${person.approximateDateOfDeathFrom != null ? `?x ${SH.approximateDateOfDeathFromProperty} "` + person.approximateDateOfDeathFrom + `".` : ''}
            ${person.approximateDateOfDeathTo != null ? `?x ${SH.approximateDateOfDeathToProperty} "` + person.approximateDateOfDeathTo + `".` : ''}
            ${person.approximateDateOfDeathBy != null ? `?x ${SH.approximateDateOfDeathByProperty} "` + person.approximateDateOfDeathBy + `".` : ''}
            ${person.flourishedFrom != null ? `?x ${SH.flourishedFromProperty} "` + person.flourishedFrom + `".` : ''}
            ${person.flourishedTo != null ? `?x ${SH.flourishedToProperty} "` + person.flourishedTo + `".` : ''}
            ${person.flourishedBy != null ? `?x ${SH.flourishedByProperty} "` + person.flourishedBy + `".` : ''}
        }
        WHERE {
            ?x a ${SH.personClass}.
            FILTER(?x = IRI("${person.name}"))
        };
        `;

        return sparql;
    }

    public static deletePerson(person: Person): string {
        var sparql = PREFIXES +
        `
        DELETE { ?x ?y ?z }
        WHERE {
        ?x a ${SH.personClass}.
        ?x ?y ?z.
        FILTER(?x = IRI("${person.name}"))
        }
        `;

        return sparql;
    }

}
