import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { School } from 'src/app/models/jena/school';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogSchoolManageComponent } from './dialog-school-manage/dialog-school-manage.component';

@Component({
    selector: 'app-school-list',
    templateUrl: './school-list.component.html',
    styleUrls: ['./school-list.component.scss']
})
export class SchoolListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    displayedColumns: string[] = ['name', 'institution', 'fullName', 'street', 'city', 'state', 'actions'];
    dataSource: MatTableDataSource<School>;
    public schools: Array<School>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar) {
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.dataSource = new MatTableDataSource();
        this.schools = new Array<School>();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getSchools(this.entityId!);
        }
    }

    private _getSchools(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getSchools(datasetName).subscribe({
            next: (result: Array<School>) => {
                this.schools = result;
                this.dataSource.data = this.schools;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public new() {
        const dialogRef = this._dialog.open(DialogSchoolManageComponent, {
            width: '800px',
            data: {
                school: new School(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.createSchool(this.entityId!, result.school, result.institution).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new school', '', Helpers.getGreenSnackbarConfig());
                            this._getSchools(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while creating new school', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public delete(school: School) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '480px',
            data: {
                titleMessage: `Delete school ${school.name}`,
                infoMessage: 'Are you sure you want to delete this school?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteSchool(this.entityId!, school).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted school', '', Helpers.getGreenSnackbarConfig());
                            this._getSchools(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting school', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public edit(school: School) {
        const dialogRef = this._dialog.open(DialogSchoolManageComponent, {
            width: '800px',
            data: {
                school: school,
                entityId: this.entityId,
                isEdit: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.updateSchool(this.entityId!, result.school, result.institution).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully updated school', '', Helpers.getGreenSnackbarConfig());
                            this._getSchools(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while updating school', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public view(school: School) {
        this._dialog.open(DialogSchoolManageComponent, {
            width: '800px',
            data: {
                school: school,
                entityId: this.entityId,
                isLocked: true
            }
        });
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }
}
