import { sha256 } from "js-sha256";
import { DisplayCharacter } from "./display/displayCharacter";
import { Sentence } from "./sentence";

export class Word {
    characters: Array<DisplayCharacter>;
    wordHash: string;
    sentence?: Sentence;
    index?: number;

    constructor(characters: Array<DisplayCharacter>, index?: number) {
        this.characters = characters;
        let hashData = '';
        characters.forEach(char => {
            hashData += char.order.toString() + char.owlOrder?.toString() + char.utfCode.toString();
        });
        this.wordHash = sha256(hashData);
        this.index = index;
    }

    toString(): string {
        let word = '';
        this.characters.forEach(char => {
            word += String.fromCharCode(char.utfCode);
        });
        return word;
    }
}
