import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Actor } from 'src/app/models/jena/actor';
import { Tool } from 'src/app/models/jena/tool';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { Manus } from 'src/app/models/jena/manus';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';

@Component({
  selector: 'app-dialog-manus-manage',
  templateUrl: './dialog-manus-manage.component.html',
  styleUrls: ['./dialog-manus-manage.component.scss']
})
export class DialogManusManageComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;
    public isLocked = false;
    public manus: Manus;
    public actors: Array<Actor>;
    public tools: Array<Tool>;
    public selectedActor?: Actor;
    public selectedTool?: Tool;

    constructor(private _fusekiService: FusekiService,
        private _dialog: MatDialog,
        private _activatedRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogManusManageComponent>
    ) {
        this.manus = this.data.manus;
        this.entityId = data.entityId
        this.actors = new Array<Actor>();
        this.tools = new Array<Tool>();

        if (!Helpers.isNullOrEmpty(this.manus.name!)) {
            this.isLocked = true;
        }
        this._getActors(this.entityId!);
        this._getTools(this.entityId!);
    }

    ngOnInit(): void {
    }

    private _getActors(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getActors(datasetName).subscribe({
            next: (result: Array<Actor>) => {
                this.actors = result;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    private _getTools(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getTools(datasetName).subscribe({
            next: (result: Array<Tool>) => {
                this.tools = result;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public okClicked() {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: 'Create new manus',
                infoMessage: 'Are you sure you want to create a new manus?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close({actor: this.selectedActor, tool: this.selectedTool});
            }
        });
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }

}
