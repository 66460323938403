import { Device } from "../models/jena/device";
import { Manuscript } from "../models/jena/manuscript";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class ManuscriptSparql {
    public static getManuscripts(): string {
        var sparql = PREFIXES +
            `
        SELECT
        ?manuscript
        ?genericName
        ?siglum
        ?device
        WHERE {
            ?manuscript a ${SH.manuscriptClass};
                ${SH.continuantPartOfAtSomeTime} ?device.
            OPTIONAL{?device ${SH.genericNameProperty} ?genericName}.
            OPTIONAL{?device ${SH.siglumProperty} ?siglum}.
            FILTER (STRSTARTS(str(?manuscript), str(ent:)))
        }
        ORDER BY ?manuscript
        `;

        return sparql;
    }

    public static createManuscript(manuscript: Manuscript, device: Device): string {

        var sparql = PREFIXES +
            `
        INSERT {
            ?newManuscript a ${SH.manuscriptClass};
                ${SH.continuantPartOfAtSomeTime} ?device;
                ${manuscript.genericName != null ? `${SH.genericNameProperty} "` + manuscript.genericName + `";` : ''}
                ${manuscript.siglum != null ? `${SH.siglumProperty} "` + manuscript.siglum + `";` : ''}
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'ms',STR(MAX(?res) + 1))) as ?newManuscript)

            (IRI('${device.name}') as ?device)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{
                            ?z a ${SH.manuscriptClass}.
                            FILTER (STRSTARTS(str(?z), str(ent:)))
                        }.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + 'ms', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.manuscriptClass}.
                                FILTER (STRSTARTS(str(?s), str(ent:)))
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };

        INSERT {
            ent:depleaf1 ${SH.memberPartOfAtAllTimes} ?ms
        }
        WHERE {
            ?ms a ${SH.manuscriptClass}
        }
        `;

        return sparql;
    }

    public static duplicateManuscript(manuscriptName: string): string {
        var sparql = PREFIXES +
            `
            SELECT ?s ?p ?o
            WHERE {
                GRAPH <urn:x-arq:DefaultGraph> {
                    ?s ?p ?o
                }
            }
            `;

        return sparql;
    }

    public static deleteManuscript(manuscript: Manuscript): string {
        var sparql = PREFIXES +
            `
        DELETE { ?x ?y ?z }
        WHERE {
        ?x a ${SH.manuscriptClass}.
        ?x ?y ?z.
        FILTER(?x = IRI("${manuscript.name}"))
        }
        `;

        return sparql;
    }

}
