import * as $ from "jquery";
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BlockUIModule } from 'ng-block-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfigInitService } from './init/config-init.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DialogInfoComponent } from './components/shared/dialog-info/dialog-info.component';
import { DialogYesNoComponent } from './components/shared/dialog-yes-no/dialog-yes-no.component';
import { EditEntityComponent } from './components/entities/edit/edit-entity.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormEntityComponent } from './components/entities/form/form-entity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LeftComponent } from './components/left/left.component';
import { ListEntitiesComponent } from './components/entities/list/list-entities.component';
import { LoginComponent } from './components/login/login.component';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MaterialModule } from './modules/material.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NewEntityComponent } from './components/entities/new/new-entity.component';
import { OverlayModule } from '@angular/cdk/overlay';
import * as Sentry from "@sentry/angular-ivy";
import { initializeKeycloak } from './init/keycloak-init.factory';
import { DialogParticipantsComponent } from "./components/entities/list/dialog-participants/dialog-participants.component";
import { MenuItemComponent } from './components/shared/menu-item/menu-item.component';
import { FormUserComponent } from "./components/admin/users/form/form-user.component";
import { NewUserComponent } from "./components/admin/users/new/new-user.component";
import { EditUserComponent } from "./components/admin/users/edit/edit-user.component";
import { ListUsersComponent } from "./components/admin/users/list/list-users.component";
import { DialogRolesUserComponent } from "./components/admin/users/list/dialog-roles-user/dialog-roles-user.component";
import { ManageOwlItemsComponent } from './components/admin/owlItems/manage-owl-items/manage-owl-items.component';
import { DialogEditOwlItemComponent } from './components/admin/owlItems/manage-owl-items/dialog-edit-owl-item/dialog-edit-owl-item.component';
import { DialogAddOwlItemComponent } from './components/admin/owlItems/manage-owl-items/dialog-add-owl-item/dialog-add-owl-item.component';
import { BoardComponent } from './components/entities/board/board.component';
import { AngularEditorModule } from "@kolkov/angular-editor";
import { DialogDerivationComponent } from './components/entities/dialog-derivation/dialog-derivation.component';
import { DelaEditorComponent } from './components/entities/dela-editor/dela-editor.component';
import { DialogSpaceDerivationComponent } from './components/entities/dialog-space-derivation/dialog-space-derivation.component';
import { EditionComponent } from './components/entities/edition/edition.component';
import { CriticalEditingComponent } from './components/entities/critical-editing/critical-editing.component';
import { CollationComponent } from './components/entities/collation/collation.component';
import { DialogOntologyInfoComponent } from "./components/entities/dialog-info/dialog-info.component";
import { DialogToolManageComponent } from './components/entities/common/tool-list/dialog-tool-manage/dialog-tool-manage.component';
import { ActorListComponent } from './components/entities/common/actor-list/actor-list.component';
import { ToolListComponent } from './components/entities/common/tool-list/tool-list.component';
import { PersonListComponent } from './components/entities/common/person-list/person-list.component';
import { CommonTopComponent } from './components/entities/common/common-top/common-top.component';
import { DialogPersonManageComponent } from './components/entities/common/person-list/dialog-person-manage/dialog-person-manage.component';
import { DialogActorManageComponent } from './components/entities/common/actor-list/dialog-actor-manage/dialog-actor-manage.component';
import { ManusListComponent } from './components/entities/common/manus-list/manus-list.component';
import { DialogManusManageComponent } from './components/entities/common/manus-list/dialog-manus-manage/dialog-manus-manage.component';
import { InstitutionListComponent } from './components/entities/common/institution-list/institution-list.component';
import { SchoolListComponent } from './components/entities/common/school-list/school-list.component';
import { DepartmentListComponent } from './components/entities/common/department-list/department-list.component';
import { TeamListComponent } from './components/entities/common/team-list/team-list.component';
import { FacultyListComponent } from './components/entities/common/faculty-list/faculty-list.component';
import { DialogDepartmentManageComponent } from './components/entities/common/department-list/dialog-department-manage/dialog-department-manage.component';
import { DialogFacultyManageComponent } from './components/entities/common/faculty-list/dialog-faculty-manage/dialog-faculty-manage.component';
import { DialogInstitutionManageComponent } from './components/entities/common/institution-list/dialog-institution-manage/dialog-institution-manage.component';
import { DialogSchoolManageComponent } from './components/entities/common/school-list/dialog-school-manage/dialog-school-manage.component';
import { DialogTeamManageComponent } from './components/entities/common/team-list/dialog-team-manage/dialog-team-manage.component';
import { DeviceListComponent } from './components/entities/common/device-list/device-list.component';
import { DialogDeviceManageComponent } from './components/entities/common/device-list/dialog-device-manage/dialog-device-manage.component';
import { ManuscriptListComponent } from './components/entities/common/manuscript-list/manuscript-list.component';
import { DialogManuscriptManageComponent } from './components/entities/common/manuscript-list/dialog-manuscript-manage/dialog-manuscript-manage.component';
import { DialogInitialComponent } from './components/entities/dialog-initial/dialog-initial.component';
import { AuxiliaryEditorComponent } from './components/entities/auxiliary-editor/auxiliary-editor.component';
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { appReducers } from "./store/reducers/app.reducers";
import { DxHtmlEditorModule } from "devextreme-angular";

import { OverallInfoComponent } from './components/entities/overall-info/overall-info.component';
import { DialogIntroComponent } from './components/dialog-intro/dialog-intro.component';
import {Router} from "@angular/router";
import {MatFormFieldModule} from "@angular/material/form-field";
import {
    DialogAutoPunctuationComponent
} from "./components/entities/dialog-autopuntucation/dialog-auto-punctuation.component";
@NgModule({
    declarations: [
        AppComponent,
        LeftComponent,
        LoginComponent,
        NavbarComponent,
        DialogInfoComponent,
        DialogYesNoComponent,
        NewEntityComponent,
        EditEntityComponent,
        ListUsersComponent,
        FormEntityComponent,
        ListEntitiesComponent,
        EditUserComponent,
        NewUserComponent,
        FormUserComponent,
        DialogRolesUserComponent,
        DialogParticipantsComponent,
        MenuItemComponent,
        ManageOwlItemsComponent,
        DialogEditOwlItemComponent,
        DialogAddOwlItemComponent,
        BoardComponent,
        DialogDerivationComponent,
        DelaEditorComponent,
        DialogSpaceDerivationComponent,
        EditionComponent,
        CollationComponent,
        CriticalEditingComponent,
        DialogOntologyInfoComponent,
        DialogToolManageComponent,
        ActorListComponent,
        ToolListComponent,
        PersonListComponent,
        CommonTopComponent,
        DialogPersonManageComponent,
        DialogActorManageComponent,
        ManusListComponent,
        DialogManusManageComponent,
        InstitutionListComponent,
        SchoolListComponent,
        DepartmentListComponent,
        TeamListComponent,
        FacultyListComponent,
        DialogDepartmentManageComponent,
        DialogFacultyManageComponent,
        DialogInstitutionManageComponent,
        DialogSchoolManageComponent,
        DialogTeamManageComponent,
        DeviceListComponent,
        DialogDeviceManageComponent,
        ManuscriptListComponent,
        DialogManuscriptManageComponent,
        DialogInitialComponent,
        AuxiliaryEditorComponent,
        OverallInfoComponent,
        DialogIntroComponent,
        DialogAutoPunctuationComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BlockUIModule.forRoot(),
        BrowserAnimationsModule,
        FlexLayoutModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule,
        HttpClientModule,
        KeycloakAngularModule,
        MaterialModule,
        AngularEditorModule,
        StoreModule.forRoot(appReducers),
        StoreDevtoolsModule.instrument(),
        DxHtmlEditorModule,
        MatFormFieldModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [
                KeycloakService,
                ConfigInitService
            ],
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [ Router ]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
