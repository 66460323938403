import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Device } from 'src/app/models/jena/device';
import { Manuscript } from 'src/app/models/jena/manuscript';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-dialog-manuscript-manage',
    templateUrl: './dialog-manuscript-manage.component.html',
    styleUrls: ['./dialog-manuscript-manage.component.scss']
})
export class DialogManuscriptManageComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;
    public manuscript: Manuscript;
    public isLocked = false;
    public devices: Array<Device>;
    public selectedDevice?: Device;

    constructor(private _fusekiService: FusekiService,
        private _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogManuscriptManageComponent>,) {
            this.manuscript = this.data.manuscript;
            this.entityId = data.entityId
            this.devices = new Array<Device>();

            if (!Helpers.isNullOrEmpty(this.manuscript.name!)) {
                this.isLocked = true;
            }

            this._getDevices(this.entityId!);
        }

    ngOnInit(): void {
    }

    private _getDevices(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getDevices(datasetName).subscribe({
            next: (result: Array<Device>) => {
                this.devices = result;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public okClicked() {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: 'Create new manuscript',
                infoMessage: 'Are you sure you want to create a new manuscript?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close({manuscript: this.manuscript, device: this.selectedDevice});
            }
        });
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }

}
