import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Actor } from 'src/app/models/jena/actor';
import { Person } from 'src/app/models/jena/person';
import { PersonRole } from 'src/app/models/jena/personRole';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-dialog-actor-manage',
    templateUrl: './dialog-actor-manage.component.html',
    styleUrls: ['./dialog-actor-manage.component.scss']
})
export class DialogActorManageComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;
    public isLocked = false;
    public actor: Actor;
    public persons: Array<Person>;
    public personRoles: Array<PersonRole>;
    public selectedPerson?: Person;
    public selectedPersonRole?: PersonRole;

    constructor(private _fusekiService: FusekiService,
        private _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogActorManageComponent>
    ) {
        this.actor = this.data.actor;
        this.entityId = data.entityId
        this.persons = new Array<Person>();
        this.personRoles = new Array<PersonRole>();

        if (!Helpers.isNullOrEmpty(this.actor.name!)) {
            this.isLocked = true;
        }
        this._getPersons(this.entityId!);
        this._getPersonRoles(this.entityId!);
    }

    ngOnInit(): void {
    }

    private _getPersons(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getPersons(datasetName).subscribe({
            next: (result: Array<Person>) => {
                this.persons = result;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    private _getPersonRoles(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getPersonRoles(datasetName).subscribe({
            next: (result: Array<PersonRole>) => {
                this.personRoles = result;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }


    public okClicked() {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: 'Create new actor',
                infoMessage: 'Are you sure you want to create a new actor?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close({person: this.selectedPerson, personRole: this.selectedPersonRole});
            }
        });
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }

}
