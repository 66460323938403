export class User {
    id?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    roles?: Array<string>;
    ssoId?: string;
    acceptedCookies?: boolean;
}
