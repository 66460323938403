<block-ui></block-ui>
<div class="content">
    <mat-card>
        <mat-card-title>
            <app-common-top [title]="'Manuscript List'" [entityId]="entityId" (newClick)="new()"></app-common-top>
        </mat-card-title>
        <mat-card-content>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <!-- <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox
                                (change)="onSelectedChange($event)"
                                (click)="$event.stopPropagation()">
                        </mat-checkbox>
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{getNameWithoutUri(element.name)}} <mat-icon matTooltip="{{ element.name }}">info</mat-icon> </td>
                    </ng-container>

                    <ng-container matColumnDef="genericName">
                        <th mat-header-cell *matHeaderCellDef> Generic Name </th>
                        <td mat-cell *matCellDef="let element"> {{ element.genericName }} </td>
                    </ng-container>

                    <ng-container matColumnDef="siglum">
                        <th mat-header-cell *matHeaderCellDef> Siglum </th>
                        <td mat-cell *matCellDef="let element"> {{ element.siglum }} </td>
                    </ng-container>

                    <ng-container matColumnDef="continuantPartOf">
                        <th mat-header-cell *matHeaderCellDef> Continuant part of</th>
                        <td mat-cell *matCellDef="let element"> {{getNameWithoutUri(element.device.name)}} <mat-icon matTooltip="{{ element.device.name }}">info</mat-icon> </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="delete(element)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                            No data found...
                        </td>
                    </tr>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>
