import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { EntityService } from 'src/app/services/entity.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-edit-entity',
    templateUrl: './edit-entity.component.html',
    styleUrls: ['./edit-entity.component.scss']
})
export class EditEntityComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    constructor(private router: Router,
        private _entityService: EntityService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _activatedRoute: ActivatedRoute) {
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
    }

    ngOnInit(): void {
    }

    public goToListActors() {
        this._router.navigate([`list-actors/${this.entityId}`]);
    }

    public goToListDepartments() {
        this._router.navigate([`list-departments/${this.entityId}`]);
    }

    public goToListDevices() {
        this._router.navigate([`list-devices/${this.entityId}`]);
    }

    public goToListFaculties() {
        this._router.navigate([`list-faculties/${this.entityId}`]);
    }

    public goToListInstitutions() {
        this._router.navigate([`list-institutions/${this.entityId}`]);
    }

    public goToListManuses() {
        this._router.navigate([`list-manuses/${this.entityId}`]);
    }

    public goToListManuscripts() {
        this._router.navigate([`list-manuscripts/${this.entityId}`]);
    }

    public goToListPersons() {
        this._router.navigate([`list-persons/${this.entityId}`]);
    }

    public goToListSchools() {
        this._router.navigate([`list-schools/${this.entityId}`]);
    }

    public goToListTeams() {
        this._router.navigate([`list-teams/${this.entityId}`]);
    }

    public goToListTools() {
        this._router.navigate([`list-tools/${this.entityId}`]);
    }

    public showCharacterInfo() {
        this._blockUI.start();

        this._entityService.updateEntityByDefaultShowCharacterInfo(this.entityId!, true).subscribe(
            (result) => {
                this._blockUI.stop();
                this._snackBar.open('Updated successfully!', '', Helpers.getGreenSnackbarConfig());
                this.router.navigateByUrl('/edit-entity/' + this.entityId);
            },
            (error) => {
                this._blockUI.stop();
                console.log(error);
                this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
            }
        );
    }

}
