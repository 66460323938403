import { AuxiliaryArea } from "../models/enums/auxiliaryArea";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class AuxiliarySparql {
    public static getAuxiliaryContent(auxiliaryArea: AuxiliaryArea): string {
        var projection = '';

        switch(auxiliaryArea) {
            case AuxiliaryArea.InRow:
                projection = SH.projectedOnTheLeftOf;
                break;
            case AuxiliaryArea.Over:
                projection = SH.projectedAsOverlapping;
                break;
            case AuxiliaryArea.Above:
                projection = SH.projectedAbove;
                break;
            case AuxiliaryArea.Below:
                projection = SH.projectedBelow;
                break;
            default:
                break;
        }

        var sparql = PREFIXES +
        `
        SELECT ?stroke ?row ?rowOrder ?column ?columnOrder ?letterDescription ?utf ?letterDescriptionOfOrigin ?utfOfOrigin
        WHERE {
            ?row ${projection} ?stroke ;
                ${SH.orderProperty} ?rowOrder .
            OPTIONAL {?characterOfOrigin ${SH.hasAncestor} ?stroke . }
            OPTIONAL {
                ?characterOfOrigin ${SH.isConcretizedByAtSomeTime} ?letterOfOrigin;
                    ${SH.hasAncestor} ?strokeInnerOfOrigin.
                ?strokeInnerOfOrigin ${SH.locatedInAtSomeTime} ?columnOfOrigin .
                ?letterOfOrigin rdfs:label ?letterDescriptionOfOrigin ;
                    ${SH.unicodeCodePointProperty} ?utfOfOrigin .
            }
            OPTIONAL {
                ?column a ${SH.columnClass} ;
                    ${SH.orderProperty} ?columnOrder ;
                    ${SH.locatedInAtSomeTime} ?row .
                OPTIONAL {
                    ?character ${SH.isConcretizedByAtSomeTime} ?letter;
                        ${SH.hasAncestor} ?strokeInner.
                    ?strokeInner ${SH.locatedInAtSomeTime} ?column .
                    ?letter rdfs:label ?letterDescription ;
                        ${SH.unicodeCodePointProperty} ?utf .
                }

            }
        }
        ORDER BY desc(?stroke) ASC(?rowOrder) ASC(?columnOrder)
        `;

        return sparql;
    }

    public static initializeAuxiliary(column: string, auxiliaryArea: AuxiliaryArea): string {
        var projection = '';

        switch(auxiliaryArea) {
            case AuxiliaryArea.InRow:
                projection = SH.projectedOnTheLeftOf;
                break;
            case AuxiliaryArea.Over:
                projection = SH.projectedAsOverlapping;
                break;
            case AuxiliaryArea.Above:
                projection = SH.projectedAbove;
                break;
            case AuxiliaryArea.Below:
                projection = SH.projectedBelow;
                break;
            default:
                break;
        }

        var sparql = PREFIXES +
        `
        INSERT {
            ?new a ${SH.rowClass} ;
                ${projection} ?stroke ;
                ${SH.orderProperty} 1 ;
        }
        WHERE {
            {
                ?stroke a ${SH.strokeClass};
                    ${SH.locatedInAtSomeTime} <${column}>
            }
            {
                SELECT (IRI(CONCAT(STR(ent:), 'row', STR(MAX(?nextRow)))) as ?new)
                WHERE {
                    SELECT (MAX(?currentRow + 1) as ?nextRow)
                    WHERE {
                        ?row a ${SH.rowClass}.
                        BIND(xsd:integer(REPLACE(STR(?row), STR(ent:) + 'row', '')) as ?currentRow).
                    }
                }
            }
        }

        `;

        return sparql;
    }
    public static addCharacter(rowName: string, character: string, position: number | undefined): string {
        var updateOrders =
        `
        DELETE {
            ?s ${SH.orderProperty} ?oldOrder
        }
        INSERT {
            ?s ${SH.orderProperty} ?newOrder
        }
        WHERE {
            ?row a ${SH.rowClass} .
            FILTER(?row = ent:${rowName}) .
            ?s a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ?oldOrder .
            FILTER(?oldOrder >= ${position})
            BIND(?oldOrder + 1 as ?newOrder)
        };
        `;

        var strokeTriplets = `
        ?newStroke a ${SH.strokeClass} ;
            ${SH.locatedInAtSomeTime} ?newColumn ;
            ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'}.
        `;

        var strokeAndLetterQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'str', STR(MAX(?res) + 1))) as ?newStroke)
            WHERE {
                {
                    SELECT ?counter ?stroke
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.strokeClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'str', '')) as ?stroke).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.strokeClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?stroke)  as ?res)
            }
        }
        {
            SELECT ?letter
            WHERE {
            ?letter a/rdfs:subClassOf* ${SH.letterClass} ;
                ${SH.unicodeCharacterProperty} '${character}' .
            }
        }
        `;

        var characterTriplets = `
        ?newCharacter a ${SH.characterClass} ;
            ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'} ;
            ${SH.isConcretizedByAtSomeTime} ?letter;
            ${SH.hasAncestor} ?newStroke .
        `;

        var characterQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'c', STR(MAX(?res) + 1))) as ?newCharacter)
            WHERE {
                {
                    SELECT ?counter ?character
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.characterClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'c', '')) as ?character).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.characterClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?character)  as ?res)
            }
        }
        `;

        var sparql = PREFIXES +
        `
        ${position != undefined ? updateOrders : ''}
        INSERT {
            ?newColumn a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'} .
            ${strokeTriplets}
            ${characterTriplets}
        }
        WHERE {
            {
                SELECT ?row
                WHERE {
                    ?row a ${SH.rowClass} .
                    FILTER(?row = ent:${rowName}) .
                }
            }
            {
                SELECT (IRI(CONCAT(STR(ent:), 'cl', STR(MAX(?res) + 1))) as ?newColumn)
                WHERE {
                    {
                        SELECT ?counter ?column
                        WHERE {
                            OPTIONAL{?s a ${SH.columnClass}}.
                            BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'cl', '')) as ?column).
                            {
                                SELECT (count(?s) as ?counter)
                                WHERE {
                                    ?s a ${SH.columnClass}
                                }
                            }
                        }
                    }.
                    BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
                }
            }
            ${strokeAndLetterQuery}
            ${characterQuery}
            {
                SELECT ((?res + 1) as ?nextOrder)
                WHERE {
                    {
                        SELECT (MAX(?order) as ?maxOrder)
                        WHERE {
                            ?row a ${SH.rowClass} .
                            FILTER(?row = ent:${rowName}) .
                            ?col a ${SH.columnClass} ;
                                ${SH.locatedInAtSomeTime} ?row ;
                                ${SH.orderProperty} ?order .
                        }
                    }
                    {
                        SELECT (count(?s) as ?counter)
                        WHERE {
                            ?s a ${SH.columnClass}
                        }
                    }.
                    BIND(if(?counter=xsd:integer(0),?counter,?maxOrder)  as ?res)
                }
            }
        };
        `;

        return sparql;
    }

    public static addSpace(rowName: string, position: number | undefined): string {
        var updateOrders =
        `
        DELETE {
            ?s ${SH.orderProperty} ?oldOrder
        }
        INSERT {
            ?s ${SH.orderProperty} ?newOrder
        }
        WHERE {
            ?row a ${SH.rowClass} .
            FILTER(?row = ent:${rowName}) .
            ?s a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ?oldOrder .
            FILTER(?oldOrder >= ${position})
            BIND(?oldOrder + 1 as ?newOrder)
        };
        `;

        var whitespaceTriplets = `
        ?newWhitespace a ${SH.whitespaceClass} ;
            ${SH.locatedInAtSomeTime} ?newColumn ;
            ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'}.
        `;

        var whitespaceAndLetterQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'wsp', STR(MAX(?res) + 1))) as ?newWhitespace)
            WHERE {
                {
                    SELECT ?counter ?whitespace
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.whitespaceClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'wsp', '')) as ?whitespace).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.whitespaceClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?whitespace)  as ?res)
            }
        }
        `;

        var characterTriplets = `
        ?newCharacter a ${SH.characterClass} ;
            ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'} ;
            ${SH.isConcretizedByAtSomeTime} ${SH.emptySetIndividual};
            ${SH.hasAncestor} ?newWhitespace .
        `;

        var characterQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'c', STR(MAX(?res) + 1))) as ?newCharacter)
            WHERE {
                {
                    SELECT ?counter ?character
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.characterClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'c', '')) as ?character).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.characterClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?character)  as ?res)
            }
        }
        `;

        var sparql = PREFIXES +
        `
        ${position != undefined ? updateOrders : ''}
        INSERT {
            ?newColumn a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'} .
            ${whitespaceTriplets}
            ${characterTriplets}
        }
        WHERE {
            {
                SELECT ?row
                WHERE {
                    ?row a ${SH.rowClass} .
                    FILTER(?row = ent:${rowName}) .
                }
            }
            {
                SELECT (IRI(CONCAT(STR(ent:), 'cl', STR(MAX(?res) + 1))) as ?newColumn)
                WHERE {
                    {
                        SELECT ?counter ?column
                        WHERE {
                            OPTIONAL{?s a ${SH.columnClass}}.
                            BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'cl', '')) as ?column).
                            {
                                SELECT (count(?s) as ?counter)
                                WHERE {
                                    ?s a ${SH.columnClass}
                                }
                            }
                        }
                    }.
                    BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
                }
            }
            ${whitespaceAndLetterQuery}
            ${characterQuery}
            {
                SELECT ((?res + 1) as ?nextOrder)
                WHERE {
                    {
                        SELECT (MAX(?order) as ?maxOrder)
                        WHERE {
                            ?row a ${SH.rowClass} .
                            FILTER(?row = ent:${rowName}) .
                            ?col a ${SH.columnClass} ;
                                ${SH.locatedInAtSomeTime} ?row ;
                                ${SH.orderProperty} ?order .
                        }
                    }
                    {
                        SELECT (count(?s) as ?counter)
                        WHERE {
                            ?s a ${SH.columnClass}
                        }
                    }.
                    BIND(if(?counter=xsd:integer(0),?counter,?maxOrder)  as ?res)
                }
            }
        };
        `;

        return sparql;
    }

    public static deleteCharacter(rowName: string, position: number, isBackspace: boolean): string {
        var updateOrders = `
        DELETE {
            ?s ${SH.orderProperty} ?oldOrder
        }
        INSERT {
            ?s ${SH.orderProperty} ?newOrder
        }
        WHERE {
            ?row a ${SH.rowClass} .
            FILTER(?row = ent:${rowName}) .
            ?s a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ?oldOrder .
            FILTER(?oldOrder >= ${isBackspace ? position : position + 1})
            BIND(?oldOrder - 1 as ?newOrder)
        };
        `;

        var sparql = PREFIXES +
        `
        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?row a ${SH.rowClass} .
            FILTER(?row = ent:${rowName}) .
            ?col a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position} .
            ?stroke a ${SH.strokeClass};
            ${SH.locatedInAtSomeTime} ?col .
            ?x ?y ?z .
            ?x a ${SH.characterClass};
                ${SH.hasAncestor} ?stroke .
        };

        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?row a ${SH.rowClass} .
            FILTER(?row = ent:${rowName}) .
            ?col a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position} .
            ?x ?y ?z .
            ?x a ${SH.strokeClass};
                ${SH.locatedInAtSomeTime} ?col .
        };

        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?x ?y ?z .
            ?row a ${SH.rowClass} .
            FILTER(?row = ent:${rowName}) .
            ?col a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position} .
            FILTER(?z = ?col)
        };

        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?x ?y ?z .
            ?row a ${SH.rowClass} .
            FILTER(?row = ent:${rowName}) .
            ?col a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position} .
            FILTER(?x = ?col)
        };

        ${updateOrders}
        `;

        return sparql;
    }

    public static addRow(strokeName: string, auxiliaryArea: AuxiliaryArea) {
        var projection = '';

        switch(auxiliaryArea) {
            case AuxiliaryArea.InRow:
                projection = SH.projectedOnTheLeftOf;
                break;
            case AuxiliaryArea.Over:
                projection = SH.projectedAsOverlapping;
                break;
            case AuxiliaryArea.Above:
                projection = SH.projectedAbove;
                break;
            case AuxiliaryArea.Below:
                projection = SH.projectedBelow;
                break;
            default:
                break;
        }

        var sparql = PREFIXES + `
        INSERT {
            ?new a ${SH.rowClass} ;
                ${projection} ent:${strokeName} ;
                ${SH.orderProperty} ?nextOrder .
        }
        WHERE {
            {
                SELECT (MAX(?order + 1) as ?nextOrder)
                WHERE {
                    ?currentRow ${projection} ent:${strokeName} ;
                        ${SH.orderProperty} ?order .
                }
            }
            {
                SELECT (IRI(CONCAT(STR(ent:), 'row', STR(MAX(?nextRow)))) as ?new)
                WHERE {
                    SELECT (MAX(?currentRow + 1) as ?nextRow)
                    WHERE {
                        ?row a ${SH.rowClass}.
                        BIND(xsd:integer(REPLACE(STR(?row), STR(ent:) + 'row', '')) as ?currentRow).
                    }
                }
            }
        }
        `;

        return sparql;
    }
}
