export enum WindowArea {
    None = 0,
    Main = 1,
    Header = 2,
    Footer = 3,
    InnerMargin = 4,
    OuterMargin = 5,
    InterlinearSpace = 6,
    IntercolumnarSpace = 7
}
