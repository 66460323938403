<block-ui></block-ui>
<h2 mat-dialog-title>{{ isLocked ? 'View Manus' : 'New Manus' }} </h2>

<div mat-dialog-content class="content">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Continuant part of:</mat-label>
        <mat-select [(ngModel)]="selectedActor">
            <mat-option *ngFor="let actor of actors" [value]="actor">{{ getNameWithoutUri(actor.name!) }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Depends on tool:</mat-label>
        <mat-select [(ngModel)]="selectedTool">
            <mat-option *ngFor="let tool of tools" [value]="tool">{{ getNameWithoutUri(tool.name!) }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()" *ngIf="selectedActor && selectedTool">Add</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()" >Cancel</button>
</div>
