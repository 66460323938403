import { Department } from "../models/jena/department";
import { Faculty } from "../models/jena/faculty";
import { Institution } from "../models/jena/institution";
import { School } from "../models/jena/school";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class FacultySparql {
    public static getFaculties(): string {
        var sparql = PREFIXES +
        `
        SELECT
        ?faculty
        ?school
		?department
		?institution
        ?fullName
        ?shortName
        ?street
        ?streetNumber
        ?city
        ?state
        ?zipcode
        WHERE {
            ?faculty a ${SH.facultyClass}.
            OPTIONAL{
              ?faculty ${SH.memberPartOfAtSomeTime} ?school.
              ?school a ${SH.schoolClass}
            }.
            OPTIONAL{
              ?faculty ${SH.memberPartOfAtSomeTime} ?department.
              ?department a ${SH.departmentClass}
            }.
            OPTIONAL{
              ?faculty ${SH.memberPartOfAtSomeTime} ?institution.
              ?institution a ?subclass.
              ?subclass rdfs:subClassOf* ${SH.institutionClass}.
            }.
            OPTIONAL{?faculty ${SH.fullNameProperty} ?fullName}.
            OPTIONAL{?faculty ${SH.shortNameProperty} ?shortName}.
            OPTIONAL{?faculty ${SH.streetProperty} ?street}.
            OPTIONAL{?faculty ${SH.streetNumberProperty} ?streetNumber}.
            OPTIONAL{?faculty ${SH.cityProperty} ?city}.
            OPTIONAL{?faculty ${SH.stateProperty} ?state}.
            OPTIONAL{?faculty ${SH.zipCodeProperty} ?zipcode}.
            FILTER (STRSTARTS(str(?faculty), str(ent:)))
        }
        ORDER BY ?faculty
        `;

        return sparql;
    }

    public static createFaculty(faculty: Faculty, institution: Institution, department: Department, school: School): string {

        var sparql = PREFIXES +
        `
        INSERT {
            ?new a ${SH.facultyClass};
                ${institution != null ? `${SH.memberPartOfAtSomeTime} <${institution.name}>;` : ''}
                ${department != null ? `${SH.memberPartOfAtSomeTime} <${department.name}>;` : ''}
                ${school != null ? `${SH.memberPartOfAtSomeTime} <${school.name}>;` : ''}
                ${faculty.fullName != null ? `${SH.fullNameProperty} "` + faculty.fullName + `";` : ''}
                ${faculty.shortName != null ? `${SH.shortNameProperty} "` + faculty.shortName + `";` : ''}
                ${faculty.street != null ? `${SH.streetProperty} "` + faculty.street + `";` : ''}
                ${faculty.streetNumber != null ? `${SH.streetNumberProperty} "` + faculty.streetNumber + `";` : ''}
                ${faculty.city != null ? `${SH.cityProperty} "` + faculty.city + `";` : ''}
                ${faculty.state != null ? `${SH.stateProperty} "` + faculty.state + `";` : ''}
                ${faculty.zipcode != null ? `${SH.zipCodeProperty} "` + faculty.zipcode + `";` : ''}
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'fac',STR(MAX(?res) + 1))) as ?new)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{
                            ?z a ${SH.facultyClass}.
                            FILTER (STRSTARTS(str(?z), str(ent:)))
                        }.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + 'fac', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.facultyClass}.
                                FILTER (STRSTARTS(str(?s), str(ent:)))
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static updateFaculty(faculty: Faculty, institution: Institution, department: Department, school: School): string {
        var sparql = PREFIXES +
        `
        DELETE {?ent ?x ?y}
        WHERE {
            ?ent ?x ?y.
            FILTER(?ent = IRI("${faculty.name}"))
            FILTER(?y != ${SH.facultyClass})
        };

        INSERT {
            ${institution != null ? `?x ${SH.memberPartOfAtSomeTime} <${institution.name}>.` : ''}
            ${department != null ? `?x ${SH.memberPartOfAtSomeTime} <${department.name}>.` : ''}
            ${school != null ? `?x ${SH.memberPartOfAtSomeTime} <${school.name}>.` : ''}
            ${faculty.fullName != null ? `?x ${SH.fullNameProperty} "` + faculty.fullName + `".` : ''}
            ${faculty.shortName != null ? `?x ${SH.shortNameProperty} "` + faculty.shortName + `".` : ''}
            ${faculty.street != null ? `?x ${SH.streetProperty} "` + faculty.street + `".` : ''}
            ${faculty.streetNumber != null ? `?x ${SH.streetNumberProperty} "` + faculty.streetNumber + `".` : ''}
            ${faculty.city != null ? `?x ${SH.cityProperty} "` + faculty.city + `".` : ''}
            ${faculty.state != null ? `?x ${SH.stateProperty} "` + faculty.state + `".` : ''}
            ${faculty.zipcode != null ? `?x ${SH.zipCodeProperty} "` + faculty.zipcode + `".` : ''}
        }
        WHERE {
            ?x a ${SH.facultyClass}.
            FILTER(?x = IRI("${faculty.name}"))
        };
        `;

        return sparql;
    }

    public static deleteFaculty(faculty: Faculty): string {
        var sparql = PREFIXES +
        `
        DELETE { ?x ?y ?z }
        WHERE {
        ?x a ${SH.facultyClass}.
        ?x ?y ?z.
        FILTER(?x = IRI("${faculty.name}"))
        }
        `;

        return sparql;
    }

}
