export class AuxiliaryCursorPosition {
    public item?: number;
    public row?: number;
    public column?: number;

    constructor(item:number, row: number, column: number) {
        this.item = item;
        this.row = row;
        this.column = column;
    }
}
