import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { InitializeRoleMapping } from "src/assets/data/roleMapping";
import { EntityTag } from "../models/entity";
import { User } from "../models/user";

export class Helpers {
    public static xsd_prefix_uri = 'http://www.w3.org/2001/XMLSchema#';
    public static owl_prefix_uri = 'http://www.w3.org/2002/07/owl#';
    public static rdf_prefix_uri = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#';
    public static rdfs_prefix_uri = 'http://www.w3.org/2000/01/rdf-schema#';
    public static del_prefix_uri = 'http://www.w3.org/2002/07/#';
    public static ent_prefix_uri = 'http://purl.obolibrary.org/obo/bfo.owl#';
    public static obo_prefix_uri = 'http://purl.obolibrary.org/obo/';

    public static PREFIXES = `
    PREFIX xsd: <${this.xsd_prefix_uri}>
    PREFIX owl: <${this.owl_prefix_uri}>
    PREFIX rdf: <${this.rdf_prefix_uri}>
    PREFIX rdfs: <${this.rdfs_prefix_uri}>
    PREFIX del: <${this.del_prefix_uri}>
    PREFIX ent: <${this.ent_prefix_uri}>
    PREFIX obo: <${this.obo_prefix_uri}>
    `;

    public static getToolLabel(toolType: string): string {
        if (toolType === 'brush') {
            return 'brush'
        } else if (toolType === 'pen') {
            return 'pen'
        } else if (toolType === 'reed') {
            return 'reed'
        } else if (toolType === 'ruler') {
            return 'rul'
        } else if (toolType === 'ruling board') {
            return 'rulboard'
        } else if (toolType === 'stylus') {
            return 'stylus'
        }

        return '';
    }

    public static getInstitutionLabel(institutionType: string): string {
        if (institutionType === 'library') {
            return 'library'
        } else if (institutionType === 'research center') {
            return 'rc'
        } else if (institutionType === 'university') {
            return 'university'
        }

        return '';
    }

    public static getDeviceLabel(deviceType: string): string {
        if (deviceType === 'codex') {
            return 'cod'
        } else if (deviceType === 'independent leaf') {
            return 'indleaf'
        } else if (deviceType === 'scroll') {
            return 'src'
        }

        return '';
    }

    public static isNullOrEmpty(input: string | Array<any>): boolean {
        if (input === null || input === undefined) {
            return true;
        }

        if (typeof input === 'string') {
            return input === '';
        } else {
            if (input.length === 0) {
                return true;
            } else {
                for (const item of input) {
                    if (item === null || item === undefined) {
                        return true;
                    }
                }
            }

            return false;
        }
    }

    public static isNullOrUndefined(obj: any | Array<any>): boolean {
        if (Array.isArray(obj)) {
            for (const item of obj) {
                if (item !== null || item !== undefined) {
                    return false;
                }
            }
            return true;
        }

        return obj === null || obj === undefined;
    }

    public static isNotNullOrUndefined(obj: any | Array<any>): boolean {
        if (Array.isArray(obj)) {
            for (const item of obj) {
                if (item === null || item === undefined) {
                    return false;
                }
            }
            return true;
        }

        return obj !== null && obj !== undefined;
    }

    public static generateCode(length: number): string {
        let code = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < length; i++) {
            code += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return code;
    }

    public static openDownLoadLink(blob: Blob, filename: string) {
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
        }
        else {
            window.open(URL.createObjectURL(blob));
        }
    }

    public static createDownLoadLink(blob: Blob, filename: string) {
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
        }
        else {
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            document.body.appendChild(link);
            link.setAttribute('style', 'display: none');
            link.href = url;
            link.download = filename;
            link.click();

            window.URL.revokeObjectURL(url);
        }
    }

    public static canViewElement(uiElement: string, roles: Array<string> | undefined): boolean {
        let val = false;
        const config = InitializeRoleMapping();

        config.filter(x => x.userInterfaceElement === uiElement).forEach(f => {
            if (this.isNotNullOrUndefined(roles)) {
                if (roles!.findIndex(r => f.acceptedRoles.includes(r)) > -1) {
                    val = true;
                }
            }
        });

        return val;
    }

    public static getGreenSnackbarConfig() {
        return {
            duration: 3000,
            panelClass: ['green-snackbar'],
            verticalPosition: "top" as MatSnackBarVerticalPosition,
            horizontalPosition: "end" as MatSnackBarHorizontalPosition
        }
    }

    public static getGreenSnackbarConfig10() {
        return {
            duration: 10000,
            panelClass: ['green-snackbar'],
            verticalPosition: "top" as MatSnackBarVerticalPosition,
            horizontalPosition: "end" as MatSnackBarHorizontalPosition
        }
    }

    public static getYellowSnackbarConfig() {
        return {
            duration: 3000,
            panelClass: ['yellow-snackbar'],
            verticalPosition: "top" as MatSnackBarVerticalPosition,
            horizontalPosition: "end" as MatSnackBarHorizontalPosition
        }
    }

    public static getRedSnackbarConfig() {
        return {
            duration: 3000,
            panelClass: ['red-snackbar'],
            verticalPosition: "top" as MatSnackBarVerticalPosition,
            horizontalPosition: "end" as MatSnackBarHorizontalPosition
        }
    }

    public static arrayEquals(a: Array<any> | null, b: Array<any> | null) {
        return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
    }

    public static getUserFromKeycloakProfile(keycloakProfile: Keycloak.KeycloakProfile, keycloakRoles: Array<string>): User {
        let user = new User;
        user.userName = keycloakProfile.username!;
        user.firstName = keycloakProfile.firstName!;
        user.lastName = keycloakProfile.lastName!;
        user.email = keycloakProfile.email!;
        user.roles = keycloakRoles;
        return user
    }

    public static getTagsFromSelection(tags: Array<EntityTag>, selection: Selection): Array<EntityTag> {
        var foundTags = new Array<EntityTag>();

        tags.forEach(tag => {
            if (!Helpers.isNullOrEmpty(tag.text!)) {
                var startIndex = tag.startIndex;
                var endIndex = startIndex! + tag.text?.length! - 1!

                if (selection.anchorOffset >= startIndex! && selection.anchorOffset <= endIndex) {
                    foundTags.push(tag);
                }
            }
        });

        return foundTags;
    }

    public static containsHtmlTags(input: string): boolean {
        if (input.match(/(<b>|<\/b>|<a>|<\/a>|<i>|<\/i>)/g) === null) {
            return false;
        }

        return true;
    }
}
