import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-yes-no',
    templateUrl: './dialog-yes-no.component.html',
    styleUrls: ['./dialog-yes-no.component.scss']
})
export class DialogYesNoComponent implements OnInit {
    public titleMessage!: string;
    public infoMessage!: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogYesNoComponent>) { }

    ngOnInit(): void {
        this.titleMessage = this.data.titleMessage;
        this.infoMessage = this.data.infoMessage;
    }

    public yesClicked() {
        this.dialogRef.close(true);
    }

    public noClicked() {
        this.dialogRef.close(false);
    }
}
