import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { Helpers } from '../utilities/helpers';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class AdministratorGuard implements CanActivate {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
        private authService: AuthService,
        private snackBar: MatSnackBar
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.getUser()
            .pipe(
                distinctUntilChanged(),
                map(
                    (result: User) => {
                        return (result.roles!.findIndex(r => r === 'administrator') > -1);
                    }
                ),
                tap((isValidUser) => {
                    if (!isValidUser) {
                        this.snackBar.open('Cannot activate this page!', '', Helpers.getRedSnackbarConfig());
                        this.router.navigate(['index'], { queryParams: { returnUrl: state.url }});
                    }
                })
            );
    }
}
