import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Department } from 'src/app/models/jena/department';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogDepartmentManageComponent } from './dialog-department-manage/dialog-department-manage.component';

@Component({
    selector: 'app-department-list',
    templateUrl: './department-list.component.html',
    styleUrls: ['./department-list.component.scss']
})
export class DepartmentListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    displayedColumns: string[] = ['name', 'institution', 'faculty', 'school', 'fullName', 'street', 'city', 'state', 'actions'];
    dataSource: MatTableDataSource<Department>;
    public departments: Array<Department>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar) {
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.dataSource = new MatTableDataSource();
        this.departments = new Array<Department>();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getDepartments(this.entityId!);
        }
    }

    private _getDepartments(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getDepartments(datasetName).subscribe({
            next: (result: Array<Department>) => {
                this.departments = result;
                this.dataSource.data = this.departments;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public new() {
        const dialogRef = this._dialog.open(DialogDepartmentManageComponent, {
            width: '800px',
            data: {
                department: new Department(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.createDepartment(this.entityId!, result.department, result.institution, result.faculty, result.school).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new department', '', Helpers.getGreenSnackbarConfig());
                            this._getDepartments(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while creating new department', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public delete(department: Department) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '480px',
            data: {
                titleMessage: `Delete department ${department.name}`,
                infoMessage: 'Are you sure you want to delete this department?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteDepartment(this.entityId!, department).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted department', '', Helpers.getGreenSnackbarConfig());
                            this._getDepartments(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting department', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public edit(department: Department) {
        const dialogRef = this._dialog.open(DialogDepartmentManageComponent, {
            width: '800px',
            data: {
                department: department,
                entityId: this.entityId,
                isEdit: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.updateDepartment(this.entityId!, result.department, result.institution, result.faculty, result.school).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully updated department', '', Helpers.getGreenSnackbarConfig());
                            this._getDepartments(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while updating department', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public view(department: Department) {
        this._dialog.open(DialogDepartmentManageComponent, {
            width: '800px',
            data: {
                department: department,
                entityId: this.entityId,
                isLocked: true
            }
        });
    }

    public getNameWithoutUri(name: string): string {
        return name?.replace(Helpers.ent_prefix_uri, '');
    }
}
