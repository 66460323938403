import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { GenericSubclass } from 'src/app/models/jena/genericClass';
import { Institution } from 'src/app/models/jena/institution';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { SparqlHelper } from 'src/app/utilities/sparqlHelper';

@Component({
    selector: 'app-dialog-institution-manage',
    templateUrl: './dialog-institution-manage.component.html',
    styleUrls: ['./dialog-institution-manage.component.scss']
})
export class DialogInstitutionManageComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;
    public institution: Institution;
    public isLocked = false;
    public isEdit = false;
    public institutionTypes?: Array<GenericSubclass>;
    public selectedInstitutionType?: GenericSubclass;

    constructor(
        private _fusekiService: FusekiService,
        private _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogInstitutionManageComponent>,) {
            this.institution = this.data.institution;
            this.entityId = this.data.entityId;
            this.isEdit = this.data.isEdit;
            this.isLocked = this.data.isLocked;

            if (!Helpers.isNullOrUndefined(this.institution.subclass!)) {
                this.selectedInstitutionType = this.institution.subclass;
            }

            this.getInstitutionTypes();
        }

    ngOnInit(): void {
    }

    public getInstitutionTypes() {
        this._blockUI.start();

        this._fusekiService.getSubclasses(this.entityId!, SparqlHelper.institutionClass).subscribe({
            next: (result) => {
                this._blockUI.stop();
                this.institutionTypes = result;
            },
            error: (error) => {
                console.log(error.error);
                this._blockUI.stop();
            }
        });
    }

    public okClicked() {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: !this.isEdit ? 'Create new institution' : 'Update institution',
                infoMessage: !this.isEdit ? 'Are you sure you want to create a new institution?' : 'Are you sure you want to update this institution?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close({institution: this.institution, institutionType: this.selectedInstitutionType});
            }
        });
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }

    public compareInstitutionTypes(c1: GenericSubclass, c2: GenericSubclass) {
        return c1.name!.toString() === c2.name!.toString();
    }

}
