import { Actor } from "../models/jena/actor";
import { Helpers } from "../utilities/helpers";

const PREFIXES = Helpers.PREFIXES;

export class GenericSparql {
    public static getSubclasses(className: string): string {
        var sparql = PREFIXES +
        `
        SELECT ?subclass ?label
        WHERE {
            ?subclass rdfs:subClassOf* ${className};
                rdfs:label ?label.
            FILTER (langMatches(lang(?label), "en"))
            FILTER (?subclass != ${className})
        }
        ORDER by ?label
        `;

        return sparql;
    }


}
