import { GenericSubclass } from "../models/jena/genericClass";
import { Institution } from "../models/jena/institution";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class InstitutionSparql {

    public static getInstitutions(): string {
        var sparql = PREFIXES +
        `
        SELECT
        ?institution
        ?subclass
        ?label
        ?fullName
        ?shortName
        ?street
        ?streetNumber
        ?city
        ?state
        ?zipcode
        WHERE {
            ?institution a ?subclass.
            ?subclass rdfs:subClassOf* ${SH.institutionClass};
                rdfs:label ?label.
            OPTIONAL{?institution ${SH.fullNameProperty} ?fullName}.
            OPTIONAL{?institution ${SH.shortNameProperty} ?shortName}.
            OPTIONAL{?institution ${SH.streetProperty} ?street}.
            OPTIONAL{?institution ${SH.streetNumberProperty} ?streetNumber}.
            OPTIONAL{?institution ${SH.cityProperty} ?city}.
            OPTIONAL{?institution ${SH.stateProperty} ?state}.
            OPTIONAL{?institution ${SH.zipCodeProperty} ?zipcode}.
        FILTER (langMatches(lang(?label), "en"))
        FILTER (?subclass != ${SH.institutionClass})
        FILTER (STRSTARTS(str(?institution), str(ent:)))
        }
        ORDER by ?label
        `;

        return sparql;
    }

    public static createInstitution(institution: Institution, genericSubclass: GenericSubclass): string {

        var sparql = PREFIXES +
        `
        INSERT {
            ?new a <${genericSubclass.name}>;
                ${institution.fullName != null ? `${SH.fullNameProperty} "` + institution.fullName + `";` : ''}
                ${institution.shortName != null ? `${SH.shortNameProperty} "` + institution.shortName + `";` : ''}
                ${institution.street != null ? `${SH.streetProperty} "` + institution.street + `";` : ''}
                ${institution.streetNumber != null ? `${SH.streetNumberProperty} "` + institution.streetNumber + `";` : ''}
                ${institution.city != null ? `${SH.cityProperty} "` + institution.city + `";` : ''}
                ${institution.state != null ? `${SH.stateProperty} "` + institution.state + `";` : ''}
                ${institution.zipcode != null ? `${SH.zipCodeProperty} "` + institution.zipcode + `";` : ''}
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'${Helpers.getInstitutionLabel(genericSubclass.label!)}',STR(MAX(?res) + 1))) as ?new)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{
                            ?z a <${genericSubclass.name}>.
                            FILTER (STRSTARTS(str(?z), str(ent:)))
                        }.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + '${Helpers.getInstitutionLabel(genericSubclass.label!)}', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a <${genericSubclass.name}>.
                                FILTER (STRSTARTS(str(?s), str(ent:)))
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static updateInstitution(institution: Institution): string {
        var sparql = PREFIXES +
        `
        DELETE {?ent ?x ?y}
        WHERE {
            ?ent ?x ?y.
            FILTER(?ent = IRI("${institution.name}"))
            FILTER(?y != IRI("${institution.subclass?.name}"))
        };

        INSERT {
            ${institution.fullName != null ? `?x ${SH.fullNameProperty} "` + institution.fullName + `".` : ''}
            ${institution.shortName != null ? `?x ${SH.shortNameProperty} "` + institution.shortName + `".` : ''}
            ${institution.street != null ? `?x ${SH.streetProperty} "` + institution.street + `".` : ''}
            ${institution.streetNumber != null ? `?x ${SH.streetNumberProperty} "` + institution.streetNumber + `".` : ''}
            ${institution.city != null ? `?x ${SH.cityProperty} "` + institution.city + `".` : ''}
            ${institution.state != null ? `?x ${SH.stateProperty} "` + institution.state + `".` : ''}
            ${institution.zipcode != null ? `?x ${SH.zipCodeProperty} "` + institution.zipcode + `".` : ''}
        }
        WHERE {
            ?x a <${institution.subclass?.name}>.
            FILTER(?x = IRI("${institution.name}"))
        };
        `;

        return sparql;
    }

    public static deleteInstitution(institution: Institution): string {
        var sparql = PREFIXES +
        `
        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?x a <${institution.subclass?.name}>.
            ?x ?y ?z.
            FILTER(?x = IRI('${institution.name}'))
        };
        `;

        return sparql;
    }

}
