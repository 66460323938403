export class OverallInfo {
    name?: string;
    utfCode?: number;
    columnOrder?: number;
    rowName?: string;
    rowOrder?: number;
    pageName?: string;
    pageOrder?: number;
    areaName?: string;
    strokeName?: string;
    transcriptionName?: string;
    manusName?: string;
    annotatorName?: string;
    manuscriptName?: string;
}
