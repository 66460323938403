<block-ui></block-ui>

<div class="dialog">
    <h2 mat-dialog-title>Create New Project</h2>

    <div mat-dialog-content class="content">
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Enter Project name:</mat-label>
            <input matInput [(ngModel)]="projectName">
        </mat-form-field>
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Select manuscript side:</mat-label>
            <mat-select [(ngModel)]="selectedSide">
                <mat-option value="1">Two pages</mat-option>
                <mat-option value="2">Recto - verso</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button color="primary" (click)="save()" *ngIf="projectName && selectedSide">Save</button>
        <button mat-flat-button color="accent" (click)="cancelClicked()" >Cancel</button>
    </div>
</div>
