import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Tool } from 'src/app/models/jena/tool';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogToolManageComponent } from './dialog-tool-manage/dialog-tool-manage.component';

@Component({
    selector: 'app-tool-list',
    templateUrl: './tool-list.component.html',
    styleUrls: ['./tool-list.component.scss']
})
export class ToolListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    displayedColumns: string[] = ['name', 'subclassName', 'label', 'actions'];
    dataSource: MatTableDataSource<Tool>;
    public tools: Array<Tool>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar) {
        // Get the id from the url
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.dataSource = new MatTableDataSource();
        this.tools = new Array<Tool>();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getTools(this.entityId!);
        }
    }

    private _getTools(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getTools(datasetName).subscribe({
            next: (result: Array<Tool>) => {
                this.tools = result;
                this.dataSource.data = this.tools;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public new() {
        const dialogRef = this._dialog.open(DialogToolManageComponent, {
            width: '800px',
            data: {
                tool: new Tool(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.createTool(this.entityId!, result).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new tool', '', Helpers.getGreenSnackbarConfig());
                            this._getTools(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while creating new tool', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public delete(tool: Tool) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '480px',
            data: {
                titleMessage: `Delete tool ${tool.name}`,
                infoMessage: 'Are you sure you want to delete this tool?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteTool(this.entityId!, tool).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted tool', '', Helpers.getGreenSnackbarConfig());
                            this._getTools(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting tool', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }
}
