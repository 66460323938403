<span #spanTrigger class="context-menu-trigger" #matTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu.childMenu">
</span>
<app-menu-item #menu
    [ngStyle]="getRightClickMenuStyle()"
    [items]="nestedMenuItems"
    (onContextMenuItemClick)="handleMenuItemClick($event, matTrigger)">
</app-menu-item>
<div *blockUI="'editor-container'"
    class="editor-container"
    tabindex="0"
    [ngStyle]="{'height.px': height}"
    (mousedown)="onEditorClick($event)"
    (contextmenu)="onRightClick($event, matTrigger, spanTrigger)">
        <div #entityContainer [class]="mode == 'annotation' ? 'entity-container annotation-mode': 'entity-container'"></div>
</div>
