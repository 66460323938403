import {Component, OnInit, ViewChild, Injectable, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Entity } from 'src/app/models/entity';
import { SortingDirection } from 'src/app/models/enums/sortingDirection';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { EntityService } from 'src/app/services/entity.service';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogYesNoComponent } from '../../shared/dialog-yes-no/dialog-yes-no.component';
import { DialogParticipantsComponent } from './dialog-participants/dialog-participants.component';
import { CollationService } from 'src/app/services/collation.service';
import {DialogInitialComponent} from "../dialog-initial/dialog-initial.component";
import {DialogIntroComponent} from "../../dialog-intro/dialog-intro.component";

@Component({
    selector: 'app-list-entities',
    templateUrl: './list-entities.component.html',
    styleUrls: ['./list-entities.component.scss']
})
export class ListEntitiesComponent implements OnInit {
    @BlockUI('entitiesContainer') uiBlocker!: NgBlockUI;
    displayedColumns: string[] = ['checkbox', 'id', 'title', 'createdDate', 'username', 'numberOfTags', 'numberOfParticipants', 'actions'];
    dataSource: MatTableDataSource<Entity>;
    public entities: Array<Entity>;
    public currentUser?: User;
    public selectedEntities: Array<Entity>;
    public isCollationShown: boolean = false;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    length!: number;
    pageIndex!: number;
    pageSize!: number;

    @Input('mode') mode: string = "list";

    private defaultSortingColumn = 'createdDate';
    private defaultSortingDirection = SortingDirection.Descending;

    constructor(private _entityService: EntityService,
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _snackBar: MatSnackBar,
        private _authService: AuthService,
        private _collationService: CollationService) {
        this._authService.getUser().subscribe(result => { this.currentUser = result });
        this.dataSource = new MatTableDataSource();
        this.entities = new Array<Entity>();
        this.selectedEntities = new Array<Entity>();
    }

    ngOnInit(): void {
        if (this._router.url == '/') {
            var homeDialog = this._dialog.open(DialogIntroComponent, {
                disableClose: true,
            });
            setTimeout(() => {
                homeDialog.close();
            }, 5000);
        }

        this.pageIndex = 1;
        this.pageSize = 10;
        this._findEntities(this.pageIndex, this.pageSize, this.defaultSortingColumn, this.defaultSortingDirection);
        this.selectedEntities = new Array<Entity>();
    }

    public ngAfterViewInit(): void {
        this.sort.sortChange.subscribe((sort: Sort) => {
            this._findEntities(this.pageIndex, this.pageSize, sort.active, SortingDirection[sort.direction as keyof typeof SortingDirection]);
        });
    }

    private _findEntities(pageNo: number, size: number, sortingColumn: string, sortDirection: SortingDirection) {
        this.uiBlocker.start();

        const conditions = new Array<string>();

        this._entityService.findEntities(conditions, pageNo, size, sortingColumn, sortDirection).subscribe(
            (result) => {
                this.entities = result.data;
                this.length = result.count;
                this.dataSource.data = this.entities;

                this.uiBlocker.stop();
            },
            (error) => {
                this.uiBlocker.stop();
            }
        );
    }

    public getNumberOfTags(entity: Entity): number {
        if (Helpers.isNotNullOrUndefined(entity.entityTags)) {
            return entity.entityTags!.length;
        }

        return 0;
    }

    public getNumberOfParticipants(entity: Entity): number {
        if (Helpers.isNotNullOrUndefined(entity.participants)) {
            return entity.participants!.length;
        }

        return 0;
    }

    public edit(entityId: number) {
        this._router.navigate(['edit-entity/' + entityId]);
    }

    public deleteFile(entity: Entity) {
        this.uiBlocker.start(`Deleting file with id: ${entity.id} ...`);

        this._entityService.deleteEntity(entity).subscribe({
            next: (deleteResult: Entity) => {
                this.uiBlocker.update(`Deleted file object and proceeding to delete dataset from Fuseki...`);

                this._fusekiService.deleteDataset(entity.id!).subscribe({
                    next: (deleteFusekiResult: any) => {
                        this.uiBlocker.stop();
                        this._snackBar.open('File deleted successfully', '', Helpers.getGreenSnackbarConfig());
                        this._findEntities(this.pageIndex, this.pageSize, this.defaultSortingColumn, this.defaultSortingDirection);
                    },
                    error: (error) => {
                        this.uiBlocker.stop();
                        this._findEntities(this.pageIndex, this.pageSize, this.defaultSortingColumn, this.defaultSortingDirection);
                        if (error.error.includes('No such dataset registered')) {
                            this._snackBar.open('File deleted from the database but no Fuseki dataset found to delete', '', Helpers.getRedSnackbarConfig());
                        }
                        console.log(error);
                    }
                });
            },
            error: (error) => {
                this.uiBlocker.stop();
                console.log(error);
            }
        });
    }

    public delete(entity: Entity) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: `Delete Project (${entity.title})`,
                infoMessage: 'Are you sure that you want to delete this project?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result)
                this.deleteFile(entity);
        });
    }

    public assignParticipants(entity: Entity) {
        this.openParticipantsDialog(entity);
    }

    public openParticipantsDialog(entity: Entity) {
        const dialogRef = this._dialog.open(DialogParticipantsComponent, {
            width: '800px',
            data: {
                entity: entity
            }
        });

        dialogRef.afterClosed().subscribe(
            result => {
                if (result) {
                    this._findEntities(this.pageIndex, this.pageSize, this.defaultSortingColumn, this.defaultSortingDirection);
                }
            }
        );
    }

    public canViewElement(element: string) {
        var elementView = Helpers.canViewElement(element, this.currentUser?.roles);

        return elementView;
    }

    public handlePageEvent(e: PageEvent) {
        this.length = e.length;
        this.pageSize = e.pageSize;
        this.pageIndex = e.pageIndex + 1;
        this._findEntities(this.pageIndex, this.pageSize, this.sort.active, SortingDirection[this.sort.direction as keyof typeof SortingDirection]);
    }

    public onCheckboxClick(e: any, entity: Entity): void {
        e.checked ? this.selectedEntities.push(entity) : this.selectedEntities = this.selectedEntities.filter(x => x.id != entity.id)
    }

    public onCollateClick() {
        if (this.mode == 'collate') {
            this._collationService.deleteEntities();
            this._collationService.setEntities(this.selectedEntities);
        }
    }

    public sortData(sort: Sort) { }

    public onDuplicateClick(entity: Entity | null) {
        this.uiBlocker.start('Duplicating manuscript');

        let entities: Array<Entity> = new Array<Entity>;

        if (entity != null)
            entities.push(entity);
        else
            entities = this.selectedEntities;

        entities.forEach(entity => {
            let baseDatasetId = entity.id!;
            entity!.id = undefined;
            if (entity.default)
                entity!.default.id = undefined;

            this._entityService
                .createEntity(entity!)
                .subscribe({
                    next: (next) => {
                        const newId = next.id;

                        this._fusekiService.getDataset(baseDatasetId).subscribe({
                            next: (blob) => {
                                this._fusekiService.createDataset(newId!, 'tdb2').subscribe({
                                    complete: () => {
                                        this._fusekiService.uploadDatasetTurtle(newId!, blob).subscribe({
                                            complete: () => this._findEntities(
                                                this.pageIndex,
                                                this.pageSize,
                                                this.sort.active,
                                                SortingDirection[this.sort.direction as keyof typeof SortingDirection])
                                        });
                                    }
                                });
                            },
                            error: (error) => {
                                console.log(error);
                            }
                        });
                        this.uiBlocker.stop();
                    },
                    error: (error) => {
                        this.uiBlocker.stop();
                        console.log(error);
                        this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
                    }
                });
        });

        this._findEntities(this.pageIndex, this.pageSize, this.defaultSortingColumn, this.defaultSortingDirection);

        this.selectedEntities = new Array<Entity>();
    }

    public deleteFiles() {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: `Delete Projects`,
                infoMessage: 'Are you sure that you want to delete selected projects?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedEntities.forEach(entity =>
                    this.deleteFile(entity));

                this.selectedEntities = new Array<Entity>();
            }
        });

    }
}
