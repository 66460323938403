<block-ui></block-ui>
<h2 mat-dialog-title>Derivation</h2>

<div mat-dialog-content class="content">
    <mat-expansion-panel expanded disabled class="panel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Is transcription of
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="checkboxes">
            <mat-checkbox>stroke</mat-checkbox>
            <mat-checkbox>flat stroke part(s)</mat-checkbox>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded disabled class="panel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Has dependent info
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="checkboxes">
            <mat-checkbox>over (overlapping)</mat-checkbox>
            <mat-checkbox>before (inline)</mat-checkbox>
            <mat-checkbox>after (inline)</mat-checkbox>
            <mat-checkbox>above</mat-checkbox>
            <mat-checkbox>below</mat-checkbox>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded disabled class="panel">
        <div class="checkboxes" style="margin-top:20px;">
            <mat-checkbox>has variant</mat-checkbox>
        </div>
    </mat-expansion-panel>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()" >Cancel</button>
</div>
