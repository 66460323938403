import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Department } from 'src/app/models/jena/department';
import { Faculty } from 'src/app/models/jena/faculty';
import { Institution } from 'src/app/models/jena/institution';
import { School } from 'src/app/models/jena/school';
import { Team } from 'src/app/models/jena/team';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-dialog-team-manage',
    templateUrl: './dialog-team-manage.component.html',
    styleUrls: ['./dialog-team-manage.component.scss']
})
export class DialogTeamManageComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;
    public team: Team;
    public isLocked = false;
    public isEdit = false;
    public institutions?: Array<Institution>;
    public selectedInstitution?: Institution;
    public schools?: Array<School>;
    public selectedSchool?: School;
    public departments?: Array<Department>;
    public selectedDepartment?: Department;
    public faculties?: Array<Faculty>;
    public selectedFaculty?: Faculty;

    constructor(
        private _fusekiService: FusekiService,
        private _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogTeamManageComponent>,) {
            this.team = this.data.team;
            this.entityId = this.data.entityId;
            this.isEdit = this.data.isEdit;
            this.isLocked = this.data.isLocked;

            if (!Helpers.isNullOrUndefined(this.team.institution!) && !Helpers.isNullOrUndefined(this.team.institution!.name)) {
                this.selectedInstitution = this.team.institution;
            }

            if (!Helpers.isNullOrUndefined(this.team.school!) && !Helpers.isNullOrUndefined(this.team.school!.name)) {
                this.selectedSchool = this.team.school;
            }

            if (!Helpers.isNullOrUndefined(this.team.department!) && !Helpers.isNullOrUndefined(this.team.department!.name)) {
                this.selectedDepartment = this.team.department;
            }

            if (!Helpers.isNullOrUndefined(this.team.faculty!) && !Helpers.isNullOrUndefined(this.team.faculty!.name)) {
                this.selectedFaculty = this.team.faculty;
            }

            this.getInstitutions();
            this.getDepartments();
            this.getSchools();
            this.getFaculties();
        }

    ngOnInit(): void {
    }

    public getInstitutions() {
        this._blockUI.start();

        this._fusekiService.getInstitutions(this.entityId!).subscribe({
            next: (result) => {
                this._blockUI.stop();
                this.institutions = result;
            },
            error: (error) => {
                console.log(error.error);
                this._blockUI.stop();
            }
        });
    }

    public getDepartments() {
        this._blockUI.start();

        this._fusekiService.getDepartments(this.entityId!).subscribe({
            next: (result) => {
                this._blockUI.stop();
                this.departments = result;
            },
            error: (error) => {
                console.log(error.error);
                this._blockUI.stop();
            }
        });
    }

    public getSchools() {
        this._blockUI.start();

        this._fusekiService.getSchools(this.entityId!).subscribe({
            next: (result) => {
                this._blockUI.stop();
                this.schools = result;
            },
            error: (error) => {
                console.log(error.error);
                this._blockUI.stop();
            }
        });
    }

    public getFaculties() {
        this._blockUI.start();

        this._fusekiService.getFaculties(this.entityId!).subscribe({
            next: (result) => {
                this._blockUI.stop();
                this.faculties = result;
            },
            error: (error) => {
                console.log(error.error);
                this._blockUI.stop();
            }
        });
    }

    public okClicked() {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: !this.isEdit ? 'Create new team' : 'Update team',
                infoMessage: !this.isEdit ? 'Are you sure you want to create a new team?' : 'Are you sure you want to update this team?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close({team: this.team, faculty: this.selectedFaculty, institution: this.selectedInstitution, department: this.selectedDepartment, school: this.selectedSchool});
            }
        });
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }

    public compareInstitutions(c1: Institution, c2: Institution) {
        return c1.name!.toString() === c2.name!.toString();
    }

    public compareDepartments(c1: Department, c2: Department) {
        return c1.name!.toString() === c2.name!.toString();
    }

    public compareFaculties(c1: Faculty, c2: Faculty) {
        return c1.name!.toString() === c2.name!.toString();
    }

    public compareSchools(c1: School, c2: School) {
        return c1.name!.toString() === c2.name!.toString();
    }

}
