<block-ui></block-ui>
<h2 mat-dialog-title>{{ isLocked ? 'View School' : (isEdit ? 'Update School' : 'New School') }} </h2>

<div mat-dialog-content class="content">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Name">
            <div class="tab-container">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Full name</mat-label>
                    <input matInput [(ngModel)]="school.fullName!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Short name</mat-label>
                    <input matInput [(ngModel)]="school.shortName!" [disabled]="isLocked">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Address">
            <div class="tab-container">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Street</mat-label>
                    <input matInput [(ngModel)]="school.street!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Street number</mat-label>
                    <input matInput [(ngModel)]="school.streetNumber!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>City</mat-label>
                    <input matInput [(ngModel)]="school.city!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>State</mat-label>
                    <input matInput [(ngModel)]="school.state!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Zip code</mat-label>
                    <input matInput [(ngModel)]="school.zipcode!" [disabled]="isLocked">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Member">
            <div class="tab-container">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Select institution:</mat-label>
                    <mat-select [(ngModel)]="selectedInstitution" [compareWith]="compareInstitutions" [disabled]="isLocked">
                        <mat-option *ngFor="let institution of institutions" [value]="institution">{{ getNameWithoutUri(institution.name!) }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()">Cancel</button>
</div>
