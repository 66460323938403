import { initialUIState } from './../states/ui.state';
import { EUIActions, UIActions } from './../actions/ui.actions';

export function uiReducers(state = initialUIState, action: UIActions) {
    switch (action.type) {
        case EUIActions.SetOverallInfo: {
            return {
                ...state,
                overallInfo: action.payload
            };
        }
        default:
            return state;
    }
}

