import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-intro',
  templateUrl: './dialog-intro.component.html',
  styleUrls: ['./dialog-intro.component.scss']
})
export class DialogIntroComponent implements OnInit {

  constructor(
        public dialogRef: MatDialogRef<DialogIntroComponent>
  ) { }

  ngOnInit(): void {
  }

}
