<block-ui></block-ui>
<h2 mat-dialog-title>{{ isLocked ? 'View Manuscript' : 'New Manuscript' }} </h2>

<div mat-dialog-content class="content">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Name">
            <div class="tab-container">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Generic name</mat-label>
                    <input matInput [(ngModel)]="manuscript.genericName!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Siglum</mat-label>
                    <input matInput [(ngModel)]="manuscript.siglum!" [disabled]="isLocked">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Date">
            <div class="tab-container">
                <div class="tab-title">
                    <h2>Exact</h2>
                </div>
                <div class="date-container">
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Day</mat-label>
                        <input matInput [(ngModel)]="manuscript.dayExact!" [disabled]="isLocked" type="number"
                            pattern="^(3[01]|[12][0-9]|[1-9])$">
                    </mat-form-field>
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Month</mat-label>
                        <input matInput [(ngModel)]="manuscript.monthExact!" [disabled]="isLocked" type="number"
                            pattern="^(1[0-2]|[1-9])$">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Year</mat-label>
                        <input matInput [(ngModel)]="manuscript.yearExact!" [disabled]="isLocked"
                            pattern="^\d{1,4}\s(AD|BC)$" placeholder="Use format YYYY AD or YYYY BC">
                    </mat-form-field>
                </div>
                <div class="tab-title">
                    <h2>Approximate</h2>
                </div>
                <div class="date-container">
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Day</mat-label>
                        <input matInput [(ngModel)]="manuscript.dayApproximate!" [disabled]="isLocked" type="number"
                            pattern="^(3[01]|[12][0-9]|[1-9])$">
                    </mat-form-field>
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Month</mat-label>
                        <input matInput [(ngModel)]="manuscript.monthApproximate!" [disabled]="isLocked" type="number"
                            pattern="^(1[0-2]|[1-9])$">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Year</mat-label>
                        <input matInput [(ngModel)]="manuscript.yearApproximate!" [disabled]="isLocked"
                            pattern="^\d{1,4}\s(AD|BC)$" placeholder="Use format YYYY AD or YYYY BC">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Around">
            <div class="tab-container">
                <div class="date-container">
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Around from</mat-label>
                        <input matInput [(ngModel)]="manuscript.aroundFrom!" [disabled]="isLocked">
                    </mat-form-field>
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Around to</mat-label>
                        <input matInput [(ngModel)]="manuscript.aroundTo!" [disabled]="isLocked">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Around by</mat-label>
                        <input matInput [(ngModel)]="manuscript.aroundBy!" [disabled]="isLocked">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Device">
            <div class="tab-container">
                <div class="date-container">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Continuant part of:</mat-label>
                        <mat-select [(ngModel)]="selectedDevice">
                            <mat-option *ngFor="let device of devices" [value]="device">{{
                                getNameWithoutUri(device.name!) }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()" *ngIf="selectedDevice">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()">Cancel</button>
</div>
