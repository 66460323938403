import { Word } from "../word";
import { DisplayPage } from "./displayPage";
import { DisplayRow } from "./displayRow";
import {ElementRef} from "@angular/core";

export class DisplayCharacter {
    owlName: string | undefined;
    owlName2: string | undefined;
    characterName: string | undefined;
    utfCode: number;
    page?: DisplayPage;
    row: DisplayRow;
    order: number;
    owlOrder?: number;
    isVirtual: boolean;
    clickedOrder: number = 0;
    hasInRow?: boolean;
    hasOver?: boolean;
    hasAbove?: boolean;
    hasBelow?: boolean;
    strokeName?: string;
    transcriptionName?: string;
    manusName?: string;
    annotatorName?: string;
    manuscriptName?: string;
    word?: Word;
    wordIndex?: number;
    previousCharacter?: DisplayCharacter;
    nextCharacter?: DisplayCharacter;

    constructor(
        owlName: string | undefined,
        owlName2: string | undefined,
        utfCode: number,
        row: DisplayRow,
        order: number,
        isVirtual: boolean,
        owlOrder?: number,
        page?: DisplayPage,
        previousCharacter: DisplayCharacter | undefined = undefined,
        nextCharacter: DisplayCharacter | undefined = undefined
    ) {
        this.owlName = owlName;
        this.owlName2 = owlName2;
        this.utfCode = utfCode;
        this.page = page;
        this.row = row;
        this.order = order;
        this.owlOrder = owlOrder;
        this.isVirtual = isVirtual;
        this.previousCharacter = previousCharacter;
        this.nextCharacter = nextCharacter;
    }
}
