<div class="content">
    <mat-card>
        <div class="row">
            <div style="margin-bottom: 10px;">
                <button mat-flat-button style="margin-right: 5px;" color="primary">Refresh Selected Files</button>
                <button mat-flat-button style="margin-right: 5px;" color="primary">Save</button>
                <button mat-flat-button style="margin-right: 5px;" color="primary">Export</button>
            </div>
        </div>

        <div>
            <div
                [style]="
                    collationEntities.length > 1 ? 'float:left; width:50%;' : ''
                "
            >
                <h1 *ngIf="collationEntities.length > 1" style="text-align: center;">Main Text</h1>

                <dx-html-editor valueType="html" [(value)]="firstEditorValue">
                    <dxo-toolbar [multiline]="true">
                        <dxi-item name="increaseIndent"></dxi-item>
                        <dxi-item name="decreaseIndent"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="alignLeft"></dxi-item>
                        <dxi-item name="alignCenter"></dxi-item>
                        <dxi-item name="alignRight"></dxi-item>
                        <dxi-item name="alignJustify"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="bold"></dxi-item>
                        <dxi-item name="italic"></dxi-item>
                        <dxi-item name="underline"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="color"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item
                            name="size"
                            [acceptedValues]="[
                                '8pt',
                                '10pt',
                                '12pt',
                                '14pt',
                                '18pt',
                                '24pt',
                                '36pt'
                            ]"
                        ></dxi-item>
                        <dxi-item
                            name="font"
                            [acceptedValues]="[
                                'Arial',
                                'Georgia',
                                'Tahoma',
                                'Times New Roman',
                                'Verdana'
                            ]"
                        ></dxi-item>
                    </dxo-toolbar>
                </dx-html-editor>
            </div>
            <div
                *ngIf="collationEntities.length > 1"
                [style]="
                    collationEntities.length > 1
                        ? 'float:right; width:50%;'
                        : ''
                "
            >
                <h1 *ngIf="collationEntities.length > 1" style="text-align: center;">Critical Apparatus</h1>

                <dx-html-editor valueType="html" [(value)]="secondEditorValue">
                    <dxo-toolbar [multiline]="true">
                        <dxi-item name="increaseIndent"></dxi-item>
                        <dxi-item name="decreaseIndent"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="alignLeft"></dxi-item>
                        <dxi-item name="alignCenter"></dxi-item>
                        <dxi-item name="alignRight"></dxi-item>
                        <dxi-item name="alignJustify"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="bold"></dxi-item>
                        <dxi-item name="italic"></dxi-item>
                        <dxi-item name="underline"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="color"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item
                            name="size"
                            [acceptedValues]="[
                                '8pt',
                                '10pt',
                                '12pt',
                                '14pt',
                                '18pt',
                                '24pt',
                                '36pt'
                            ]"
                        ></dxi-item>
                        <dxi-item
                            name="font"
                            [acceptedValues]="[
                                'Arial',
                                'Georgia',
                                'Tahoma',
                                'Times New Roman',
                                'Verdana'
                            ]"
                        ></dxi-item>
                    </dxo-toolbar>
                </dx-html-editor>
            </div>
        </div>
    </mat-card>
</div>
