<div class="container">
    <app-list-entities [mode]="'collate'"></app-list-entities>

    <div class="content">
        <mat-card>
            <div class="row">
                <button
                    mat-flat-button
                    color="primary"
                >
                    Refresh Selected Files
                </button>
                <button
                    mat-flat-button
                    color="primary"
                >
                    Save
                </button>
                <button
                    mat-flat-button
                    color="primary"
                >
                    Export
                </button>
            </div>

            <div>
                <div style="float:left; width:50%;">
                    <h1 style="text-align: center;">Main Text</h1>

                    <dx-html-editor style="margin-right: 7px" valueType="html" [(value)]="firstEditorValue">
                        <dxo-toolbar [multiline]="true">
                            <dxi-item name="increaseIndent"></dxi-item>
                            <dxi-item name="decreaseIndent"></dxi-item>
                            <dxi-item name="separator"></dxi-item>
                            <dxi-item name="alignLeft"></dxi-item>
                            <dxi-item name="alignCenter"></dxi-item>
                            <dxi-item name="alignRight"></dxi-item>
                            <dxi-item name="alignJustify"></dxi-item>
                            <dxi-item name="separator"></dxi-item>
                            <dxi-item name="bold"></dxi-item>
                            <dxi-item name="italic"></dxi-item>
                            <dxi-item name="underline"></dxi-item>
                            <dxi-item name="separator"></dxi-item>
                            <dxi-item name="color"></dxi-item>
                            <dxi-item name="separator"></dxi-item>
                            <dxi-item
                                name="size"
                                [acceptedValues]="[
                                    '8pt',
                                    '10pt',
                                    '12pt',
                                    '14pt',
                                    '18pt',
                                    '24pt',
                                    '36pt'
                                ]"
                            ></dxi-item>
                            <dxi-item
                                name="font"
                                [acceptedValues]="[
                                    'Arial',
                                    'Georgia',
                                    'Tahoma',
                                    'Times New Roman',
                                    'Verdana'
                                ]"
                            ></dxi-item>
                        </dxo-toolbar>
                    </dx-html-editor>
                </div>
                <div style="float:right; width:50%;">
                    <h1 style="text-align: center;">Critical Apparatus</h1>

                    <dx-html-editor style="margin-left: 7px" valueType="html" [(value)]="secondEditorValue">
                        <dxo-toolbar [multiline]="true">
                            <dxi-item name="increaseIndent"></dxi-item>
                            <dxi-item name="decreaseIndent"></dxi-item>
                            <dxi-item name="separator"></dxi-item>
                            <dxi-item name="alignLeft"></dxi-item>
                            <dxi-item name="alignCenter"></dxi-item>
                            <dxi-item name="alignRight"></dxi-item>
                            <dxi-item name="alignJustify"></dxi-item>
                            <dxi-item name="separator"></dxi-item>
                            <dxi-item name="bold"></dxi-item>
                            <dxi-item name="italic"></dxi-item>
                            <dxi-item name="underline"></dxi-item>
                            <dxi-item name="separator"></dxi-item>
                            <dxi-item name="color"></dxi-item>
                            <dxi-item name="separator"></dxi-item>
                            <dxi-item
                                name="size"
                                [acceptedValues]="[
                                    '8pt',
                                    '10pt',
                                    '12pt',
                                    '14pt',
                                    '18pt',
                                    '24pt',
                                    '36pt'
                                ]"
                            ></dxi-item>
                            <dxi-item
                                name="font"
                                [acceptedValues]="[
                                    'Arial',
                                    'Georgia',
                                    'Tahoma',
                                    'Times New Roman',
                                    'Verdana'
                                ]"
                            ></dxi-item>
                        </dxo-toolbar>
                    </dx-html-editor>
                </div>
            </div>
        </mat-card>
    </div>
</div>
