import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { ActivatedRoute } from '@angular/router';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Manuscript } from 'src/app/models/jena/manuscript';
import { DialogManuscriptManageComponent } from './dialog-manuscript-manage/dialog-manuscript-manage.component';

@Component({
    selector: 'app-manuscript-list',
    templateUrl: './manuscript-list.component.html',
    styleUrls: ['./manuscript-list.component.scss']
})
export class ManuscriptListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    // public checkboxes: { [key: string]: boolean };
    displayedColumns: string[] = [
        // 'checkbox',
        'name',
        'genericName',
        'siglum',
        'continuantPartOf',
        'actions'
    ];
    dataSource: MatTableDataSource<Manuscript>;
    public manuscripts: Array<Manuscript>;

    constructor(
        private _fusekiService: FusekiService,
        private _snackBar: MatSnackBar,
        private _activatedRoute: ActivatedRoute,
        private _dialog: MatDialog) {
        this.manuscripts = new Array<Manuscript>();
        // Get the id from the url
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        // this.checkboxes = {};
        this.dataSource = new MatTableDataSource();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getManuscripts(this.entityId!);
        }
    }

    public new() {
        const dialogRef = this._dialog.open(DialogManuscriptManageComponent, {
            width: '800px',
            data: {
                manuscript: new Manuscript(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(
            (result) => {
                if (result) {
                    this._fusekiService.createManuscript(this.entityId!, result.manuscript, result.device).subscribe({
                        next: () => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new manuscript', '', Helpers.getGreenSnackbarConfig());
                            this._getManuscripts(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                        }
                    });
                }
            }
        );
    }

    private _getManuscripts(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getManuscripts(datasetName).subscribe({
            next: (result: Array<Manuscript>) => {
                this.manuscripts = result;
                this.dataSource.data = this.manuscripts;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public delete(manuscript: Manuscript) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: `Delete manuscript ${manuscript.name}`,
                infoMessage: 'Are you sure you want to delete this manuscript?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteManuscript(this.entityId!, manuscript).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted manuscript', '', Helpers.getGreenSnackbarConfig());
                            this._getManuscripts(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting manuscript', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }

}
