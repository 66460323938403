import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageOwlItemsComponent } from './components/admin/owlItems/manage-owl-items/manage-owl-items.component';
import { EditUserComponent } from './components/admin/users/edit/edit-user.component';
import { ListUsersComponent } from './components/admin/users/list/list-users.component';
import { NewUserComponent } from './components/admin/users/new/new-user.component';
import { ActorListComponent } from './components/entities/common/actor-list/actor-list.component';
import { EditEntityComponent } from './components/entities/edit/edit-entity.component';
import { ListEntitiesComponent } from './components/entities/list/list-entities.component';
import { NewEntityComponent } from './components/entities/new/new-entity.component';
import { LoginComponent } from './components/login/login.component';
import { AdministratorGuard } from './guards/administrator.guard';
import { AuthGuard } from './guards/auth.guard';
import { ToolListComponent } from './components/entities/common/tool-list/tool-list.component';
import { PersonListComponent } from './components/entities/common/person-list/person-list.component';
import { ManusListComponent } from './components/entities/common/manus-list/manus-list.component';
import { DepartmentListComponent } from './components/entities/common/department-list/department-list.component';
import { FacultyListComponent } from './components/entities/common/faculty-list/faculty-list.component';
import { InstitutionListComponent } from './components/entities/common/institution-list/institution-list.component';
import { SchoolListComponent } from './components/entities/common/school-list/school-list.component';
import { TeamListComponent } from './components/entities/common/team-list/team-list.component';
import { DeviceListComponent } from './components/entities/common/device-list/device-list.component';
import { ManuscriptListComponent } from './components/entities/common/manuscript-list/manuscript-list.component';
import { CollationComponent } from './components/entities/collation/collation.component';
import { HelpComponent } from './components/help/help.component';

const routes: Routes = [
    // General
    {
        path: '',
        component: ListEntitiesComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    // New
    {
        path: 'new-entity',
        component: NewEntityComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'new-user',
        component: NewUserComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    // Edit
    {
        path: 'edit-entity/:entityId',
        component: EditEntityComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'edit-user',
        component: EditUserComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    // List
    {
        path: 'list-entities',
        component: ListEntitiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'list-users',
        component: ListUsersComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-actors/:entityId',
        component: ActorListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-tools/:entityId',
        component: ToolListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-persons/:entityId',
        component: PersonListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-manuses/:entityId',
        component: ManusListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-teams/:entityId',
        component: TeamListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-schools/:entityId',
        component: SchoolListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-departments/:entityId',
        component: DepartmentListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-faculties/:entityId',
        component: FacultyListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-institutions/:entityId',
        component: InstitutionListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-devices/:entityId',
        component: DeviceListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    {
        path: 'list-manuscripts/:entityId',
        component: ManuscriptListComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    // Other
    {
        path: 'manage-owl',
        component: ManageOwlItemsComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    // Collation
    {
        path: 'collation',
        component: CollationComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
    // Help
    {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthGuard, AdministratorGuard]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
