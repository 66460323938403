import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CollationService } from 'src/app/services/collation.service';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Entity } from 'src/app/models/entity';

@Component({
  selector: 'app-critical-editing',
  templateUrl: './critical-editing.component.html',
  styleUrls: ['./critical-editing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriticalEditingComponent implements OnInit {
  @ViewChild(DxHtmlEditorComponent, { static: false }) htmlEditor: DxHtmlEditorComponent | undefined;
  public collationEntities: string[] = [];
  public firstEditorValue: string = '';
  public secondEditorValue: string = '';
  _entity!: Entity;

  @Input()
  set entity(val: Entity) {
      this.entityChange.emit(val);
      this._entity = val;
  }
  get entity() {
      return this._entity;
  }

    @Output()
    entityChange: EventEmitter<Entity> = new EventEmitter<Entity>();

  constructor(
    private _router: Router,
    private _fusekiService: FusekiService,
    private _collationService: CollationService,
    private _changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {

  }
}
