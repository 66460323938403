<mat-toolbar class="toolbar navbar">
    <mat-toolbar-row>
        <div fxHide.lt-sm style="display: flex; width:100%;">
            <button mat-button fxShow="true" (click)="goToHome()"><mat-icon>home</mat-icon>Intro</button>
            <button mat-button fxShow="true"
                [matMenuTriggerFor]="menuEntities"><mat-icon>description</mat-icon>Project</button>
            <button mat-button fxShow="true"
                [matMenuTriggerFor]="menuIndividuals"><mat-icon>description</mat-icon>Define</button>
            <button mat-button (click)="goToHelp()"><mat-icon>help</mat-icon>Help</button>
        </div>
        <div fxHide.lt-sm style="margin-right: 0;">
            <div class="rightContainer">
                <div class="rightIcon">
                    <button mat-button fxShow="true" [matMenuTriggerFor]="menuUser">
                        <mat-icon style="vertical-align: middle;">account_box</mat-icon>
                        {{ getUserFullName() }}
                    </button>
                </div>
            </div>
        </div>
        <div fxHide.gt-xs style="display: flex;">
            <button mat-icon-button [matMenuTriggerFor]="matMenuCombined" fxShow="true">
                <mat-icon>menu</mat-icon>
            </button>
            <h2 (click)="goToHome()" style="cursor: pointer;align-self:center">Intro</h2>
        </div>
        <mat-menu #matMenuCombined="matMenu" xPosition="before">
            <button mat-menu-item [matMenuTriggerFor]="menuEntities">Project</button>
            <button mat-menu-item [matMenuTriggerFor]="menuIndividuals">Define</button>
            <button mat-menu-item (click)="goToHelp()">Help</button>
            <button mat-menu-item [matMenuTriggerFor]="menuUser">Account</button>
        </mat-menu>
        <mat-menu #menuEntities="matMenu" xPosition="before">
            <button mat-menu-item (click)="goToNewEntity()">New Project</button>
            <button mat-menu-item (click)="goToListEntities()">Open Project</button>
            <button mat-menu-item fxShow="true" [matMenuTriggerFor]="menuControlPanel">Settings</button>
        </mat-menu>
        <mat-menu #menuIndividuals="matMenu" xPosition="before">
            <button mat-menu-item (click)="goToListDevices()" [disabled]="!canViewProject()">Device</button>
            <button mat-menu-item (click)="goToListManuscripts()" [disabled]="!canViewProject()">Manuscript</button>
            <button mat-menu-item (click)="goToListPersons()" [disabled]="!canViewProject()">Person</button>
            <button mat-menu-item (click)="goToListActors()" [disabled]="!canViewProject()">Actor/Role</button>
            <button mat-menu-item (click)="goToListTools()" [disabled]="!canViewProject()">Tool</button>
            <button mat-menu-item (click)="goToListManuses()" [disabled]="!canViewProject()">Manus</button>
            <hr flex />
            <button mat-menu-item (click)="goToListInstitutions()" [disabled]="!canViewProject()">Institution</button>
            <button mat-menu-item (click)="goToListFaculties()" [disabled]="!canViewProject()">Faculty</button>
            <button mat-menu-item (click)="goToListDepartments()" [disabled]="!canViewProject()">Department</button>
            <button mat-menu-item (click)="goToListSchools()" [disabled]="!canViewProject()">School</button>
            <button mat-menu-item (click)="goToListTeams()" [disabled]="!canViewProject()">Team</button>
        </mat-menu>
        <mat-menu #menuControlPanel="matMenu" xPosition="before">
            <button mat-menu-item (click)="showCharacterInfo()" [disabled]="!canViewProject()">Show Character
                Info</button>
        </mat-menu>
        <mat-menu #menuUser="matMenu" xPosition="before">
            <button mat-menu-item (click)="goToListUsers()"><mat-icon>supervisor_account</mat-icon>List</button>
            <button mat-menu-item (click)="goToLogout()"><mat-icon>logout</mat-icon>Logout</button>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>
