<block-ui></block-ui>
<div class="content">
    <mat-card>
        <mat-card-title>
            <app-common-top [title]="'Faculties List'" [entityId]="entityId" (newClick)="new()"></app-common-top>
        </mat-card-title>
        <mat-card-content>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{getNameWithoutUri(element.name)}} <mat-icon matTooltip="{{ element.name }}">info</mat-icon> </td>
                    </ng-container>

                    <ng-container matColumnDef="institution">
                        <th mat-header-cell *matHeaderCellDef> Institution </th>
                        <td mat-cell *matCellDef="let element"> {{getNameWithoutUri(element.institution?.name)}} <mat-icon matTooltip="{{ element.institution?.name }}" *ngIf="element.institution.name">info</mat-icon> </td>
                    </ng-container>

                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef> Department </th>
                        <td mat-cell *matCellDef="let element"> {{getNameWithoutUri(element.department?.name)}} <mat-icon matTooltip="{{ element.department?.name }}" *ngIf="element.department.name">info</mat-icon> </td>
                    </ng-container>

                    <ng-container matColumnDef="school">
                        <th mat-header-cell *matHeaderCellDef> School </th>
                        <td mat-cell *matCellDef="let element"> {{getNameWithoutUri(element.school?.name)}} <mat-icon matTooltip="{{ element.school?.name }}" *ngIf="element.school.name">info</mat-icon> </td>
                    </ng-container>

                    <ng-container matColumnDef="fullName">
                        <th mat-header-cell *matHeaderCellDef> Full name </th>
                        <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="street">
                        <th mat-header-cell *matHeaderCellDef> Street </th>
                        <td mat-cell *matCellDef="let element"> {{element.street}} {{element.streetNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="city">
                        <th mat-header-cell *matHeaderCellDef> City </th>
                        <td mat-cell *matCellDef="let element"> {{element.city}} </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef> State </th>
                        <td mat-cell *matCellDef="let element"> {{element.state}} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="view(element)">
                                    <mat-icon>info</mat-icon>
                                    <span>View</span>
                                </button>
                                <button mat-menu-item (click)="edit(element)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item (click)="delete(element)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                            No data found...
                        </td>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>
