import { WindowArea } from "../models/enums/windowArea";
import { Actor } from "../models/jena/actor";
import { Manus } from "../models/jena/manus";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class EntitySparql {
    public static getEntityContent(windowArea: WindowArea): string {
        var sparql = PREFIXES +
        `
        SELECT ?sideType ?sideTypeOrder ?row ?rowOrder ?wordIndex ?column ?columnOrder ?character ?letterDescription ?utf ?area ?rowInRow ?rowOver ?rowAbove ?rowBelow ?stroke ?transcription ?manus ?actor ?manuscript
        WHERE {
            ?sideType a ?subclass ;
                ${SH.absoluteOrderProperty} ?sideTypeOrder .
            ?subclass rdfs:subClassOf* ${SH.sideClass} .
            OPTIONAL {
                ?row ${SH.orderProperty} ?rowOrder ;
                    ${SH.locatedInAtSomeTime} ?area .
                ?area ${SH.locatedInAtSomeTime} ?sideType .
                OPTIONAL {
                    ?column a ${SH.columnClass} ;
                        ${SH.orderProperty} ?columnOrder ;
                        ${SH.locatedInAtSomeTime} ?row .
                    OPTIONAL {
                        ?character ${SH.isConcretizedByAtSomeTime} ?letter;
                            ${SH.hasAncestor} ?stroke.
                        ?stroke ${SH.locatedInAtSomeTime} ?column .
                        ?letter rdfs:label ?letterDescription ;
                            ${SH.unicodeCodePointProperty} ?utf .
                        OPTIONAL {
                          ?character ${SH.continuantPartOfAtAllTimes} ?word .
                          ?word ${SH.orderProperty} ?wordIndex .
                        }.
                        OPTIONAL {
                            ?rowInRow ${SH.projectedOnTheLeftOf} ?stroke .
                        }.
                        OPTIONAL {
                            ?rowOver ${SH.projectedAsOverlapping} ?stroke .
                        }.
                        OPTIONAL {
                            ?rowAbove ${SH.projectedAbove} ?stroke .
                        }.
                        OPTIONAL {
                            ?rowBelow ${SH.projectedBelow} ?stroke .
                        }
                        OPTIONAL {
                            ?transcription a ${SH.genericTranscriptionClass};
                                ${SH.hasParticipantAtSomeTime} ?stroke .
          					?manus a ${SH.manusClass} .
          					?transcription ${SH.hasParticipantAtSomeTime} ?manus .
          					?actor a ${SH.actorClass} .
          					?transcription ${SH.hasParticipantAtSomeTime} ?actor ;
                        }.
                        #OPTIONAL {
                        #    ?sideType ${SH.continuantPartOfAtAllTimes} ?leaf .
          				#	?leaf a ${SH.dependentLeafClass} .
          				#	?leaf ${SH.memberPartOfAtAllTimes} ?manuscript .
                        #}.
                    }
                }
            }.
            FILTER (STRSTARTS(str(?area), str(${SH.getArea(windowArea)})))
        }
        ORDER BY ASC(?sideTypeOrder) ASC(?rowOrder) ASC(?columnOrder)
        `;

        if (windowArea === WindowArea.Main) {
            console.log(sparql);
        }

        return sparql;
    }

    // public static getEntityContent_old(): string {
    //     var sparql = PREFIXES +
    //     `
    //     SELECT ?column ?rowOrder ?columnOrder ?letterDescription ?utf ?row
    //     WHERE {
    //         ?row ${SH.orderProperty} ?rowOrder .
    //         ?column a ${SH.columnClass} ;
    //             ${SH.orderProperty} ?columnOrder ;
    //             ${SH.locatedInAtSomeTime} ?row .
    //         OPTIONAL {
    //             ?stroke ${SH.locatedInAtSomeTime} ?column ;
    //                 ${SH.bearerOf} ?letter .
    //             ?letter rdfs:label ?letterDescription ;
    //                 ${SH.unicodeCodePointProperty} ?utf .
    //         }
    //     }
    //     ORDER BY ASC(?rowOrder) ASC(?columnOrder)
    //     `;

    //     return sparql;
    // }

    public static addCharacter(area: string, row: number, character: string, position: number | undefined, manus?: Manus, actor?: Actor): string {
        var updateOrders =
        `
        DELETE {
            ?s ${SH.orderProperty} ?oldOrder
        }
        INSERT {
            ?s ${SH.orderProperty} ?newOrder
        }
        WHERE {
            ?row a ${SH.rowClass} ;
                ${SH.orderProperty} ${row} ;
                ${SH.locatedInAtSomeTime} ent:${area} .
            ?s a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ?oldOrder .
            FILTER(?oldOrder >= ${position})
            BIND(?oldOrder + 1 as ?newOrder)
        };
        `;

        var strokeTriplets = `
        ?newStroke a ${SH.strokeClass} ;
            ${SH.locatedInAtSomeTime} ?newColumn ;
            ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'}.
        `;

        var strokeAndLetterQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'str', STR(MAX(?res) + 1))) as ?newStroke)
            WHERE {
                {
                    SELECT ?counter ?stroke
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.strokeClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'str', '')) as ?stroke).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.strokeClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?stroke)  as ?res)
            }
        }
        {
            SELECT ?letter
            WHERE {
            ?letter a/rdfs:subClassOf* ${SH.letterClass} ;
                ${SH.unicodeCharacterProperty} '${character}' .
            }
        }
        `;

        var characterTriplets = `
        ?newCharacter a ${SH.characterClass} ;
            ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'} ;
            ${SH.isConcretizedByAtSomeTime} ?letter;
            ${SH.hasAncestor} ?newStroke .
        `;

        var characterQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'c', STR(MAX(?res) + 1))) as ?newCharacter)
            WHERE {
                {
                    SELECT ?counter ?character
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.characterClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'c', '')) as ?character).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.characterClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?character)  as ?res)
            }
        }
        `;

        var genericTransTriplets = `
        ?newGeneric a ${SH.genericTranscriptionClass} ;
            ${SH.hasParticipantAtSomeTime} ?newStroke ;
            ${SH.hasParticipantAtSomeTime} <${manus!.name}> ;
            ${SH.hasParticipantAtSomeTime} <${actor!.name}> ;
            ${SH.hasParticipantAtSomeTime} ?newCharacter .
        `;

        var genericTransQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'trans', STR(MAX(?res) + 1))) as ?newGeneric)
            WHERE {
                {
                    SELECT ?counter ?generic
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.genericTranscriptionClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'trans', '')) as ?generic).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.genericTranscriptionClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?generic)  as ?res)
            }
        }
        `;

        var sparql = PREFIXES +
        `
        ${position != undefined ? updateOrders : ''}
        INSERT {
            ?newColumn a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'} .
            ${strokeTriplets}
            ${characterTriplets}
            ${genericTransTriplets}
        }
        WHERE {
            {
                SELECT ?row
                WHERE {
                    ?row a ${SH.rowClass} ;
                        ${SH.orderProperty} ${row} ;
                        ${SH.locatedInAtSomeTime} ent:${area} .
                }
            }
            {
                SELECT (IRI(CONCAT(STR(ent:), 'cl', STR(MAX(?res) + 1))) as ?newColumn)
                WHERE {
                    {
                        SELECT ?counter ?column
                        WHERE {
                            OPTIONAL{?s a ${SH.columnClass}}.
                            BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'cl', '')) as ?column).
                            {
                                SELECT (count(?s) as ?counter)
                                WHERE {
                                    ?s a ${SH.columnClass}
                                }
                            }
                        }
                    }.
                    BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
                }
            }
            ${strokeAndLetterQuery}
            ${characterQuery}
            ${genericTransQuery}
            {
                SELECT ((?res + 1) as ?nextOrder)
                WHERE {
                    {
                        SELECT (MAX(?order) as ?maxOrder)
                        WHERE {
                            ?row a ${SH.rowClass} ;
                                ${SH.orderProperty} ${row} ;
                                ${SH.locatedInAtSomeTime} ent:${area} .
                            ?col a ${SH.columnClass} ;
                                ${SH.locatedInAtSomeTime} ?row ;
                                ${SH.orderProperty} ?order .
                        }
                    }
                    {
                        SELECT (count(?s) as ?counter)
                        WHERE {
                            ?s a ${SH.columnClass}
                        }
                    }.
                    BIND(if(?counter=xsd:integer(0),?counter,?maxOrder)  as ?res)
                }
            }
        };
        `;

        return sparql;
    }

    public static addSpace(area: string, row: number, position: number | undefined, manus?: Manus, actor?: Actor): string {
        var updateOrders =
        `
        DELETE {
            ?s ${SH.orderProperty} ?oldOrder
        }
        INSERT {
            ?s ${SH.orderProperty} ?newOrder
        }
        WHERE {
            ?row a ${SH.rowClass} ;
                ${SH.orderProperty} ${row} ;
                ${SH.locatedInAtSomeTime} ent:${area} .
            ?s a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ?oldOrder .
            FILTER(?oldOrder >= ${position})
            BIND(?oldOrder + 1 as ?newOrder)
        };
        `;

        var whitespaceTriplets = `
        ?newWhitespace a ${SH.whitespaceClass} ;
            ${SH.locatedInAtSomeTime} ?newColumn ;
            ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'}.
        `;

        var whitespaceAndLetterQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'wsp', STR(MAX(?res) + 1))) as ?newWhitespace)
            WHERE {
                {
                    SELECT ?counter ?whitespace
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.whitespaceClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'wsp', '')) as ?whitespace).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.whitespaceClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?whitespace)  as ?res)
            }
        }
        `;

        var characterTriplets = `
        ?newCharacter a ${SH.characterClass} ;
            ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'} ;
            ${SH.isConcretizedByAtSomeTime} ${SH.emptySetIndividual};
            ${SH.hasAncestor} ?newWhitespace .
        `;

        var characterQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'c', STR(MAX(?res) + 1))) as ?newCharacter)
            WHERE {
                {
                    SELECT ?counter ?character
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.characterClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'c', '')) as ?character).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.characterClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?character)  as ?res)
            }
        }
        `;

        var genericTransTriplets = `
        ?newGeneric a ${SH.genericTranscriptionClass} ;
            ${SH.hasParticipantAtSomeTime} ?newWhitespace ;
            ${SH.hasParticipantAtSomeTime} <${manus!.name}> ;
            ${SH.hasParticipantAtSomeTime} <${actor!.name}> ;
            ${SH.hasParticipantAtSomeTime} ?newCharacter .
        `;

        var genericTransQuery = `
        {
            SELECT (IRI(CONCAT(STR(ent:), 'trans', STR(MAX(?res) + 1))) as ?newGeneric)
            WHERE {
                {
                    SELECT ?counter ?generic
                    WHERE {
                        OPTIONAL{
                            ?s a ${SH.genericTranscriptionClass}
                        }.
                        BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'trans', '')) as ?generic).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.genericTranscriptionClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?generic)  as ?res)
            }
        }
        `;

        var sparql = PREFIXES +
        `
        ${position != undefined ? updateOrders : ''}
        INSERT {
            ?newColumn a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position != undefined ? position : '?nextOrder'} .
            ${whitespaceTriplets}
            ${characterTriplets}
            ${genericTransTriplets}
        }
        WHERE {
            {
                SELECT ?row
                WHERE {
                    ?row a ${SH.rowClass} ;
                        ${SH.orderProperty} ${row} ;
                        ${SH.locatedInAtSomeTime} ent:${area} .
                }
            }
            {
                SELECT (IRI(CONCAT(STR(ent:), 'cl', STR(MAX(?res) + 1))) as ?newColumn)
                WHERE {
                    {
                        SELECT ?counter ?column
                        WHERE {
                            OPTIONAL{?s a ${SH.columnClass}}.
                            BIND(xsd:integer(REPLACE(STR(?s), STR(ent:) + 'cl', '')) as ?column).
                            {
                                SELECT (count(?s) as ?counter)
                                WHERE {
                                    ?s a ${SH.columnClass}
                                }
                            }
                        }
                    }.
                    BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
                }
            }
            ${whitespaceAndLetterQuery}
            ${characterQuery}
            ${genericTransQuery}
            {
                SELECT ((?res + 1) as ?nextOrder)
                WHERE {
                    {
                        SELECT (MAX(?order) as ?maxOrder)
                        WHERE {
                            ?row a ${SH.rowClass} ;
                                ${SH.orderProperty} ${row} ;
                                ${SH.locatedInAtSomeTime} ent:${area} .
                            ?col a ${SH.columnClass} ;
                                ${SH.locatedInAtSomeTime} ?row ;
                                ${SH.orderProperty} ?order .
                        }
                    }
                    {
                        SELECT (count(?s) as ?counter)
                        WHERE {
                            ?s a ${SH.columnClass}
                        }
                    }.
                    BIND(if(?counter=xsd:integer(0),?counter,?maxOrder)  as ?res)
                }
            }
        };
        `;

        return sparql;
    }

    public static deleteCharacter(area: string, row: number, position: number, isBackspace: boolean, windowArea: WindowArea): string {
        var updateOrders = `
        DELETE {
            ?s ${SH.orderProperty} ?oldOrder
        }
        INSERT {
            ?s ${SH.orderProperty} ?newOrder
        }
        WHERE {
            ?row a ${SH.rowClass} ;
                ${SH.orderProperty} ${row} ;
                ${SH.locatedInAtSomeTime} ent:${area} .
            ?s a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ?oldOrder .
            FILTER(?oldOrder >= ${isBackspace ? position : position + 1})
            BIND(?oldOrder - 1 as ?newOrder)
        };
        `;

        var sparql = PREFIXES +
        `
        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?row a ${SH.rowClass} ;
                ${SH.orderProperty} ${row} ;
                ${SH.locatedInAtSomeTime} ent:${area} .
            ?col a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position} .
            ?stroke a ${SH.strokeClass};
            ${SH.locatedInAtSomeTime} ?col .
            ?x ?y ?z .
            ?x a ${SH.characterClass};
                ${SH.hasAncestor} ?stroke .
        };

        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?row a ${SH.rowClass} ;
                ${SH.orderProperty} ${row} ;
                ${SH.locatedInAtSomeTime} ent:${area} .
            ?col a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position} .
            ?x ?y ?z .
            ?x a ${SH.strokeClass};
                ${SH.locatedInAtSomeTime} ?col .
        };

        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?x ?y ?z .
            ?row a ${SH.rowClass} ;
                ${SH.orderProperty} ${row} ;
                ${SH.locatedInAtSomeTime} ent:${area} .
            ?col a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position} .
            FILTER(?z = ?col)
        };

        DELETE {
            ?x ?y ?z
        }
        WHERE {
            ?x ?y ?z .
            ?row a ${SH.rowClass} ;
                ${SH.orderProperty} ${row} ;
                ${SH.locatedInAtSomeTime} ent:${area} .
            ?col a ${SH.columnClass} ;
                ${SH.locatedInAtSomeTime} ?row ;
                ${SH.orderProperty} ${position} .
            FILTER(?x = ?col)
        };

        ${updateOrders}
        `;

        return sparql;
    }

    public static addRow(area: string, rowPosition: number, columnPosition: number, windowArea: WindowArea) {
        var sparql = PREFIXES + `
        DELETE { ?row ${SH.orderProperty} ?oldOrder }
        INSERT { ?row ${SH.orderProperty} ?newOrder }
        WHERE {
            ?row a ${SH.rowClass} ;
                 ${SH.locatedInAtSomeTime} ent:${area} ;
                 ${SH.orderProperty} ?oldOrder .
            FILTER(?oldOrder > ${rowPosition})
            BIND(?oldOrder + 1 as ?newOrder)
        };

        INSERT {
            ?new a ${SH.rowClass} ;
                 ${SH.orderProperty} ${rowPosition + 1} ;
                 ${SH.locatedInAtSomeTime} ent:${area} .
        }
        WHERE {
            {
                SELECT (MAX(?order + 1) as ?nextOrder)
                WHERE {
                    ?currentRow a ${SH.rowClass} ;
                                ${SH.locatedInAtSomeTime} ent:${area} ;
                                ${SH.orderProperty} ?order .
                }
            }
            {
                SELECT (IRI(CONCAT(STR(ent:), 'row', STR(MAX(?nextRow)))) as ?new)
                WHERE {
                    SELECT (MAX(?currentRow + 1) as ?nextRow)
                    WHERE {
                        ?row a ${SH.rowClass}.
                        BIND(xsd:integer(REPLACE(STR(?row), STR(ent:) + 'row', '')) as ?currentRow).
                    }
                }
            }
        };

        DELETE { ?column ${SH.locatedInAtSomeTime} ?oldRow }
        INSERT { ?column ${SH.locatedInAtSomeTime} ?newRow }
        WHERE {
              SELECT ?oldRow ?newRow ?column
              WHERE {
                    ?column a ${SH.columnClass} ;
                            ${SH.locatedInAtSomeTime} ?oldRow ;
                            ${SH.orderProperty} ?columnOrder .
                    FILTER(?columnOrder > ${columnPosition - 1})

                    {
                          SELECT ?oldRow
                          WHERE {
                                ?oldRow a ${SH.rowClass} ;
                                        ${SH.locatedInAtSomeTime} ent:${area} ;
                                        ${SH.orderProperty} ${rowPosition} .
                          }
                    }
                    {
                          SELECT ?newRow
                          WHERE {
                                ?newRow a ${SH.rowClass} ;
                                        ${SH.locatedInAtSomeTime} ent:${area} ;
                                        ${SH.orderProperty} ${rowPosition + 1} .
                          }
                    }
              }
        };

        DELETE { ?column ${SH.orderProperty} ?oldOrder }
        INSERT { ?column ${SH.orderProperty} ?newOrder }
        WHERE {
            SELECT ?column ?oldOrder ?newOrder
            WHERE {
                {
                    SELECT (MIN(?oldOrder) - 1 as ?minOrder)
                    WHERE {
                        ?column a ${SH.columnClass} ;
                                ${SH.locatedInAtSomeTime} ?newRow ;
                                ${SH.orderProperty} ?oldOrder .
                        {
                            SELECT ?newRow
                            WHERE {
                                ?newRow a ${SH.rowClass} ;
                                        ${SH.locatedInAtSomeTime} ent:${area} ;
                                        ${SH.orderProperty} ${rowPosition + 1} .
                            }
                        }
                    }
                }

                ?column a ${SH.columnClass} ;
                        ${SH.locatedInAtSomeTime} ?newRow ;
                        ${SH.orderProperty} ?oldOrder .
                BIND(?oldOrder - ?minOrder as ?newOrder)
                {
                    SELECT ?newRow
                    WHERE {
                        ?newRow a ${SH.rowClass} ;
                                ${SH.locatedInAtSomeTime} ent:${area} ;
                                ${SH.orderProperty} ${rowPosition + 1} .
                    }
                }
            }
        };
        `;

        return sparql;
    }

    public static deleteRow(area: string, rowPosition: number, windowArea: WindowArea, merge: boolean = true) {
        var sparql = PREFIXES + `
        DELETE { ?column ${SH.orderProperty} ?oldOrder }
        INSERT { ?column ${SH.orderProperty} ?newOrder }
        WHERE {
            {
                SELECT ?row ?column ?oldOrder ?newOrder
                WHERE {
                    ?column a ${SH.columnClass} ;
                            ${SH.locatedInAtSomeTime} ?row ;
                            ${SH.orderProperty} ?oldOrder .

                    ?row ${SH.locatedInAtSomeTime} ent:${area} ;
                         ${SH.orderProperty} ${rowPosition} .

                    {
                        SELECT (MAX(?prevOrder) as ?maxOrder)
                        WHERE {
                            ?column a ${SH.columnClass} ;
                                    ${SH.locatedInAtSomeTime} ?row ;
                                    ${SH.orderProperty} ?prevOrder .

                            ?row ${SH.locatedInAtSomeTime} ent:${area} ;
                                 ${SH.orderProperty} ${rowPosition - 1} .
                    }
                }
                BIND(?oldOrder + ?maxOrder as ?newOrder)
                FILTER(BOUND(?newOrder))
            }
          }
        };

        DELETE { ?column ${SH.locatedInAtSomeTime} ?oldRow }
        INSERT { ?column ${SH.locatedInAtSomeTime} ?newRow }
        WHERE {
            SELECT ?oldRow ?newRow ?column
            WHERE {
                ?column a ${SH.columnClass} ;
                        ${SH.locatedInAtSomeTime} ?oldRow ;
                        ${SH.orderProperty} ?columnOrder .

                {
                    SELECT ?oldRow
                    WHERE {
                        ?oldRow a ${SH.rowClass} ;
                                ${SH.locatedInAtSomeTime} ent:${area} ;
                                ${SH.orderProperty} ${rowPosition} .
                    }
                }
                {
                    SELECT ?newRow
                    WHERE {
                        ?newRow a ${SH.rowClass} ;
                                ${SH.locatedInAtSomeTime} ent:${area} ;
                                ${SH.orderProperty} ${rowPosition - 1} .
                    }
                }
            }
        };

        DELETE { ?row ?p ?o }
        WHERE {
            ?row a ${SH.rowClass} ;
                 ${SH.locatedInAtSomeTime} ent:${area} ;
                 ${SH.orderProperty} ${rowPosition} ;
                 ?p ?o .
        };

        DELETE { ?row ${SH.orderProperty} ?oldOrder }
        INSERT { ?row ${SH.orderProperty} ?newOrder }
        WHERE {
            ?row a ${SH.rowClass} ;
                 ${SH.locatedInAtSomeTime} ent:${area} ;
                 ${SH.orderProperty} ?oldOrder .

            FILTER(?oldOrder > ${rowPosition})
            BIND(?oldOrder - 1 as ?newOrder)
        };
        `;

        return sparql;
    }

    public static addPages(side: string) {
        var sparql = PREFIXES + `

        INSERT {
            ?newDepleaf a ${SH.dependentLeafClass} .
            ?newFront a ${side === '1' ? SH.pageClass : SH.rectoClass} ;
                ${SH.continuantPartOfAtAllTimes} ?newDepleaf ;
                ${SH.orderProperty} 1 ;
                ${SH.absoluteOrderProperty} ?newFrontOrder .
            ?newBack a ${side === '1' ? SH.pageClass : SH.versoClass} ;
                ${SH.continuantPartOfAtAllTimes} ?newDepleaf ;
                ${SH.orderProperty} 2 ;
                ${SH.absoluteOrderProperty} ?newBackOrder .
            ?newMainFront a ${SH.mainAreaClass} ;
                ${SH.locatedInAtSomeTime} ?newFront .
            ?newMainBack a ${SH.mainAreaClass} ;
                ${SH.locatedInAtSomeTime} ?newBack .
            ?newHeadFront a ${SH.headClass} ;
                ${SH.locatedInAtSomeTime} ?newFront .
            ?newHeadBack a ${SH.headClass} ;
                ${SH.locatedInAtSomeTime} ?newBack .
            ?newFootFront a ${SH.footClass} ;
                ${SH.locatedInAtSomeTime} ?newFront .
            ?newFootBack a ${SH.footClass} ;
                ${SH.locatedInAtSomeTime} ?newBack .
            ?newInnerFront a ${SH.innerMarginClass} ;
                ${SH.locatedInAtSomeTime} ?newFront .
            ?newInnerBack a ${SH.innerMarginClass} ;
                ${SH.locatedInAtSomeTime} ?newBack .
            ?newOuterFront a ${SH.outerMarginClass} ;
                ${SH.locatedInAtSomeTime} ?newFront .
            ?newOuterBack a ${SH.outerMarginClass} ;
                ${SH.locatedInAtSomeTime} ?newBack .
            ?newInterlinearFront a ${SH.interlinearSpaceClass} ;
                ${SH.locatedInAtSomeTime} ?newFront .
            ?newInterlinearBack a ${SH.interlinearSpaceClass} ;
                ${SH.locatedInAtSomeTime} ?newBack .
            ?newIntercolumnarFront a ${SH.intercolumnarSpaceClass} ;
                ${SH.locatedInAtSomeTime} ?newFront .
            ?newIntercolumnarBack a ${SH.intercolumnarSpaceClass} ;
                ${SH.locatedInAtSomeTime} ?newBack .
            ?newRowMainFront a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newMainFront ;
                ${SH.orderProperty} 1 .
            ?newRowHeadFront a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newHeadFront ;
                ${SH.orderProperty} 1 .
            ?newRowFootFront a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newFootFront ;
                ${SH.orderProperty} 1 .
            ?newRowInnerFront a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newInnerFront ;
                ${SH.orderProperty} 1 .
            ?newRowOuterFront a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newOuterFront ;
                ${SH.orderProperty} 1 .
            ?newRowInterlinearFront a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newInterlinearFront ;
                ${SH.orderProperty} 1 .
            ?newRowIntercolumnarFront a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newIntercolumnarFront ;
                ${SH.orderProperty} 1 .
            ?newRowMainBack a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newMainBack ;
                ${SH.orderProperty} 1 .
            ?newRowHeadBack a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newHeadBack ;
                ${SH.orderProperty} 1 .
            ?newRowFootBack a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newFootBack ;
                ${SH.orderProperty} 1 .
            ?newRowInnerBack a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newInnerBack ;
                ${SH.orderProperty} 1 .
            ?newRowOuterBack a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newOuterBack ;
                ${SH.orderProperty} 1 .
            ?newRowInterlinearBack a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newInterlinearBack ;
                ${SH.orderProperty} 1 .
            ?newRowIntercolumnarBack a ${SH.rowClass} ;
                ${SH.locatedInAtSomeTime} ?newIntercolumnarBack ;
                ${SH.orderProperty} 1 .
        }
        WHERE {
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), ${side === '1' ? '\'page\'' : '\'r\''} , STR(?currentSideTypeOrder + 1))) as ?newFront)
                    (IRI(CONCAT(STR(ent:), ${side === '1' ? '\'page\'' : '\'v\''} , STR(?currentSideTypeOrder + 2))) as ?newBack)
                    ((?currentSideTypeOrder + 1) as ?newFrontOrder)
                    ((?currentSideTypeOrder + 2) as ?newBackOrder)
                WHERE {
                    SELECT (MAX(?sideTypeOrder) as ?currentSideTypeOrder)
                    WHERE {
                        ?sideType a ?subclass ;
                            ${SH.absoluteOrderProperty} ?sideTypeOrder .
                        ?subclass rdfs:subClassOf* ${SH.sideClass} .
                    }
                }
            }.
            {
                SELECT (IRI(CONCAT(STR(ent:), 'depleaf', STR(?newDepleafCounter))) as ?newDepleaf)
                WHERE {
                    SELECT (MAX(?depleafCounter) + 1 as ?newDepleafCounter)
                    WHERE {
                        ?depleaf a ${SH.dependentLeafClass} .
                        BIND(xsd:integer(REPLACE(STR(?depleaf), STR(ent:) + 'depleaf', '')) as ?depleafCounter).
                    }
                }
            }.
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), 'main', STR(?currentMainCounter + 1))) as ?newMainFront)
                    (IRI(CONCAT(STR(ent:), 'main', STR(?currentMainCounter + 2))) as ?newMainBack)
                WHERE {
                    SELECT (MAX(?mainCounter) as ?currentMainCounter)
                    WHERE {
                        ?main a ${SH.mainAreaClass} .
                        BIND(xsd:integer(REPLACE(STR(?main), STR(ent:) + 'main', '')) as ?mainCounter).
                    }
                }
            }.
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), 'head', STR(?currentHeadCounter + 1))) as ?newHeadFront)
                    (IRI(CONCAT(STR(ent:), 'head', STR(?currentHeadCounter + 2))) as ?newHeadBack)
                WHERE {
                    SELECT (MAX(?headCounter) as ?currentHeadCounter)
                    WHERE {
                        ?head a ${SH.headClass} .
                        BIND(xsd:integer(REPLACE(STR(?head), STR(ent:) + 'head', '')) as ?headCounter).
                    }
                }
            }.
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), 'foot', STR(?currentFootCounter + 1))) as ?newFootFront)
                    (IRI(CONCAT(STR(ent:), 'foot', STR(?currentFootCounter + 2))) as ?newFootBack)
                WHERE {
                    SELECT (MAX(?footCounter) as ?currentFootCounter)
                    WHERE {
                        ?foot a ${SH.footClass} .
                        BIND(xsd:integer(REPLACE(STR(?foot), STR(ent:) + 'foot', '')) as ?footCounter).
                    }
                }
            }.
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), 'inner', STR(?currentInnerCounter + 1))) as ?newInnerFront)
                    (IRI(CONCAT(STR(ent:), 'inner', STR(?currentInnerCounter + 2))) as ?newInnerBack)
                WHERE {
                    SELECT (MAX(?innerCounter) as ?currentInnerCounter)
                    WHERE {
                        ?inner a ${SH.innerMarginClass} .
                        BIND(xsd:integer(REPLACE(STR(?inner), STR(ent:) + 'inner', '')) as ?innerCounter).
                    }
                }
            }.
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), 'outer', STR(?currentOuterCounter + 1))) as ?newOuterFront)
                    (IRI(CONCAT(STR(ent:), 'outer', STR(?currentOuterCounter + 2))) as ?newOuterBack)
                WHERE {
                    SELECT (MAX(?outerCounter) as ?currentOuterCounter)
                    WHERE {
                        ?outer a ${SH.outerMarginClass} .
                        BIND(xsd:integer(REPLACE(STR(?outer), STR(ent:) + 'outer', '')) as ?outerCounter).
                    }
                }
            }.
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), 'interlinear', STR(?currentInterlinearCounter + 1))) as ?newInterlinearFront)
                    (IRI(CONCAT(STR(ent:), 'interlinear', STR(?currentInterlinearCounter + 2))) as ?newInterlinearBack)
                WHERE {
                    SELECT (MAX(?interlinearCounter) as ?currentInterlinearCounter)
                    WHERE {
                        ?interlinear a ${SH.interlinearSpaceClass} .
                        BIND(xsd:integer(REPLACE(STR(?interlinear), STR(ent:) + 'interlinear', '')) as ?interlinearCounter).
                    }
                }
            }.
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), 'intercolumnar', STR(?currentIntercolumnarCounter + 1))) as ?newIntercolumnarFront)
                    (IRI(CONCAT(STR(ent:), 'intercolumnar', STR(?currentIntercolumnarCounter + 2))) as ?newIntercolumnarBack)
                WHERE {
                    SELECT (MAX(?intercolumnarCounter) as ?currentIntercolumnarCounter)
                    WHERE {
                        ?intercolumnar a ${SH.intercolumnarSpaceClass} .
                        BIND(xsd:integer(REPLACE(STR(?intercolumnar), STR(ent:) + 'intercolumnar', '')) as ?intercolumnarCounter).
                    }
                }
            }.
            {
                SELECT
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 1))) as ?newRowMainFront)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 2))) as ?newRowHeadFront)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 3))) as ?newRowFootFront)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 4))) as ?newRowInnerFront)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 5))) as ?newRowOuterFront)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 6))) as ?newRowInterlinearFront)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 7))) as ?newRowIntercolumnarFront)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 8))) as ?newRowMainBack)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 9))) as ?newRowHeadBack)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 10))) as ?newRowFootBack)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 11))) as ?newRowInnerBack)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 12))) as ?newRowOuterBack)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 13))) as ?newRowInterlinearBack)
                    (IRI(CONCAT(STR(ent:), 'row', STR(?currentRowCounter + 14))) as ?newRowIntercolumnarBack)
                WHERE {
                    SELECT (MAX(?rowCounter) as ?currentRowCounter)
                    WHERE {
                        ?row a ${SH.rowClass} .
                        BIND(xsd:integer(REPLACE(STR(?row), STR(ent:) + 'row', '')) as ?rowCounter).
                    }
                }
            }
        };


        `;

        return sparql;
    }
}
