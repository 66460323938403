import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { KeycloakProfile } from 'keycloak-js';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SortingDirection } from 'src/app/models/enums/sortingDirection';
import { KeycloakUser } from 'src/app/models/keycloakUser';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { DialogRolesUserComponent } from './dialog-roles-user/dialog-roles-user.component';

@Component({
    selector: 'app-list-users',
    templateUrl: './list-users.component.html',
    styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit {
    @BlockUI('usersContainer') usersContainerBlock!: NgBlockUI;
    displayedColumns: string[] = ['id','username','email','firstName','lastName','enabled', 'emailVerified', 'actions'];
    dataSource: MatTableDataSource<KeycloakUser>;
    public keycloakUsers: Array<KeycloakUser>;
    public currentUser?: KeycloakProfile

    // @ViewChild(MatPaginator) paginator!: MatPaginator;
    // @ViewChild(MatSort) sort!: MatSort;
    length!: number;
    pageIndex!: number;
    pageSize!: number;

    private defaultSortingColumn = 'id';
    private defaultSortingDirection = SortingDirection.Descending;

    constructor(private _userService: UserService,
        private _dialog: MatDialog,
        private _router: Router,
        private _authService: AuthService) {
        this.dataSource = new MatTableDataSource();
        this.keycloakUsers = new Array<KeycloakUser>();
    }

    ngOnInit(): void {
        this.pageIndex = 1;
        this.pageSize = 10;
        this._findUsers(this.pageIndex, this.pageSize, this.defaultSortingColumn, this.defaultSortingDirection);

    }

    // public ngAfterViewInit(): void {
    //     this.sort.sortChange.subscribe((sort: Sort) => {
    //         this._findUsers(this.pageIndex, this.pageSize, sort.active, SortingDirection[sort.direction as keyof typeof SortingDirection]);
    //     });
    // }

    private _findUsers(pageNo: number, size: number, sortingColumn: string, sortDirection: SortingDirection) {
        this.usersContainerBlock.start();

        const conditions = new Array<string>();

        this._userService.getKeycloakUsers().subscribe(
            (result) => {
                this.keycloakUsers = result;
                this.dataSource.data = this.keycloakUsers;

                this.usersContainerBlock.stop();
            },
            (error) => {
                this.usersContainerBlock.stop();
            }
        );
    }

    public openRolesDialog(keycloakUser: KeycloakUser) {
        const dialogRef = this._dialog.open(DialogRolesUserComponent, {
            width: '480px',
            data: {
                keycloakUser: keycloakUser
            }
        });

        dialogRef.afterClosed().subscribe(

        );
    }
}
