export function InitializeRoleMapping(): Array<RoleMap> {
    return [
        {
            'userInterfaceElement': 'settings-menu',
            'acceptedRoles': ['administrator']
        },
        {
            'userInterfaceElement': 'assign-participants',
            'acceptedRoles': ['administrator']
        },
        {
            'userInterfaceElement': 'new-entity',
            'acceptedRoles': ['administrator']
        }
    ];
}

export function InitializeShowMapping(): Array<RoleMap> {
    return [
        {
            'userInterfaceElement': 'settings-menu',
            'acceptedRoles': ['administrator']
        }
    ];
}

export class RoleMap {
    userInterfaceElement!: string;
    acceptedRoles!: Array<string>;
}
