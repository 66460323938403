import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Entity } from '../models/entity';
import { NavItem } from '../models/navItem';
import { OwlItem } from '../models/owlItem';

@Injectable({
    providedIn: 'root'
})
export class OwlService {

    constructor(private http: HttpClient) {
    }

    routeParams: any;
    webApiUrl = environment.webApiUrl;
    headers: Headers | undefined;

    // -------------- //
    // CREATE METHODS //
    // -------------- //

    public createOwlItemsFromFile(): Observable<Array<OwlItem>> {
        return this.http.post(this.webApiUrl + `/api/OwlItems/CreateOwlItemsFromFile`,
        null)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public addOwlItem(_owlItem: OwlItem): Observable<Entity> {
        return this.http.post(this.webApiUrl + `/api/OwlItems/AddOwlItem`,
        _owlItem)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // GET METHODS    //
    // -------------- //

    public getOntologyTreeAsNavItems(): Observable<Array<NavItem>> {
        return this.http.get(this.webApiUrl + `/api/OwlItems/GetOntologyTreeAsNavItems`)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public getOntologyTreeAsNavItemsForPresentation(): Observable<Array<NavItem>> {
        return this.http.get(this.webApiUrl + `/api/OwlItems/GetOntologyTreeAsNavItemsForPresentation`)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // UPDATE METHODS //
    // -------------- //

    public updateOwlItem(_owlItem: OwlItem): Observable<Entity> {
        return this.http.put(this.webApiUrl + `/api/OwlItems/UpdateOwlItem`,
        _owlItem)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // DELETE METHODS //
    // -------------- //

    public deleteOwlItem(_owlItem: OwlItem): Observable<Entity> {
        // var json = JSON.parse('{"id":"' + id + '"}');
        return this.http.post(this.webApiUrl + `/api/OwlItems/DeleteOwlItem`,
        _owlItem)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }
}
