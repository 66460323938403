import { Entity } from '../entity';
import { EntityRow } from './entityRow';

export class EntityColumn {
    public name: string = '';
    public character: string = '';
    public order: number = -1;
    public row!: EntityRow;
    public letterDescription: string = '';
    public utfCode: number = 0;
    public inRow?: Array<EntityRow>;
    public over?: Array<EntityRow>;
    public above?: Array<EntityRow>;
    public below?: Array<EntityRow>;
    public strokeName?: string;
    public transcriptionName?: string;
    public manusName?: string;
    public annotatorName?: string;
    public manuscriptName?: string;
    public word?: number;

    constructor(init?: Partial<EntityColumn>) {
        Object.assign(this, init);
    }
}
