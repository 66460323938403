import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-common-top',
    templateUrl: './common-top.component.html',
    styleUrls: ['./common-top.component.scss']
})
export class CommonTopComponent implements OnInit {
    @Input() title?: string;
    @Input() entityId?: string | null;
    @Input() isSelectionMode = false;
    @Output() newClick = new EventEmitter();

    constructor(
        private _router: Router,) { }

    ngOnInit(): void {
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public newClicked() {
        this.newClick.emit(true);
    }

}
