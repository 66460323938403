<block-ui></block-ui>
<h2 mat-dialog-title>Participants of Project: {{entity.title}}</h2>

<div mat-dialog-content class="content">
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox
                        [(ngModel)]="checkboxes[element.username]"
                        (change)="onSelectedChange($event, element)"
                        (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef> Username </th>
                <td mat-cell *matCellDef="let element"> {{element.username}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef> First Name </th>
                <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef> Last Name </th>
                <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
</div>
