import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Manus } from 'src/app/models/jena/manus';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogManusManageComponent } from './dialog-manus-manage/dialog-manus-manage.component';
import { ActivatedRoute } from '@angular/router';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EntityService } from 'src/app/services/entity.service';
import { DefaultType } from 'src/app/models/enums/defaultType';
import { Default } from 'src/app/models/default';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-manus-list',
    templateUrl: './manus-list.component.html',
    styleUrls: ['./manus-list.component.scss']
})
export class ManusListComponent implements OnInit, OnChanges {
    @BlockUI() _blockUI!: NgBlockUI;
    @Input() public entityId!: string | null;
    @Input() public default?: string;
    @Input() public isSelectionMode = false;
    @Output() public onSelection = new EventEmitter<Manus>();
    @Output() public onDefaultSelection = new EventEmitter<boolean>();

    public checkboxes: any;

    displayedColumns: string[] = ['name', 'continuantPartOf', 'dependsOnTool', 'actions'];
    dataSource: MatTableDataSource<Manus>;
    public manuses: Array<Manus>;

    constructor(private _entityService: EntityService,
        private _fusekiService: FusekiService,
        private _snackBar: MatSnackBar,
        private _activatedRoute: ActivatedRoute,
        private _dialog: MatDialog) {
        this.manuses = new Array<Manus>();
        // Get the id from the url
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.checkboxes = {};
        this.dataSource = new MatTableDataSource();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getManuses(this.entityId!);
        }
        this.setColumns();
    }

    ngOnChanges(changes: {[property: string]: SimpleChange }) {
        let change = changes['default'];
        if (change.previousValue === null) {
            if (this.manuses.length > 0) {
                this.initCheckboxes(this.manuses);
            }
        }
    }

    private setColumns() {
        if (this.isSelectionMode) {
            this.displayedColumns = ['checkbox', 'name', 'continuantPartOf', 'dependsOnTool', 'actions'];
        } else {
            this.displayedColumns = ['name', 'continuantPartOf', 'dependsOnTool', 'actions'];
        }
    }

    public new() {
        const dialogRef = this._dialog.open(DialogManusManageComponent, {
            width: '640px',
            data: {
                manus: new Manus(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(
            (result) => {
                if (result) {
                    this._fusekiService.createManus(this.entityId!, result.actor, result.tool).subscribe({
                        next: () => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new manus', '', Helpers.getGreenSnackbarConfig());
                            this._getManuses(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                        }
                    });
                }
            }
        );
    }

    private _getManuses(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getManuses(datasetName).subscribe({
            next: (result: Array<Manus>) => {
                this.manuses = result;
                this.dataSource.data = this.manuses;
                this.initCheckboxes(this.manuses);

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    private initCheckboxes(data: Array<Manus>): void {
        if (data !== null) {
            data.map(
                (entry: Manus) => {
                    entry.name! === this.default ? this.checkboxes[entry.name!] = true : this.checkboxes[entry.name!] = false;
                }
            );

            for (const id of Object.keys(this.checkboxes)) {
                if (this.checkboxes[id] === true) {
                    this.onSelection.emit(this.manuses.find(i => i.name === id));
                }
            }
        }
    }

    public delete(manus: Manus) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: `Delete manus ${manus.name}`,
                infoMessage: 'Are you sure you want to delete this manus?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteManus(this.entityId!, manus).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted manus', '', Helpers.getGreenSnackbarConfig());
                            this._getManuses(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting manus', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public saveDefault() {
        this._blockUI.start();

        this._entityService.updateEntityByDefaultType(this.entityId!, DefaultType.Manus, encodeURIComponent(this.selectedManusName())).subscribe(
            (result) => {
                this._blockUI.stop();
                this._snackBar.open('Updated successfully!', '', Helpers.getGreenSnackbarConfig());
                this.default = result.default?.manus;
                this.onDefaultSelection.emit(true);
                //this._router.navigate(['list-entities']);
            },
            (error) => {
                this._blockUI.stop();
                console.log(error);
                this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
            }
        )
    }

    public removeDefault() {
        this._blockUI.start();

        this._entityService.updateEntityByDefaultType(this.entityId!, DefaultType.Manus, '').subscribe(
            (result) => {
                this._blockUI.stop();
                this._snackBar.open('Updated successfully!', '', Helpers.getGreenSnackbarConfig());
                this.default = result.default?.manus;
                this.onDefaultSelection.emit(false);
                //this._router.navigate(['list-entities']);
            },
            (error) => {
                this._blockUI.stop();
                console.log(error);
                this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
            }
        )
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }

    public onSelectedChange(event: MatCheckboxChange, manus: Manus): void {
        this.clearAllSelections();
        this.checkboxes[manus.name!] = event.checked;

        if (event.checked) {
            this.onSelection.emit(manus);
        } else {
            this.onSelection.emit(undefined);
        }
    }

    public existsSelectedManus(): boolean {
        for (const id of Object.keys(this.checkboxes)) {
            if (this.checkboxes[id] === true) {
                return true;
            }
        }

        return false;
    }

    public isAlreadySavedAsDefault(): boolean {
        for (const id of Object.keys(this.checkboxes)) {
            if (this.checkboxes[id] === true) {
                if (id === this.default) {
                    return true;
                }
            }
        }

        return false;
    }

    public selectedManusName(): string {
        for (const id of Object.keys(this.checkboxes)) {
            if (this.checkboxes[id] === true) {
                return id;
            }
        }

        return '';
    }

    private clearAllSelections() {
        for (const id of Object.keys(this.checkboxes)) {
            this.checkboxes[id] = false;
        }
    }

    public getContentClass() {
        return this.isSelectionMode ? 'content-selection-mode' : 'content'
    }

    public getTableContainerClass() {
        return this.isSelectionMode ? 'table-container-selection-mode' : 'table-container'
    }

}
