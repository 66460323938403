import { Institution } from "../models/jena/institution";
import { School } from "../models/jena/school";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class SchoolSparql {
    public static getSchools(): string {
        var sparql = PREFIXES +
        `
        SELECT
        ?school
        ?institution
        ?fullName
        ?shortName
        ?street
        ?streetNumber
        ?city
        ?state
        ?zipcode
        WHERE {
            ?school a ${SH.schoolClass}.
            OPTIONAL{?school ${SH.memberPartOfAtSomeTime} ?institution}.
            OPTIONAL{?school ${SH.fullNameProperty} ?fullName}.
            OPTIONAL{?school ${SH.shortNameProperty} ?shortName}.
            OPTIONAL{?school ${SH.streetProperty} ?street}.
            OPTIONAL{?school ${SH.streetNumberProperty} ?streetNumber}.
            OPTIONAL{?school ${SH.cityProperty} ?city}.
            OPTIONAL{?school ${SH.stateProperty} ?state}.
            OPTIONAL{?school ${SH.zipCodeProperty} ?zipcode}.
            FILTER (STRSTARTS(str(?school), str(ent:)))
        }
        ORDER BY ?school
        `;

        return sparql;
    }

    public static createSchool(school: School, institution: Institution): string {

        var sparql = PREFIXES +
        `
        INSERT {
            ?new a ${SH.schoolClass};
                ${SH.memberPartOfAtSomeTime} <${institution.name}>;
                ${school.fullName != null ? `${SH.fullNameProperty} "` + school.fullName + `";` : ''}
                ${school.shortName != null ? `${SH.shortNameProperty} "` + school.shortName + `";` : ''}
                ${school.street != null ? `${SH.streetProperty} "` + school.street + `";` : ''}
                ${school.streetNumber != null ? `${SH.streetNumberProperty} "` + school.streetNumber + `";` : ''}
                ${school.city != null ? `${SH.cityProperty} "` + school.city + `";` : ''}
                ${school.state != null ? `${SH.stateProperty} "` + school.state + `";` : ''}
                ${school.zipcode != null ? `${SH.zipCodeProperty} "` + school.zipcode + `";` : ''}
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'sch',STR(MAX(?res) + 1))) as ?new)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{
                            ?z a ${SH.schoolClass}.
                            FILTER (STRSTARTS(str(?z), str(ent:)))
                        }.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + 'sch', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.schoolClass}.
                                FILTER (STRSTARTS(str(?s), str(ent:)))
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static updateSchool(school: School, institution: Institution): string {
        var sparql = PREFIXES +
        `
        DELETE {?ent ?x ?y}
        WHERE {
            ?ent ?x ?y.
            FILTER(?ent = IRI("${school.name}"))
            FILTER(?y != ${SH.schoolClass})
        };

        INSERT {
            ?x ${SH.memberPartOfAtSomeTime} <${institution.name}>.
            ${school.fullName != null ? `?x ${SH.fullNameProperty} "` + school.fullName + `".` : ''}
            ${school.shortName != null ? `?x ${SH.shortNameProperty} "` + school.shortName + `".` : ''}
            ${school.street != null ? `?x ${SH.streetProperty} "` + school.street + `".` : ''}
            ${school.streetNumber != null ? `?x ${SH.streetNumberProperty} "` + school.streetNumber + `".` : ''}
            ${school.city != null ? `?x ${SH.cityProperty} "` + school.city + `".` : ''}
            ${school.state != null ? `?x ${SH.stateProperty} "` + school.state + `".` : ''}
            ${school.zipcode != null ? `?x ${SH.zipCodeProperty} "` + school.zipcode + `".` : ''}
        }
        WHERE {
            ?x a ${SH.schoolClass}.
            FILTER(?x = IRI("${school.name}"))
        };
        `;

        return sparql;
    }

    public static deleteSchool(school: School): string {
        var sparql = PREFIXES +
        `
        DELETE { ?x ?y ?z }
        WHERE {
        ?x a ${SH.schoolClass}.
        ?x ?y ?z.
        FILTER(?x = IRI("${school.name}"))
        }
        `;

        return sparql;
    }

}
