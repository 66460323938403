import { sha256 } from "js-sha256";
import { Word } from "./word";

export class Sentence{
    words: Array<Word>;
    sentenceHash: string;

    constructor(words: Array<Word>){
        this.words = words;
        let hashData = '';
        words.forEach(x=> {
            hashData += x.wordHash
        });
        this.sentenceHash = sha256(hashData);
    }
}
