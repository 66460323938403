import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'app-dialog-auto-punctuation',
    templateUrl: './dialog-auto-punctuation.component.html',
    styleUrls: ['./dialog-auto-punctuation.component.scss']
})
export class DialogAutoPunctuationComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;

    content: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogAutoPunctuationComponent>) { }

    ngOnInit(): void {
        this.content = this.data.content;
    }

    public okClicked() {
        this.dialogRef.close(null);
    }
}
