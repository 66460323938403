import { Helpers } from "src/app/utilities/helpers";
import { Individual } from "./individual";

export class Person extends Individual {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    genericName?: string;
    nickName?: string;
    dayOfBirth?: number;
    monthOfBirth?: number;
    yearOfBirth?: string;
    dayOfDeath?: number;
    monthOfDeath?: number;
    yearOfDeath?: string;
    approximateDateOfBirthFrom?: string;
    approximateDateOfBirthTo?: string;
    approximateDateOfBirthBy?: string;
    approximateDateOfDeathFrom?: string;
    approximateDateOfDeathTo?: string;
    approximateDateOfDeathBy?: string;
    flourishedFrom?: string;
    flourishedTo?: string;
    flourishedBy?: string;

    public getDisplayName(): string {
        var result = '';

        if (Helpers.isNotNullOrUndefined(this.firstName) && !Helpers.isNullOrEmpty(this.firstName!)) {
            result += this.firstName + ' ';
        }

        if (Helpers.isNotNullOrUndefined(this.middleName) && !Helpers.isNullOrEmpty(this.middleName!)) {
            result += this.middleName + ' ';
        }

        if (Helpers.isNotNullOrUndefined(this.lastName) && !Helpers.isNullOrEmpty(this.lastName!)) {
            result += this.lastName + ' ';
        }

        return result;
    }
}
