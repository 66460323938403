<div class="container">
    <div>
        <h1>Dela Web Application</h1>
    </div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Όνομα χρήστη</mat-label>
            <input matInput [(ngModel)]="username">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Κωδικός</mat-label>
            <input matInput type="password" [(ngModel)]="password" (keyup.enter)="login()">
        </mat-form-field>
    </div>
    <div>
        <button mat-flat-button color="primary" type="submit" (click)="login()">Είσοδος</button>
    </div>
</div>

