<block-ui></block-ui>
<h2 mat-dialog-title>{{ isLocked ? 'View Device' : 'New Device' }} </h2>

<div mat-dialog-content class="content">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Select device type:</mat-label>
        <mat-select [(ngModel)]="selectedDeviceType">
            <mat-option *ngFor="let deviceType of deviceTypes" [value]="deviceType">{{ deviceType.label }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Generic name</mat-label>
        <input matInput [(ngModel)]="device.genericName!" [disabled]="isLocked">
    </mat-form-field>
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Siglum</mat-label>
        <input matInput [(ngModel)]="device.siglum!" [disabled]="isLocked">
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()">Cancel</button>
</div>
