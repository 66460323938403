import {Sentence} from "../models/sentence";
import {Word} from "../models/word";
import {Helpers} from "../utilities/helpers";
import {SparqlHelper} from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class AnnotationSparql {
    public static getAnnotatedContext(): string {
        return PREFIXES +
            `
        SELECT ?entityTypeLabel ?entity ?parentTypeLabel ?parent ?orderInParent ?letterUnicode WHERE {
          # CHARACTERS
          {
            ?entity a owl:MSC_0002547 ;
                obo:BFO_0000177 ?parent .
            ?parent a ?parentType .
            ?parentType rdfs:label ?parentTypeLabel.
            ?entity obo:BFO_0000058 ?letter .
            ?letter del:MSC_0000295 ?letterUnicode .
            FILTER(?parentType != owl:NamedIndividual)
          }
          UNION
          # WORDS
          {
            ?entity a owl:MSC_0001677 .
          }
          UNION
          # PHRASES
          {
            ?entity a owl:MSC_0001679 .
          }
          UNION
          # CLAUSES
          {
            ?entity a owl:MSC_0001678 .
          }
          UNION
          # COLONS
          {
            ?entity a del:MSC_0003481 .
          }
          UNION
          # SENTENCES
          {
            ?entity a owl:MSC_0001958 .
          }
          UNION
          # PARAGRAPHS
          {
            ?entity a owl:MSC_0003927 .
          }

          ?entity a ?entityType .
          ?entityType rdfs:label ?entityTypeLabel .
          FILTER(?entityType != owl:NamedIndividual)

          OPTIONAL
          {
            ?parent obo:BFO_0000110 ?entity ;
                a ?parentType .
            ?parentType rdfs:label ?parentTypeLabel .
            FILTER(?parentType != owl:NamedIndividual)
          }

          ?entity owl:MSC_0002563 ?orderInParent .
        }
        `;
    }

    public static deleteAllWords(): string {
        return PREFIXES +
            `
        DELETE WHERE {
          ?character ${SH.continuantPartOfAtAllTimes} ?word .
          ?word a ${SH.wordClass} .
        }
        `;
    }

    public static groupCharactersToWord(word: Word, index: number): string {
        let sparql = PREFIXES +
            `
        INSERT DATA {
            del:w${index} rdf:type ${SH.wordClass} ;
                          rdfs:label "w${index}" ;
                          ${SH.orderProperty} ${index} .
        `;

        let characterIndex = 1;
        word.characters.forEach((char) => {
            sparql += `
                ${'<' + char.characterName + '>'} ${SH.continuantPartOfAtAllTimes} del:w${index} .

            `;
            characterIndex++;
        });

        sparql += `
        }`;

        return sparql;
    }

    public static groupWordsToSentence(sentence: Sentence, index: number): string {
        let sparql = PREFIXES +
            `
        INSERT DATA {
            del:s${index} rdf:type ${SH.sentenceClass} ;
                          rdfs:label "s${index}" ;
                          ${SH.orderProperty} ${index} .
        `;

        let wordIndex = 1;
        sentence.words.forEach(word => {
            sparql += `
                del:s${index} ${SH.hasContinuantPartAtAllTimes} del:w${word.index} .
            `;
            wordIndex++;
        });

        sparql += `
        }`;

        return sparql;
    }

    public static getSentences(): string {
        let sparql = PREFIXES +
            `
            SELECT ?sentence ?wordIndex
            WHERE
            {
                ?sentence a owl:MSC_0001958 ;
                          obo:BFO_0000110 ?word .
                ?word owl:MSC_0002563 ?wordIndex .
            }
            `;
        return sparql;
    }
}
