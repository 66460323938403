<block-ui></block-ui>
<h2 mat-dialog-title>{{ isLocked ? 'View Person' : (isEdit ? 'Update Person' : 'New Person') }} </h2>

<div mat-dialog-content class="content">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Name">
            <div class="tab-container">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>First name</mat-label>
                    <input matInput [(ngModel)]="person.firstName!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Middle name</mat-label>
                    <input matInput [(ngModel)]="person.middleName!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Last name</mat-label>
                    <input matInput [(ngModel)]="person.lastName!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Generic name</mat-label>
                    <input matInput [(ngModel)]="person.genericName!" [disabled]="isLocked">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Nickname</mat-label>
                    <input matInput [(ngModel)]="person.nickName!" [disabled]="isLocked">
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Date">
            <div class="tab-container">
                <div class="tab-title">
                    <h2>Birth</h2>
                </div>
                <div class="date-container">
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Day of birth</mat-label>
                        <input matInput [(ngModel)]="person.dayOfBirth!" [disabled]="isLocked" type="number" pattern="^(3[01]|[12][0-9]|[1-9])$">
                    </mat-form-field>
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Month of birth</mat-label>
                        <input matInput [(ngModel)]="person.monthOfBirth!" [disabled]="isLocked" type="number" pattern="^(1[0-2]|[1-9])$">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Year of birth</mat-label>
                        <input matInput [(ngModel)]="person.yearOfBirth!" [disabled]="isLocked" pattern="^\d{1,4}\s(AD|BC)$" placeholder="Use format YYYY AD or YYYY BC">
                    </mat-form-field>
                </div>
                <div class="tab-title">
                    <h2>Death</h2>
                </div>
                <div class="date-container">
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Day of death</mat-label>
                        <input matInput [(ngModel)]="person.dayOfDeath!" [disabled]="isLocked" type="number" pattern="^(3[01]|[12][0-9]|[1-9])$">
                    </mat-form-field>
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Month of death</mat-label>
                        <input matInput [(ngModel)]="person.monthOfDeath!" [disabled]="isLocked" type="number" pattern="^(1[0-2]|[1-9])$">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Year of death</mat-label>
                        <input matInput [(ngModel)]="person.yearOfDeath!" [disabled]="isLocked" pattern="^\d{1,4}\s(AD|BC)$" placeholder="Use format YYYY AD or YYYY BC">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Approximately">
            <div class="tab-container">
                <div class="tab-title">
                    <h2>Birth</h2>
                </div>
                <div class="date-container">
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Date of birth from</mat-label>
                        <input matInput [(ngModel)]="person.approximateDateOfBirthFrom!" [disabled]="isLocked">
                    </mat-form-field>
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Date of birth to</mat-label>
                        <input matInput [(ngModel)]="person.approximateDateOfBirthTo!" [disabled]="isLocked">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Date of birth by</mat-label>
                        <input matInput [(ngModel)]="person.approximateDateOfBirthBy!" [disabled]="isLocked">
                    </mat-form-field>
                </div>
                <div class="tab-title">
                    <h2>Death</h2>
                </div>
                <div class="date-container">
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Date of death from</mat-label>
                        <input matInput [(ngModel)]="person.approximateDateOfDeathFrom!" [disabled]="isLocked">
                    </mat-form-field>
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Date of death to</mat-label>
                        <input matInput [(ngModel)]="person.approximateDateOfDeathTo!" [disabled]="isLocked">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Date of death by</mat-label>
                        <input matInput [(ngModel)]="person.approximateDateOfDeathBy!" [disabled]="isLocked">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Flourished">
            <div class="tab-container">
                <div class="date-container">
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Flourished from</mat-label>
                        <input matInput [(ngModel)]="person.flourishedFrom!" [disabled]="isLocked">
                    </mat-form-field>
                    <mat-form-field class="full-width date-main" appearance="fill">
                        <mat-label>Flourished to</mat-label>
                        <input matInput [(ngModel)]="person.flourishedTo!" [disabled]="isLocked">
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Flourished by</mat-label>
                        <input matInput [(ngModel)]="person.flourishedBy!" [disabled]="isLocked">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()">Cancel</button>
</div>
