import { Action } from "@ngrx/store";
import { OverallInfo } from "src/app/models/overallInfo";

export enum EUIActions {
    SetOverallInfo = '[UI] SET OVERALL INFO'
}

export class SetOverallInfo implements Action {
    public readonly type = EUIActions.SetOverallInfo;
    constructor(public payload: OverallInfo) { }
}

export type UIActions =
    | SetOverallInfo
