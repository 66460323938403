<div class="intro-dialog-container">
    <div class="intro-dialog-content-group intro-dialog-content-group-1">
        <div>
            <h3 class="intro-dialog-content-title">Developed by</h3>
            <a href="https://dhl.phil.uoa.gr/" class="intro-dialog-content-link">NKUA DH Lab</a>
            |
            <a href="http://omegatech.gr/" class="intro-dialog-content-link">Omega Technology</a>
        </div>
    </div>
    <div class="intro-dialog-content-group intro-dialog-content-group-2">
        <div>
            <h3 class="intro-dialog-content-title">Conducted by</h3>
            <a href="http://www.phil.uoa.gr/tomeis/tomeas-klasikis-filologias/melh-dep-klas-biografika/dionyssis_benetos_gr.html" class="intro-dialog-content-link">Dionysios Benetos</a>
            |
            <a href="http://www.phil.uoa.gr/tomeis/tomeas-glwssologias/melh-dep/markopoylos-giorgos.html" class="intro-dialog-content-link">Georgios Markopoulos</a>
        </div>
    </div>
</div>
