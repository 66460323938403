import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of } from 'rxjs';
import { User } from '../models/user';
import { Helpers } from '../utilities/helpers';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private keycloakService: KeycloakService) { }

    public getUser(): Observable<User> {
        if (Helpers.isNotNullOrUndefined(localStorage.getItem('keycloakUser'))) {
            return of(JSON.parse(localStorage.getItem('keycloakUser')!));
        } else {
            return from(this.keycloakService.loadUserProfile().then(user => {
                localStorage.setItem('keycloakUser', JSON.stringify(Helpers.getUserFromKeycloakProfile(user, this.keycloakService.getUserRoles())));
                return (Helpers.getUserFromKeycloakProfile(user, this.keycloakService.getUserRoles()));
            }));
        }
    }
}

