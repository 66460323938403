<div class="content">
    <mat-card>
        <mat-card-title style="padding-top: 10px;">Available manuscripts</mat-card-title>
        <mat-card-title style="margin-top:15px; height: 40px;">
            <button mat-flat-button color="primary" class="card-title-button" *ngIf="selectedEntities.length > 0 && mode != 'collate'"
                (click)="onDuplicateClick(null)">Duplicate</button>
            <button mat-flat-button color="primary" class="card-title-button" *ngIf="selectedEntities.length > 1 && mode == 'collate'"
                (click)="onCollateClick()">Collate</button>
            <button mat-flat-button color="primary" class="card-title-button" *ngIf="selectedEntities.length > 0 && mode != 'collate'"
                (click)="deleteFiles()">Delete</button>
        </mat-card-title>
        <mat-card-content>
            <div class="table-container" *blockUI="'entitiesContainer'">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="createdDate"
                    matSortDirection="desc">
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox (change)="onCheckboxClick($event, element)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="id" style="width: 0px !important">
                        <th mat-header-cell *matHeaderCellDef style="width: 0px !important"></th>
                        <td mat-cell *matCellDef="let element" style="width: 0px !important"></td>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="title">File Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                    </ng-container>

                    <ng-container matColumnDef="createdDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">Created</th>
                        <td mat-cell *matCellDef="let element">{{element.createdDate | date: 'dd/MM/yyyy'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">User</th>
                        <td mat-cell *matCellDef="let element">{{element.createdBy}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numberOfTags">
                        <th mat-header-cell *matHeaderCellDef># of Tags</th>
                        <td mat-cell *matCellDef="let element">{{getNumberOfTags(element)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="numberOfParticipants">
                        <th mat-header-cell *matHeaderCellDef># of Users</th>
                        <td mat-cell *matCellDef="let element">{{getNumberOfParticipants(element)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="edit(element.id)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button><button mat-menu-item (click)="onDuplicateClick(element)">
                                    <mat-icon>file_copy</mat-icon>
                                    <span>Duplicate</span>
                                </button>
                                <button mat-menu-item (click)="delete(element)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                                <button mat-menu-item (click)="assignParticipants(element)"
                                    *ngIf="canViewElement('assign-participants')">
                                    <mat-icon>accessibility</mat-icon>
                                    <span>Users</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <mat-paginator #paginator [length]=length [pageSize]=pageSize [pageSizeOptions]="[5, 10, 30, 100, 1000]"
                [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>
