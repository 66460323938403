import {EventEmitter, Injectable} from "@angular/core";
import {Entity} from "../models/entity";
import {forkJoin, Observable} from "rxjs";
import {FusekiService} from "./fuseki.service";
import {EntityRoot} from "../models/jena/entityRoot";
import {WindowArea} from "../models/enums/windowArea";
import {DisplayEntity} from "../models/display/displayEntity";
import {AnnotationService} from "./annotation.service";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Sentence} from "../models/sentence";

type StringOccurrence = {
    text: string;
    count: number;
    witnesses: number[];
};

type PositionStrings = {
    position: number;
    strings: StringOccurrence[];
};

@Injectable({
    providedIn: 'root'
})
export class CollationService {
    entitiesToCollate: Array<Entity>;
    public entitiesChanged: EventEmitter<void> = new EventEmitter();
    public collationFinished: EventEmitter<any> = new EventEmitter();
    private _displayEntities: DisplayEntity[] = [];
    private _annotationServices: AnnotationService[] = [];
    public mostCommonText: String = '';
    public criticalApparatus: String = '';

    constructor(
        private _fusekiService: FusekiService,
        private httpClient: HttpClient) {
        this.entitiesToCollate = new Array<Entity>();
    }

    setEntities(entities: Array<Entity>) {
        if (entities.length < 2) {
            throw new Error('You need to select at least two entities to collate');
        }

        this.entitiesToCollate.push(...entities);

        this._displayEntities = [];
        this._annotationServices = [];

        entities.forEach(entity => {
            this._annotationServices.push(new AnnotationService(this._fusekiService));
        });

        this.loadManuscripts();
        this.entitiesChanged.emit();
    }

    deleteEntities(): void {
        this.entitiesToCollate = [];
    }

    static getFullText(annotationService: AnnotationService, punctuate: boolean = false): string {
        let text = '';
        let curSentenceHash: string = '';

        annotationService.getCurrentWords().forEach(word => {
            if (punctuate && word.sentence != undefined && word.sentence.sentenceHash !== curSentenceHash) {
                curSentenceHash = word.sentence.sentenceHash;
                if (text.charAt(text.length - 1) === ' ') {
                    text = text.slice(0, -1);
                }
                text += '. ';
            }

            text += word.toString() + ' ';
        });

        if (text.charAt(text.length - 1) === ' ') {
            text = text.slice(0, -1);
        }

        if (text.startsWith('. ')) {
            text = text.slice(2);
        }

        if (text.charAt(text.length - 1) !== '.') {
            text += '.';
        }

        return text;
    }

    loadManuscripts() {
        console.log("Loading manuscripts for collation");

        let entityRoots$: Observable<EntityRoot>[] = [];

        this.entitiesToCollate.forEach((entity, index) => {
            this._displayEntities.push(new DisplayEntity());
            entityRoots$.push(this._fusekiService.getEntityContent(entity.id!, WindowArea.Main));
        });

        forkJoin(entityRoots$).subscribe((results: EntityRoot[]) => {
            results.forEach((result, index) => {
                this._displayEntities[index].refresh(result);
                this.getWords(this._displayEntities[index], this._annotationServices[index]);
            });

            this.httpClient.post('https://collatex.net/demo/collate',
                JSON.stringify(this.constructCollatexRequest()),
                { headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Accept', 'application/json') }).subscribe((response: any) => {
                this.constructCollationOutput(response);
            });
        });
    }

    constructCollationOutput(collatexOutput: any) {
        const numOfWitnesses = collatexOutput.witnesses.length;
        console.log(`Number of witnesses: ${numOfWitnesses}`);

        const positionStrings = this.transformData(collatexOutput.table);
        this.mostCommonText = this.createSentenceFromMostCommonTexts(positionStrings);
        console.log(this.mostCommonText);

        let criticalApparatus: String = '';

        for (let i = 0; i < positionStrings.length; i++) {
            if (positionStrings[i].strings[0].count === numOfWitnesses) {
                continue;
            }

            for (let k = 0; k < positionStrings[i].strings.length; k++) {
                const string = positionStrings[i].strings[k].text;

                if (k === 0) {
                    criticalApparatus += string.trim() + "] ";
                } else {
                    for (let l = 0; l < positionStrings[i].strings[k].witnesses.length; l++) {
                        if (string === '') {
                            criticalApparatus += '<i>om.</i> <b>W' + positionStrings[i].strings[k].witnesses[l] + '</b>; ';
                        } else {
                            criticalApparatus += string.trim() + ' <b>W' + positionStrings[i].strings[k].witnesses[l] + '</b>; ';
                        }
                    }
                }
            }

            if (criticalApparatus.slice(-2) === '; ') {
                criticalApparatus = criticalApparatus.slice(0, -2);
            }

            criticalApparatus += ' | ';
        }

        if (criticalApparatus.slice(-3) === ' | ') {
            criticalApparatus = criticalApparatus.slice(0, -3);
        }

        this.criticalApparatus = criticalApparatus;
        this.collationFinished.emit({
            mostCommonText: this.mostCommonText,
            criticalApparatus: this.criticalApparatus
        });
        return criticalApparatus;
    }

    constructCollatexRequest() {
        const witnessArray: object[] = [];

        for (let i = 0; i < this._annotationServices.length; i++) {
            const witnessName = 'W' + (i + 1);
            const witnessContent = {
                "id": witnessName,
                "content": CollationService.getFullText(this._annotationServices[i])
            }

            witnessArray.push(witnessContent);
        }

        return {
            "witnesses": witnessArray
        };
    }

    getWords(displayEntity: DisplayEntity, annotationService: AnnotationService): void {
        let wordIndex = 0;
        displayEntity.pages.forEach(page => {
            page.rows.forEach(row => {
                row.characters.forEach(character => {
                    if (character.wordIndex) {
                        if (wordIndex != character.wordIndex) {
                            annotationService.makeWordFromCharacters();
                        }

                        annotationService.addCharacter(character);
                        wordIndex = character.wordIndex;
                    }
                });
            });
        });

        if (wordIndex != 0) {
            annotationService.makeWordFromCharacters();
        }
    }

    transformData(input: string[][][]): PositionStrings[] {
        return input.map((table, tableIndex) => {
            const occurrences = new Map<string, StringOccurrence>();

            table.forEach((row, rowIndex) => {
                const combinedString = row.join('');

                if (!occurrences.has(combinedString)) {
                    occurrences.set(combinedString, { text: combinedString, count: 0, witnesses: [] });
                }

                const occurrence = occurrences.get(combinedString);
                occurrence!.count += 1;
                occurrence!.witnesses.push(rowIndex + 1);
            });

            const sortedOccurrences = Array.from(occurrences.values()).sort((a, b) => {
                if (b.count === a.count) {
                    // If counts are equal and one is empty and the other is not, prefer the non-empty one
                    if (a.text && !b.text) return -1;
                    if (!a.text && b.text) return 1;
                    return a.text.localeCompare(b.text); // If both are non-empty or both are empty, sort alphabetically
                }
                return b.count - a.count;
            });

            return {
                position: tableIndex + 1,
                strings: sortedOccurrences
            };
        });
    }

    createSentenceFromMostCommonTexts(positions: PositionStrings[]): string {
        return positions.map(position => {
            // Since the strings are already sorted by count (descending) and then text, the first element is the most common
            const mostCommonString = position.strings[0]?.text || '';
            return mostCommonString.trim(); // Trim to remove any leading/trailing whitespace
        }).join(' ').trim(); // Join all texts with a space and trim the final sentence
    }
}
