import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Entity } from 'src/app/models/entity';
import { EntityService } from 'src/app/services/entity.service';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-dialog-initial',
    templateUrl: './dialog-initial.component.html',
    styleUrls: ['./dialog-initial.component.scss']
})
export class DialogInitialComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;
    public selectedSide?: string;
    public projectName?: string;
    public entity: Entity = new Entity();

    constructor(private _entityService: EntityService,
        private _fusekiService: FusekiService,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<DialogInitialComponent>,
        private _router: Router) { }

    ngOnInit(): void {
    }

    private bindFormToModel(): Entity {
        const obj = Object.assign({}, this.entity);

        obj.title = this.projectName;
        obj.side = this.selectedSide;

        const codeText = <HTMLDivElement>document.getElementById('codeText');
        if (Helpers.isNotNullOrUndefined(codeText)) {
            obj.rawText = codeText.textContent!;
        }

        return obj;
    }

    public save() {
        this.blockUI.start('Creating new entity...');

        this.entity = this.bindFormToModel();

        this._entityService
            .createEntity(this.entity)
            .subscribe(
                (result) => {
                    this.blockUI.update(`Created entity with id: ${result.id}. Getting local owl file...`);

                    this._fusekiService.getLocalFile('Manuscribus.owl').subscribe(fileResult => {
                        this.blockUI.update(`Creating the dataset in Fuseki...`);

                        this._fusekiService.createDataset(result.id!, 'tdb2').subscribe(creationResult => {
                            this.blockUI.update(`Uploading the dataset file in Fuseki...`);

                            this._fusekiService.uploadDatasetFile(result.id!, fileResult).subscribe(uploadResult => {
                                this.blockUI.update(`Initialize manuscript...`);

                                this._fusekiService.initializeManuscript(result.id!, this.selectedSide!).subscribe(initializationResult => {
                                    this.blockUI.stop();

                                    this._snackBar.open('Saved successfully!', '', Helpers.getGreenSnackbarConfig());

                                    this._router.navigate(['edit-entity/' + result.id]);
                                });
                            });
                        });
                    });
                },
                (error) => {
                    this.blockUI.stop();
                    console.log(error);
                    this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
                })

        this.dialogRef.close(this.selectedSide);
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }
}
