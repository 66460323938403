<block-ui></block-ui>
<div [ngClass]="getContentClass()">
    <mat-card>
        <mat-card-title>
            <app-common-top [title]="'Actors List'" [entityId]="entityId" (newClick)="new()" [isSelectionMode]="isSelectionMode"></app-common-top>
        </mat-card-title>
        <mat-card-content>
            <div [ngClass]="getTableContainerClass()">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox
                                [ngModel]="checkboxes[element.name]"
                                (change)="onSelectedChange($event, element)"
                                (click)="$event.stopPropagation()">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{getNameWithoutUri(element.name)}} <mat-icon matTooltip="{{ element.name }}">info</mat-icon> </td>
                    </ng-container>

                    <ng-container matColumnDef="person">
                        <th mat-header-cell *matHeaderCellDef> Person </th>
                        <td mat-cell *matCellDef="let element"> {{getNameWithoutUri(element.person.name)}} <mat-icon matTooltip="{{ element.person.name }}">info</mat-icon> </td>
                    </ng-container>

                    <ng-container matColumnDef="personRole">
                        <th mat-header-cell *matHeaderCellDef> Person Role </th>
                        <td mat-cell *matCellDef="let element"> {{element.personRole.label}} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="delete(element)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                            No data found...
                        </td>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div *ngIf="isSelectionMode">
                <button mat-stroked-button color="primary" style="margin-top:10px;" *ngIf="existsSelectedManus() && !isAlreadySavedAsDefault()" (click)="saveDefault()">Save as default</button>
                <button mat-stroked-button color="primary" style="margin-top:10px;" *ngIf="isAlreadySavedAsDefault()" (click)="removeDefault()">Remove default</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

