import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Actor } from 'src/app/models/jena/actor';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogActorManageComponent } from './dialog-actor-manage/dialog-actor-manage.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EntityService } from 'src/app/services/entity.service';
import { DefaultType } from 'src/app/models/enums/defaultType';
import { Default } from 'src/app/models/default';

@Component({
    selector: 'app-actor-list',
    templateUrl: './actor-list.component.html',
    styleUrls: ['./actor-list.component.scss']
})
export class ActorListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    @Input() public entityId!: string | null;
    @Input() public default?: string;
    @Input() public isSelectionMode = false;
    @Output() public onSelection = new EventEmitter<Actor>();
    @Output() public onDefaultSelection = new EventEmitter<boolean>();

    public checkboxes: any;

    displayedColumns: string[] = ['name', 'person', 'personRole', 'actions'];
    dataSource: MatTableDataSource<Actor>;
    public actors: Array<Actor>;

    constructor(private _entityService: EntityService,
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar) {
        // Get the id from the url
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.checkboxes = {};
        this.dataSource = new MatTableDataSource();
        this.actors = new Array<Actor>();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getActors(this.entityId!);
        }

        this.setColumns();
    }

    ngOnChanges(changes: {[property: string]: SimpleChange }) {
        let change = changes['default'];
        if (change.previousValue === null) {
            if (this.actors.length > 0) {
                this.initCheckboxes(this.actors);
            }
        }
    }

    private setColumns() {
        if (this.isSelectionMode) {
            this.displayedColumns = ['checkbox', 'name', 'person', 'personRole', 'actions'];
        } else {
            this.displayedColumns = ['name', 'person', 'personRole', 'actions'];
        }
    }

    private _getActors(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getActors(datasetName).subscribe({
            next: (result: Array<Actor>) => {
                this.actors = result;
                this.dataSource.data = this.actors;
                this.initCheckboxes(this.actors);

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    private initCheckboxes(data: Array<Actor>): void {
        if (data !== null) {
            data.map(
                (entry: Actor) => {
                    entry.name! === this.default ? this.checkboxes[entry.name!] = true : this.checkboxes[entry.name!] = false;
                }
            );

            for (const id of Object.keys(this.checkboxes)) {
                if (this.checkboxes[id] === true) {
                    this.onSelection.emit(this.actors.find(i => i.name === id));
                }
            }
        }
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public new() {
        const dialogRef = this._dialog.open(DialogActorManageComponent, {
            width: '640px',
            data: {
                actor: new Actor(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(
            (result) => {
                if (result) {
                    this._blockUI.start();

                this._fusekiService.createActor(this.entityId!, result.person, result.personRole).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new actor', '', Helpers.getGreenSnackbarConfig());
                            this._getActors(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while creating new actor', '', Helpers.getRedSnackbarConfig());
                        }
                    });
                }
            }
        );
    }

    public delete(actor: Actor) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: `Delete actor ${actor.name}`,
                infoMessage: 'Are you sure you want to delete this actor?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteActor(this.entityId!, actor).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted actor', '', Helpers.getGreenSnackbarConfig());
                            this._getActors(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting actor', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public saveDefault() {
        this._blockUI.start();

        this._entityService.updateEntityByDefaultType(this.entityId!, DefaultType.Actor, encodeURIComponent(this.selectedManusName())).subscribe(
            (result) => {
                this._blockUI.stop();
                this._snackBar.open('Updated successfully!', '', Helpers.getGreenSnackbarConfig());
                this.default = result.default?.actor;
                this.onDefaultSelection.emit(true);
                //this._router.navigate(['list-entities']);
            },
            (error) => {
                this._blockUI.stop();
                console.log(error);
                this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
            }
        )
    }

    public removeDefault() {
        this._blockUI.start();

        this._entityService.updateEntityByDefaultType(this.entityId!, DefaultType.Actor, '').subscribe(
            (result) => {
                this._blockUI.stop();
                this._snackBar.open('Updated successfully!', '', Helpers.getGreenSnackbarConfig());
                this.default = result.default?.actor;
                this.onDefaultSelection.emit(false);
                //this._router.navigate(['list-entities']);
            },
            (error) => {
                this._blockUI.stop();
                console.log(error);
                this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
            }
        )
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }

    public onSelectedChange(event: MatCheckboxChange, actor: Actor): void {
        this.clearAllSelections();
        this.checkboxes[actor.name!] = event.checked;

        if (event.checked) {
            this.onSelection.emit(actor);
        } else {
            this.onSelection.emit(undefined);
        }
    }

    public existsSelectedManus(): boolean {
        for (const id of Object.keys(this.checkboxes)) {
            if (this.checkboxes[id] === true) {
                return true;
            }
        }

        return false;
    }

    public isAlreadySavedAsDefault(): boolean {
        for (const id of Object.keys(this.checkboxes)) {
            if (this.checkboxes[id] === true) {
                if (id === this.default) {
                    return true;
                }
            }
        }

        return false;
    }

    public selectedManusName(): string {
        for (const id of Object.keys(this.checkboxes)) {
            if (this.checkboxes[id] === true) {
                return id;
            }
        }

        return '';
    }

    private clearAllSelections() {
        for (const id of Object.keys(this.checkboxes)) {
            this.checkboxes[id] = false;
        }
    }

    public getContentClass() {
        return this.isSelectionMode ? 'content-selection-mode' : 'content'
    }

    public getTableContainerClass() {
        return this.isSelectionMode ? 'table-container-selection-mode' : 'table-container'
    }
}
