import { Component, HostListener, OnInit } from '@angular/core';
import { ContextMenu } from 'src/app/models/contextMenu';
import { Helpers } from 'src/app/utilities/helpers';
import { Entity } from 'src/app/models/entity';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { EntityService } from 'src/app/services/entity.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlService } from 'src/app/services/owl.service';
import { FusekiService } from 'src/app/services/fuseki.service';
import { DialogInitialComponent } from '../dialog-initial/dialog-initial.component';
import { CollationService } from 'src/app/services/collation.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AnnotationService } from 'src/app/services/annotation.service';
import { DialogIntroComponent } from '../../dialog-intro/dialog-intro.component';

@Component({
    selector: 'app-form-entity',
    templateUrl: './form-entity.component.html',
    styleUrls: ['./form-entity.component.scss']
})
export class FormEntityComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;
    public entityId!: string | null;
    public entityFormGroup!: FormGroup;
    public isDisplayContextMenu!: boolean;
    public rightClickMenuItems: Array<ContextMenu> = [];
    public rightClickMenuPositionX!: number;
    public rightClickMenuPositionY!: number;
    public isCodeTextEditable = true;
    public codeTextStart?: number;
    public codeTextEnd?: number;
    public codeTextValue?: string;
    public entity: Entity;
    public rawTextHtml: any;
    public fixed: boolean = false;

    private selectedSide = '1';

    constructor(private _entityService: EntityService,
        private _owlService: OwlService,
        private _fusekiService: FusekiService,
        private formBuilder: FormBuilder,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _annotationService: AnnotationService,
        private _dialog: MatDialog) {
        this.entity = new Entity();
        // Get the id from the url
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
    }

    ngOnInit(): void {
        var entities_se: string[] = [];
        entities_se.push(this.entityId!);

        let urlLastPart = window.location.href.split('/').pop()!;

        /*if (Helpers.isNullOrEmpty(urlLastPart)) {
            var homeDialog = this._dialog.open(DialogIntroComponent, {
                disableClose: true,
            });
            setTimeout(() => {
                homeDialog.close();
            }, 5000);
        }*/

        this.createForm();

        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this.blockUI.start();

            this._entityService.getEntityById(this.entityId!).subscribe(
                result => {
                    this.entity = result;
                    this.bindModelToForm();

                    this.blockUI.stop();
                },
                error => {
                    if (error.error == 'You do not have access to this entity!') {
                        this._snackBar.open('You do not have access to this entity!', '', Helpers.getRedSnackbarConfig());
                        this._router.navigate(['list-entities']);
                    }
                    this.blockUI.stop();
                }
            );
        } else {
            if (!Helpers.isNullOrEmpty(urlLastPart)) {
                const dialogRef = this._dialog.open(DialogInitialComponent, {
                    disableClose: true,
                    width: '420px'
                });

                dialogRef.afterClosed().subscribe(
                    (result) => {
                        this.selectedSide = result;
                    });
            }
        }
    }

    public goToListEntities(e: MatTabChangeEvent) {
        if (e.index == 2)
            this._router.navigate(['list-entities']);
    }

    public resetCustomAttribute(node: Node) {
        if (node instanceof HTMLElement) {
            (<HTMLElement>node).removeAttribute('dela-status');
        }

        for (var i = 0; i < node.childNodes.length; i++) {
            this.resetCustomAttribute(node.childNodes[i]);
        }
    }

    private createForm() {
        this.entityFormGroup = this.formBuilder.group({
            'title': [null, [Validators.required]]
        });
    }

    public codeTextClicked(event: any) {
        this.codeTextStart! = window.getSelection()?.anchorOffset!;
        this.codeTextEnd! = window.getSelection()?.focusOffset!;
        this.codeTextValue! = window.getSelection()?.toString()!;
    }

    public generateHtml(): void {
        var htmlToAdd = '';

        if (!Helpers.isNullOrEmpty(this.entity.rawText!)) {
            htmlToAdd = this.entity.rawText!;
            // if (this.entity.entityTags?.length! > 0) {
            //     this.entity.entityTags?.forEach(tag => {
            //         htmlToAdd = this.entity.rawText?.substring(0, tag.startIndex)
            //         + '<strong>'
            //         + this.entity.rawText?.substring(tag.startIndex!, tag.startIndex! + tag.value?.length!)
            //         + '</strong>' + this.entity.rawText?.slice(tag.startIndex! + tag.value?.length!) ;
            //     });
            // } else {
            //     htmlToAdd = this.entity.rawText!;
            // }
        }

        this.rawTextHtml = htmlToAdd;
    }

    public show() {
        var selection = window.getSelection();

        var foundTags = Helpers.getTagsFromSelection(this.entity.entityTags!, selection!);
    }

    public save() {
        if (this.entityFormGroup.valid) {

            this.entity = this.bindFormToModel();

            if (Helpers.isNotNullOrUndefined(this.entity.id)) {
                // go to edit
                this.blockUI.start();

                this._annotationService.addAnnotations(this.entity.id!);

                this._entityService.updateEntity(this.entity).subscribe(
                    (result) => {
                        this.blockUI.stop();
                        this._snackBar.open('Updated successfully!', '', Helpers.getGreenSnackbarConfig());
                        this._router.navigate(['list-entities']);
                    },
                    (error) => {
                        this.blockUI.stop();
                        console.log(error);
                        this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
                    }
                )
            } else {
                // go to create new
                this.blockUI.start('Creating new entity...');

                this._entityService.createEntity(this.entity).subscribe(
                    (result) => {
                        this.blockUI.update(`Created entity with id: ${result.id}. Getting local owl file...`);

                        this._fusekiService.getLocalFile('Manuscribus.owl').subscribe(fileResult => {
                            this.blockUI.update(`Creating the dataset in Fuseki...`);

                            this._fusekiService.createDataset(result.id!, 'mem').subscribe(creationResult => {
                                this.blockUI.update(`Uploading the dataset file in Fuseki...`);

                                this._fusekiService.uploadDatasetFile(result.id!, fileResult).subscribe(uploadResult => {
                                    this.blockUI.update(`Initialize manuscript...`);

                                    this._fusekiService.initializeManuscript(result.id!, this.selectedSide).subscribe(initializationResult => {
                                        this.blockUI.stop();

                                        this._snackBar.open('Saved successfully!', '', Helpers.getGreenSnackbarConfig());

                                        // this._router.navigate(['list-entities']);
                                        this._router.navigate(['edit-entity/' + result.id]);
                                    });
                                });
                            });
                        });
                    },
                    (error) => {
                        this.blockUI.stop();
                        console.log(error);
                        this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
                    }
                )
            }
        } else {
            this._snackBar.open('You haven\'t filled all the required fields!', '', Helpers.getRedSnackbarConfig());
        }
    }

    public submit() {
        const codeText = <HTMLDivElement>document.getElementById('codeText');

        if (!Helpers.isNullOrEmpty(codeText.textContent!)) {
            this.entity.rawText = codeText.textContent!;

            // Use regex pattern to make an array of words
            this.entity.words = new Array<string>();
            this.entity.rawText.match(/\b(\w+)\b/g)?.forEach(word => {
                this.entity.words?.push(word);
            });
        }

        this.generateHtml();
    }

    private bindFormToModel(): Entity {
        const obj = Object.assign({}, this.entity);
        const form = this.entityFormGroup!;

        obj.title = form.get('title')!.value;
        obj.side = this.selectedSide;

        const codeText = <HTMLDivElement>document.getElementById('codeText');
        if (Helpers.isNotNullOrUndefined(codeText)) {
            obj.rawText = codeText.textContent!;
        }

        return obj;
    }

    private bindModelToForm() {
        if (Helpers.isNotNullOrUndefined(this.entity)) {
            this.entityFormGroup.get('title')?.setValue(this.entity?.title, { emitEvent: false });

            this.rawTextHtml = this.entity.rawText!;
        } else {
            this.entityFormGroup.get('patientId')?.setValue(null, { emitEvent: false });
        }
    }

    public boldClicked() {
        var a = window.getSelection();

        console.log(a);
    }

    @HostListener('document:click')
    documentClick(): void {
        this.isDisplayContextMenu = false;
    }
}
