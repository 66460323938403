import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { KeycloakUser } from 'src/app/models/keycloakUser';
import { UserService } from 'src/app/services/user.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
  selector: 'app-dialog-roles-user',
  templateUrl: './dialog-roles-user.component.html',
  styleUrls: ['./dialog-roles-user.component.scss']
})
export class DialogRolesUserComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;
    public keycloakUser!: KeycloakUser;

    public administrator_role = false;
    public basic_role = false;
    public guest_role = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogRolesUserComponent>,
        private _userService: UserService,
        private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        this.keycloakUser = this.data.keycloakUser;

        this.blockUI.start();

        this._userService.getKeycloakRolesOfUser(this.keycloakUser!.id!).subscribe(
            result => {
                result.forEach(role => {
                    switch(role.name) {
                        case 'administrator': {
                            this.administrator_role = true;
                            break;
                        }
                        case 'basic': {
                            this.basic_role = true;
                            break;
                        }
                        case 'guest': {
                            this.guest_role = true;
                            break;
                        }
                    }
                });

                this.blockUI.stop();
            },
            error => {
                this.blockUI.stop();
                this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
            }
        );
    }

    public onSelectedChange(event: any, roleName: string) {
        if (event.checked === true) {
            this.blockUI.start();

            this._userService.addKeycloakRoleToUser(this.keycloakUser!.id!, roleName).subscribe(
                result => {
                    this.blockUI.stop();
                    this._snackBar.open('Successfully added role ' + roleName + ' to user ' + this.keycloakUser.username, '', Helpers.getGreenSnackbarConfig());
                },
                error => {
                    this.blockUI.stop();
                    this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
                }
            );
        } else if (event.checked === false) {
            this.blockUI.start();

            this._userService.deleteKeycloakRoleFromUser(this.keycloakUser!.id!, roleName).subscribe(
                result => {
                    this.blockUI.stop();
                    this._snackBar.open('Successfully deleted role ' + roleName + ' from user ' + this.keycloakUser.username, '', Helpers.getGreenSnackbarConfig());
                },
                error => {
                    this.blockUI.stop();
                    this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
                }
            );
        }
    }

    public okClicked() {
        this.dialogRef.close(true);
    }
}
