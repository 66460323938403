import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Entity } from 'src/app/models/entity';
import { Manus } from 'src/app/models/jena/manus';
import { Actor } from 'src/app/models/jena/actor';
import { Router } from '@angular/router';
import { WindowArea } from 'src/app/models/enums/windowArea';
import { DisplayAuxiliary } from 'src/app/models/display/displayAuxiliary';
import { Observable, catchError, switchMap, tap, throwError } from 'rxjs';
import { AuxiliaryRoot } from 'src/app/models/jena/auxiliaryRoot';
import { AuxiliaryArea } from 'src/app/models/enums/auxiliaryArea';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-auxiliary-editor',
    templateUrl: './auxiliary-editor.component.html',
    styleUrls: ['./auxiliary-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuxiliaryEditorComponent implements OnInit, OnChanges {
    @Input() height: string = '100';
    @Input() datasetName: string | undefined;
    @Input() entity: Entity | undefined;
    @Input() auxiliaryArea: AuxiliaryArea = AuxiliaryArea.None;
    @BlockUI('aux-editor-container') blockUI!: NgBlockUI;
    @ViewChild('auxEntityContainer', { read: ElementRef }) auxEntityContainer!: ElementRef;

    public displayAuxiliary: DisplayAuxiliary;

    private removeKeyDown!: () => void;

    public isFocused = false;

    constructor(private router: Router,
        private _renderer: Renderer2,
        private _fusekiService: FusekiService,
        private _dialog: MatDialog,
        private _changeDetectorRef: ChangeDetectorRef) {
        this.displayAuxiliary = new DisplayAuxiliary();
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
          };
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        this.updateEntity().subscribe({
            next: (result) => {
                this.updateDisplay();
                this._changeDetectorRef.detectChanges();
            },
            error: (error) => console.log(error.message)
        });
    }

    initialize(column: string) {
        this.focus();

        this.blockUI.start('Initializing auxiliary content ...');

        this._fusekiService.initializeAuxiliary(
            this.datasetName!,
            column,
            this.auxiliaryArea).pipe(
                switchMap(() => this.updateEntity())
            ).subscribe({
                next: (result: any) => {
                    this.updateDisplay();
                    this.blockUI.stop();
                },
                error: (error) => {
                    console.log(error);
                    this.blockUI.stop();
                }
            }
        );
    }

    focus() {
        this.auxEntityContainer.nativeElement.focus();
        this.isFocused = true;
    }

    focusToItem(strokeName: string) {
        this.focus();
        var itemIndex = this.displayAuxiliary.items?.findIndex(i => i.stroke === strokeName);
        this.displayAuxiliary.setCursorPosition(itemIndex! + 1, 1, 1)
        this.updateDisplay();
    }

    reload() {
        this.router.navigateByUrl('/edit-entity/' + this.datasetName);
    }

    updateEntity(): Observable<AuxiliaryRoot | undefined> {
        if (this.datasetName) {
            return this._fusekiService.getAuxiliaryContent(this.datasetName, this.auxiliaryArea)
                .pipe(
                    tap((result: AuxiliaryRoot) => {
                        this.displayAuxiliary.refresh(result, this.auxiliaryArea);
                    }),
                    catchError((error) => {
                        console.error(`Failed to get auxiliary content: ${error.message}`)
                        return throwError(() => error);
                    })
                );
        } else {
            return throwError(() => new Error('datasetName is undefined'));
        }
    }

    clearDisplay(): void {
        Array.from(this.auxEntityContainer.nativeElement.children).forEach((child: any)  => {
            this._renderer.removeChild(this.auxEntityContainer.nativeElement, child);
        });
    }

    updateDisplay(): void {
        this.clearDisplay();

        var itemIndex = 1;

        this.displayAuxiliary.items!.forEach(item => {
            item!.rows!.forEach(row => {
                row.characters.forEach(character => {
                    let divColumn = this._renderer.createElement('div');
                    this._renderer.addClass(divColumn, 'column');
                    this._renderer.listen(divColumn, 'mousedown', (event) => {
                        this.onCharacterClick(event);
                        event.preventDefault();
                        this.auxEntityContainer.nativeElement.parentElement.focus();
                    });

                    let metaItem = this._renderer.createElement('meta');
                    this._renderer.setProperty(metaItem, 'name', 'item');
                    this._renderer.setProperty(metaItem, 'content', itemIndex);

                    let metaRow = this._renderer.createElement('meta');
                    this._renderer.setProperty(metaRow, 'name', 'row');
                    this._renderer.setProperty(metaRow, 'content', row.order);

                    let metaOrder = this._renderer.createElement('meta');
                    this._renderer.setProperty(metaOrder, 'name', 'order');
                    this._renderer.setProperty(metaOrder, 'content', character.order);

                    let divCharacter = this._renderer.createElement('div');
                    this._renderer.addClass(divCharacter, 'character');
                    let newChar = String.fromCharCode(character.utfCode);
                    let textCharacter = this._renderer.createText(newChar == ' ' ? '\u00A0' : newChar);

                    if (character.isVirtual) {
                        if (character.owlName === 'linkedCharacter' || character.owlName === 'sectionbreak') {
                            switch (this.auxiliaryArea) {
                                case AuxiliaryArea.InRow:
                                    this._renderer.addClass(divCharacter, 'inrow-character');
                                    break;
                                case AuxiliaryArea.Over:
                                    this._renderer.addClass(divCharacter, 'over-character');
                                    break;
                                case AuxiliaryArea.Above:
                                    this._renderer.addClass(divCharacter, 'above-character');
                                    break;
                                case AuxiliaryArea.Below:
                                    this._renderer.addClass(divCharacter, 'below-character');
                                    break;
                            }
                        }
                    }

                    this._renderer.appendChild(divCharacter, textCharacter);

                    this._renderer.appendChild(divColumn, metaItem);
                    this._renderer.appendChild(divColumn, metaRow);
                    this._renderer.appendChild(divColumn, metaOrder);
                    this._renderer.appendChild(divColumn, divCharacter);

                    if (itemIndex === this.displayAuxiliary.cursorPosition.item &&
                        character.order == this.displayAuxiliary.cursorPosition.column &&
                        character.row.order == this.displayAuxiliary.cursorPosition.row &&
                        this.isFocused) {
                        let divCursor = this._renderer.createElement('div');
                        this._renderer.addClass(divCursor, 'cursor');
                        this._renderer.appendChild(divColumn, divCursor);
                    }

                    this._renderer.appendChild(this.auxEntityContainer.nativeElement, divColumn);
                });
            });

            itemIndex++;
        });

        if (this.removeKeyDown) {
            this.removeKeyDown();
        }

        this.removeKeyDown = this._renderer.listen(
            this.auxEntityContainer.nativeElement.parentElement, 'keydown', (event) => this.onKeyDown(event));
        this._renderer.setAttribute(this.auxEntityContainer.nativeElement.parentElement, 'tabindex', '-1');
    }

    private controlAndEnterPressed(): void {

    }

    private enterPressed(): void {
        this._addRow();
        this.updateDisplay();

    }

    private arrowRightPressed(): void {
        this.displayAuxiliary.moveCursorRight(true);
        this.updateDisplay();
    }

    private arrowLeftPressed(): void {
        this.displayAuxiliary.moveCursorLeft(true);
        this.updateDisplay();

    }

    private backspacePressed(): void {
        if (this.displayAuxiliary.isCursorAtStart() ||
        this.displayAuxiliary.isCursorAtFirstRow()) {
            return;
        }

        if (this.displayAuxiliary.getColumnPositionOfCharacter()! > 1) {

            this.blockUI.start('Deleting character');

            this._fusekiService.deleteAuxiliaryCharacter(
                this.datasetName!,
                this.displayAuxiliary.getRowNameOfCharacter()!,
                this.displayAuxiliary.getColumnPositionOfCharacter()! - 1,
                true
                ).pipe(
                    switchMap(() => this.updateEntity())
                ).subscribe({
                    next: (result: any) => {
                        this.displayAuxiliary.moveCursorLeft(false);
                        this.updateDisplay();

                        this.blockUI.stop();
                    },
                    error: (error) => {
                        console.log(error);
                        this.blockUI.stop();
                    }
                }
            );
        }
    }

    private deletePressed(): void {
        if (this.displayAuxiliary.isCursorAtStart() ||
        this.displayAuxiliary.isCursorAtEnd() ||
        this.displayAuxiliary.isCursorAtFirstRow() ||
        this.displayAuxiliary.isCursorAtRowStart() ||
        this.displayAuxiliary.isCursorAtItemStart()) {
            return;
        }

        this.blockUI.start('Deleting character');

        this._fusekiService.deleteAuxiliaryCharacter(
            this.datasetName!,
            this.displayAuxiliary.getRowNameOfCharacter()!,
            this.displayAuxiliary.getColumnPositionOfCharacter()!,
            true,
            ).pipe(
                switchMap(() => this.updateEntity())
            ).subscribe({
                next: (result: any) => {
                    this.displayAuxiliary.updateCursorPositionAfterDelete();
                    this.updateDisplay();
                    this.blockUI.stop();
                },
                error: (error) => {
                    console.log(error);
                    this.blockUI.stop();
                }
            }
        );
    }

    private spaceKeyPressed(): void {
        if (this.displayAuxiliary.isCursorAtStart() ||
        this.displayAuxiliary.isCursorAtFirstRow()) {
            return;
        }

        this._addSpace();
    }

    private defaultKeyPressed(event: KeyboardEvent): void {
        if (this.displayAuxiliary.isCursorAtStart() ||
        this.displayAuxiliary.isCursorAtFirstRow()) {
            return;
        }

        this._addCharacter(event);
    }

    private _addCharacter(event: KeyboardEvent) {
        this.blockUI.start('Inserting character');

        this._fusekiService.addAuxiliaryCharacter(
            this.datasetName!,
            this.displayAuxiliary.getRowNameOfCharacter()!,
            //this.displayAuxiliary.getRowPositionOfCharacter()!,
            event.key,
            this.displayAuxiliary.getColumnPositionOfCharacter()!
        ).pipe(
            switchMap(() => this.updateEntity())
        ).subscribe({
            next: () => {
                this.displayAuxiliary.moveCursorRight(false);
                this.updateDisplay();
                this.blockUI.stop();
            },
            error: error => console.log(error)
        });
    }

    private _addSpace() {
        this.blockUI.start('Inserting space');

        this._fusekiService.addAuxiliarySpace(
            this.datasetName!,
            this.displayAuxiliary.getRowNameOfCharacter()!,
            //this.displayAuxiliary.getRowPositionOfCharacter()!,
            this.displayAuxiliary.getColumnPositionOfCharacter()!
        ).pipe(
            switchMap(() => this.updateEntity())
        ).subscribe({
            next: () => {
                this.displayAuxiliary.moveCursorRight(false);
                this.updateDisplay();
                this.blockUI.stop();
            },
            error: error => console.log(error)
        });
    }

    private _addRow() {
        this.blockUI.start('Inserting new row');

        this._fusekiService.addAuxiliaryRow(
            this.datasetName!,
            this.displayAuxiliary.getStrokeNameOfCharacter()!,
            this.auxiliaryArea
        ).pipe(
            switchMap(() => this.updateEntity())
        ).subscribe({
            next: () => {
                this.updateDisplay();
                this.blockUI.stop();
            },
            error: error => console.log(error)
        });
    }

    onEditorClick(event: MouseEvent) {
        this.isFocused = true;
        this.displayAuxiliary.setCursorPosition(1,1,1);
        this.updateDisplay();
    }

    onCharacterClick(event: MouseEvent) {
        this.isFocused = true;
        let cell = event.currentTarget as HTMLElement;
        let page = cell.querySelectorAll('meta')[0];
        let row = cell.querySelectorAll('meta')[1];
        let order = cell.querySelectorAll('meta')[2];
        this.displayAuxiliary.setCursorPosition(+page.content, +row.content, +order.content);
        this.updateDisplay();
        event.stopPropagation();
    }

    onFocusOut(event: any) {
        this.isFocused = false;
        this.updateDisplay();
    }

    fromCharCode(code: number): string {
        return String.fromCharCode(code);
    }

    public getStyle(): string {
        return `height:${this.height}px;`;
    }

    public onKeyDown(event: KeyboardEvent) {
        event.preventDefault();

        if (this.blockUI.isActive) {
            return;
        }

        // if (this.displayEntity.isCursorAtFirstPage()) {
        //     return;
        // }

        // if (this.displayEntity.isCursorAtFirstRow()) {
        //     return;
        // }

        if (event.ctrlKey && event.key === 'Enter') {
            event.preventDefault();
            this.controlAndEnterPressed();

        } else if (event.key === 'Enter') {
            event.preventDefault();
            this.enterPressed();

        } else if (event.key === 'ArrowRight') {
            event.preventDefault();
            this.arrowRightPressed();

        } else if (event.key === 'ArrowLeft') {
            event.preventDefault();
            this.arrowLeftPressed();

        } else if (event.key === 'ArrowUp') {
            event.preventDefault();

        } else if (event.key === 'ArrowDown') {
            event.preventDefault();

        } else if (event.key === 'Backspace') {
            event.preventDefault();
            this.backspacePressed();

        } else if (event.key === 'Delete') {
            event.preventDefault();
            this.deletePressed();

        } else if (event.key === ' ') {
            event.preventDefault();
            this.spaceKeyPressed();

        } else if (!event.ctrlKey && !event.shiftKey && event.key !== 'Escape') {
            event.preventDefault();
            this.defaultKeyPressed(event);

        }
    }
}

