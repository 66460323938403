import { DisplayRow } from "./displayRow";

export class DisplayPage {
    owlName: string;
    areaName: string;
    order: number;
    rows: Array<DisplayRow>;

    get length(): number {
        return this.rows.length;
    }

    constructor(areaName: string, owlName: string, order: number, rows: Array<DisplayRow>) {
        this.areaName = areaName;
        this.owlName = owlName;
        this.order = order;
        this.rows = rows;
    }

    isEmpty(): boolean {
        return this.rows.length == 1 && this.rows[0].characters.length == 2;
    }
}
