import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Actor } from 'src/app/models/jena/actor';
import { Manus } from 'src/app/models/jena/manus';
import { DialogInfoComponent } from '../../shared/dialog-info/dialog-info.component';
import { Default } from 'src/app/models/default';
import { EntityService } from 'src/app/services/entity.service';
import { Helpers } from 'src/app/utilities/helpers';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-dialog-info',
    templateUrl: './dialog-info.component.html',
    styleUrls: ['./dialog-info.component.scss']
})
export class DialogOntologyInfoComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;
    public default?: Default;
    public selectedManus?: Manus;
    public selectedActor?: Actor;
    public selectedDefaultManus?: boolean;
    public selectedDefaultActor?: boolean;
    public selectedShowCharacterInfo = true;

    constructor(private _entityService: EntityService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogOntologyInfoComponent>) {
            this.entityId = data.entityId;

            this._blockUI.start();
            this._entityService.getEntityById(this.entityId!).subscribe(
                result => {
                    this.default = result.default;

                    if (Helpers.isNotNullOrUndefined(this.default?.actor)) {
                        this.selectedDefaultActor = true;
                    }

                    if (Helpers.isNotNullOrUndefined(this.default?.manus)) {
                        this.selectedDefaultManus = true;
                    }

                    if (Helpers.isNotNullOrUndefined(this.default?.showCharacterInfo)) {
                        this.selectedShowCharacterInfo = this.default?.showCharacterInfo!;
                    }

                    this._blockUI.stop();
                },
                error => {
                    this._blockUI.stop();
                }
            );
        }

    ngOnInit(): void {
    }

    public okClicked() {
        this.dialogRef.close({manus: this.selectedManus, actor: this.selectedActor, default: this.default});
    }

    public cancelClicked() {
        this.dialogRef.close(false);
    }

    public onSelectedManus($event: any) {
        this.selectedManus = $event;
    }

    public onDefaultSelectedManus($event: any) {
        this.selectedDefaultManus = $event;
    }

    public onSelectedActor($event: any) {
        this.selectedActor = $event;
    }

    public onDefaultSelectedActor($event: any) {
        this.selectedDefaultActor = $event;
    }

    public showCheckbox(): boolean {
        return this.selectedDefaultManus! && this.selectedDefaultActor!;
    }

    public showCheckboxChanged(matCheckboxChange: MatCheckboxChange) {
        this.selectedShowCharacterInfo = !matCheckboxChange.checked;

        this._blockUI.start();

        this._entityService.updateEntityByDefaultShowCharacterInfo(this.entityId!, !matCheckboxChange.checked).subscribe(
            (result) => {
                this._blockUI.stop();
                this._snackBar.open('Updated successfully!', '', Helpers.getGreenSnackbarConfig());
                this.default = result.default;
            },
            (error) => {
                this._blockUI.stop();
                console.log(error);
                this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
            }
        );

    }

}

