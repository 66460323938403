import { DisplayCharacter } from "./displayCharacter";
import { CursorPosition } from "./cursorPosition";
import { DisplayRow } from "./displayRow";
import { Helpers } from "src/app/utilities/helpers";
import { DisplayPage } from "./displayPage";
import { EntityPage } from "../jena/entityPage";
import { EntityRoot } from "../jena/entityRoot";
import { EntityRow } from "../jena/entityRow";

export class DisplayEntity {
    pages: Array<DisplayPage>;
    public cursorPosition;

    constructor() {
        this.pages = [];
        this.cursorPosition = new CursorPosition(1, 1, 1);
    }

    public getCharacter(pageOrder?: number, rowOrder?: number, characterOrder?: number): DisplayCharacter | undefined {
        var page = this.pages.find(x => x.order === pageOrder);
        var row = page ? page.rows.find(x => x.order === rowOrder) : undefined;
        return row?.characters.find(x => x.order === characterOrder);
    }

    public getCharacterById(id: string): DisplayCharacter | undefined {
        let found = undefined;

        this.pages.forEach(page => {
            page.rows.forEach(row => {
                row.characters.forEach(character => {
                    if (character.owlName2 === id) found = character;
                })
            });
        });

        return found;
    }

    public getCharacterAtCursor(): DisplayCharacter | undefined {
        return this.getCharacter(this.cursorPosition.page, this.cursorPosition.row, this.cursorPosition.column);
    }

    public getCharacterBeforeCursor(): DisplayCharacter | undefined {
        return this.getCharacterAtCursor()?.previousCharacter;
    }

    public getCharacterAfterCursor(): DisplayCharacter | undefined {
        return this.getCharacterAtCursor()?.nextCharacter;
    }

    public getLastElements(): [DisplayPage, DisplayRow, DisplayCharacter] {
        const lastPage = this.pages[this.pages.length - 1];
        const lastRow = lastPage.rows[lastPage.rows.length - 1];
        let lastCharacter = lastRow.characters[lastRow.characters.length - 1];

        return [lastPage, lastRow, lastCharacter];
    }

    public getAreaAtCursor(): string {
        if (this.isCursorAtPageStart() && this.cursorPosition.page! > 1) {
            return this.pages[this.cursorPosition.page! - 2].areaName;
        } else {
            return this.pages[this.cursorPosition.page! - 1].areaName;
        }
    }

    public isCursorAtFirstPage(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'pagebreak' && this.cursorPosition.page === 1 && this.cursorPosition.row === 1;
    }

    public isCursorAtFirstRow(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'linebreak' && this.cursorPosition.row === 1;
    }

    public isCursorAtRowStart(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'linebreak';
    }

    public isCursorAtEnd(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'leadingspace';
    }

    public isCursorAtInRowSymbol(): boolean | undefined {
        var characterAtCursor = this.getCharacterAtCursor();
        return characterAtCursor && characterAtCursor.isVirtual && characterAtCursor.owlName === 'inrowsymbol';
    }

    public isCursorAtPageStart(includeLinebreak: boolean = false): boolean | undefined {
        if (includeLinebreak) {
            return this.cursorPosition.row === 1 && (
                this.cursorPosition.column === 1 || this.cursorPosition.column === 2);
        } else {
            return this.cursorPosition.row === 1 && this.cursorPosition.column === 1;
        }
    }

    public isCursorAtStart(): boolean | undefined {
        return this.cursorPosition.page === 1 && this.cursorPosition.row === 1 && this.cursorPosition.column === 1;
    }

    public getLastRow(pageOrder?: number): DisplayRow | null {
        var page = this.pages.find(x => x.order === pageOrder);
        return page!.rows.length > 0 ? page!.rows[page!.rows.length - 1] : null;
    }

    public getRowPositionOfCharacter(): number | undefined {
        var tempPage = this.cursorPosition.page;

        if (this.isCursorAtPageStart() && this.cursorPosition.page! > 1) {
            tempPage = this.cursorPosition.page! - 1;

            return this.pages[tempPage - 1].rows.length;
        } else {
            if (this.cursorPosition.row === 1) {
                return 1;
            } else {
                if (this.cursorPosition.column === 1) {
                    return this.cursorPosition.row! - 1;
                } else {
                    return this.cursorPosition.row;
                }
            }
        }
    }

    public getColumnPositionOfCharacter(): number | undefined {
        var tempRow = this.getRowPositionOfCharacter();
        var tempPage = this.cursorPosition.page;

        if (this.isCursorAtPageStart() && this.cursorPosition.page! > 1) {
            tempPage = this.cursorPosition.page! - 1;
        }

        var page = this.pages.find(x => x.order === tempPage);
        var virtualCharacters = new Array<DisplayCharacter>();
        var result = -1;

        if (this.cursorPosition.column! > 1) {
            if (Helpers.isNotNullOrUndefined(page?.rows[tempRow! - 1])) {
                page?.rows[this.cursorPosition.row! - 1].characters.filter(i => i.isVirtual && i.order < this.cursorPosition.column!).forEach(v => {
                    virtualCharacters.push(v);
                });

                result = this.cursorPosition.column! - virtualCharacters.length;
            }
        } else {
            if (Helpers.isNotNullOrUndefined(page?.rows[tempRow! - 1])) {
                page?.rows[tempRow! - 1].characters.filter(i => i.isVirtual).forEach(v => {
                    virtualCharacters.push(v);
                });

                result = page?.rows[tempRow! - 1]!.characters!.length! - virtualCharacters.length + 1;
            }
        }

        return result;
    }

    public refresh(root: EntityRoot): void {
        if (root.pages && root.pages.length > 0) {
            const pages: DisplayPage[] = [];
            let previousCharacter: DisplayCharacter | undefined = undefined;

            root.pages.forEach((entityPage: EntityPage) => {
                const rows: DisplayRow[] = [];
                const page = new DisplayPage(entityPage.areaName, entityPage.name, entityPage.order, rows);

                let pageIndex = 1;
                let rowIndex = 1;

                entityPage.rows.forEach((entityRow: EntityRow) => {
                    const characters: DisplayCharacter[] = [];
                    const row = new DisplayRow(entityRow.name, entityRow.order, characters);

                    var columnsIndex = 1;

                    if (rowIndex === 1) {
                        let pagebreak = new DisplayCharacter(
                            'pagebreak',
                            entityPage.name,
                            0x007C,
                            row,
                            columnsIndex,
                            true,
                            undefined,
                            page,
                            previousCharacter);

                        characters.push(pagebreak);

                        if (previousCharacter) {
                            previousCharacter.nextCharacter = pagebreak;
                        }

                        previousCharacter = pagebreak;
                        columnsIndex++;
                    }

                    let linebreak = new DisplayCharacter(
                        'linebreak',
                        entityRow.name,
                        0x00A6,
                        row,
                        columnsIndex,
                        true,
                        undefined,
                        page,
                        previousCharacter);

                    characters.push(linebreak);

                    if (previousCharacter) {
                        previousCharacter.nextCharacter = linebreak;
                    }

                    previousCharacter = linebreak;
                    columnsIndex++;

                    entityRow.columns.forEach(column => {
                        if (column.inRow!.length > 0) {
                            let inrow = new DisplayCharacter(
                                'inrowsymbol',
                                undefined,
                                0x2e3e,
                                row,
                                columnsIndex,
                                true,
                                undefined,
                                page,
                                previousCharacter);

                            characters.push(inrow);

                            if (previousCharacter) {
                                previousCharacter.nextCharacter = inrow;
                            }

                            previousCharacter = inrow;
                            columnsIndex++;
                        }

                        const character = new DisplayCharacter(
                            column.name,
                            column.name,
                            column.utfCode,
                            row,
                            columnsIndex,
                            false,
                            column.order,
                            page,
                            previousCharacter
                        );

                        character.characterName = column.character;
                        character.wordIndex = column.word;

                        column.inRow!.length > 0 ? character.hasInRow = true : character.hasInRow = false;
                        column.over!.length > 0 ? character.hasOver = true : character.hasOver = false;
                        column.above!.length > 0 ? character.hasAbove = true : character.hasAbove = false;
                        column.below!.length > 0 ? character.hasBelow = true : character.hasBelow = false;
                        character.strokeName = column.strokeName;
                        character.transcriptionName = column.transcriptionName;
                        character.manusName = column.manusName;
                        character.annotatorName = column.annotatorName;
                        character.manuscriptName = column.manuscriptName;

                        characters.push(character);

                        if (previousCharacter) {
                            previousCharacter.nextCharacter = character;
                        }

                        previousCharacter = character;

                        columnsIndex++;
                    });

                    rows.push(row);
                    rowIndex++;
                });

                pages.push(page);

                pageIndex++;
            });

            let lastRow = pages[pages.length - 1].rows[pages[pages.length - 1].rows.length - 1];
            const leadingspace = new DisplayCharacter(
                'leadingspace',
                undefined,
                0x0020,
                lastRow,
                lastRow.characters.length + 1,
                true,
                undefined,
                pages[pages.length - 1],
                previousCharacter
            );
            lastRow.characters.push(leadingspace);
            leadingspace.previousCharacter!.nextCharacter = leadingspace;

            this.pages = pages;
        } else {
            this.pages = [];
        }
    }

    addCharacter(character: string) {
        let lastInRow = false;
        let currentCharacter = this.getCharacterAtCursor()!;
        if (currentCharacter.owlName == 'pagebreak' || currentCharacter.owlName == 'linebreak') {
            currentCharacter = currentCharacter.previousCharacter!;
            lastInRow = true;
        }

        let page = currentCharacter.page;
        let row = currentCharacter.row;
        let insertAt = currentCharacter.order;

        let newDisplayCharacter = new DisplayCharacter(
            'provisional',
            'provisional',
            character.charCodeAt(0),
            row,
            insertAt,
            false,
            currentCharacter!.owlOrder,
            page,
            lastInRow ? currentCharacter : currentCharacter?.previousCharacter,
            lastInRow ? currentCharacter.nextCharacter : currentCharacter
        );

        if (!lastInRow) {
            currentCharacter.row.characters.slice(currentCharacter.order - 1).forEach(char => {
                char.order++;
                if (char.owlOrder != null)
                    char.owlOrder++;
            });

            if (currentCharacter.previousCharacter)
                currentCharacter.previousCharacter.nextCharacter = newDisplayCharacter;

            currentCharacter.previousCharacter = newDisplayCharacter;
            row.characters.splice(insertAt - 1, 0, newDisplayCharacter);
            this.moveCursorRight(false);
        } else {
            newDisplayCharacter.order++;
            if (newDisplayCharacter.owlOrder != null)
                newDisplayCharacter.owlOrder++;
            if (newDisplayCharacter.nextCharacter)
                newDisplayCharacter.nextCharacter.previousCharacter = newDisplayCharacter;
            if (newDisplayCharacter.previousCharacter)
                newDisplayCharacter.previousCharacter.nextCharacter = newDisplayCharacter;
            row.characters = row.characters.concat(newDisplayCharacter);
        }
    }

    deleteCharacter(back: boolean): void {
        let targetCharacter: DisplayCharacter | undefined;

        if (back)
            targetCharacter = this.getCharacterAtCursor()!.previousCharacter!;
        else
            targetCharacter = this.getCharacterAtCursor();

        if (!targetCharacter || targetCharacter.owlName == 'linebreak') return;

        let row = targetCharacter.row;
        let deleteAt = targetCharacter.order - 1;

        row.characters.splice(deleteAt, 1);
        if (targetCharacter.previousCharacter)
            targetCharacter.previousCharacter.nextCharacter = targetCharacter.nextCharacter;
        if (targetCharacter.nextCharacter)
            targetCharacter.nextCharacter.previousCharacter = targetCharacter.previousCharacter;

        row.characters.slice(targetCharacter.order - 1).forEach(char => {
            char.order--;
            if (char.owlOrder != null)
                char.owlOrder--;
        });
        this.setCursorPositionToCharacter(targetCharacter.nextCharacter!);
    }

    addRow() {
        let currentCharacter = this.getCharacterAtCursor()!;
        let atPagebreak = false;
        if (currentCharacter.owlName == 'pagebreak') atPagebreak = true;

        let page = atPagebreak ? currentCharacter.previousCharacter!.page : currentCharacter.page;
        if (!page) {
            throw new Error('No page found for character at cursor');
        }

        let row = atPagebreak ? currentCharacter.previousCharacter!.row : currentCharacter.row;
        let insertAt = atPagebreak ? currentCharacter.previousCharacter!.order : currentCharacter.order;
        let amountToTransfer = 0;
        let transferredCharacters: DisplayCharacter[] = [];

        if (!atPagebreak) {
            page.rows.slice(row.order).forEach(idxRow => idxRow.order++);
            amountToTransfer = currentCharacter.row.characters.length - insertAt + 1;
            transferredCharacters = currentCharacter.row.characters.splice(insertAt - 1, amountToTransfer);
        }

        let newRow = new DisplayRow('provisional', row.order + 1, transferredCharacters);
        let linebreak = new DisplayCharacter('linebreak',
            'provisional',
            0x00A6,
            newRow,
            1,
            true,
            undefined,
            page,
            currentCharacter.previousCharacter,
            currentCharacter);

        currentCharacter.previousCharacter!.nextCharacter = linebreak;
        currentCharacter.previousCharacter = linebreak;

        let idx = 2;
        transferredCharacters.forEach(char => {
            char.row = newRow;
            char.order = idx;
            char.owlOrder = idx - 1;
            idx++;
        });
        transferredCharacters.splice(0, 0, linebreak);

        page.rows.splice(row.order, 0, newRow);
        this.setCursorPositionToCharacter(linebreak);
        this.moveCursorRight();
    }

    deleteRow(back: boolean): void {
        let targetCharacter: DisplayCharacter | undefined = undefined;
        let lastInPage = false;

        if (back)
            targetCharacter = this.getCharacterAtCursor()!.previousCharacter!;
        else
            targetCharacter = this.getCharacterAtCursor();

        if (!targetCharacter) return;

        if (targetCharacter.nextCharacter?.owlName == 'pagebreak')
            lastInPage = true;

        let page = targetCharacter.page!;
        let previousRow = targetCharacter.previousCharacter!.row;
        let deleteAt = targetCharacter.row.order;

        const deleted = page.rows.splice(deleteAt - 1, 1);
        if (deleted[0].characters.length > 1) {
            deleted[0].characters.shift();

            let newOrder = previousRow.characters[previousRow.characters.length - 1].order + 1;
            let newOwlOrder = previousRow.characters[previousRow.characters.length - 1].owlOrder! + 1;
            deleted[0].characters.forEach(char => {
                char.row = previousRow;
                char.order = newOrder;
                char.owlOrder = newOwlOrder;
                newOrder++;
                newOwlOrder++;
            });
            deleted[0].characters[0].previousCharacter = previousRow.characters[previousRow.characters.length - 1];
            previousRow.characters[previousRow.characters.length - 1].nextCharacter = deleted[0].characters[0];
            previousRow.characters.push(...deleted[0].characters);
        } else {
            deleted[0].characters[0].nextCharacter!.previousCharacter = previousRow.characters[previousRow.characters.length - 1];
            previousRow.characters[previousRow.characters.length - 1].nextCharacter = deleted[0].characters[0].nextCharacter;
        }

        page.rows.slice(deleteAt - 1).forEach(row => {
            row.order--;
        });

        if (!lastInPage)
            this.setCursorPositionToCharacter(deleted[0].characters[0]);
        else
            this.setCursorPositionToCharacter(deleted[0].characters[0].nextCharacter!);
    }

    addPage(): void {
        let targetCharacter: DisplayCharacter = this.getCharacterAtCursor()!;
        let page = targetCharacter.page;
        let row = targetCharacter.row;
        if (!page) return;

        let transferredCharacters: DisplayCharacter[] | undefined = undefined;
        let rowAmountToTransfer: number = 0;
        let transferredRows: DisplayRow[] = [];

        if (targetCharacter.owlName == 'pagebreak') {
            return;
        } else if (targetCharacter.owlName == 'linebreak') {
            rowAmountToTransfer = page.rows.length - row.order + 1;
            transferredRows = page.rows.splice(row.order - 1, rowAmountToTransfer);
        } else {
            let charAmountToTransfer = row.characters.length - targetCharacter.order + 1;
            transferredCharacters = row.characters.splice(targetCharacter.order - 1, charAmountToTransfer);
            rowAmountToTransfer = page.rows.length - row.order;
            transferredRows = page.rows.splice(row.order, rowAmountToTransfer);
        }

        this.pages.filter(x => x.order > page!.order).forEach(page => {
            page.order++;
        });

        const newPage = new DisplayPage(
            'provisional',
            'provisional',
            page.order! + 1,
            []);
        const newRow = new DisplayRow('provisional', 1, new Array<DisplayCharacter>());
        const newPagebreak = new DisplayCharacter(
            'pagebreak',
            'provisional',
            0x007c,
            newRow,
            1,
            true,
            undefined,
            newPage,
            targetCharacter.previousCharacter,
            undefined);

        if (targetCharacter.owlName == 'linebreak') {
            newPagebreak.nextCharacter = transferredRows[0].characters[0];
            transferredRows[0].characters[0].previousCharacter = newPagebreak;
            transferredRows[0].characters.forEach(char => char.order++);
            transferredRows[0].characters.unshift(newPagebreak);
            newPage.rows = transferredRows;
        } else if (transferredCharacters) {
            const newLinebreak = new DisplayCharacter(
                'linebreak',
                'provisional',
                0x00A6,
                newRow,
                2,
                true,
                undefined,
                newPage,
                newPagebreak,
                undefined
            );
            newPagebreak.nextCharacter = newLinebreak;
            newLinebreak.nextCharacter = transferredCharacters[0];
            transferredCharacters[0].previousCharacter = newLinebreak;

            newRow.characters = [
                newPagebreak,
                newLinebreak,
                ...transferredCharacters
            ];

            let idx = 1;
            newRow.characters.forEach(char => {
                char.order = idx;
                char.owlOrder = idx > 2  ? idx - 2 : undefined;
                char.page = newPage;
                char.row = newRow;
                idx++;
            });

            newPage.rows = [
                newRow,
                ...transferredRows
            ];
        }

        let idx = transferredCharacters ? 2 : 1;
        transferredRows.forEach(idxRow => {
            idxRow.order = idx;
            idxRow.characters.forEach(idxChar => {
                idxChar.page = newPage;
            });
            idx++;
        });

        newPage.rows[0].characters[0].previousCharacter!.nextCharacter = newPage.rows[0].characters[0];

        this.pages.splice(page.order!, 0, newPage);
        this.addEmptyPageAtEnd();
        this.setCursorPositionToCharacter(newPage.rows[0].characters[0].nextCharacter?.nextCharacter!);
    }

    addEmptyPageAtEnd(): void {
        let [lastPage, lastRow, lastCharacter] = this.getLastElements();

        if (lastCharacter.owlName == 'leadingspace') {
            lastRow.characters.splice(lastRow.characters.length - 1, 1);
            lastCharacter = lastRow.characters[lastRow.characters.length - 1];
        }

        const newRow = new DisplayRow('provisional', 1, new Array<DisplayCharacter>());
        const pagebreak = new DisplayCharacter(
            'pagebreak',
            'provisional',
            0x007c,
            newRow,
            1,
            true,
            undefined,
            undefined,
            lastCharacter,
            undefined);
        lastCharacter.nextCharacter = pagebreak;

        const linebreak = new DisplayCharacter(
            'linebreak',
            'provisional',
            0x00A6,
            newRow,
            2,
            true,
            undefined,
            undefined,
            pagebreak,
            undefined
        );
        pagebreak.nextCharacter = linebreak;

        const leadingspace = new DisplayCharacter(
            'leadingspace',
            undefined,
            0x0020,
            newRow,
            3,
            true,
            undefined,
            undefined,
            linebreak
        );
        linebreak.nextCharacter = leadingspace;

        newRow.characters.push(pagebreak, linebreak, leadingspace);

        const newPage = new DisplayPage(
            'provisional',
            'provisional',
            lastPage.order + 1,
            [newRow]
        );

        pagebreak.page = newPage;
        linebreak.page = newPage;
        leadingspace.page = newPage;

        this.pages.push(newPage);
    }

    deletePage(deleteRow: boolean): void {
        let targetPage = this.getCharacterAtCursor()!.page!;

        if (this.getCharacterAtCursor()?.owlName == 'pagebreak') {
            targetPage = this.getCharacterAtCursor()?.previousCharacter?.page!;
        }

        const nextPage = this.pages[targetPage.order];
        const deletedPage = this.pages.splice(targetPage!.order - 1, 1)[0];
        deletedPage.rows[0].characters.splice(0, 1);

        let idx = 1;
        deletedPage.rows[0].characters.forEach(char => {
            char.order = idx;
            idx++;
        });

        let previousPage = this.pages[deletedPage.order - 2];
        let previousRow = previousPage.rows.at(-1)!;

        deletedPage.rows.forEach(row => {
            row.characters.forEach(char => {
                char.page = previousPage;
            });
        });

        idx = 1;
        this.pages.forEach(page => {
            page.order = idx;
            idx++;
        });

        previousPage.rows.push(...deletedPage.rows);
        previousRow.characters.at(-1)!.nextCharacter = deletedPage.rows[0].characters[0];
        deletedPage.rows[0].characters[0].previousCharacter = previousRow.characters.at(-1)!;
        if (nextPage) nextPage.rows[0].characters[0].previousCharacter = deletedPage.rows.at(-1)!.characters.at(-1)!;

        idx = 1;
        previousPage.rows.forEach(row => {
            row.order = idx;
            idx++;
        });

        /*if (this.pages.length % 2 != 0) {
            this.addEmptyPageAtEnd();
        }*/

        this.setCursorPositionToCharacter(deletedPage.rows[0].characters[0].nextCharacter!);
        if (deleteRow) this.deleteRow(true);
    }

    moveCursorRightTimes(times: number, fromKeyboard: boolean = true): void {
        for (let i = 0; i < times; i++) {
            this.moveCursorRight(fromKeyboard);
        }
    }

    moveCursorRight(fromKeyboard: boolean = true): void {
        let target = this.getCharacterAfterCursor();
        if (target) {
            if (target.previousCharacter?.owlName == 'pagebreak' && target.previousCharacter?.nextCharacter?.owlName == 'linebreak') {
                target = target.nextCharacter;
            }

            if (target) {
                this.setCursorPositionToCharacter(target!);
            }
        }
    }

    moveCursorLeftTimes(times: number, fromKeyboard: boolean = true): void {
        for (let i = 0; i < times; i++) {
            this.moveCursorLeft(fromKeyboard);
        }
    }

    moveCursorLeft(fromKeyboard: boolean = true): void {
        let target = this.getCharacterBeforeCursor();
        if (target) {
            if (target.owlName == 'linebreak' && target.previousCharacter?.owlName == 'pagebreak') {
                target = target.previousCharacter;
            }

            if (target) {
                this.setCursorPositionToCharacter(target!);
            }
        }
    }

    updateCursorPositionAfterDelete() {
        if ((this.cursorPosition.column! > this.pages[this.cursorPosition.page! - 1].rows[this.cursorPosition.row! - 1].characters.length)
            && !this.isCursorAtEnd()) {
            if (this.cursorPosition.page != null)
                this.cursorPosition.page++;
            this.cursorPosition.row = 1;
            this.cursorPosition.column = 1;
        }
    }

    setCursorPosition(page?: number, row?: number, column?: number) {
        this.cursorPosition.page = page;
        this.cursorPosition.row = row;
        this.cursorPosition.column = column;
    }

    setCursorPositionToCharacter(character: DisplayCharacter) {
        const page = character.page?.order;
        const row = character.row.order;
        const column = character.order;

        this.setCursorPosition(page, row, column);
    }
}
