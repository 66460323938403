import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Person } from 'src/app/models/jena/person';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
  selector: 'app-dialog-person-manage',
  templateUrl: './dialog-person-manage.component.html',
  styleUrls: ['./dialog-person-manage.component.scss']
})
export class DialogPersonManageComponent implements OnInit {
    public person: Person;
    public isLocked = false;
    public isEdit = false;

    constructor(
        private _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogPersonManageComponent>,) {
            this.person = this.data.person;
            this.isEdit = this.data.isEdit;
            this.isLocked = this.data.isLocked;
        }

    ngOnInit(): void {
    }

    public okClicked() {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: !this.isEdit ? 'Create new person' : 'Update person',
                infoMessage: !this.isEdit ? 'Are you sure you want to create a new person?' : 'Are you sure you want to update this person?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close(this.person);
            }
        });
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }

}
