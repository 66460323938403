import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { EntityService } from 'src/app/services/entity.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;

    public currentUser?: User;
    public entityId!: string | null;

    constructor(private router: Router,
        private _entityService: EntityService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _keycloakService: KeycloakService,
        private _authService: AuthService) {
        this._authService
            .getUser()
            .subscribe(result => {
                this.currentUser = result
            });
    }

    ngOnInit(): void {
    }

    public goToHome() {
        this.entityId = null;
        this._router.navigate(['']);
    }

    public goToNewEntity() {
        this.entityId = null;
        this._router.navigate(['new-entity']);
    }

    public goToNewUser() {
        this.entityId = null;
        this._router.navigate(['new-user']);
    }

    public goToListEntities() {
        this.entityId = null;
        this._router.navigate(['list-entities']);
    }

    public goToListUsers() {
        this.entityId = null;
        this._router.navigate(['list-users']);
    }

    public goToManageOwlItems() {
        this._router.navigate(['manage-owl']);
    }

    public goToHelp() {
        this.entityId = null;
        this._router.navigate(['help']);
    }

    public goToLogout() {
        localStorage.clear();

        this._keycloakService.logout().then(() => this._keycloakService.clearToken());
    }

    public getUserFullName() {
        if (Helpers.isNotNullOrUndefined(this.currentUser)) {
            return this.currentUser?.lastName + ' ' + this.currentUser?.firstName + ' ' + this.currentUser?.userName;
        }

        return '';
    }

    public canViewElement(element: string) {
        var elementView = Helpers.canViewElement(element, this.currentUser?.roles);

        return elementView;
    }

    public canViewProject() {
        this.entityId = this.getEntityId();
        let isProjectCreated = this.entityId != null;

        return isProjectCreated;
    }

    public goToListActors() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-actors/${this.entityId}`]);
    }

    public goToListDepartments() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-departments/${this.entityId}`]);
    }

    public goToListDevices() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-devices/${this.entityId}`]);
    }

    public goToListFaculties() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-faculties/${this.entityId}`]);
    }

    public goToListInstitutions() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-institutions/${this.entityId}`]);
    }

    public goToListManuses() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-manuses/${this.entityId}`]);
    }

    public goToListManuscripts() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-manuscripts/${this.entityId}`]);
    }

    public goToListPersons() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-persons/${this.entityId}`]);
    }

    public goToListSchools() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-schools/${this.entityId}`]);
    }

    public goToListTeams() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-teams/${this.entityId}`]);
    }

    public goToListTools() {
        this.entityId = this.getEntityId();
        this._router.navigate([`list-tools/${this.entityId}`]);
    }

    public showCharacterInfo() {
        this._blockUI.start();

        this.entityId = this.getEntityId();
        this._entityService.updateEntityByDefaultShowCharacterInfo(this.entityId!, true).subscribe(
            (result) => {
                this._blockUI.stop();
                this._snackBar.open('Updated successfully!', '', Helpers.getGreenSnackbarConfig());
                this.router.navigateByUrl('/edit-entity/' + this.entityId);
            },
            (error) => {
                this._blockUI.stop();
                console.log(error);
                this._snackBar.open('An error occurred!', '', Helpers.getRedSnackbarConfig());
            }
        );
    }

    private isValidGuid(guid: string | null): boolean {
        if (guid == null || guid.length == 0)
            return false;

        let regex = new RegExp("^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$");

        if (!regex.test(guid))
            return false;

        return true;
    }

    private getEntityId() {
        let urlLastPart = window.location.href.split('/').pop()!;

        if (!this.isValidGuid(urlLastPart))
            return null;

        return urlLastPart;
    }

    public exitApp() {
        window.open(window.location.pathname, '_parent', '')?.close();
    }
}
