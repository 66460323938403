import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { KeycloakRole } from '../models/keycloakRole';
import { KeycloakUser } from '../models/keycloakUser';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) {
    }

    routeParams: any;
    webApiUrl = environment.webApiUrl;
    headers: Headers | undefined;

    // -------------- //
    // GET METHODS    //
    // -------------- //

    public getKeycloakUsers(): Observable<Array<KeycloakUser>> {
        return this.http.get(this.webApiUrl + `/api/Users/GetKeycloakUsers`)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    public getKeycloakRolesOfUser(userId: string): Observable<Array<KeycloakRole>> {
        return this.http.get(this.webApiUrl + `/api/Users/GetKeycloakRolesOfUser?userId=${userId}`)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // POST METHODS   //
    // -------------- //

    public addKeycloakRoleToUser(userId: string, roleName: string): Observable<KeycloakRole> {
        return this.http.post(this.webApiUrl + `/api/Users/AddKeycloakRoleToUser?userId=${userId}&roleName=${roleName}`,
        null)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }

    // -------------- //
    // DELETE METHODS //
    // -------------- //

    public deleteKeycloakRoleFromUser(userId: string, roleName: string): Observable<Array<KeycloakRole>> {
        return this.http.delete(this.webApiUrl + `/api/Users/DeleteKeycloakRoleFromUser?userId=${userId}&roleName=${roleName}`)
            .pipe(
                map(
                    (result: any) => {
                        return result;
                    }
                )
            );
    }
}
