import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { GenericSubclass } from 'src/app/models/jena/genericClass';
import { Tool } from 'src/app/models/jena/tool';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { SparqlHelper } from 'src/app/utilities/sparqlHelper';

@Component({
  selector: 'app-dialog-tool-manage',
  templateUrl: './dialog-tool-manage.component.html',
  styleUrls: ['./dialog-tool-manage.component.scss']
})
export class DialogToolManageComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;
    public tool: Tool;
    public isLocked = false;
    public toolTypes?: Array<GenericSubclass>;
    public selectedToolType?: GenericSubclass;

    constructor(
        private _fusekiService: FusekiService,
        private _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogToolManageComponent>,) {
            this.tool = this.data.tool;
            this.entityId = this.data.entityId;

            if (!Helpers.isNullOrEmpty(this.tool.name!)) {
                this.isLocked = true;
            }

            this.getToolTypes();
        }

    ngOnInit(): void {
    }

    public getToolTypes() {
        this._blockUI.start();

        this._fusekiService.getSubclasses(this.entityId!, SparqlHelper.toolClass).subscribe({
            next: (result) => {
                this._blockUI.stop();
                this.toolTypes = result;
            },
            error: (error) => {
                console.log(error.error);
                this._blockUI.stop();
            }
        });
    }

    public okClicked() {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '420px',
            data: {
                titleMessage: 'Create new tool',
                infoMessage: 'Are you sure you want to create a new tool?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close(this.selectedToolType);
            }
        });
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }

}
