import {concat, debounceTime, Observable, Subject, tap} from "rxjs";

export type DeferredCall = {
    fn: (...args: any[]) => Observable<any>;
    context: any;
    args: any[];
};

export function DeferredExecute(deferred: DeferredCall) {
    return deferred.fn.call(deferred.context, ...deferred.args);
}

export class BatchManager {
    private static _instance: BatchManager;

    private _queue: DeferredCall[];
    private _trigger = new Subject<void>();
    private _onCompleteCallbacks: any[] = [];

    get hasQueued(): boolean {
        return this._queue.length > 0;
    }

    private constructor() {
        console.log('Initializing batch manager');
        this._queue = [];

        // Uncomment this to auto-save
        /*this._trigger.pipe(
            debounceTime(5000)
        ).subscribe(() => {
            this._executeDeferredCallsSequentially();
            this._queue = [];
        });*/
    }

    public static getInstance(): BatchManager {
        if (!BatchManager._instance) {
            BatchManager._instance = new BatchManager();
        }
        return BatchManager._instance;
    }

    public AddCallback(callback: any): void {
        this._onCompleteCallbacks.push(callback);
    }

    private _executeCallbacks(): void {
        this._onCompleteCallbacks.forEach((callback: any) => {
            callback.call();
        });
    }

    public Enqueue(call: DeferredCall) {
        console.log('Adding to queue: ' + call.fn.name);
        this._queue.push(call);
        this._trigger.next();
    }

    public ExecuteDeferredCallsSequentially(): void {
        console.log('Executing batched calls');
        const observables = this._queue.map(dc => dc.fn.apply(dc.context, dc.args));
        concat(...observables).subscribe({
            error: err => console.error(err),
            complete: () => {
                this._queue = [];
                this._executeCallbacks();
            }
        });
    }
}
