import { Department } from "../models/jena/department";
import { Faculty } from "../models/jena/faculty";
import { Institution } from "../models/jena/institution";
import { School } from "../models/jena/school";
import { Team } from "../models/jena/team";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class TeamSparql {
    public static getTeams(): string {
        var sparql = PREFIXES +
        `
        SELECT
        ?team
        ?department
        ?school
		?faculty
		?institution
        ?fullName
        ?shortName
        ?street
        ?streetNumber
        ?city
        ?state
        ?zipcode
        WHERE {
            ?team a ${SH.teamClass}.
            OPTIONAL{
              ?team ${SH.memberPartOfAtSomeTime} ?school.
              ?school a ${SH.schoolClass}
            }.
            OPTIONAL{
              ?team ${SH.memberPartOfAtSomeTime} ?faculty.
              ?faculty a ${SH.facultyClass}
            }.
            OPTIONAL{
              ?team ${SH.memberPartOfAtSomeTime} ?department.
              ?department a ${SH.departmentClass}
            }.
            OPTIONAL{
              ?team ${SH.memberPartOfAtSomeTime} ?institution.
              ?institution a ?subclass.
              ?subclass rdfs:subClassOf* ${SH.institutionClass}.
            }.
            OPTIONAL{?team ${SH.fullNameProperty} ?fullName}.
            OPTIONAL{?team ${SH.shortNameProperty} ?shortName}.
            OPTIONAL{?team ${SH.streetProperty} ?street}.
            OPTIONAL{?team ${SH.streetNumberProperty} ?streetNumber}.
            OPTIONAL{?team ${SH.cityProperty} ?city}.
            OPTIONAL{?team ${SH.stateProperty} ?state}.
            OPTIONAL{?team ${SH.zipCodeProperty} ?zipcode}.
            FILTER (STRSTARTS(str(?team), str(ent:)))
        }
        ORDER BY ?team
        `;

        return sparql;
    }

    public static createTeam(team: Team, faculty: Faculty, institution: Institution, department: Department, school: School): string {

        var sparql = PREFIXES +
        `
        INSERT {
            ?new a ${SH.teamClass};
                ${institution != null ? `${SH.memberPartOfAtSomeTime} <${institution.name}>;` : ''}
                ${department != null ? `${SH.memberPartOfAtSomeTime} <${department.name}>;` : ''}
                ${faculty != null ? `${SH.memberPartOfAtSomeTime} <${faculty.name}>;` : ''}
                ${school != null ? `${SH.memberPartOfAtSomeTime} <${school.name}>;` : ''}
                ${team.fullName != null ? `${SH.fullNameProperty} "` + team.fullName + `";` : ''}
                ${team.shortName != null ? `${SH.shortNameProperty} "` + team.shortName + `";` : ''}
                ${team.street != null ? `${SH.streetProperty} "` + team.street + `";` : ''}
                ${team.streetNumber != null ? `${SH.streetNumberProperty} "` + team.streetNumber + `";` : ''}
                ${team.city != null ? `${SH.cityProperty} "` + team.city + `";` : ''}
                ${team.state != null ? `${SH.stateProperty} "` + team.state + `";` : ''}
                ${team.zipcode != null ? `${SH.zipCodeProperty} "` + team.zipcode + `";` : ''}
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'team',STR(MAX(?res) + 1))) as ?new)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{
                            ?z a ${SH.teamClass}.
                            FILTER (STRSTARTS(str(?z), str(ent:)))
                        }.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + 'team', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.teamClass}.
                                FILTER (STRSTARTS(str(?s), str(ent:)))
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static updateTeam(team: Team, faculty: Faculty, institution: Institution, department: Department, school: School): string {
        var sparql = PREFIXES +
        `
        DELETE {?ent ?x ?y}
        WHERE {
            ?ent ?x ?y.
            FILTER(?ent = IRI("${team.name}"))
            FILTER(?y != ${SH.teamClass})
        };

        INSERT {
            ${institution != null ? `?x ${SH.memberPartOfAtSomeTime} <${institution.name}>.` : ''}
            ${department != null ? `?x ${SH.memberPartOfAtSomeTime} <${department.name}>.` : ''}
            ${faculty != null ? `?x ${SH.memberPartOfAtSomeTime} <${faculty.name}>.` : ''}
            ${school != null ? `?x ${SH.memberPartOfAtSomeTime} <${school.name}>.` : ''}
            ${team.fullName != null ? `?x ${SH.fullNameProperty} "` + team.fullName + `".` : ''}
            ${team.shortName != null ? `?x ${SH.shortNameProperty} "` + team.shortName + `".` : ''}
            ${team.street != null ? `?x ${SH.streetProperty} "` + team.street + `".` : ''}
            ${team.streetNumber != null ? `?x ${SH.streetNumberProperty} "` + team.streetNumber + `".` : ''}
            ${team.city != null ? `?x ${SH.cityProperty} "` + team.city + `".` : ''}
            ${team.state != null ? `?x ${SH.stateProperty} "` + team.state + `".` : ''}
            ${team.zipcode != null ? `?x ${SH.zipCodeProperty} "` + team.zipcode + `".` : ''}
        }
        WHERE {
            ?x a ${SH.teamClass}.
            FILTER(?x = IRI("${team.name}"))
        };
        `;

        return sparql;
    }

    public static deleteTeam(team: Team): string {
        var sparql = PREFIXES +
        `
        DELETE { ?x ?y ?z }
        WHERE {
        ?x a ${SH.teamClass}.
        ?x ?y ?z.
        FILTER(?x = IRI("${team.name}"))
        }
        `;

        return sparql;
    }

}
