<div class="container" *ngIf="selectedOverallInfo">
    <div class="item">
        <div class="itemTitle">
            <strong>Process</strong>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Transcription: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo ? selectedOverallInfo!.transcriptionName : ''}}</div>
        </div>
    </div>
    <div class="item">
        <div class="itemTitle">
            <strong>Annotator</strong>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailValue">{{selectedOverallInfo ? selectedOverallInfo!.annotatorName : ''}}</div>
        </div>
    </div>
    <div class="item">
        <div class="itemTitle">
            <strong>Character derivation</strong>
        </div>
        <!--<div class="itemContent">
            <div class="itemContentDetailTitle">Manuscript: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo ? selectedOverallInfo!.manuscriptName : ''}}</div>
        </div>-->
        <div class="itemContent">
            <div class="itemContentDetailTitle">Manus: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo ? selectedOverallInfo!.manusName : ''}}</div>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Stroke(s): </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo ? selectedOverallInfo!.strokeName : ''}}</div>
        </div>
    </div>
    <div class="item">
        <div class="itemTitle">
            <strong>Style in MS</strong>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Case style: </div>
            <div class="itemContentDetailValue"></div>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Color: </div>
            <div class="itemContentDetailValue"></div>
        </div>
    </div>
    <div class="item">
        <div class="itemTitle">
            <strong>Location in MS</strong>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Character Order: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo.columnOrder}}</div>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Area: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo.areaName}}</div>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Row order: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo.rowOrder}}</div>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Row name: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo.rowName}}</div>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Side order: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo.pageOrder}}</div>
        </div>
        <div class="itemContent">
            <div class="itemContentDetailTitle">Side name: </div>
            <div class="itemContentDetailValue">{{selectedOverallInfo.pageName}}</div>
        </div>
    </div>
</div>
