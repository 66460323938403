<block-ui></block-ui>
<h2 mat-dialog-title>Edit Owl Item: {{navItem.displayName}}</h2>

<div mat-dialog-content class="content">
    <div fxLayout="row" class="item">
       <div class="itemTitle">Name: </div>
       <div class="itemValue">{{ navItem.displayName }}</div>
    </div>
    <div fxLayout="row" class="item">
       <div class="itemTitle">Id: </div>
       <div class="itemValue">{{ navItem.id }}</div>
    </div>
    <div fxLayout="row" class="item">
        <div class="itemTitle">Disabled: </div>
        <div class="itemValue"><mat-checkbox [(ngModel)]="navItem.disabled"></mat-checkbox></div>
    </div>
    <div fxLayout="row" class="item">
        <div class="itemTitle">Is Root: </div>
        <div class="itemValue"><mat-checkbox [(ngModel)]="navItem.isRoot"></mat-checkbox></div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()" >Cancel</button>
</div>
