import { Actor } from "../models/jena/actor";
import { Manus } from "../models/jena/manus";
import { Tool } from "../models/jena/tool";
import { Helpers } from "../utilities/helpers";
import { SparqlHelper } from "../utilities/sparqlHelper";

const PREFIXES = Helpers.PREFIXES;
const SH = SparqlHelper;

export class ManusSparql {
    public static createManus(actor: Actor, tool: Tool): string {
        var sparql = PREFIXES +
        `
        INSERT {
            ?newManus a ${SH.manusClass};
    	        ${SH.genericallyDependsOnAtSomeTime} ?tool;
    	        ${SH.continuantPartOfAtSomeTime} ?act
        }
        WHERE {
            SELECT (IRI(CONCAT(STR(ent:),'m',STR(MAX(?res) + 1))) as ?newManus)
            (IRI('${tool.name}') as ?tool)
            (IRI('${actor.name}') as ?act)
            WHERE {
                {
                    SELECT ?column ?counter
                    WHERE {
                        OPTIONAL{?z a ${SH.manusClass}}.
                        BIND(xsd:integer(REPLACE(STR(?z), STR(ent:) + 'm', '')) as ?column).
                        {
                            SELECT (count(?s) as ?counter)
                            WHERE {
                                ?s a ${SH.manusClass}
                            }
                        }
                    }
                }.
                BIND(if(?counter=xsd:integer(0),?counter,?column)  as ?res)
            }
        };
        `;

        return sparql;
    }

    public static getManuses(): string {
        var sparql = PREFIXES +
        `
        SELECT ?manus ?tool ?actor
        WHERE {
            ?manus a ${SH.manusClass};
                ${SH.genericallyDependsOnAtSomeTime} ?tool;
                ${SH.continuantPartOfAtSomeTime} ?actor
        }
        ORDER BY ?manus
        `;

        return sparql;
    }

    public static deleteManus(manus: Manus): string {
        var sparql = PREFIXES +
        `
        DELETE { ?x ?y ?z }
        WHERE {
        ?x a ${SH.manusClass}.
        ?x ?y ?z.
        FILTER(?x = IRI("${manus.name}"))
        }
        `;

        return sparql;
    }
}
