import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NavItem } from 'src/app/models/navItem';
import { OwlService } from 'src/app/services/owl.service';

@Component({
    selector: 'app-dialog-edit-owl-item',
    templateUrl: './dialog-edit-owl-item.component.html',
    styleUrls: ['./dialog-edit-owl-item.component.scss']
})
export class DialogEditOwlItemComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;
    public navItem!: NavItem;

    public administrator_role = false;
    public basic_role = false;
    public guest_role = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogEditOwlItemComponent>,
        private _owlService: OwlService,
        private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        this.navItem = this.data.navItem;
    }

    public okClicked() {
        this.dialogRef.close(this.navItem);
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }
}
