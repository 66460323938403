import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-new-entity',
    templateUrl: './new-entity.component.html',
    styleUrls: ['./new-entity.component.scss']
})
export class NewEntityComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
}
