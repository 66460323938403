import { OverallInfo } from "src/app/models/overallInfo";

export interface IUIState {
    overallInfo: OverallInfo | null
}

export const initialUIState: IUIState = {
    overallInfo: null
};

