import { DisplayCharacter } from "./displayCharacter";

export class DisplayRow {
    owlName: string;
    order: number;
    characters: Array<DisplayCharacter>;

    get lengthWithoutVirtuals(): number {
        return this.characters.filter(x => !x.isVirtual).length;
    }

    get length(): number {
        return this.characters.length;
    }

    constructor(owlName: string, order: number, characters: Array<DisplayCharacter>) {
        this.owlName = owlName;
        this.order = order;
        this.characters = characters;
    }

    getLastCharacter(ignoreVirtuals: boolean = false): DisplayCharacter {
        if (ignoreVirtuals) {
            let filtered = this.characters.filter(x => !x.isVirtual);
            return filtered[filtered.length - 1]
        } else {
            return this.characters[this.length - 1];
        }
    }

    addCharacterBefore(character: DisplayCharacter, position: number) {
        character.nextCharacter = this.characters[position];

        let previous = this.characters[position].previousCharacter;
        if (previous) {
            previous.nextCharacter = character;
        }

        this.characters.splice(position, 0, character);
    }
}
