import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NavItem } from 'src/app/models/navItem';
import { OwlService } from 'src/app/services/owl.service';
import { Helpers } from 'src/app/utilities/helpers';

@Component({
    selector: 'app-dialog-add-owl-item',
    templateUrl: './dialog-add-owl-item.component.html',
    styleUrls: ['./dialog-add-owl-item.component.scss']
})
export class DialogAddOwlItemComponent implements OnInit {
    @BlockUI() blockUI!: NgBlockUI;
    public parentNavItem!: NavItem;
    public newNavItem!: NavItem;

    public administrator_role = false;
    public basic_role = false;
    public guest_role = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogAddOwlItemComponent>,
        private _owlService: OwlService,
        private _snackBar: MatSnackBar) {
            this.parentNavItem = this.data.navItem;

            this.newNavItem = {} as NavItem;
            if (Helpers.isNotNullOrUndefined(this.data.navItem)) {
                this.newNavItem.parentId = this.parentNavItem.id;
            }
            this.newNavItem.disabled = false;
            this.newNavItem.isRoot = false;
        }

    ngOnInit(): void {
    }

    public okClicked() {
        this.dialogRef.close(this.newNavItem);
    }

    public cancelClicked() {
        this.dialogRef.close(null);
    }
}
