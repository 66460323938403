import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Device } from 'src/app/models/jena/device';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogDeviceManageComponent } from './dialog-device-manage/dialog-device-manage.component';

@Component({
    selector: 'app-device-list',
    templateUrl: './device-list.component.html',
    styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    displayedColumns: string[] = ['name', 'subclassName', 'label', 'genericName', 'siglum', 'actions'];
    dataSource: MatTableDataSource<Device>;
    public devices: Array<Device>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar) {
        // Get the id from the url
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.dataSource = new MatTableDataSource();
        this.devices = new Array<Device>();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getDevices(this.entityId!);
        }
    }

    private _getDevices(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getDevices(datasetName).subscribe({
            next: (result: Array<Device>) => {
                this.devices = result;
                this.dataSource.data = this.devices;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public new() {
        const dialogRef = this._dialog.open(DialogDeviceManageComponent, {
            width: '800px',
            data: {
                device: new Device(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.createDevice(this.entityId!, result).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new device', '', Helpers.getGreenSnackbarConfig());
                            this._getDevices(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while creating new device', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public delete(device: Device) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '480px',
            data: {
                titleMessage: `Delete device ${device.name}`,
                infoMessage: 'Are you sure you want to delete this device?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteDevice(this.entityId!, device).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted device', '', Helpers.getGreenSnackbarConfig());
                            this._getDevices(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting device', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public getNameWithoutUri(name: string): string {
        return name.replace(Helpers.ent_prefix_uri, '');
    }
}
