import { Actor } from "../models/jena/actor";
import { AuxiliaryItem } from "../models/jena/auxiliaryItem";
import { AuxiliaryRoot } from "../models/jena/auxiliaryRoot";
import { EntityColumn } from "../models/jena/column";
import { Department } from "../models/jena/department";
import { Device } from "../models/jena/device";
import { EntityPage } from "../models/jena/entityPage";
import { EntityRoot } from "../models/jena/entityRoot";
import { EntityRow } from "../models/jena/entityRow";
import { Faculty } from "../models/jena/faculty";
import { GenericSubclass } from "../models/jena/genericClass";
import { Institution } from "../models/jena/institution";
import { Manus } from "../models/jena/manus";
import { Manuscript } from "../models/jena/manuscript";
import { Person } from "../models/jena/person";
import { PersonRole } from "../models/jena/personRole";
import { School } from "../models/jena/school";
import { Team } from "../models/jena/team";
import { Tool } from "../models/jena/tool";
import { Triplet } from "../models/jena/triplet";
import { Helpers } from "./helpers";

export class ModelHelper {
    public static bindResultToActors(bindings: any): Array<Actor> {
        var individuals = new Array<Actor>;

        bindings.forEach((element: any) => {
            var individual = new Actor();
            individual.name = element.actor.value;

            var person = new Person();
            person.name = element.person.value;

            individual.person = person;

            var personRole = new PersonRole();
            personRole.name = element.personRole.value;
            personRole.label = element.label.value;

            individual.personRole = personRole;
            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToTools(bindings: any): Array<Tool> {
        var individuals = new Array<Tool>;

        bindings.forEach((element: any) => {
            var individual = new Tool();
            individual.name = element.tool.value;

            var subclass = new GenericSubclass();
            subclass.name = element.subclass.value;
            subclass.label = element.label.value;

            individual.subclass = subclass;
            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToDevices(bindings: any): Array<Device> {
        var individuals = new Array<Device>;

        bindings.forEach((element: any) => {
            var individual = new Device();
            individual.name = element.device.value;
            individual.genericName = element.genericName?.value;
            individual.siglum = element.siglum?.value;

            var subclass = new GenericSubclass();
            subclass.name = element.subclass.value;
            subclass.label = element.label.value;

            individual.subclass = subclass;
            individuals.push(individual);
        });

        return individuals;
    }

    public static bindResultToPersons(bindings: any): Array<Person> {
        var individuals = new Array<Person>;

        bindings.forEach((element: any) => {
            var individual = new Person();
            individual.name = element.person.value;
            individual.firstName = element.firstName?.value;
            individual.middleName = element.middleName?.value;
            individual.lastName = element.lastName?.value;
            individual.genericName = element.genericName?.value;
            individual.nickName = element.nickName?.value;
            individual.dayOfBirth = element.dayOfBirth?.value;
            individual.monthOfBirth = element.monthOfBirth?.value;
            individual.yearOfBirth = element.yearOfBirth?.value;
            individual.dayOfDeath = element.dayOfDeath?.value;
            individual.monthOfDeath = element.monthOfDeath?.value;
            individual.yearOfDeath = element.yearOfDeath?.value;
            individual.approximateDateOfBirthFrom = element.approximateDateOfBirthFrom?.value;
            individual.approximateDateOfBirthTo = element.approximateDateOfBirthTo?.value;
            individual.approximateDateOfBirthBy = element.approximateDateOfBirthBy?.value;
            individual.approximateDateOfDeathFrom = element.approximateDateOfDeathFrom?.value;
            individual.approximateDateOfDeathTo = element.approximateDateOfDeathTo?.value;
            individual.approximateDateOfDeathBy = element.approximateDateOfDeathBy?.value;
            individual.flourishedFrom = element.flourishedFrom?.value;
            individual.flourishedTo = element.flourishedTo?.value;
            individual.flourishedBy = element.flourishedBy?.value;
            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToDepartments(bindings: any): Array<Department> {
        var individuals = new Array<Department>;

        bindings.forEach((element: any) => {
            var individual = new Department();
            individual.name = element.department.value;
            individual.fullName = element.fullName?.value;
            individual.shortName = element.shortName?.value;
            individual.street = element.street?.value;
            individual.streetNumber = element.streetNumber?.value;
            individual.city = element.city?.value;
            individual.state = element.state?.value;
            individual.zipcode = element.zipcode?.value;

            var institution = new Institution();
            institution.name = element.institution?.value;

            individual.institution = institution;

            var school = new School();
            school.name = element.school?.value;

            individual.school = school;

            var faculty = new Faculty();
            faculty.name = element.faculty?.value;

            individual.faculty = faculty;

            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToFaculties(bindings: any): Array<Faculty> {
        var individuals = new Array<Faculty>;

        bindings.forEach((element: any) => {
            var individual = new Faculty();
            individual.name = element.faculty.value;
            individual.fullName = element.fullName?.value;
            individual.shortName = element.shortName?.value;
            individual.street = element.street?.value;
            individual.streetNumber = element.streetNumber?.value;
            individual.city = element.city?.value;
            individual.state = element.state?.value;
            individual.zipcode = element.zipcode?.value;

            var institution = new Institution();
            institution.name = element.institution?.value;

            individual.institution = institution;

            var school = new School();
            school.name = element.school?.value;

            individual.school = school;

            var department = new Department();
            department.name = element.department?.value;

            individual.department = department;
            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToInstitutions(bindings: any): Array<Institution> {
        var individuals = new Array<Institution>;

        bindings.forEach((element: any) => {
            var individual = new Institution();
            individual.name = element.institution.value;
            individual.fullName = element.fullName?.value;
            individual.shortName = element.shortName?.value;
            individual.street = element.street?.value;
            individual.streetNumber = element.streetNumber?.value;
            individual.city = element.city?.value;
            individual.state = element.state?.value;
            individual.zipcode = element.zipcode?.value;

            var subclass = new GenericSubclass();
            subclass.name = element.subclass.value;
            subclass.label = element.label.value;

            individual.subclass = subclass;

            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToSchools(bindings: any): Array<School> {
        var individuals = new Array<School>;

        bindings.forEach((element: any) => {
            var individual = new School();
            individual.name = element.school.value;
            individual.fullName = element.fullName?.value;
            individual.shortName = element.shortName?.value;
            individual.street = element.street?.value;
            individual.streetNumber = element.streetNumber?.value;
            individual.city = element.city?.value;
            individual.state = element.state?.value;
            individual.zipcode = element.zipcode?.value;

            var institution = new Institution();
            institution.name = element.institution.value;

            individual.institution = institution;

            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToTeams(bindings: any): Array<Team> {
        var individuals = new Array<Team>;

        bindings.forEach((element: any) => {
            var individual = new Team();
            individual.name = element.team.value;
            individual.fullName = element.fullName?.value;
            individual.shortName = element.shortName?.value;
            individual.street = element.street?.value;
            individual.streetNumber = element.streetNumber?.value;
            individual.city = element.city?.value;
            individual.state = element.state?.value;
            individual.zipcode = element.zipcode?.value;

            var institution = new Institution();
            institution.name = element.institution?.value;

            individual.institution = institution;

            var school = new School();
            school.name = element.school?.value;

            individual.school = school;

            var department = new Department();
            department.name = element.department?.value;

            individual.department = department;

            var faculty = new Faculty();
            faculty.name = element.faculty?.value;

            individual.faculty = faculty;

            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToManuses(bindings: any): Array<Manus> {
        var individuals = new Array<Manus>;

        bindings.forEach((element: any) => {
            var individual = new Manus();
            individual.name = element.manus.value;

            var actor = new Actor();
            actor.name = element.actor.value;

            var tool = new Tool();
            tool.name = element.tool.value;

            individual.actor = actor;
            individual.tool = tool;
            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToManuscripts(bindings: any): Array<Manuscript> {
        var individuals = new Array<Manuscript>;

        bindings.forEach((element: any) => {
            var individual = new Manuscript();
            individual.name = element.manuscript.value;
            individual.genericName = element.genericName.value;
            individual.siglum = element.siglum.value;

            var device = new Device();
            device.name = element.device.value;

            individual.device = device;
            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToManuscriptXML(bindings: any): Array<Triplet> {
        var triplets = new Array<Triplet>();

        bindings.forEach((element: any) => {
            let triplet = new Triplet();

            triplet.s = element.s.value;
            triplet.p = element.p.value;
            triplet.o = element.o.value;

            triplets.push(triplet);
        });

        return triplets;
    }

    public static bindResultToGenericSubclasses(bindings: any): Array<GenericSubclass> {
        var individuals = new Array<GenericSubclass>;

        bindings.forEach((element: any) => {
            var individual = new GenericSubclass();
            individual.name = element.subclass.value;
            individual.label = element.label.value;
            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToPersonRoles(bindings: any): Array<PersonRole> {
        var individuals = new Array<PersonRole>;

        bindings.forEach((element: any) => {
            var individual = new PersonRole();
            individual.name = element.personRole.value;
            individual.label = element.label.value;
            individuals.push(individual);
        });
        return individuals;
    }

    public static bindResultToSentences(bindings: any): { sentence: any; wordIndex: any }[] {
        const sentenceToWordMappings: { sentence: any; wordIndex: any; }[] = [];

        bindings.forEach((element: any) => {
            var mapping = {
                'sentence': element.sentence.value,
                'wordIndex': element.wordIndex.value
            }

            sentenceToWordMappings.push(mapping);
        });

        return sentenceToWordMappings;
    }

    public static bindResultToEntityRoot(bindings: any): EntityRoot {
        var pages = new Array<EntityPage>();
        var pageIndex = 0;

        var rows = new Array<EntityRow>();
        var rowIndex = 0;

        var columnName = ''

        bindings.forEach((element: any) => {
            var newPageIndex = +element.sideTypeOrder.value;

            if (newPageIndex != pageIndex) {
                var page = new EntityPage();
                page.name = element.sideType.value.split('#')[1];
                page.rows = new Array<EntityRow>;
                page.order = newPageIndex;
                page.areaName = element.area.value.split('#')[1];
                pages.push(page);

                rowIndex = 0;
                rows = new Array<EntityRow>();

                pageIndex = newPageIndex;
            }

            if (Helpers.isNotNullOrUndefined(element.row)) {
                var newRowIndex = +element.rowOrder.value;

                if (newRowIndex != rowIndex) {
                    var row = new EntityRow();
                    row.name = element.row.value.split('#')[1];
                    row.columns = new Array<EntityColumn>;
                    row.order = newRowIndex;

                    pages[newPageIndex - 1].rows.push(row);

                    rowIndex = newRowIndex;
                }

                if (Helpers.isNotNullOrUndefined(element.column)) {
                    var utfCode = 0x23FF; // a.k.a. "⍉"

                    if (Helpers.isNotNullOrUndefined(element.letterDescription)) {
                        if (element.letterDescription.value === 'empty set') {
                            utfCode = 0x25E6;
                        } else {
                            utfCode = parseInt(element.utf.value.toString().replace('U+', '0x'));
                        }
                    } else {
                        utfCode = 0x25E6;
                    }

                    if (columnName !== element.column.value) {
                        var column = new EntityColumn({
                            name: element.column.value,
                            character: element.character.value,
                            order: +element.columnOrder.value,
                            letterDescription: element.letterDescription != undefined ? element.letterDescription.value : 'whitespace',
                            utfCode: utfCode,
                            inRow: new Array<EntityRow>(),
                            over: new Array<EntityRow>(),
                            above: new Array<EntityRow>(),
                            below: new Array<EntityRow>(),
                            strokeName: element.stroke.value.split('#')[1],
                            transcriptionName: element.transcription != undefined ? element.transcription.value.split('#')[1] : null,
                            manusName: element.manus != undefined ? element.manus.value.split('#')[1] : null,
                            annotatorName: element.actor != undefined ? element.actor.value.split('#')[1] : null,
                            manuscriptName: element.manuscript != undefined ? element.manuscript.value.split('#')[1] : null,
                            word: element.wordIndex ? Number.parseInt(element.wordIndex.value) : undefined
                        });

                        if (Helpers.isNotNullOrUndefined(element.rowInRow)) {
                            var inRow = new EntityRow();
                            inRow.name = element.rowInRow.value;
                            column.inRow?.push(inRow);
                        }

                        if (Helpers.isNotNullOrUndefined(element.rowOver)) {
                            var over = new EntityRow();
                            over.name = element.rowOver.value;
                            column.over?.push(over);
                        }

                        if (Helpers.isNotNullOrUndefined(element.rowAbove)) {
                            var above = new EntityRow();
                            above.name = element.rowAbove.value;
                            column.above?.push(above);
                        }

                        if (Helpers.isNotNullOrUndefined(element.rowBelow)) {
                            var below = new EntityRow();
                            below.name = element.rowBelow.value;
                            column.below?.push(below);
                        }

                        pages[newPageIndex - 1].rows[newRowIndex - 1].columns.push(column);

                        columnName = element.column.value;
                    } else {
                        var column = pages[newPageIndex - 1].rows[newRowIndex - 1].columns.filter(i => i.order === +element.columnOrder.value)[0];

                        if (Helpers.isNotNullOrUndefined(element.rowInRow)) {
                            var inRow = new EntityRow();
                            inRow.name = element.rowInRow.value;
                            column.inRow?.push(inRow);
                        }

                        if (Helpers.isNotNullOrUndefined(element.rowOver)) {
                            var over = new EntityRow();
                            over.name = element.rowOver.value;
                            column.over?.push(over);
                        }

                        if (Helpers.isNotNullOrUndefined(element.rowAbove)) {
                            var above = new EntityRow();
                            above.name = element.rowAbove.value;
                            column.above?.push(above);
                        }

                        if (Helpers.isNotNullOrUndefined(element.rowBelow)) {
                            var below = new EntityRow();
                            below.name = element.rowBelow.value;
                            column.below?.push(below);
                        }
                    }
                }
            }
        });

        var root = new EntityRoot();
        root.pages = pages;

        return root;
    }

    public static bindResultToAuxiliaryRoot(bindings: any): AuxiliaryRoot {
        var items = new Array<AuxiliaryItem>();
        var itemIndex = 0;

        var rows = new Array<EntityRow>();
        var rowIndex = 0;

        var currentStroke = '';

        bindings.forEach((element: any) => {
            var stroke = element.stroke;
            var item: AuxiliaryItem;

            if (currentStroke !== stroke.value) {
                currentStroke = stroke.value;
                itemIndex++;

                item = new AuxiliaryItem();
                item.rows = new Array<EntityRow>();
                item.stroke = currentStroke.split('#')[1];
                item.letterDescriptionOfOrigin = element.letterDescriptionOfOrigin.value
                item.utfCodeOfOrigin = parseInt(element.utfOfOrigin.value.toString().replace('U+', '0x'));

                rowIndex = 0;
                rows = new Array<EntityRow>();

                items.push(item);
            }

            if (Helpers.isNotNullOrUndefined(element.row)) {
                var newRowIndex = +element.rowOrder.value;

                if (newRowIndex != rowIndex) {
                    var row = new EntityRow();
                    row.name = element.row.value.split('#')[1];
                    row.columns = new Array<EntityColumn>;
                    row.order = element.rowOrder.value;

                    items[itemIndex - 1].rows.push(row);

                    rowIndex = newRowIndex;
                }

                if (Helpers.isNotNullOrUndefined(element.column)) {
                    var utfCode = 0x23FF; // a.k.a. "⍉"

                    if (Helpers.isNotNullOrUndefined(element.letterDescription)) {
                        if (element.letterDescription.value === 'empty set') {
                            utfCode = 0x25E6;
                        } else {
                            utfCode = parseInt(element.utf.value.toString().replace('U+', '0x'));
                        }
                    } else {
                        utfCode = 0x25E6;
                    }

                    var column = new EntityColumn({
                        name: element.column.value,
                        order: +element.columnOrder.value,
                        letterDescription: element.letterDescription != undefined ? element.letterDescription.value : 'whitespace',
                        utfCode: utfCode
                    });

                    items[itemIndex - 1].rows[newRowIndex - 1].columns.push(column);
                }
            }
        });


        var root = new AuxiliaryRoot();
        root.items = items;

        return root;
    }
}
