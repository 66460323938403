<block-ui></block-ui>
<h2 mat-dialog-title>{{ isLocked ? 'View Tool' : 'New Tool' }} </h2>

<div mat-dialog-content class="content">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Select tool type:</mat-label>
        <mat-select [(ngModel)]="selectedToolType">
            <mat-option *ngFor="let toolType of toolTypes" [value]="toolType">{{ toolType.label }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()">Cancel</button>
</div>
