<div class="board-container" *blockUI="'board-container'">
    <div class="toolbar-container">
        <div class="toolbar-element">
            <mat-form-field>
                <mat-label>Project Name</mat-label>
                <input matInput [(ngModel)]="_entity.title" (focusout)="onProjectNameFocusOut($event)">
            </mat-form-field>
        </div>
        <div class="toolbar-element">
            <mat-form-field>
                <mat-label>Mode</mat-label>
                <mat-select [(value)]="editorMode">
                    <mat-option value="transcription">Transcription</mat-option>
                    <mat-option value="annotation">Annotation</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="toolbar-element">
            <mat-form-field>
                <mat-label>Group Level</mat-label>
                <mat-select [(value)]="annotationLevel" [disabled]="editorMode == 'transcription'">
                    <mat-option class="word-hover" value="word">Word</mat-option>
                    <mat-option class="sentence-hover" value="sentence">Sentence</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="toolbar-element" style="flex: 1 1;"></div>
        <div class="toolbar-element">
            <button *ngIf="editorMode == 'annotation'" mat-flat-button color="primary"
                    (click)="annotate()"
                    [disabled]="!canGroup()">Group</button>
            <button *ngIf="editorMode == 'annotation'" mat-flat-button color="accent"
                    (click)="saveAnnotations()"
                    [disabled]="annotationService.words.length == 0">Save annotations</button>
            <button *ngIf="editorMode == 'annotation'" mat-flat-button color="primary" (click)="openAutopunctuationDialog()">Auto-punctuate</button>
            <button *ngIf="editorMode == 'transcription'" mat-flat-button color="accent"
                    (click)="saveTranscription()"
                    [disabled]="!hasChanges">Save</button>
        </div>
    </div>
    <div class="area-container">
        <div class="left">
            <mat-expansion-panel class="headerPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Header
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-dela-editor #headerEditor [datasetName]="_entity.id" [windowArea]="2" [height]="'80'"
                        [entity]="_entity" [mode]="editorMode" [annotationLevel]="annotationLevel"
                        (onLinkClick)="onLinkClick($event)"></app-dela-editor>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="footerPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Footer
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-dela-editor #footerEditor [datasetName]="_entity.id" [windowArea]="3" [height]="'80'"
                        [entity]="_entity" [mode]="editorMode" [annotationLevel]="annotationLevel"
                        (onLinkClick)="onLinkClick($event)"></app-dela-editor>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="innerMarginPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Inner Margin
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-dela-editor #innerMarginEditor [datasetName]="_entity.id" [windowArea]="4" [height]="'80'"
                        [entity]="_entity" [mode]="editorMode" [annotationLevel]="annotationLevel"
                        (onLinkClick)="onLinkClick($event)"></app-dela-editor>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="outerMarginPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Outer Margin
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-dela-editor #outerMarginEditor [datasetName]="_entity.id" [windowArea]="5" [height]="'80'"
                        [entity]="_entity" [mode]="editorMode" [annotationLevel]="annotationLevel"
                        (onLinkClick)="onLinkClick($event)"></app-dela-editor>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="center">
            <mat-expansion-panel class="mainPanel" expanded hideToggle disabled>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Main Area
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-dela-editor #mainEditor [datasetName]="_entity.id" [windowArea]="1" [height]="'200'"
                        [entity]="_entity" [mode]="editorMode" [annotationLevel]="annotationLevel"
                        (onLinkClick)="onLinkClick($event)"></app-dela-editor>
                </div>
            </mat-expansion-panel>
            <div class="centerBottom">
                <mat-expansion-panel class="abovePanel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Interlinear Space
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <app-dela-editor #interlinearEditor [datasetName]="_entity.id" [windowArea]="6" [height]="'80'"
                            [entity]="_entity" [mode]="editorMode" [annotationLevel]="annotationLevel"
                            (onLinkClick)="onLinkClick($event)"></app-dela-editor>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="belowPanel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Intercolumnar Space
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <app-dela-editor #intercolumnarEditor [datasetName]="_entity.id" [windowArea]="7"
                            [height]="'80'" [entity]="_entity" [mode]="editorMode" [annotationLevel]="annotationLevel"
                            (onLinkClick)="onLinkClick($event)"></app-dela-editor>
                    </div>
                </mat-expansion-panel>
            </div>
        </div>
        <div class="right">
            <mat-expansion-panel class="headerPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Overall Info
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-overall-info></app-overall-info>
            </mat-expansion-panel>
            <mat-expansion-panel class="headerPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Variant
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>The variant section</div>
            </mat-expansion-panel>
            <mat-expansion-panel class="headerPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        In row
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-auxiliary-editor #inRow [datasetName]="_entity.id" [auxiliaryArea]="1" [height]="'80'"
                        [entity]="_entity"></app-auxiliary-editor>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="headerPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Over
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-auxiliary-editor #over [datasetName]="_entity.id" [auxiliaryArea]="2" [height]="'80'"
                        [entity]="_entity"></app-auxiliary-editor>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="headerPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Above
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-auxiliary-editor #above [datasetName]="_entity.id" [auxiliaryArea]="3" [height]="'80'"
                        [entity]="_entity"></app-auxiliary-editor>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="headerPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Below
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-auxiliary-editor #below [datasetName]="_entity.id" [auxiliaryArea]="4" [height]="'80'"
                        [entity]="_entity"></app-auxiliary-editor>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>
