<div style="padding: 20px;">
    <p>Η πλατφόρμα Manuscribus έχει αναπτυχθεί ως ολοκληρωμένο σύστημα διαχείρισης που τρέχει σε διαδικτυακό
    περιβάλλον και αποτελείται από ένα σύνολο λογισμικών εργαλείων τα οποία και σχεδιάστηκαν για να διευκολύνουν
    την αλληλεπίδραση, τη συνεργασία, τη διαχείριση και την ανταλλαγή πληροφοριών που εισάγονται από τον χρήστη στο
    σύστημα. Ως αποτέλεσμα, η πλατφόρμα Manuscribus επιτρέπει χάρη στον σχεδιασμό της να εκτελούνται πολύπλοκες
    λειτουργίες, όπως η μεταγραφή, η επισημείωση χειρογράφων και τελικά η αυτοματοποιημένη σύγκρισή τους.</p>

    <p>Η αυτοματοποιημένη σύγκριση των χειρογράφων επιτρέπει την επεξεργασία πολύπλοκων δεδομένων και ενισχύει την
    ακρίβεια των κριτικών εκδόσεων για τον καθορισμό της πιο αξιόπιστης ή αυθεντικής εκδοχής ενός έργου και για την
    προώθηση της ακαδημαϊκής κατανόησης της χειρογραφικής παράδοσης και της κειμενικής ιστορίας.</p>

    <p>Για να ξεκινήσετε, το λογισμικό υποστηρίζει τη δημιουργία Project, που αντιστοιχεί σε ένα αρχείο μεταγραφής το οποίο
    δημιουργείται ώστε να αποθηκεύσει την εργασία σας ανά χειρόγραφο. Στο νέο Project δίνετε το όνομα που επιθυμείτε
    (συνήθως το όνομα του χειρογράφου το οποίο σκοπεύετε  να μεταγράψετε) και ορίζετε το είδος των όψεων που το
    χειρόγραφό σας περιέχει (page ή recto/verso).</p>

    <p>Για να μπορέσετε να μεταγράψετε το κείμενο που επιθυμείτε πρέπει κατ’ αρχάς να δημιουργήσετε τη «συσκευή» (device)
    επί της οποίας υπάρχει το χειρόγραφο κείμενο που πρόκειται να μεταγράψειτε, άρα να δημιουργήσετε εξ αρχής μια
    αυτοματοποιημένη σύνδεση μεταξύ του κειμένου που θα πληκτρολογήσετε και του φυσικού αντικειμένου από το οποίο
    μεταγράφετε.</p>

    <p>Στην συνέχεια πρέπει να δηλώσετε και την «ενότητα φύλλων» (~manuscript) από την οποία προέρχεται το κείμενο που θα
    μεταγράψετε. Με τον τρόπο αυτόν το λογισμικό συνδέει το χειρόγραφο (manuscript) με έναν φορέα (device), με βάση τις
    επιλογές σας.</p>

    <p>Μπορείτε να δηλώσετε και πολλά άλλα στοιχεία που προσδιορίζουν το χειρόγραφο που θέλετε να μεταγράψετε, όπως
    πρόσωπα, ρόλοι, γραφίδες και χείρες (~γραφικοί χαρακτήρες, αν και ο όρος αυτός δεν αποδίδει πλήρως τον όρο
    «χείρα»).</p>

    <p><strong>Η μεταγραφή ενός χειρογράφου αφορά τη μεταφορά αυτού που ο άνθρωπος-παρατηρητής αντιλαμβάνεται ως «χειρόγραφο
    κείμενο», το οποίο και σώζεται σε κάποιον «φορέα»(π.χ. σε έναν κώδικα, σε έναν κύλινδρο ή σε ένα ανεξάρτητο φύλλο).
    Η μεταγραφή ανήκει στις διαδικασίες που τιτλοφορούνται με τον γενικό τίτλο «μετασχηματισμοί» και μέχρι σήμερα δεν
        αποτελούσε καλώς περιγεγραμμένη διαδικασία.</strong></p>

    <p>Χάρη στο λογισμικό ολοκληρωμένης διαχείρισή της η πλατφόρμα Manuscribus οπτικοποιεί το μόνο μέχρι σήμερα φιλικό
    προς τον χρήστη περιβάλλον που υποστηρίζει τη σχεσιακά οργανωμένη διαδικασία μεταγραφής ενός χειρογράφου, η οποία
    και αφορά τα εξής στάδια:</p>

    <ul>
        <li>Χωροταξική αναγνώριση χειρόγραφου κειμένου</li>
        <li>Πληκτρολόγηση του κειμένου στο αντίστοιχο παράθυρο</li>
        <li>Σύνδεση κειμένων μεταξύ τους</li>
        <li>Παρακολούθηση της ροής της πληροφορίας στο παράθυρο Overall info</li>
    </ul>

    <p><strong>Για αναλυτικές πληροφορίες δείτε το αναλυτικό εγχειρίδιο χρήσης <a href="assets/pdf/ManuscribusManual.pdf">εδώ</a>.</strong></p>

</div>
