<h2 mat-dialog-title>{{ titleMessage }}</h2>

<div mat-dialog-content>
    <div fxLayout="row">
        {{ infoMessage }}
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="yesClicked()">Yes</button>
    <button mat-flat-button color="accent" (click)="noClicked()" >No</button>
</div>
