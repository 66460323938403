import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogYesNoComponent } from 'src/app/components/shared/dialog-yes-no/dialog-yes-no.component';
import { Faculty } from 'src/app/models/jena/faculty';
import { FusekiService } from 'src/app/services/fuseki.service';
import { Helpers } from 'src/app/utilities/helpers';
import { DialogFacultyManageComponent } from './dialog-faculty-manage/dialog-faculty-manage.component';

@Component({
    selector: 'app-faculty-list',
    templateUrl: './faculty-list.component.html',
    styleUrls: ['./faculty-list.component.scss']
})
export class FacultyListComponent implements OnInit {
    @BlockUI() _blockUI!: NgBlockUI;
    public entityId!: string | null;

    displayedColumns: string[] = ['name', 'institution', 'department', 'school', 'fullName', 'street', 'city', 'state', 'actions'];
    dataSource: MatTableDataSource<Faculty>;
    public faculties: Array<Faculty>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _fusekiService: FusekiService,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar) {
        this.entityId = this._activatedRoute.snapshot.paramMap.get('entityId');
        this.dataSource = new MatTableDataSource();
        this.faculties = new Array<Faculty>();
    }

    ngOnInit(): void {
        if (Helpers.isNotNullOrUndefined(this.entityId)) {
            this._getFaculties(this.entityId!);
        }
    }

    private _getFaculties(datasetName: string) {
        this._blockUI.start();

        this._fusekiService.getFaculties(datasetName).subscribe({
            next: (result: Array<Faculty>) => {
                this.faculties = result;
                this.dataSource.data = this.faculties;

                this._blockUI.stop();
            },
            error: (error) => {
                this._blockUI.stop();
            }
        });
    }

    public back() {
        this._router.navigate([`edit-entity/${this.entityId}`]);
    }

    public new() {
        const dialogRef = this._dialog.open(DialogFacultyManageComponent, {
            width: '800px',
            data: {
                faculty: new Faculty(),
                entityId: this.entityId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.createFaculty(this.entityId!, result.faculty, result.institution, result.department, result.school).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully created new faculty', '', Helpers.getGreenSnackbarConfig());
                            this._getFaculties(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while creating new faculty', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public delete(faculty: Faculty) {
        const dialogRef = this._dialog.open(DialogYesNoComponent, {
            width: '480px',
            data: {
                titleMessage: `Delete faculty ${faculty.name}`,
                infoMessage: 'Are you sure you want to delete this faculty?'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.deleteFaculty(this.entityId!, faculty).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully deleted faculty', '', Helpers.getGreenSnackbarConfig());
                            this._getFaculties(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while deleting faculty', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public edit(faculty: Faculty) {
        const dialogRef = this._dialog.open(DialogFacultyManageComponent, {
            width: '800px',
            data: {
                faculty: faculty,
                entityId: this.entityId,
                isEdit: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._blockUI.start();

                this._fusekiService.updateFaculty(this.entityId!, result.faculty, result.institution, result.department, result.school).subscribe(
                    {
                        next: (result) => {
                            this._blockUI.stop();
                            this._snackBar.open('Successfully updated faculty', '', Helpers.getGreenSnackbarConfig());
                            this._getFaculties(this.entityId!);
                        },
                        error: (error) => {
                            this._blockUI.stop();
                            console.log("error:", error);
                            this._snackBar.open('Error while updating faculty', '', Helpers.getRedSnackbarConfig());
                        }
                    }

                );
            }
        });
    }

    public view(faculty: Faculty) {
        this._dialog.open(DialogFacultyManageComponent, {
            width: '800px',
            data: {
                faculty: faculty,
                entityId: this.entityId,
                isLocked: true
            }
        });
    }

    public getNameWithoutUri(name: string): string {
        return name?.replace(Helpers.ent_prefix_uri, '');
    }
}
