export class Area {
    name?: string;
    fullName?: string;
    shortName?: string;
    street?: string;
    streetNumber?: string;
    city?: string;
    state?: number;
    zipcode?: number;
}
