<block-ui></block-ui>
<div class="content">
    <mat-card>
        <mat-card-title>
            <h2>Manage Owl</h2>
        </mat-card-title>
        <mat-card-content>
            <div style="display:flex;margin-bottom:10px;">
                <button style="margin-left:auto;" mat-flat-button color="primary" (click)="addParent()">New Root Item</button>
            </div>
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <button mat-icon-button disabled></button>
                  <div [ngClass]="getNodeClass(node.node)" class="nodeItem" (mouseover)="onMouseOverNode(node)">
                    <div class="nodeItemName">{{node.name}}</div>
                    <div class="nodeItemIcons" *ngIf="isMouseOver(node)">
                        <mat-icon (click)="addChild(node)">add_circle</mat-icon>
                        <mat-icon (click)="nodeClicked(node)">edit</mat-icon>
                        <mat-icon (click)="deleteNode(node)">delete</mat-icon>
                    </div>
                  </div>
                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                  <button mat-icon-button
                          [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>
                  <div [ngClass]="getNodeClass(node.node)" class="nodeItem" (mouseover)="onMouseOverNode(node)">
                    <div class="nodeItemName" matTreeNodeToggle>{{node.name}}</div>
                    <div class="nodeItemIcons" *ngIf="isMouseOver(node)">
                        <mat-icon (click)="addChild(node)">add_circle</mat-icon>
                        <mat-icon (click)="nodeClicked(node)">edit</mat-icon>
                        <mat-icon (click)="deleteNode(node)">delete</mat-icon>
                    </div>
                  </div>
                  <mat-progress-bar *ngIf="node.isLoading"
                                    mode="indeterminate"
                                    class="example-tree-progress-bar"></mat-progress-bar>
                </mat-tree-node>
              </mat-tree>
        </mat-card-content>
    </mat-card>
</div>
