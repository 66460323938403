<block-ui></block-ui>
<h2 mat-dialog-title>Info</h2>

<div mat-dialog-content class="content">
    <div class="top">
        <mat-tab-group class="group">
            <mat-tab label="Manus">
                <app-manus-list style="height: 100px;"
                [entityId]="entityId"
                [default]="default?.manus"
                [isSelectionMode]="true"
                (onSelection)="onSelectedManus($event)"
                (onDefaultSelection)="onDefaultSelectedManus($event)"
                ></app-manus-list>
            </mat-tab>
            <mat-tab label="Actor">
                <app-actor-list
                [entityId]="entityId"
                [default]="default?.actor"
                [isSelectionMode]="true"
                (onSelection)="onSelectedActor($event)"
                (onDefaultSelection)="onDefaultSelectedActor($event)"></app-actor-list>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="bottom" style="margin-top: 10px;" *ngIf="showCheckbox()">
        <mat-checkbox (change)="showCheckboxChanged($event)" [(ngModel)]="!selectedShowCharacterInfo">don't show this again</mat-checkbox>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="okClicked()" *ngIf="selectedManus && selectedActor">OK</button>
    <button mat-flat-button color="accent" (click)="cancelClicked()" >Cancel</button>
</div>
