import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CollationService} from 'src/app/services/collation.service';
import HtmlEditor from 'devextreme/ui/html_editor';
import {DxHtmlEditorComponent} from 'devextreme-angular';
import {FusekiService} from 'src/app/services/fuseki.service';
import {catchError, map, tap, throwError} from 'rxjs';
import {EntityRoot} from "../../../models/jena/entityRoot";

@Component({
    selector: 'app-collation',
    templateUrl: './collation.component.html',
    styleUrls: ['./collation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollationComponent implements OnInit {
    @ViewChild(DxHtmlEditorComponent, {static: false}) htmlEditor: DxHtmlEditorComponent | undefined;
    public collationEntities: string[] = [];
    public firstEditorValue: string = '';
    public secondEditorValue: string = '';

    constructor(
        private _collationService: CollationService,
        private _fusekiService: FusekiService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this._collationService.collationFinished.subscribe((data) => {
            console.log(data);
            this.firstEditorValue = data.mostCommonText;
            this.secondEditorValue = data.criticalApparatus;
            this._changeDetectorRef.detectChanges();
        });
    }

    getEditorSelectionIndex() {
        return this.htmlEditor?.instance.getSelection(true).index;
    }

    getEditorSelectionLength() {
        return this.htmlEditor?.instance.getSelection(true).length;
    }

    loadContext() {
        let manuscripts: [] = [];

        this.collationEntities.forEach(manuscript_id =>
            this._fusekiService
                .getAnnotatedContext(manuscript_id)
                .pipe(map((result: any) => {
                    return result;
                }))
                .subscribe({
                    next: (a) => {
                    },
                    error: error => console.log(error)
                }));

        console.log(manuscripts);
    }
}
